export default {
  langs: {
    "zh-CN": "中文",
    en: "英文",
    fr: "法语",
    es: "西班牙语",
    "ru-RU": "俄文",
    hi: "印地语",
    ur: "乌尔都语",
    ja: "日语",
    ko: "韩语",
    th: "泰语",
    de: "德语",
    it: "意大利语",
    ro: "罗马尼亚",
    bn: "孟加拉语",
    vi: "越南语",
    fa: "波斯语",
    ar: "阿拉伯语",
    nl: "荷兰语",
    my: "缅甸语",
    id: "印尼语",
    ms: "马来语",
    pt: "葡萄牙语",
    tr: "土耳其语",
    pl: "波兰语",
    el: "希腊语",
    ne: "尼泊尔语",
    sv: "瑞典语",
    cs: "捷克语",
    hu: "匈牙利语",
    uz: "乌兹别克语"
  },
  responFail: "加载失败",
  responRetry: "点击重试",
  responEmpty: "暂无数据",
  resendMsg: "消息发送失败，点击重试",
  sendImage: "发送图片",
  send: "发送",
  //fastSend: "按下 {key} 快速发送",
  //sendMsg: "发送消息",
  //clickDownload: "点击下载",
  timeAgo: {
    0: "刚刚",
    1: "{n}分钟前",
    2: "{n}小时前",
    3: "昨天"
  },
  copyGroup: "复制成员到新的讨论组",
  quitGroup: "退出讨论组",
  createGroup: "创建讨论组",
  inviteFriend: "邀请好友",
  // web site
  login: "登录",
  loginerror: "登录失败！",
  register: "注册",
  keepOn: "保持登录",
  forgetPwd: "忘记密码?",
  logoutTip: "确定退出登陆",
  resetPwd: "重置登录密码",
  all: "全部",
  // 注册
  phoneErr: "电话号码不能为空",
  phoneformatErr: "电话号码格式不正确",
  needNumber: "请输入数字值",
  registerErr: "注册失败",
  // publish
  publish: "发布",
  group: "群组",
  school: "学校",
  profess: "专业",
  degree: "学位",
  time: "时间",
  desc: '描述',
  mustSchool: "学校不能为空",
  mustCompany: "公司不能为空",
  company: "企业",
  workexp: "工作经历",
  education: "教育经历",
  companyName: "公司",
  job: "职位",
  name: "姓名",
  address: "地址",
  called: "名称",
  title: "标题",
  price: "价格",
  tags: "标签",
  emoji: "表情",
  sendFile: "发送文件",
  transferAccount: "转账",
  image: "图片",
  video: "视频",
  voice: "语音",
  file: "文件",
  permissions:'权限',
  groupRoule: {
    "0": "公开群: 所有用户都可以自由加入",
    "1": "会员群: 用户提交申请后，由群组管理员批复后即可加入",
    "2": "私密群: 在群组搜索中不可见，且只有群组本人才能添加成员"
  },
  man: "男",
  woman: "女",
  mustBeTM: "标题/名称必填",
  mustBeTag: "至少填写一个标签",
  mustBeCate: "所在分类至少选择一个",
  contentTip: "在这里填写详细内容...",
  uploadSuccess: "上传成功！",
  publishError: "发布失败！",
  // footer
  contactus: "联系我们",
  privacy: "隐私条款",
  companyright: "版权所有",

  // 其他
  sys_error: "系统错误！",
  replayEmpty: "评论内容不能为空！",
  replay: "回复",
  replayErr: "评论失败",
  delSuccess: "删除成功",
  defFail: "删除失败",
  refresh: "更新成功",
  updateSuccess: "修改成功,请重新登录",
  old: "旧",
  theNew: "新",
  changeFail: "密码修改失败",
  pwdlength: "密码长度",
  getverchatCode: "获取验证码",
  nopeoplezan: "还没有人点赞，快去成为第一个点赞的人吧！",
  pwdResetSuccess: "密码修改成功",
  email_required: "邮箱不能为空",
  phone_required: "手机号不能为空",
  addfriend: "加好友申请发送成功,等待对方同意",
  Draft: "草稿",
  breakPwd: '找回密码',
  "Request to view history messages": "申请查看历史消息",
  "Wait for group manager to agree": "等待群主同意",
  'Please enter a search term': '请输入搜索关键词',
  done: '确定',
  cancle: '取消',
  groupType: {
    '1': {
      name: '公开群',
      code: '1',
      desc: `公开群:任何用户都可以创建,自由加入的群,不需要审核. 群名字,描述,成员在平台上可以被搜索,浏览。`
    },

    '2': {
      name: '会员群',
      code: '2',
      desc: `会员群:任何用户都可以创建,自由加入的群,需要群主审核. 群名字,描述,成员在平台上可以被搜索,浏览。`
    },
    '3': {
      name: '讨论组',
      code: '3',
      desc: `讨论组:任何用户都可以创建,创建者及成员都可以邀请自己的好友加入,群名字,成员在平台上可以被搜索,浏览。`
    },
    '4': {
      name: '私密群',
      code: '4',
      desc: `私密群:任何用户都可以创建,群名字,描述,群成员在平台上不可以被搜索,浏览。`
    }
  },
  newPwd:'请输入新的登录密码',
  repNewPwd:'请重复输入新密码',
  // 2021年3月4号
  meetingTips: "请先下载melinked App或升级melinked APP到最新版本",
  // mechat协议
  mechat: {
    title: 'Me聊用户协议',
    text1:'Me聊是由Maes Consulting提供的一款AI聊天陪伴问答的产品。用户在同意本用户协议之时，即视为已经同意本用户协议的全部内容。',
    header1: '一．我们如何与第三方共享和披露用户的个人信息',
    text2: '在如下情况下，本应用将依据用户的个人意愿或法律的规定全部或部分的披露用户的个人信息：',
    text3: '1. 经用户事先同意，向第三方披露；',
    text4: '2. 为提供用户所要求的产品和服务，而必须和第三方分享用户的个人信息；',
    text5: '3. 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；',
    text6: '4. 如用户出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；',
    text7: '5. 如用户是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；',
    text8: '6. 其他本应用根据法律、法规或者网站政策认为合适的披露。',
    header2: '二、信息存储和交换',
    text9: 'Me聊收集的有关用户的信息和资料将保存在Me聊及（或）其关联公司的服务器上。在用户使用Me聊的过程中仅发送以及接收的信息会被上传到服务器，用于AI模型的答复。',
    header3: '三、知识产权相关',
    text10:'我方不对用户上传或发布的内容的合法性、正当性、完整性或品质作出任何保证，用户需自行承担因使用或依赖由软件所传送的内容或资源所产生的风险，我方在任何情况下对此种风险可能或实际带来的损失或损害都不负任何责任。',
    header4: '四、法律责任与免责申明',
    text11: '在任何情况下用户因使用或不能使用Me聊所产生的直接、间接、偶然、特殊及后续的损害及风险，我方及合作方不承担任何责任。 因技术故障等不可抗事件影响到服务的正常运行的，我方及合作方承诺在第一时间内与相关单位配合，及时处理进行修复，但用户因此而遭受的一切损失，我方及合作方不承担责任。',
    text12: 'AI模型发出的信息是由计算机生成，可能是虚构的，请用户自行辨别是非。用户在使用本应用咨询医疗、法律等相关问题时，本应用仅提供医疗建议和法律建议，不代表医疗服务法律服务或治疗。用户在使用本应用前必须咨询医生和相关法律人士，对医疗和法律决定负最终责任。本应用的信息及服务应仅供参考，不构成医疗服务法律服务或医疗方案，我们不承担由使用本应用而产生的任何责任。',
  },
  originalMail: "原始邮件",
  emailRecipient: "收件人",
  emailFrom: "发件人",
  CcTo: "抄送人",
  // =========================================5.7.0===================================
  domainVerification: "域名验证",
  verifyDNSConfig: "验证DNS配置",
  txtManualHeaderText: "Me Mail是免费的电子邮件服务平台，您可以在我们的平台上使用您自己的域名(如：melinked.email)，生成邮箱地址使用邮箱服务。在此之前，需要先经过验证，第一步证明该域名所属权属于您，第二步再完成DNS配置验证即可。Me Mail会按照域名验证规则进行验证，以确保您提供的域名在有效期内且非仿冒地址，并且您是域名的所属权人，拥有域名的配置权限。",
  manualHeaderTitle: "通过 TXT 记录验证域名所属权",
  manualHeaderTitleTips: "（图示以国内某域名服务提供商为例）",
  manualContent_1: "前往您的域名(如:melinked.email)服务提供商官网进行登录:",
  manualContentInfo_1: "在登录之前，请确保您拥有此域名的管理员ID和密码，完成登录进入首页。",
  manualContent_2: "添加TXT记录：",
  manualContentInfo_2: "登录服务提供商网站主页后，点击此域名的“Manage”按钮：",
  manualContentInfo_3: "在域名管理页面，找到您需要验证的域名(如:melinked.email)，点击管理选项的解析按钮：",
  manualContentInfo_4: "在域名解析页面，点击“添加记录”按钮：",
  manualContentInfo_5: "返回域名验证页面，复制TXT记录：",
  manualContentInfo_6: "对照TXT记录表格内容，按要求将数据依次填入服务商的记录项里，点击保存：",
  manualContent_3: "完成以上操作后，返回域名页面，点击“验证域名”按钮：",
  manualContent_4: "完成后，请耐心等待验证结果：",
  manualContentInfo_7: "TXT记录验证成功可能需要5分钟到24小时，请耐心等待。如果失败请按照失败提示重新操作即可。",
  manualContent_5: "添加两条验证记录：",
  manualContentInfo_8: "返回DNS验证页面，查看列表中MX、SPF两条记录，并按照不同类型进行内容复制（两条需要全部添加）：",
  manualContentInfo_9: "对照MX、SPF两条记录的内容，按要求将数据依次填入服务商的记录项里，点击保存",
  manualContent_6: "完成以上操作后，返回域名页面，点击“验证DNS配置”按钮：",
  manualContentInfo_10: "验证成功可能需要5分钟到24小时，请耐心等待。如果失败请按照失败提示重新操作即可。",
  verificationSuccess: "域名验证配置成功，欢迎使用！",
  largeAttachment: "超大附件",

};