var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wap-gigitem",
      on: {
        click: function($event) {
          return _vm.handlerToDetail(_vm.item.groupId)
        }
      }
    },
    [
      _vm.type == "group"
        ? _c("Thumb", {
            attrs: {
              src: _vm._f("checkUrl")(_vm.item.groupHeader),
              width: "110px",
              height: "90px",
              margin: "0"
            }
          })
        : _c("Thumb", {
            attrs: {
              src: _vm._f("checkUrl")(_vm.item.profilePhoto),
              width: "110px",
              height: "90px",
              margin: "0"
            }
          }),
      _c("span", { staticClass: "wap-gigitem--title" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.handlerSliceTitle(_vm.item.name, _vm.length)) +
            "\n  "
        )
      ]),
      _c("i", {
        class: {
          icon: true,
          "me-icon-group": _vm.type == "group",
          "me-icon-company": _vm.type == "company"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }