var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.item.type == "user"
      ? _c("div", [
          _vm.item.users && _vm.item.users.length
            ? _c(
                "div",
                { staticClass: "providerPerson" },
                [
                  _vm._l(_vm.item.users, function(item, inx) {
                    return _c(
                      "div",
                      {
                        key: inx,
                        staticClass: "personItem",
                        on: {
                          click: function($event) {
                            return _vm.handler2Detail("user", item.providerId)
                          }
                        }
                      },
                      [
                        _c("Avatar", {
                          attrs: {
                            src: item.profilePhoto,
                            width: "65px",
                            height: "65px",
                            round: true,
                            id: item.id,
                            size: "30px"
                          }
                        }),
                        _c("div", { staticClass: "userName" }, [
                          _vm._v(_vm._s(item.name))
                        ]),
                        _c("div", {
                          staticClass: "aboutMe",
                          domProps: { innerHTML: _vm._s(item.aboutMe) }
                        }),
                        _c("div", { staticClass: "skills" }, [
                          _c("div", { staticClass: "labelItem" }, [
                            _c("img", {
                              staticStyle: { width: "20px", height: "20px" },
                              attrs: {
                                src: require("../../../assets/fontImg/tag2.png"),
                                alt: ""
                              }
                            }),
                            _c("span", [
                              _vm._v(_vm._s(item.skills.split(",")[0]))
                            ])
                          ])
                        ])
                      ],
                      1
                    )
                  }),
                  _c("div", { staticClass: "personItem" }, [
                    _vm.item.users && _vm.item.users.length == 4
                      ? _c("i", {
                          staticClass: "me-icon-more-dian icon-more user ",
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "list",
                                params: { type: "user" }
                              })
                            }
                          }
                        })
                      : _vm._e()
                  ])
                ],
                2
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm.item.type == "group"
      ? _c("div", [
          _vm.item.groups && _vm.item.groups.length
            ? _c(
                "div",
                { staticClass: "recommendGroups" },
                [
                  _vm._l(_vm.item.groups, function(item, inx) {
                    return _c(
                      "div",
                      {
                        key: inx,
                        staticClass: "groupItem",
                        on: {
                          click: function($event) {
                            return _vm.handler2Detail("group", item.groupId)
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "100%", height: "132px" },
                          attrs: {
                            src: _vm._f("checkUrl")(item.groupHeader),
                            alt: ""
                          }
                        }),
                        _c("div", { staticClass: "groupName" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.groupName) +
                              "\n        "
                          )
                        ]),
                        _c("div", {
                          staticClass: "groupInfo",
                          domProps: { innerHTML: _vm._s(item.groupInfo) }
                        })
                      ]
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "groupItem",
                      staticStyle: {
                        "align-items": "center",
                        "justify-content": "center"
                      }
                    },
                    [
                      _vm.item.groups && _vm.item.groups.length == 4
                        ? _c("i", {
                            staticClass: "me-icon-more-dian icon-more user ",
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "list",
                                  params: { type: "group" }
                                })
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ],
                2
              )
            : _vm._e()
        ])
      : _vm._e(),
    !_vm.item.type
      ? _c(
          "div",
          { staticClass: "item" },
          [
            _c("Author", { ref: "author", attrs: { item: _vm.author } }),
            _c(
              "div",
              {
                on: {
                  click: function($event) {
                    return _vm.handlerToDetail(_vm.item)
                  }
                }
              },
              [
                _vm.item.detail
                  ? [
                      JSON.parse(_vm.item.detail)[0] &&
                      JSON.parse(_vm.item.detail)[0].key == "text"
                        ? _c("div", [
                            _c("div", {
                              staticStyle: { padding: "10px 0" },
                              domProps: {
                                innerHTML: _vm._s(
                                  JSON.parse(_vm.item.detail)[0] &&
                                    JSON.parse(_vm.item.detail)[0].content
                                )
                              }
                            })
                          ])
                        : _vm._e(),
                      JSON.parse(_vm.item.detail)[0] &&
                      JSON.parse(_vm.item.detail)[0].key == "image"
                        ? _c("div", [
                            _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                src:
                                  JSON.parse(_vm.item.detail)[0] &&
                                  JSON.parse(_vm.item.detail)[0].content,
                                alt: ""
                              }
                            })
                          ])
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm.item.attachments.length > 0
                  ? _c(
                      "div",
                      _vm._l(_vm.item.attachments, function(atta, inx) {
                        return _c(
                          "div",
                          { key: inx, staticClass: "attaItem" },
                          [
                            atta.type == 3
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pictrueContainer",
                                      staticStyle: {
                                        width: "300px",
                                        height: "200px"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          "max-width": "100%",
                                          height: "100%",
                                          "border-radius": "6px",
                                          "object-fit": "cover"
                                        },
                                        attrs: { src: atta.path, alt: "" }
                                      })
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            atta.type == 1
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "videoAttachments",
                                      staticStyle: {
                                        height: "214px",
                                        width: "100%",
                                        background: "#999"
                                      }
                                    },
                                    [
                                      _c("video", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          "border-radius": "6px",
                                          "object-fit": "contain"
                                        },
                                        attrs: { src: atta.path, controls: "" }
                                      })
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            atta.type == 4
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      background: "#f5f5f5",
                                      width: "fit-content",
                                      padding: "10px",
                                      "border-radius": "6px",
                                      color: "#373737",
                                      "font-weight": "700"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.toDoload(atta)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(atta.name) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.item.thumb
                  ? _c("Thumb", {
                      staticStyle: {
                        "max-width": "100%",
                        "max-height": "200px",
                        "border-radius": "6px",
                        overflow: "hidden"
                      },
                      attrs: {
                        width: "auto",
                        height: "auto",
                        src: _vm.item.thumb
                      }
                    })
                  : _vm._e()
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }