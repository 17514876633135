export default {
    state: {
        userinfo: localStorage.getItem("userinfo") ? JSON.parse(localStorage.getItem("userinfo")) : {}
    },
    mutations: {
        setUserinfo(state, data) {
            state.userinfo = data;
        }
    },
    actions: {
        commitUserinfo: ({
            commit
        }, data) => {
            window.localStorage.setItem("userinfo", JSON.stringify(data));
            commit("setUserinfo", data);
        }
    },
    getters: {
        getUserinfo(state) {
            return state.userinfo;
        }
    }
};