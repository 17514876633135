<template>
  <div>
    <!-- 推荐的用户 -->
    <div v-if="item.type == 'user'">
      <div class="providerPerson" v-if="item.users && item.users.length">
        <div
          v-for="(item, inx) in item.users"
          :key="inx"
          class="personItem"
          @click="handler2Detail('user', item.providerId)"
        >
          <Avatar
            :src="item.profilePhoto"
            width="65px"
            height="65px"
            :round="true"
            :id="item.id"
            size="30px"
          />
          <div class="userName">{{ item.name }}</div>
          <div class="aboutMe" v-html="item.aboutMe"></div>
          <div class="skills">
            <div class="labelItem">
              <img
                src="../../../assets/fontImg/tag2.png"
                style="width:20px;height:20px"
                alt=""
              />
              <span>{{ item.skills.split(",")[0] }}</span>
            </div>
          </div>
        </div>
        <div class="personItem">
          <i
            @click="$router.push({ name: 'list', params: { type: 'user' } })"
            class="me-icon-more-dian icon-more user "
            v-if="item.users && item.users.length == 4"
          ></i>
        </div>
      </div>
    </div>
    <!-- 推荐的群 -->
    <div v-if="item.type == 'group'">
      <div class="recommendGroups" v-if="item.groups && item.groups.length">
        <div
          v-for="(item, inx) in item.groups"
          :key="inx"
          class="groupItem"
          @click="handler2Detail('group', item.groupId)"
        >
          <img
            :src="item.groupHeader | checkUrl"
            style="width:100%;height: 132px"
            alt=""
          />
          <div class="groupName">
            {{ item.groupName }}
          </div>
          <div class="groupInfo" v-html="item.groupInfo"></div>
        </div>
        <div
          class="groupItem"
          style=" align-items: center;justify-content: center;"
        >
          <i
            @click="$router.push({ name: 'list', params: { type: 'group' } })"
            class="me-icon-more-dian icon-more user "
            v-if="item.groups && item.groups.length == 4"
          ></i>
        </div>
      </div>
    </div>
    <!-- 帖子 -->
    <div v-if="!item.type" class="item">
      <Author :item="author" ref="author"></Author>
      <div class="" @click="handlerToDetail(item)">
        <template v-if="item.detail">
          <div
            v-if="
              JSON.parse(item.detail)[0] &&
                JSON.parse(item.detail)[0].key == 'text'
            "
          >
            <div
              v-html="
                JSON.parse(item.detail)[0] && JSON.parse(item.detail)[0].content
              "
              style="padding:10px 0"
            ></div>
          </div>
          <div
            v-if="
              JSON.parse(item.detail)[0] &&
                JSON.parse(item.detail)[0].key == 'image'
            "
          >
            <img
              style="width:100%"
              :src="
                JSON.parse(item.detail)[0] && JSON.parse(item.detail)[0].content
              "
              alt=""
            />
          </div>
        </template>
        <!-- {{item.attachments}} -->
        <div v-if="item.attachments.length > 0">
          <div
            v-for="(atta, inx) in item.attachments"
            :key="inx"
            class="attaItem"
          >
            <!-- 图片 -->
            <div v-if="atta.type == 3">
              <div
                class="pictrueContainer"
                style="width: 300px; height: 200px;"
              >
                <img
                  style="max-width: 100%;height: 100%; border-radius: 6px; object-fit: cover;"
                  :src="atta.path"
                  alt=""
                />
              </div>
            </div>
            <!-- 视频 -->
            <div v-if="atta.type == 1">
              <div
                class="videoAttachments"
                style="height: 214px;width: 100%;background: #999;"
              >
                <video
                  :src="atta.path"
                  style="width: 100%; height: 100%; border-radius: 6px;  object-fit: contain;"
                  controls
                ></video>
              </div>
            </div>
            <!-- 文件 -->
            <div
              v-if="atta.type == 4"
              style="background:#f5f5f5;width:fit-content;padding:10px;border-radius:6px;color:#373737;font-weight:700"
              @click.stop="toDoload(atta)"
            >
              {{ atta.name }}
            </div>
          </div>
        </div>

        <Thumb
          style="max-width:100%;max-height:200px;border-radius:6px;overflow:hidden"
          v-if="item.thumb"
          width="auto"
          height="auto"
          :src="item.thumb"
        ></Thumb>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "@/components/common/avatar";
import Thumb from "@/components/common/thumb";
import Action from "@/components/business/h5/actions";
import Author from "@/components/business/author";

export default {
  name: "serviceItem",
  data() {
    return {
      author: {},
      inx: 0,
    };
  },
  components: {
    Avatar,
    Action,
    Thumb,
    Author,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          avatar: "",
          name: "test",
          time: 1572501968171,
          thumb: "",
          title: "这是测试dome",
          tags: ["adfasdf", "adfasfasdf", "dfasodfjasdofjasdfasdfdsfasdif"],
        };
      },
    },
  },
  watch: {
    item: {
      handler(val) {
        const {
          name,
          time,
          avatar,
          price,
          profilePhoto,
          publishTime,
          providerId,
        } = val;
        this.author = {
          name,
          id: providerId,
          time,
          avatar,
          price,
          publishTime,
        };
        if (!this.author.avatar) {
          this.author.avatar = profilePhoto;
        }
      },
      deep: true,
    },
  },
  filters: {
    checkUrl(url) {
      let purl = url;
      if (url && url.indexOf("http") == -1) {
        purl = "https://pic.melinked.com/" + url;
      }
      return purl;
    },
  },
  created() {
    const {
      name,
      id,
      time,
      avatar,
      price,
      profilePhoto,
      publishTime,
      providerId,
    } = this.item;

    this.author = {
      name,
      id: providerId,
      time,
      avatar,
      price,
      publishTime,
    };
    if (!this.author.avatar) {
      this.author.avatar = profilePhoto;
    }
  },
  mounted() {},
  computed: {
    itemInfo() {
      return this.item;
    },
  },
  methods: {
    handlerToDetail(item) {
      this.$router.push({
        name: "detail",
        params: { id: "pai_" + Base64.encode(item.gigId), type: "service" },
      });
    },
    handler2Detail(type, id) {
      id = "pai_" + Base64.encode(id);
      this.$router.push({
        name: type === "user" ? "user" : type === "group" ? "group" : "detail",
        params: { type: type === "company" ? "enterprise" : type, id },
      });
    },
    toDoload(atta) {
      var a = document.createElement("a"); //创建一个<a></a>标签
      a.href = atta.path; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
      a.download = atta.name; //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
      a.style.display = "none"; // 障眼法藏起来a标签
      document.body.appendChild(a); // 将a标签追加到文档对象中
      a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove();
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index.styl';

+b(item) {
  display: flex;
  flex-flow: column;
  padding: 1rem 0.5rem;
  border-bottom: 10px solid #f0f0f0;
  overflow: hidden;

  +m(actions) {
    margin-top: 0.5rem;
  }

  +e(content) {
    display: flex;
    flex-flow: row;
    margin-top: 0.5rem;

    .me-thumb {
      margin-right: 0.5rem;
    }

    +m(right) {
      flex: 1;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .title {
        font-size: 16px;
        color: #333333;
        font-weight: 600;
        height: 24px;
        text-align: left;
        // display: inline-table;
      }

      .tags {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;

        // margin-top: 0.3rem;
        span {
          font-size: 12px;
          margin: 0.25rem 0.25rem 0 0;
          // color: #999999;
        }
      }
    }
  }
}

.attaItem{
  margin-top: 10px;
  overflow hidden
}
</style>
