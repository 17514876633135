var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ul", { staticClass: "item-action" }, [
        _c("li", { staticClass: "zan" }, [
          _c("i", {
            class: _vm.actionInfo.likeState
              ? "me-icon-zanSolid"
              : "me-icon-zan",
            on: {
              click: function($event) {
                return _vm.zanFun()
              }
            }
          }),
          parseInt(_vm.actionInfo.zanNum)
            ? _c("span", {
                domProps: { innerHTML: _vm._s(_vm.actionInfo.zanNum) },
                on: {
                  click: function($event) {
                    return _vm.getLikeList($event)
                  }
                }
              })
            : _c("span", {
                domProps: { innerHTML: _vm._s(_vm.actionInfo.zanNum) }
              })
        ]),
        _c("li", [
          _c("i", {
            staticClass: "me-icon-edit",
            domProps: { innerHTML: _vm._s(_vm.actionInfo.replyCount) },
            on: {
              click: function($event) {
                return _vm.replayFunc()
              }
            }
          })
        ]),
        _vm.showChat || _vm.actionInfo.type === "user"
          ? _c(
              "li",
              {
                staticClass: "chat",
                on: {
                  click: function($event) {
                    return _vm.handlerChatWith(
                      { name: _vm.actionInfo.name, id: _vm.actionInfo.id },
                      "chat"
                    )
                  }
                }
              },
              [_c("i", { staticClass: "me-icon-chat-dot" })]
            )
          : _c(
              "li",
              {
                staticClass: "share",
                on: {
                  click: function($event) {
                    return _vm.handlerShareClick($event)
                  }
                }
              },
              [_c("i", { staticClass: "me-icon-share" })]
            )
      ]),
      _c(
        "van-dialog",
        {
          attrs: {
            closeOnClickOverlay: "",
            showConfirmButton: false,
            closeOnPopstate: true
          },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        _vm._l(_vm.likeList, function(item, index) {
          return _c(
            "van-cell",
            { key: index, staticClass: "userlist" },
            [
              _c("Avatar", {
                attrs: {
                  width: "50px",
                  height: "50px",
                  src: item.userAvatar,
                  id: item.providerId
                }
              }),
              _c("span", [_vm._v(_vm._s(item.userName))])
            ],
            1
          )
        }),
        1
      ),
      _c("myShare", {
        attrs: { show: _vm.shareStatus, data: _vm.actionData },
        on: { close: _vm.handlerShareClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }