import Vue from "vue";
import VueI18n from "vue-i18n";
import {
  Locale,
} from 'vant';
import lang from "./lang";
import {
  get
} from "../utils/cache";

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: get("locale") || "zh-CN",
  messages: lang,
  silentFallbackWarn: true,
});
Locale.use(get("locale"), lang[get("locale")])

// ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n;