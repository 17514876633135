<template>
  <div>
    <ul class="item-action">
      <li class="zan">
        <i
          @click="zanFun()"
          :class="actionInfo.likeState ? 'me-icon-zanSolid' : 'me-icon-zan'"
        ></i>
        <span
          v-html="actionInfo.zanNum"
          v-if="parseInt(actionInfo.zanNum)"
          @click="getLikeList($event)"
        ></span>
        <span v-else v-html="actionInfo.zanNum"></span>
      </li>
      <li>
        <i
          class="me-icon-edit"
          v-html="actionInfo.replyCount"
          @click="replayFunc()"
        ></i>
      </li>
      <li class="chat" v-if="showChat || actionInfo.type === 'user'" @click="handlerChatWith({name:actionInfo.name,id:actionInfo.id},'chat')">
        <i class="me-icon-chat-dot"></i>
        <!-- @click="chatWithAuthor(actionInfo.userinfo, 'user')" -->
      </li>
      <li class="share" v-else @click="handlerShareClick($event)">
        <i class="me-icon-share"></i>
      </li>
    </ul>
    <van-dialog v-model="showDialog" closeOnClickOverlay :showConfirmButton="false" :closeOnPopstate="true">
      <van-cell class="userlist" v-for="(item, index) in likeList" :key="index">
        <Avatar width="50px" height="50px" :src="item.userAvatar" :id="item.providerId"/>
        <span>{{item.userName}}</span>
      </van-cell>
    </van-dialog>
    <myShare
      :show="shareStatus"
      @close="handlerShareClose"
      :data="actionData"
    />
  </div>
</template>
<script>
import myShare from "@/components/common/share";
import Avatar from "@/components/common/avatar";
import { Base64 } from "js-base64";
export default {
  name: "action",
  data() {
    return {
      actionInfo: {},
      vshareConfig: {},
      showDialog: false,
      likeList: [],
      route_name: "",
      shareStatus: false
    };
  },
  props: {
    actionData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showChat: {
      type: Boolean,
      default: false
    }
  },
  components: {
    myShare,
    Avatar
  },
  computed: {
    watchActionData() {
      return this.actionData;
    }
  },
  watch: {
    watchActionData: {
      handler(val, old) {
        this.actionInfo = val;
      },
      dreep: true
    }
  },
  created() {
    this.actionInfo = this.actionData;
  },
  methods: {
    handlerChatWith(user,type){
      const {name,id} = user
      const chatObj = {
        id,
        name,
        type
      }
      localStorage.setItem('chatObj',JSON.stringify(chatObj))
      this.$router.push({
        name:'chatWith',
        params:{
          id:"pai_" + Base64.encode(id),
          type:type
        }
      })
    },
    handlerShareClick(event) {
      event.stopPropagation();
      this.shareStatus = true;
    },
    handlerShareClose() {
      this.shareStatus = false;
    },
    closeHandler() {
      this.showDialog = false;
    },
    async getLikeList(event) {
      event.stopPropagation();

      let id = this.actionInfo.id ? this.actionInfo.id : this.actionInfo.pkId;
      let url = `/rest/home/like/${id}/list`;
      let params = {
        language: localStorage.getItem("langCode") || "1",
        type: typeof this.actionData.type ==='string' && this.actionData.type.length > 2 ? this.transTypeToNumber(this.actionData.type) : this.actionData.type || 1
      };
      let result = await this.$http({
        url,
        params,
        withCredentials: false
      }).catch(e => {});
      if (result) {
        this.likeList = result.data.likeList;
        this.showDialog = true;
        
      }
    },
    replayFunc() {
      if (this._checkUserLogin()) {
        // 评论
        let name = "detail";
        const params = {
          id: this.actionInfo.id,
          replay: 1
        };
        if (this.actionInfo.type) {
          if (this.actionInfo.type !== "enterprise" && this.actionInfo.type !== 'company' && this.actionInfo.type !== 'service')
            name = this.actionInfo.type === "group" ? "group" : "user";
          params.type = this.actionInfo.type === 'company' ? 'enterprise' : this.actionInfo.type;
        } else {
          params.type = "service";
        }
        if(name ==='user') params.active = 3
        this.$router.push({ name, params });
      }
    },
      transTypeToNumber(type){
      let num = 1
      switch(type){
        case 'user':
          num = 3
          break
        case 'group':
          num = 4
          break
        case 'enterprise':
        case 'company':
          num = 5
        break;
      }
      return num
    },
    async zanFun() {
      if (this._checkUserLogin()) {
        let params = {
          userId: this.$store.state.User.userinfo.id,
          language: localStorage.getItem("langCode") || "1",
          action: !this.actionInfo.likeState,
          type: typeof this.actionData.type ==='string' && this.actionData.type.length > 2 ? this.transTypeToNumber(this.actionData.type) : this.actionData.type || 1
        };
        let baseUrl = "rest/home/like/";
        let url = this.actionInfo.pkId
          ? baseUrl + this.actionInfo.pkId
          : baseUrl + this.actionInfo.id;
        let result = await this.$http({
          url,
          params,
          withCredentials: false,
          method: "post"
        }).catch(e => {});
        if (result) {
          this.actionInfo.zanNum = parseInt(this.actionInfo.zanNum);
          this.actionInfo.zanNum = params.action
            ? (this.actionInfo.zanNum += 1)
            : (this.actionInfo.zanNum -= 1);
          this.actionInfo.likeState = !this.actionInfo.likeState;
        }
      }
    },
    _checkUserLogin() {
      // return this.$store.state.webStore.userinfo.id ? true : false;
      if (!this.$store.state.User.userinfo.id) {
        this.$router.push({ name: "login" });
        return false;
      }
      return true;
    }
  }
};
</script>
<style lang="stylus">
.userlist{
  .van-cell__value{
    display flex;
    align-items center;
  }
}
.shareItem {
  // padding: 0 !important;
  .social-share {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    .icon-wechat {
      h4 {
        display: none;
      }

      .qrcode {
        margin: 0;
      }

      .wechat-qrcode {
        width: 110px;
        height: 110px;
        top: -130px;
        left: -50px;
        text-align: center;
        padding: 5px;

        img {
          margin: 0;
        }
      }
    }

    a {
      margin: 0 !important;
      padding: 0;
      min-width: 32px;
    }

    i {
      font-size: 0.98rem;
      color: #999999;
      cursor: pointer;

      &:hover {
        color: #33cc66;
      }
    }
  }
}

.item-action {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;

  li {
    margin: 0 0.25rem;
    color: #666;
    position: relative;
    min-width: 50px;

    &.share, &.chat {
      min-width: auto;

      span {
        line-height: 18px !important;
        font-size: 1px !important;
      }
    }

    &.chat {
      color: #33cc66;
    }

    &:hover {
      color: #999;
    }

    &.zan {
      span {
        font-size: 13px !important;
        cursor: pointer;

        &:hover {
          color: #33cc66;
          text-decoration: underline;
        }
      }
    }

    i {
      font-size: 13px !important;

      &:before {
        margin-right: 0.45rem;
        font-size: 18px;
        cursor: pointer;
      }

      &.me-icon-chat {
        color: #33cc66;
      }

      &.me-icon-zanSolid:before {
        color: #fe0657;
      }
    }
  }
}
</style>
