export default {
    langs: {
        "zh-CN": "چینی ها",
        en: "انگلیسی",
        fr: "فرانسوی",
        es: "اسپانیایی",
        "ru-RU": "روسی",
        hi: "هندی",
        ur: "اردو",
        ja: "ژاپنی",
        ko: "کره ای",
        th: "تایلندی",
        de: "آلمانی",
        it: "ایتالیایی",
    },
    responFail: "بارگیری ناموفق بود",
    responRetry: "سعی مجدد را کلیک کنید",
    responEmpty: "اطلاعاتی وجود ندارد",
    resendMsg: "پیام ارسال نشد، برای امتحان مجدد کلیک کنید",
    sendImage: "عکسها را بفرست",
    send: "ارسال",
    //fastSend: "برای ارسال سریع، {کلید} را فشار دهید",
    //sendMsg: "ارسال یک پیام",
    //clickDownload: "برای دانلود کلیک کنید",
    timeAgo: {
        0: "فقط",
        1: "{n} دقیقه قبل",
        2: "{n} ساعت قبل",
        3: "دیروز",
    },
    copyGroup: "اعضا را در گروه بحث جدید کپی کنید",
    quitGroup: "گروه بحث را ترک کنید",
    createGroup: "ایجاد گروه بحث",
    inviteFriend: "دوستان را دعوت کنید",
    // web site
    login: "وارد شدن",
    loginerror: "ورود ناموفق بود!",
    register: "ثبت نام",
    keepOn: "وارد سیستم بمانید",
    forgetPwd: "رمز عبور را فراموش کرده اید؟",
    logoutTip: "خوب برای خروج از سیستم",
    resetPwd: "بازنشانی رمز ورود به سیستم",
    all: "همه",
    // 注册
    phoneErr: "شماره تلفن نمی تواند خالی باشد",
    phoneformatErr: "قالب شماره تلفن نادرست است",
    needNumber: "لطفا یک مقدار عددی وارد کنید",
    registerErr: "ثبت نام انجام نشد",
    // publish
    publish: "رهایی",
    group: "گروه",
    school: "مدرسه",
    profess: "عمده",
    degree: "لیسانس علوم",
    time: "زمان",
    desc: "توصیف کردن",
    mustSchool: "مدرسه نمی تواند خالی باشد",
    mustCompany: "شرکت نمی تواند خالی باشد",
    company: "شرکت، پروژه",
    workexp: "تجربه کاری",
    education: "سوابق آموزشی",
    companyName: "شرکت",
    job: "موقعیت",
    name: "نام",
    address: "نشانی",
    called: "نام",
    title: "عنوان",
    price: "قیمت",
    tags: "برچسب",
    emoji: "اصطلاح",
    sendFile: "ارسال فایل",
    transferAccount: "انتقال",
    image: "تصویر",
    video: "ویدئو",
    voice: "صدا",
    file: "سند",
    permissions: "مجوزها",
    groupRoule: {
        "0": "گروه عمومی: همه کاربران برای عضویت آزاد هستند",
        "1": "گروه اعضا: پس از ارسال درخواست توسط کاربر، پس از تایید مدیر گروه می تواند به عضویت درآید",
        "2": "گروه های خصوصی: در جستجوی گروهی قابل مشاهده نیست و فقط گروه می تواند اعضا اضافه کند",
    },
    man: "نر",
    woman: "زن",
    mustBeTM: "عنوان/نام الزامی است",
    mustBeTag: "حداقل یک تگ را پر کنید",
    mustBeCate: "حداقل یک دسته را انتخاب کنید",
    contentTip: "مشخصات را اینجا پر کنید...",
    uploadSuccess: "با موفقیت آپلود شد!",
    publishError: "انتشار ناموفق بود!",
    // footer
    contactus: "با ما تماس بگیرید",
    privacy: "سیاست حفظ حریم خصوصی",
    companyright: "تمامی حقوق محفوظ است",
    // 其他
    sys_error: "خطای سیستم!",
    replayEmpty: "محتوای نظر نمی تواند خالی باشد!",
    replay: "پاسخ",
    replayErr: "نظر ناموفق بود",
    delSuccess: "با موفقیت حذف شد",
    defFail: "حذف نشد",
    refresh: "به روز رسانی کامل شد",
    updateSuccess: "اصلاح با موفقیت انجام شد، لطفا دوباره وارد شوید",
    old: "قدیمی",
    theNew: "جدید",
    changeFail: "تغییر رمز عبور انجام نشد",
    pwdlength: "طول رمز عبور",
    getverchatCode: "دریافت کد تایید",
    nopeoplezan: "هنوز کسی آن را دوست ندارد، اولین کسی باشید که آن را دوست دارید!",
    pwdResetSuccess: "بازنشانی رمز عبور کامل شد",
    email_required: "ایمیل نمی تواند خالی باشد",
    phone_required: "شماره تلفن همراه نمی تواند خالی باشد",
    addfriend: "برنامه افزودن دوستان با موفقیت ارسال شد و منتظر موافقت طرف مقابل بود",
    Draft: "پیش نویس",
    breakPwd: "بازیابی رمز عبور",
    "Request to view history messages": "برای مشاهده اخبار تاریخی اقدام کنید",
    "Wait for group manager to agree": "منتظر بمانید تا مالک گروه موافقت کند",
    'Please enter a search term': "کلمات کلیدی جستجو را وارد کنید",
    done: "مطمئن",
    cancle: "لغو کنید",
    groupType: {
        '1': {
            name: "گروه عمومی",
            code: "1",
            desc: "`گروه باز: هر کاربری می تواند گروهی ایجاد کند که بدون بررسی به آن رایگان بپیوندد. نام گروه، اعضا را توصیف می کند و می تواند در پلتفرم جستجو و مرور شود. `",
        },
        '2': {
            name: "گروه عضو",
            code: "2",
            desc: "`گروه اعضا: هر کاربری می تواند گروهی ایجاد کند که می تواند آزادانه به آن ملحق شود و نیاز به بررسی توسط مالک گروه دارد. نام گروه اعضا را توصیف می کند و می تواند در پلتفرم جستجو و مرور شود. `",
        },
        '3': {
            name: "گروه بحث",
            code: "3",
            desc: "`گروه بحث: هر کاربری می تواند سازندگان ایجاد کند و اعضا می توانند دوستان خود را برای پیوستن دعوت کنند نام اعضای گروه را می توان در پلت فرم جستجو و مرور کرد. `",
        },
        '4': {
            name: "گروه خصوصی",
            code: "4",
            desc: "`گروه خصوصی: هر کاربری می تواند یک نام گروه برای توصیف اعضای گروه ایجاد کند و نمی توان آن را در پلتفرم جستجو و مرور کرد. `",
        }
    },
    newPwd: "لطفا یک رمز ورود جدید وارد کنید",
    repNewPwd: "لطفا رمز عبور جدید را دوباره وارد کنید",
    // 2021年3月4号
    meetingTips: "لطفاً ابتدا برنامه melinked را دانلود کنید یا برنامه melinked را به آخرین نسخه ارتقا دهید",
    // mechat协议
    mechat: {
        title: "قرارداد کاربر من چت",
        text1: "Mechat یک محصول پرسش و پاسخ همراه چت هوش مصنوعی است که توسط Maes Consulting ارائه شده است. هنگامی که کاربر با این قرارداد کاربر موافقت می کند، به نظر می رسد که با کل محتوای این قرارداد کاربر موافقت کرده است.",
        header1: "یکی نحوه اشتراک گذاری و افشای اطلاعات شخصی کاربران با اشخاص ثالث",
        text2: "تحت شرایط زیر، این برنامه اطلاعات شخصی کاربر را به طور کامل یا جزئی بر اساس خواسته های شخصی کاربر یا مقررات قانونی فاش می کند:",
        text3: "1. با رضایت قبلی کاربر برای اشخاص ثالث افشا شده است.",
        text4: "2. برای ارائه محصولات و خدمات درخواستی کاربر، لازم است اطلاعات شخصی کاربر با شخص ثالث به اشتراک گذاشته شود.",
        text5: "3- طبق مقررات مربوط قانون یا الزامات دستگاههای اداری یا قضایی به اشخاص ثالث یا دستگاههای اداری یا قضایی افشاء شود.",
        text6: "4. اگر کاربر قوانین و مقررات مربوطه چین یا این قرارداد خدمات برنامه کاربردی یا قوانین مرتبط را نقض کند، باید به شخص ثالث افشا شود.",
        text7: "5. اگر کاربر شاکی واجد شرایط مالکیت معنوی باشد و شکایتی را ثبت کرده باشد، باید آن را به درخواست پاسخ دهنده برای پاسخگو فاش کند تا هر دو طرف بتوانند به اختلافات حقوقی احتمالی رسیدگی کنند.",
        text8: "6. سایر موارد افشایی که این برنامه مطابق با قوانین، مقررات یا سیاست های وب سایت مناسب می داند.",
        header2: "2. ذخیره و تبادل اطلاعات",
        text9: "اطلاعات و داده های مربوط به کاربران جمع آوری شده توسط MeChat در سرورهای MeChat و (یا) شرکت های وابسته به آن ذخیره می شود. تنها اطلاعات ارسالی و دریافتی در فرآیند چت با من در سرور آپلود می شود که برای پاسخگویی به مدل هوش مصنوعی استفاده می شود.",
        header3: "3. مربوط به مالکیت معنوی",
        text10: "ما هیچ تضمینی برای قانونی، مشروعیت، یکپارچگی یا کیفیت محتوای آپلود یا منتشر شده توسط کاربران نداریم. کاربران باید خطرات ناشی از استفاده یا اتکا به محتوا یا منابع ارسال شده توسط نرم افزار را متحمل شوند. در هر صورت، ما باید not مسئولیتی در قبال هر گونه ضرر و زیان احتمالی یا واقعی ناشی از چنین خطری ندارد.",
        header4: "4. مسئولیت قانونی و سلب مسئولیت",
        text11: "در هر صورت، ما و شرکای ما هیچ مسئولیتی در قبال خسارات و خطرات مستقیم، غیرمستقیم، اتفاقی، خاص و بعدی ناشی از استفاده یا ناتوانی کاربران در استفاده از من چت نداریم. در صورتی که عملکرد عادی سرویس تحت تاثیر حوادث فورس ماژور مانند نقص فنی قرار گیرد، ما و همکارانمان قول می دهیم در اسرع وقت با واحدهای مربوطه برای رسیدگی و تعمیر به موقع آنها همکاری کنیم.بدون مسئولیت.",
        text12: "اطلاعات ارسال شده توسط مدل هوش مصنوعی توسط کامپیوتر تولید می شود و ممکن است ساختگی باشد.از کاربران خواسته می شود که درست را از غلط تشخیص دهند. هنگامی که کاربران از این برنامه برای مشاوره پزشکی، حقوقی و سایر موارد مرتبط استفاده می کنند، این برنامه فقط مشاوره پزشکی و مشاوره حقوقی ارائه می دهد و خدمات پزشکی، خدمات حقوقی یا درمان را ارائه نمی دهد. کاربران قبل از استفاده از این اپلیکیشن باید با پزشکان و اشخاص حقوقی مربوطه مشورت کنند و در نهایت مسئولیت تصمیمات پزشکی و حقوقی را بر عهده دارند. اطلاعات و خدمات موجود در این برنامه فقط برای مرجع هستند و خدمات قانونی یا راه حل های پزشکی برای خدمات پزشکی را تشکیل نمی دهند و ما هیچ مسئولیتی را که ناشی از استفاده از این برنامه باشد بر عهده نمی گیریم.",
    },
    originalMail: "پیام اصلی",
    emailRecipient: "گیرنده",
    emailFrom: "فرستنده",
    CcTo: "CC",
};
