<template>
  <van-image fit="cover" v-if="src" :src="formartImageUrl(src)" style="flex-shrink: 0;" :height="height" :width="width" :round="round" @click="$emit('click')">
    <template v-slot:loading>
      <van-loading type="spinner" size="20" />
    </template>
    <template v-slot:error :style="errorStyle">
      <div class="error-img">Melinked</div>
    </template>
  </van-image>
  <div class="no-thumb" :style="noImg" v-else @click="$emit('click')">Melinked</div>
</template>
<script>
import { formartImageUrl } from "@/utils/help";
export default {
  name: "thumb",
  props: {
    width: {
      type: String,
      default: "120px"
    },
    height: {
      type: String,
      default: "120px"
    },
    src: {
      type: String,
      default: ""
    },
    round: {
      type: Boolean,
      default: false
    },
    margin: {
      type: String,
      default: ".5rem"
    }
  },
  computed: {
    errorStyle() {
      return {
        width: this.width,
        height: this.height,
        lineHeight: this.height,
        marginRight: this.margin,
        "border-radius": "3px",
        overflow: "hidden"
      };
    },
    noImg() {
      return {
        width: this.width,
        height: this.height,
        lineHeight: this.height,
        marginRight: this.margin,
        "min-width": this.width == "100%" ? "120px" : this.width,
        "min-height": this.height == "auto" ? "120px" : this.height,
        "border-radius": "3px",
        overflow: "hidden"
      };
    }
  },
  created() {},
  methods: {
    formartImageUrl
  }
};
</script>
<style lang="stylus" scoped>
.no-thumb {
  background: #f8f8f8;
  color: #e5e5e5;
  margin-right: 0.5rem;
  text-align: center;
}

.error-img {
  background: #f8f8f8;
  color: #e5e5e5;
  font-size: 16px;
}

.van-image__error {
  .me-icon-user {
    font-size: 18px;
    color: #c5c5c5;
  }
}
.van-image__img{
	width auto!important;
}
</style>
