<template>
  <div
    class="header"
    :style="{
      height: headerHeight !== 0 ? `${headerHeight}px` : 'auto',
      padding: headerHeight === 0 ? '0.9rem 0' : '0',
      background: headerBackground,
    }"
  >
    <div class="left">
      <slot name="left-one"></slot>
      <slot name="left-second"></slot>
      <slot name="left-thred"></slot>
    </div>
    <div class="center">
      <slot name="center"></slot>
    </div>
    <div class="right">
      <slot name="right-two"></slot>
      <slot name="right-one"></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  watch: {
    "$store.state.User.userinfo": {
      handler(val) {
        if (!val && this.$route.name !== "home") {
          this.$route.push({ name: "home" });
        }
      },
      deep: true,
    },
  },
  props: {
    // 组件高度
    headerHeight: {
      type: Number,
      default: 0,
    },
    // 背景颜色
    headerBackground: {
      type: String,
      default: "#33cc66",
    },
  },
  created() {
    this.initLanguage();
  },
  methods: {
    initLanguage() {
      const catlange = localStorage.getItem("locale");
      if (!catlange) {
        let langCode = 1;
        let locale = "zh-CN";
        var navigatorStr = (
          navigator.language || navigator.browserLanguage
        ).toLowerCase();

        if (navigatorStr.indexOf("zh") >= 0) {
          // langCode
          // 假如浏览器语言是中文
        } else if (navigatorStr.indexOf("ko") !== -1) {
          langCode = 9;
          locale = "ko";
        } else if (navigatorStr.indexOf("ja") !== -1) {
          langCode = 8;
          locale = "ja";
        } else if (navigatorStr.indexOf("th") !== -1) {
          langCode = 10;
          locale = "th";
        } else {
          langCode = 2;
          locale = "en";
        }
        console.log(navigatorStr, langCode, "/navigatorStr");
        localStorage.setItem("langCode", langCode);
        localStorage.setItem("locale", locale);
        this.$i18n.locale = locale;
      } else {
        this.$i18n.locale = catlange;
      }
    },
  },
};
</script>
<style lang="stylus">
.header {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  position: fixed;
  flex: 1;
  z-index: 6;
  width: 100%;
  top: 0;

  i {
    margin: 0 0.9rem;
    font-size: 24px;
  }

  .left {
    margin-left: 0.9rem;
    display: inline-block;

    i:first-child {
      margin-left: 0;
    }

    i:last-child {
      margin-right: 0;
    }
  }

  .center {
    flex: 1;
    margin: 0 0.5rem 0 0.9rem;
    overflow: hidden;
    text-align: center;
  }

  .right {
    display: inline-block;
  }

  .header-right {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-right: 0.9rem;
    position: relative;

    .langBox {
      display: flex;
      flex-flow: row;
      align-items: center;
    }

    i {
      margin: 0;
    }
  }
}
</style>
