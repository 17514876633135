export default {
  "langs": {
    "zh-CN": "چینی",
    "en": "انگریزی",
    "fr": "فرانسیسی",
    "es": "ہسپانوی",
    "ru-RU": "روسی",
    "hi": "ہندی",
    "ur": "اردو۔",
    "ja": "جاپانی",
    "ko": "کورین",
    "th": "تھائی",
    "de": "جرمن",
    "it": "اطالوی"
  },
  "responFail": "لوڈ ناکام ہوگیا۔",
  "responRetry": "دوبارہ کوشش کریں پر کلک کریں۔",
  "responEmpty": "ابھی تک کوئی ڈیٹا نہیں ہے۔",
  "resendMsg": "پیغام بھیجنے میں ناکام ، دوبارہ کوشش کرنے کے لئے دبائیں۔",
  "sendImage": "تصویر بھیجیں۔",
  "send": "بھیجیں۔",
  "timeAgo": {
    "0": "بس",
    "1": "{n} منٹ پہلے",
    "2": "{n} گھنٹے پہلے",
    "3": "کل۔"
  },
  "copyGroup": "ممبروں کو ایک نئے ڈسکشن گروپ میں کاپی کریں۔",
  "quitGroup": "بحث گروپ سے باہر نکلیں۔",
  "createGroup": "ایک مباحثہ گروپ بنائیں۔",
  "inviteFriend": "دوستوں کو مدعو کریں۔",
  "login": "لاگ ان",
  "loginerror": "لاگ ان ناکام!",
  "register": "رجسٹریشن۔",
  "keepOn": "لاگ ان کرتے رہیں۔",
  "forgetPwd": "اپنا پاس ورڈ بھول گئے؟",
  "logoutTip": "لاگ آؤٹ کرنا ٹھیک ہے۔",
  "resetPwd": "لاگ ان کا پاس ورڈ دوبارہ ترتیب دیں۔",
  "all": "سب",
  "phoneErr": "فون نمبر خالی نہیں ہوسکتا۔",
  "phoneformatErr": "فون نمبر کی شکل غلط ہے۔",
  "needNumber": "براہ کرم ایک عددی قیمت درج کریں۔",
  "registerErr": "اندراج ناکام ہوگیا۔",
  "publish": "رہائی۔",
  "group": "گروپ۔",
  "school": "اسکول۔",
  "profess": "پیشہ ور۔",
  "degree": "ڈگری",
  "time": "وقت",
  "mustSchool": "اسکول خالی نہیں ہوسکتا۔",
  "mustCompany": "کمپنی خالی نہیں ہوسکتی ہے۔",
  "company": "کاروبار۔",
  "workexp": "کام کا تجربہ۔",
  "education": "تعلیمی تجربہ۔",
  "companyName": "کمپنی۔",
  "job": "پوزیشن",
  "name": "نام۔",
  "address": "پتہ۔",
  "called": "نام۔",
  "title": "عنوان",
  "price": "قیمت",
  "tags": "لیبل لگائیں۔",
  "emoji": "اظہار۔",
  "sendFile": "فائل بھیجیں۔",
  "transferAccount": "منتقلی۔",
  "image": "تصویر",
  "video": "ویڈیو",
  "voice": "آواز۔",
  "file": "فائل۔",
  "groupRoule": {
    "0": "کھلا گروپ: تمام صارفین شامل ہونے کے لئے آزاد ہیں۔",
    "1": "رکنیت: صارف کی درخواست جمع کروانے کے بعد ، وہ گروپ ایڈمنسٹریٹر کے ذریعہ شامل ہوسکتے ہیں۔",
    "2": "نجی گروپ: گروپ کی تلاش میں نظر نہیں آتا ، اور صرف گروپ ہی ممبروں کو شامل کرسکتا ہے۔"
  },
  "man": "مرد۔",
  "woman": "عورت۔",
  "mustBeTM": "عنوان / نام کی ضرورت ہے۔",
  "mustBeTag": "کم از کم ایک لیبل بھریں۔",
  "mustBeCate": "کم از کم ایک زمرہ منتخب کریں۔",
  "contentTip": "تفصیلات یہاں پر کریں ...",
  "uploadSuccess": "کامیابی کے ساتھ اپ لوڈ کیا گیا!",
  "publishError": "اشاعت ناکام!",
  "contactus": "ہم سے رابطہ کریں",
  "privacy": "رازداری کی پالیسی",
  "companyright": "کاپی رائٹ",
  "sys_error": "سسٹم کی خرابی!",
  "replayEmpty": "تبصرے خالی نہیں ہوسکتے ہیں!",
  "replay": "جواب دیں",
  "replayErr": "تبصرہ ناکام۔",
  "delSuccess": "کامیابی کے ساتھ مٹا دیا گیا۔",
  "defFail": "حذف کرنا ناکام ہوگیا۔",
  "refresh": "کامیابی کے ساتھ اپ ڈیٹ ہوا۔",
  "updateSuccess": "ترمیم کامیاب ہے ، براہ کرم دوبارہ لاگ ان کریں۔",
  "old": "پرانا",
  "theNew": "نیا",
  "changeFail": "پاس ورڈ میں ترمیم ناکام ہوگئی۔",
  "pwdlength": "پاس ورڈ کی لمبائی۔",
  "getverchatCode": "توثیقی کوڈ حاصل کریں۔",
  "nopeoplezan": "ابھی تک کسی نے بھی اسے پسند نہیں کیا ، تعریف کرنے والے پہلے شخص بنیں!",
  "pwdResetSuccess": "پاس ورڈ کو کامیابی کے ساتھ تبدیل کردیا گیا۔",
  "email_required": "میل باکس خالی نہیں ہوسکتا۔",
  "phone_required": "فون نمبر خالی نہیں ہوسکتا۔",
  el: {
    colorpicker: {
      confirm: "طے کرنا۔",
      clear: "خالی۔"
    },
    datepicker: {
      now: "اس وقت",
      today: "آج",
      cancel: "منسوخ کریں",
      clear: "خالی۔",
      confirm: "طے کرنا۔",
      selectDate: "تاریخ منتخب کریں۔",
      selectTime: "وقت کا انتخاب کریں۔",
      startDate: "آغاز کی تاریخ",
      startTime: "شروعات کا وقت",
      endDate: "اختتامی تاریخ",
      endTime: "اختتامی وقت",
      prevYear: "پچھلے سال۔",
      nextYear: "اگلے سال۔",
      prevMonth: "پچھلے مہینے",
      nextMonth: "اگلا مہینہ",
      year: "سال",
      month1: "جنوری۔",
      month2: "فروری۔",
      month3: "مارچ۔",
      month4: "اپریل۔",
      month5: "مئی",
      month6: "جون۔",
      month7: "جولائی۔",
      month8: "اگست۔",
      month9: "ستمبر۔",
      month10: "اکتوبر۔",
      month11: "نومبر۔",
      month12: "دسمبر۔",
      weeks: {
        sun: "دن۔",
        mon: "ایک",
        tue: "دو۔",
        wed: "تین",
        thu: "چار",
        fri: "پانچ",
        sat: "چھ"
      },
      months: {
        jan: "جنوری۔",
        feb: "فروری۔",
        mar: "مارچ۔",
        apr: "اپریل۔",
        may: "مئی",
        jun: "جون۔",
        jul: "جولائی۔",
        aug: "اگست۔",
        sep: "ستمبر۔",
        oct: "اکتوبر۔",
        nov: "نومبر۔",
        dec: "دسمبر۔"
      }
    },
    select: {
      loading: "لوڈ ہو رہا ہے۔",
      noMatch: "کوئی مماثل ڈیٹا نہیں ہے۔",
      noData: "کوئی ڈیٹا نہیں ہے۔",
      placeholder: "براہ کرم منتخب کریں۔"
    },
    cascader: {
      noMatch: "کوئی مماثل ڈیٹا نہیں ہے۔",
      loading: "لوڈ ہو رہا ہے۔",
      placeholder: "براہ کرم منتخب کریں۔",
      noData: "ابھی تک کوئی ڈیٹا نہیں ہے۔"
    },
    pagination: {
      goto: "جائیں۔",
      pagesize: "آرٹیکل / صفحہ۔",
      total: "کل {کل}",
      pageClassifier: "صفحہ"
    },
    messagebox: {
      title: "اشارہ کریں۔",
      confirm: "طے کرنا۔",
      cancel: "منسوخ کریں",
      error: "درج کردہ ڈیٹا قانونی نہیں ہے!"
    },
    upload: {
      deleteTip: "حذف کرنے کے لئے حذف دبائیں۔",
      delete: "حذف کریں۔",
      preview: "تصویر دیکھیں۔",
      continue: "اپ لوڈ کرنا جاری رکھیں۔"
    },
    table: {
      emptyText: "ابھی تک کوئی ڈیٹا نہیں ہے۔",
      confirmFilter: "اسکریننگ۔",
      resetFilter: "ری سیٹ کریں۔",
      clearFilter: "سب",
      sumText: "کل"
    },
    tree: {
      emptyText: "ابھی تک کوئی ڈیٹا نہیں ہے۔"
    },
    transfer: {
      noMatch: "کوئی مماثل ڈیٹا نہیں ہے۔",
      noData: "کوئی ڈیٹا نہیں ہے۔",
      titles: {
        "0": "فہرست 1۔",
        "1": "فہرست 2۔"
      },
      filterPlaceholder: "برائے مہربانی اپنی تلاش درج کریں۔",
      noCheckedFormat: "کل {کُل} آئٹمز۔",
      hasCheckedFormat: "{چیک} / {کل} منتخب کیا گیا۔"
    },
    image: {
      error: "لوڈ ناکام ہوگیا۔"
    },
    pageHeader: {
      title: "واپس"
    },
    done: 'ٹھیک ہے',
    cancle: ' منسوخ کریں',
    groupType: {
      '1': {
        name: 'عوامی گروپ',
        code: '1',
        desc: `عوامی گروپ: کوئی بھی صارف بغیر کسی جائزہ کے آزادانہ طور پر اس گروپ میں تشکیل دے سکتا ہے اور گروپ میں شامل ہوسکتا ہے۔گروپ کا نام ، تفصیل ، ممبران کو تلاش اور پلیٹ فارم پر براؤز کیا جاسکتا ہے۔`
      },
      '2': {
        name: 'ممبر گروپ',
        code: '2',
        desc: `ممبر گروپ: کوئی بھی صارف آزادانہ طور پر اس گروپ کو تشکیل دے سکتا ہے اور اس میں شامل ہوسکتا ہے ، جس کا مالک سے جائزہ لینے کی ضرورت ہوتی ہے۔گروپ کا نام ، تفصیل ، ممبران کو تلاش اور پلیٹ فارم پر براؤز کیا جاسکتا ہے۔ `
      },
      '3': {
        name: 'ڈسکشن گروپ',
        code: '3',
        desc: `تبادلہ خیال گروپ: کوئی بھی صارف اسے تشکیل دے سکتا ہے ، تخلیق کار اور ممبر اپنے دوستوں کو اس میں شامل ہونے ، گروپ کا نام ، ممبروں کی تلاش اور پلیٹ فارم پر براؤز کرسکتے ہیں۔ `
      },
      '4': {
        name: 'نجی گروپ',
        code: '4',
        desc: `نجی گروپ: کوئی بھی صارف تشکیل دے سکتا ہے ، گروپ کا نام ، تفصیل ، گروپ ممبران کو تلاش اور پلیٹ فارم پر براؤز نہیں کیا جاسکتا ہے۔ `
      }
    }
  },
  newPwd: 'براہ کرم نیا لاگ ان پاس ورڈ درج کریں',
  repNewPwd: "براہ کرم نیا پاس ورڈ دہرائیں",
  breakPwd: 'پاس ورڈ بازیافت کریں',
  // 2021年3月4号
  meetingTips: "براہ کرم پہلے میلنکڈ ایپ ڈاؤن لوڈ کریں یا میلنکڈ ایپ کو تازہ ترین ورژن میں اپ گریڈ کریں۔",
  // mechat协议
  mechat: {
    title: "می چیٹ یوزر ایگریمنٹ",
    text1: "Mechat ایک AI چیٹ ساتھی سوال و جواب پروڈکٹ ہے جو Maes Consulting کے ذریعے فراہم کیا جاتا ہے۔ جب صارف اس صارف کے معاہدے سے اتفاق کرتا ہے، تو سمجھا جاتا ہے کہ اس نے اس صارف کے معاہدے کے پورے مواد سے اتفاق کیا ہے۔",
    header1: "ایک ہم کس طرح تیسرے فریق کے ساتھ صارفین کی ذاتی معلومات کا اشتراک اور انکشاف کرتے ہیں۔",
    text2: "درج ذیل حالات میں، یہ ایپلیکیشن صارف کی ذاتی خواہشات یا قانونی ضوابط کے مطابق صارف کی ذاتی معلومات کو مکمل یا جزوی طور پر ظاہر کرے گی۔",
    text3: "1. صارف کی پیشگی رضامندی سے فریق ثالث کو ظاہر کیا گیا؛",
    text4: "2. صارف کی طرف سے درخواست کردہ مصنوعات اور خدمات فراہم کرنے کے لیے، صارف کی ذاتی معلومات کو تیسرے فریق کے ساتھ شیئر کرنا ضروری ہے۔",
    text5: "3. قانون کی متعلقہ دفعات کے مطابق، یا انتظامی یا عدالتی اداروں کے تقاضوں کے مطابق، فریق ثالث یا انتظامی یا عدالتی ایجنسیوں کو ظاہر کرنا؛",
    text6: "4. اگر صارف متعلقہ چینی قوانین اور ضوابط یا اس ایپلیکیشن سروس کے معاہدے یا متعلقہ قواعد کی خلاف ورزی کرتا ہے، تو اسے تیسرے فریق کے سامنے ظاہر کرنے کی ضرورت ہے۔",
    text7: "5. اگر صارف ایک اہل دانشورانہ املاک کا شکایت کنندہ ہے اور اس نے شکایت درج کرائی ہے، تو وہ جواب دہندہ کی درخواست پر اسے جواب دہندہ کے سامنے ظاہر کرے گا تاکہ دونوں فریقین ممکنہ حقوق کے تنازعات کو نمٹ سکیں؛",
    text8: "6. دیگر انکشافات جو کہ یہ درخواست قوانین، ضوابط یا ویب سائٹ کی پالیسیوں کے مطابق مناسب سمجھتی ہے۔",
    header2: "2. معلومات کا ذخیرہ اور تبادلہ",
    text9: "MeChat کے ذریعے جمع کردہ صارفین کے بارے میں معلومات اور ڈیٹا MeChat اور (یا) اس سے منسلک کمپنیوں کے سرورز پر محفوظ کیا جائے گا۔ میرے ساتھ چیٹنگ کے عمل میں صرف بھیجی اور موصول ہونے والی معلومات ہی سرور پر اپ لوڈ کی جائیں گی، جو AI ماڈل کے جواب کے لیے استعمال کی جائیں گی۔",
    header3: "3. دانشورانہ املاک سے متعلق",
    text10: "ہم صارفین کے ذریعے اپ لوڈ یا شائع کردہ مواد کی قانونی حیثیت، قانونی حیثیت، سالمیت یا معیار کی کوئی ضمانت نہیں دیتے ہیں۔ صارفین سافٹ ویئر کے ذریعے منتقل کردہ مواد یا وسائل کے استعمال یا ان پر انحصار کرنے سے پیدا ہونے والے خطرات کو برداشت کریں گے۔ کسی بھی صورت میں، ہم اس طرح کے خطرے سے پیدا ہونے والے کسی ممکنہ یا حقیقی نقصان یا نقصان کے لیے ذمہ دار نہیں ہوگا۔",
    header4: "4. قانونی ذمہ داری اور دستبرداری",
    text11: "کسی بھی صورت میں، ہم اور ہمارے شراکت دار کسی بھی براہ راست، بالواسطہ، حادثاتی، خصوصی اور بعد میں ہونے والے نقصانات اور صارفین کے می چیٹ استعمال کرنے میں ناکامی سے پیدا ہونے والے خطرات کے ذمہ دار نہیں ہوں گے۔ اگر سروس کا معمول کا آپریشن تکنیکی خرابیوں جیسے زبردستی حادثے سے متاثر ہوتا ہے، تو ہم اور ہمارے شراکت دار متعلقہ یونٹس کے ساتھ جلد از جلد تعاون کرنے کا وعدہ کرتے ہیں تاکہ بروقت ان سے نمٹنے اور ان کی مرمت کی جا سکے۔",
    text12: "AI ماڈل کی طرف سے بھیجی گئی معلومات کمپیوٹر کے ذریعے تیار کی جاتی ہیں اور یہ فرضی ہو سکتی ہیں۔ صارفین سے کہا جاتا ہے کہ وہ صحیح اور غلط کی تمیز کریں۔ جب صارفین اس ایپلی کیشن کو طبی، قانونی اور دیگر متعلقہ مسائل سے مشورہ کرنے کے لیے استعمال کرتے ہیں، تو یہ ایپلیکیشن صرف طبی مشورہ اور قانونی مشورہ فراہم کرتی ہے، اور طبی خدمات، قانونی خدمات یا علاج کی نمائندگی نہیں کرتی ہے۔ صارفین کو اس ایپلی کیشن کو استعمال کرنے سے پہلے ڈاکٹروں اور متعلقہ قانونی افراد سے مشورہ کرنا چاہیے، اور طبی اور قانونی فیصلوں کے لیے بالآخر ذمہ دار ہیں۔ اس ایپ میں موجود معلومات اور خدمات صرف حوالہ کے لیے ہیں، اور طبی خدمات کے لیے قانونی خدمات یا طبی حل نہیں بناتے ہیں، اور ہم اس ایپ کے استعمال سے پیدا ہونے والی کوئی ذمہ داری قبول نہیں کرتے ہیں۔",
  },
  originalMail: "اصل پیغام",
  emailRecipient: "وصول کنندہ",
  emailFrom: "بھیجنے والا",
  CcTo: "سی سی",
}