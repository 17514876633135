export default {
    "langs": {
        "zh-CN": "Chino",
        "en": "Ingles",
        "fr": "Francés",
        "es": "Espanol",
        "ru-RU": "Ruso",
        "hi": "Hindi",
        "ur": "Urdu",
        "ja": "Japonés",
        "ko": "Coreano",
        "th": "Tailandés",
        "de": "Aleman",
        "it": "Italiano"
    },
    "responFail": "Carga fallida",
    "responRetry": "Haga clic en Reintentar",
    "responEmpty": "Aún no hay datos",
    "resendMsg": "El mensaje no se pudo enviar, haga clic para volver a intentarlo",
    "sendImage": "Enviar foto",
    "send": "Enviar",
    "timeAgo": {
        "0": "Solo",
        "1": "hace {n} minutos",
        "2": "hace {n} horas",
        "3": "Ayer"
    },
    "copyGroup": "Copiar miembros a un nuevo grupo de discusión",
    "quitGroup": "Salga del grupo de discusión.",
    "createGroup": "Crea un grupo de discusión",
    "inviteFriend": "Invitar amigos",
    "login": "Iniciar sesión",
    "loginerror": "Inicio de sesión fallido!",
    "register": "Registro",
    "keepOn": "Sigue iniciando sesión",
    "forgetPwd": "¿Olvidaste tu contraseña?",
    "logoutTip": "OK para cerrar sesión",
    "resetPwd": "Restablecer contraseña de inicio de sesión",
    "all": "Todos",
    "phoneErr": "El número de teléfono no puede estar vacío.",
    "phoneformatErr": "El formato del número de teléfono es incorrecto",
    "needNumber": "Por favor ingrese un valor numérico",
    "registerErr": "Registro fallido",
    "publish": "Lanzamiento",
    "group": "Grupo",
    "school": "Escuela",
    "profess": "Profesional",
    "degree": "Grado",
    "time": "Tiempo",
    "mustSchool": "La escuela no puede estar vacía.",
    "mustCompany": "La empresa no puede estar vacía",
    "company": "Negocios",
    "workexp": "Experiencia laboral",
    "education": "Experiencia educativa",
    "companyName": "Empresa",
    "job": "Posición",
    "name": "Nombre",
    "address": "Dirección",
    "called": "Nombre",
    "title": "Titulo",
    "price": "Precio",
    "tags": "Etiqueta",
    "emoji": "Expresión",
    "sendFile": "Enviar archivo",
    "transferAccount": "Transferencia",
    "image": "Imagen",
    "video": "Video",
    "voice": "Voz",
    "file": "Archivo",
    "groupRoule": {
        "0": "Grupo abierto: todos los usuarios son libres de unirse",
        "1": "Membresía: después de que el usuario envíe la solicitud, el administrador del grupo puede unirse.",
        "2": "Grupo privado: no visible en la búsqueda de grupo, y solo el grupo puede agregar miembros"
    },
    "man": "Hombre",
    "woman": "Hembra",
    "mustBeTM": "Título / Nombre requerido",
    "mustBeTag": "Rellene al menos una etiqueta",
    "mustBeCate": "Seleccione al menos una de las categorías.",
    "contentTip": "Complete los detalles aquí ...",
    "uploadSuccess": "Subido con éxito!",
    "publishError": "¡Publicación fallida!",
    "contactus": "Contáctanos",
    "privacy": "Política de privacidad",
    "companyright": "Derechos de autor",
    "sys_error": "Error del sistema!",
    "replayEmpty": "¡Los comentarios no pueden estar vacíos!",
    "replay": "Responder",
    "replayErr": "Comentario fallido",
    "delSuccess": "Eliminado exitosamente",
    "defFail": "Eliminar fallido",
    "refresh": "Actualizado exitosamente",
    "updateSuccess": "La modificación se realizó correctamente. Vuelva a iniciar sesión.",
    "old": "Viejo",
    "theNew": "Nuevo",
    "changeFail": "La modificación de la contraseña falló",
    "pwdlength": "Longitud de la contraseña",
    "getverchatCode": "Obtén el código de verificación",
    "nopeoplezan": "A nadie le ha gustado aún, ¡sé el primero en alabar!",
    "pwdResetSuccess": "La contraseña fue modificada con éxito.",
    "email_required": "El buzón no puede estar vacío",
    "phone_required": "El número de teléfono no puede estar vacío.",
    done: 'Definitivo',
    cancle: 'cancel',
    groupType: {
        '1': {
            name: 'public group',
            code: '1',
            desc: ` grupo abierto: cualquier usuario puede crear, a la libertad, no necesita revisar. El nombre, la descripción, los miembros en la plataforma puede ser buscar, explorar.`
        },
        '2': {
            name: 'grupo de miembros',
            code: '2',
            desc: ` miembros de grupo: cualquier usuario puede crear, libertad de adhesión, necesita de dios. El nombre, la descripción, los miembros en la plataforma puede ser buscar, explorar.`
        },
        '3': {
            name: 'grupo de discusión',
            code: '3',
            desc: `grupo de debate  : cualquier usuario puede crear, y todos los miembros fundadores pueden invitar a sus amigos a bordo, de nombre, miembros de la plataforma se puede buscar, explorar.`
        },
        '4': {
            name: 'private group',
            code: '4',
            desc: ` privado grupo: cualquier usuario puede crear, el nombre, la descripción, los miembros de la plataforma no se pueden buscar, explorar.`
        }
    },
    newPwd: 'Ingrese una nueva contraseña de inicio de sesión',
    repNewPwd: "Repita la nueva contraseña",
    breakPwd: 'Recuperar contraseña',
    // 2021年3月4号
    meetingTips: "Primero descargue la aplicación Melinked o actualice la aplicación Melinked a la última versión",
    // mechat协议
    mechat: {
      title: "Acuerdo de usuario de chat",
      text1: "Mechat es un producto de preguntas y respuestas complementario de chat de IA proporcionado por Maes Consulting. Cuando el usuario acepta este acuerdo de usuario, se considera que ha aceptado todo el contenido de este acuerdo de usuario.",
      header1: "uno. Cómo compartimos y divulgamos la información personal de los usuarios con terceros",
      text2: "En las siguientes circunstancias, esta aplicación divulgará la información personal del usuario en su totalidad o en parte de acuerdo con los deseos personales del usuario o las normas legales:",
      text3: "1. Revelados a terceros con el consentimiento previo del usuario;",
      text4: "2. Para proporcionar los productos y servicios solicitados por el usuario, es necesario compartir la información personal del usuario con un tercero;",
      text5: "3. De acuerdo con las disposiciones pertinentes de la ley, o los requisitos de las agencias administrativas o judiciales, divulgar a terceros o agencias administrativas o judiciales;",
      text6: "4. Si el usuario viola las leyes y regulaciones chinas pertinentes o este acuerdo de servicio de aplicación o las reglas relacionadas, debe informarlo a un tercero;",
      text7: "5. Si el usuario es un reclamante calificado de propiedad intelectual y ha presentado una queja, deberá divulgarla a la parte demandada a solicitud de la parte demandada para que ambas partes puedan manejar posibles disputas de derechos;",
      text8: "6. Otras divulgaciones que esta aplicación considere apropiadas de conformidad con las leyes, reglamentos o políticas del sitio web.",
      header2: "2. Almacenamiento e intercambio de información",
      text9: "La información y los datos sobre los usuarios recopilados por MeChat se almacenarán en los servidores de MeChat y (o) sus empresas afiliadas. Solo la información enviada y recibida se cargará en el servidor en el proceso de chatear conmigo, que se utilizará para la respuesta del modelo de IA.",
      header3: "3. Propiedad intelectual relacionada",
      text10: "No garantizamos la legalidad, legitimidad, integridad o calidad del contenido cargado o publicado por los usuarios. Los usuarios asumirán los riesgos derivados del uso o la confianza en el contenido o los recursos transmitidos por el software. En cualquier caso, no será responsable de ninguna pérdida o daño posible o real que surja de dicho riesgo.",
      header4: "4. Responsabilidad legal y exención de responsabilidad",
      text11: "En cualquier caso, ni nosotros ni nuestros socios seremos responsables de ningún daño y riesgo directo, indirecto, incidental, especial y posterior que surja del uso o la incapacidad de los usuarios para usar Me Chat. Si el funcionamiento normal del servicio se ve afectado por eventos de fuerza mayor, como fallas técnicas, nosotros y nuestros socios nos comprometemos a cooperar con las unidades pertinentes lo antes posible para tratarlos y repararlos de manera oportuna.",
      text12: "La información enviada por el modelo de IA es generada por la computadora y puede ser ficticia. Se pide a los usuarios que distingan el bien del mal. Cuando los usuarios utilizan esta aplicación para consultar temas médicos, legales y otros relacionados, esta aplicación solo brinda asesoramiento médico y asesoramiento legal, y no representa servicios médicos, servicios legales o tratamiento. Los usuarios deben consultar a médicos y personas jurídicas pertinentes antes de utilizar esta aplicación, y son los últimos responsables de las decisiones médicas y legales. La información y los servicios en esta aplicación son solo para referencia y no constituyen servicios legales o soluciones médicas para servicios médicos, y no asumimos ninguna responsabilidad derivada del uso de esta aplicación.",
    },
    originalMail: "El mensaje original",
    emailRecipient: "recipiente",
    emailFrom: "remitente",
    CcTo: "CC",
}