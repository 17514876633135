<template>
  <div class="page-wap" ref="page">
    <div class="weixinTips" v-if="showWeixinTips">
      <img
        class="weixinTipsImg"
        :src="require('../assets/images/meeting/weixinTips.png')"
      />
    </div>
    <Header id="header">
      <span
        slot="left-one"
        class="menu-box"
        @click="handlerChangeStatus('menuDrawer')"
      >
        <van-dropdown-menu class="menu-dropdown" active-color="#33cc66">
          <van-dropdown-item
            v-model="curCategoryId"
            title-class="hidden"
            ref="item"
            :options="categories"
            @change="handlerCateGoryChange"
          ></van-dropdown-item>
        </van-dropdown-menu>
        <i class="me-icon-menu" style="margin-left: 0"></i>
      </span>

      <i
        slot="left-second"
        class="me-icon-location"
        @click="handlerChangeStatus('showRegion')"
      ></i>
      <i
        slot="left-thred"
        class="me-icon-search"
        @click="$router.push({ name: 'search' })"
      ></i>
      <span slot="right-one" class="header-right">
        <languageSelect
          :locale="locale"
          @change="changeLang"
          @close="handlerChangeStatus('showLang')"
          @open="handlerChangeStatus('showLang')"
          slot="right-one"
        ></languageSelect>
        <span class="langBox">
          {{ langName }}
          <i class="me-icon-down-solid"></i>
        </span>
      </span>
    </Header>
    <TreeSelect
      :items="regions"
      :show="showRegion"
      @closed="handlerChangeShowRegion"
      @getCountry="handlerGetCountryCode"
    />
    <!-- 打开app或下载 -->
    <div class="openApp">
      <img src="../assets/meeting/meetingIcon.png" />
      <div class="txt">Melinked</div>
      <div class="btn" @click="openApp(true)" v-if="deviceBrand != 'HUAWEI'">
        <template v-if="!showOpenLoading"
          >Open app</template
        >
        <template v-else>
          <van-loading type="spinner" size="20" />
        </template>
      </div>
    </div>
    <!-- <van-pull-refresh
      v-model="loading"
      @refresh="handlerGetAppHomeData"
      class="my-home"
    > -->
    <!-- v-scrollUpdate="updateData" -->
    <div
      class="my-home"
      v-scrollUpdate="updateData"
      v-scroll="{
        distance: 150,
        onTop: _onTop,
        onBottom: _onBottom,
      }"
      :style="{ height: scrollBoxHei }"
    >
      <div class="container" ref="container">
        <template
          v-if="
            (homeData.providerData && homeData.providerData.length) ||
              (homeData.recommendHelogigs &&
                homeData.recommendHelogigs.length) ||
              (homeData.groupData && homeData.groupData.length) ||
              (homeData.enterpriseData && homeData.enterpriseData.length)
          "
        >
          <div
            class="services"
            v-if="
              homeData.recommendHelogigs && homeData.recommendHelogigs.length
            "
          >
            <Item
              v-for="(item, index) in homeData.recommendHelogigs"
              :item="item"
              :key="index"
            >
              <span
                v-if="item.price"
                slot="price"
                class="item__header__info--price"
                >{{ item.price }}</span
              >
            </Item>
          </div>
        </template>
        <van-loading v-else-if="loading" class="loading" color="#33cc66" />
        <div class="empty" v-else>
          <i class="me-icon-empty"></i>
        </div>
      </div>
    </div>
    <div class="pubshBtn" @click="$router.push({ name: 'publish' })">
      <i class="me-icon-plus"></i>
    </div>
    <div class="openBtn" @click="openApp(true)" v-if="deviceBrand != 'HUAWEI'">
      <template v-if="!showOpenLoading"
        >Open app</template
      >
      <template v-else>
        <van-loading type="spinner" size="20" />
      </template>
    </div>
    <Tabbar />
  </div>
</template>
<script>
import CallApp from "callapp-lib";
import Avatar from "@/components/common/avatar";
import Header from "@/components/business/h5/header";
import languageSelect from "@/components/business/language-select";
import Item from "@/components/business/h5/serviceItem";
import GigItem from "@/components/business/h5/gigItem";
import TreeSelect from "@/components/business/treeSelect";
import {
  getCategories,
  fullRegion,
  getAppHomeData,
  getApphelogigData,
} from "@/api/webApi";
import Tabbar from "@/components/common/tabbar";
import Print from "@/plugins/print";
import { formartTags } from "@/utils/help";
export default {
  name: "home",
  components: {
    Header,
    languageSelect,
    Item,
    Avatar,
    GigItem,
    TreeSelect,
    Tabbar,
  },
  data() {
    return {
      menuDrawer: false,
      showRegion: false,
      showLang: false,
      curCategoryId: "",
      categories: [],
      regions: [],
      language: "1",
      curNavIndex: 1,
      locale: "zh-CN",
      homeData: {},
      loading: false,
      country: "",
      langName: "",
      loginUser: {},
      fallbackLink: "",
      tips: "",
      timer: null,
      showOpenLoading: false,
      showWeixinTips: false,
      titleMsg: "这里是自定义指令v-title",
      listNum: 10,
      showLoading: false,
      scrollBoxHei: 0,
      randomProviderDataNumber: 3,
      pageNum: 1,
      recommendHelogigs: [], //列表数据
      deviceBrand: null,
    };
  },

  watch: {
    language: {
      handler(val) {
        this.handlerPageRequests();
      },
      deep: true,
    },
  },
  created() {
    this.scrollBoxHei = window.screen.height - 80 + "px";
    this.language = localStorage.getItem("langCode") || 1;
    this.locale = localStorage.getItem("locale") || "zh-CN";
    this.langName = this.$i18n.messages[this.locale].langName;
    const curUser = localStorage.getItem("userinfo");
    this.loginUser = curUser ? JSON.parse(curUser) : {};
    this.handlerPageRequests();
  },
  async mounted() {
    /*************** */
    await this.$nextTick();
    // this.scrollTo("page");
    /*************** */
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.showWeixinTips = true;
      return;
    } else {
      this.showWeixinTips = false;
    }
    await this.bindTheListenerEvent();
    const u = navigator.userAgent,
      app = navigator.appVersion;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      this.fallbackLink =
        "https://itunes.apple.com/cn/app/melinked/id1047458085?mt=8";
    } else {
      this.fallbackLink =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.melink.android";
    }
    this.randomProviderDataNumber = this._randomNum(1, 16);
    this.registerDateToJs();
  },
  methods: {
    registerDateToJs() {
      this.$bridge.registerHandler("requestJsData", (data) => {
        try {
          this.deviceBrand = JSON.parse(data).DeviceBrand;
        } catch (err) {}
      });
    },
    updateData() {
      let that = this;
      that.showLoading = true;
      that.listNum = 10;
      this.recommendHelogigs = [];
      this.pageNum = 1;
      this.handlerGetAppHomeData();
    },
    _onTop() {
      console.log("_onTop");
    },
    _onBottom() {
      console.log("_onBottom");
      this.pageNum++;
      this.handlerGetAppHomeData();
    },
    async scrollTo(key, val = 0) {
      await this.$nextTick();
      if (this.$refs[key]) this.$refs[key].scrollTop = val;
    },
    handlerGetCountryCode(code) {
      this.country = code;
      this.$store.dispatch("commitCountryId", this.country);
      this.handlerGetAppHomeData();
    },
    handlerChangeShowRegion(status) {
      this.showRegion = status;
    },
    printPage() {
      Print(this.$refs.page);
    },
    handlerChangeStatus(field) {
      const status = ["menuDrawer", "showRegion", "showLang"];
      if (!status.includes(field)) {
        console.log(`field must be in ${status.join(",")}`);
        return;
      }
      status.map((key) => {
        if (key === field) {
          this[key] = true;
        } else {
          this[key] = false;
        }
      });
    },
    handlerCateGoryChange(category) {
      this.$store.dispatch("commitCateGoryId", category);
      this.handlerGetAppHomeData();
    },
    handlerPageRequests() {
      this.handlerGetCategories();
      this.handlerGetFullRegion();
      this.handlerGetAppHomeData();
    },
    async handlerGetAppHomeData() {
      this.loading = true;
      let params = {
        language: this.language,
        userId: this.loginUser.id || "",
        country: this.country,
        categoryId: this.curCategoryId,
        pageNum: this.pageNum,
      };
      const result2 = await getApphelogigData(params).catch((e) => {});
      this.loading = false;
      this.showLoading = false;
      if (result2) {
        this.homeData = this.handlerFormartItems(result2.data.data);
      }
    },
    handlerFormartItems(result2) {
      const itemFields = {};
      Object.keys(result2).map((key) => {
        if (key !== "code" || key !== "message")
          if (key == "recommendHelogigs") {
            let recommendHelogigs = result2.recommendHelogigs;
            //首页推荐用户数据，随机插入到首页的帖子列表中去。
            recommendHelogigs.splice(this._randomNum(1, 16), 0, {
              type: "user",
              users: result2.recommendProviders,
            });
            //首页推荐群组数据，随机插入到首页的帖子列表中去。
            recommendHelogigs.splice(this._randomNum(1, 16), 0, {
              type: "group",
              groups: result2.recommendGroups,
            });
            if (this.recommendHelogigs.length) {
              this.recommendHelogigs = this.recommendHelogigs.concat(
                result2.recommendHelogigs
              );
            } else {
              this.recommendHelogigs = recommendHelogigs;
            }
            itemFields[key] = this.recommendHelogigs;
          } else {
            // itemFields[key] = this.formartItems(key, result);
          }
        else {
          itemFields[key] = result[key];
        }
      });
      return itemFields;
    },
    formartItems(key, data) {
      let type = "";
      switch (key) {
        case "serviceJobData":
          type = "service";
          break;
        case "enterpriseData":
          type = "enterprise";
          break;
        case "groupData":
          type = "group";
          break;
        case "providerData":
          type = "user";
          break;
      }

      return data[key].data
        ? data[key].data.map((item) => {
            return {
              id: item.pkId,
              userId: item.providerId,
              avatar: item.userAvatar,
              name: item.userName,
              time: item.time,
              content: item.content,
              thumb: item.contentPic,
              title: item.subject,
              tags: formartTags(item.tag),
              price: item.fee || "",
              action: {
                id: item.pkId,
                replyCount: item.replyCount,
                zanNum: item.praiseCount,
                url: `${window.location.href}detail/${item.pkId}/${type}`,
                title: item.subject || item.userName,
                desc: item.content,
                thumb: item.contentPic,
                likeState: item.likeState,
              },
            };
          })
        : [];
    },
    changeLang(item) {
      localStorage.setItem("langCode", item.code);
      localStorage.setItem("locale", item.value);

      this.$i18n.locale = item.value;
      this.langName = item.langName;
      this.language = item.code;
    },
    async handlerGetCategories() {
      let params = {
        language: this.language,
      };
      const result = await getCategories(params);
      if (result) {
        this.categories = result.data.data.map((item) => {
          return {
            text: item.category_name,
            value: item.category_id,
          };
        });
        this.categories.unshift({ text: this.$t("all"), value: 0 });
      }
    },
    async handlerGetFullRegion() {
      let params = { language: this.language };
      const result = await fullRegion(params).catch((e) => {});
      if (result)
        this.regions = result.data.data.map((item) => {
          return {
            text: item.regionName,
            value: item.regionId,
            children: item.countries.map((el) => {
              return {
                text: el.countryName,
                id: el.countryId,
                value: el.countryCode,
                phonePrefix: el.phonePrefix,
              };
            }),
          };
        });
      this.regions.unshift({ text: this.$t("all"), value: "" });
    },
    /*************** */
    goAppStore() {
      window.location.href = this.fallbackLink;
    },
    openApp(needGoAppStore) {
      clearTimeout(this.timer);
      this.showOpenLoading = needGoAppStore;
      const options = {
        scheme: {
          protocol: "melinked",
        },
        appstore: "https://itunes.apple.com/cn/app/melinked/id1047458085?mt=8",
        yingyongbao:
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.melink.android",
        fallback: `#${this.$route.path}`,
      };
      const callLib = new CallApp(options);
      this.$copyText(`meetingKey=${this.$route.params.data}`).then(
        async (res) => {
          await this.goAppStoreDelay(needGoAppStore);
          callLib.open({
            path: "melinkedmeeting",
            callback: () => {},
          });
        },
        async (err) => {
          await this.goAppStoreDelay(needGoAppStore);
          callLib.open({
            path: "melinkedmeeting",
            callback: () => {},
          });
        }
      );
    },
    // 绑定页面是否隐藏的监听事件
    bindTheListenerEvent() {
      return new Promise((resolve, reject) => {
        document.addEventListener(
          "visibilitychange",
          this.visibilitychange,
          false
        );
        document.addEventListener(
          "webkitvisibilitychange",
          this.visibilitychange,
          false
        );
        window.addEventListener(
          "pagehide",
          () => {
            this.showLoading = false;
            clearTimeout(this.timer);
          },
          false
        );
        resolve("success");
      });
    },
    // 判断页面是否隐藏了
    visibilitychange() {
      const tag = document.hidden || document.webkitHidden;
      this.showLoading = false;
      tag && clearTimeout(this.timer);
    },
    // 定时跳转进应用商店
    goAppStoreDelay(needGoAppStore) {
      return new Promise((resolve, reject) => {
        this.timer = setTimeout(() => {
          if (needGoAppStore) {
            this.goAppStore();
          }
        }, 2500);
        resolve("success");
      });
    },
    _randomNum(Min, Max) {
      let Range = Max - Min;
      let Rand = Math.random();
      if (Math.round(Rand * Range) == 0) {
        return Min + 1;
      } else if (Math.round(Rand * Max) == Max) {
        return Max - 1;
      } else {
        let num = Min + Math.round(Rand * Range) - 1;
        return num;
      }
    },
    /*************** */
  },
};
</script>
<style lang="scss">
.van-dropdown-menu__bar {
  background: transparent !important;
}

.icon-more {
  background: #f8f8f8;
  color: #999999;
  font-size: 20px;
  margin-right: 0.5rem;

  &.user {
    min-width: 65px;
    width: 65px;
    height: 65px;
    border-radius: 100px;
    text-align: center;
    line-height: 65px;
  }

  &.item {
    width: 110px;
    height: 90px;
    min-width: 110px;
    text-align: center;
    line-height: 90px;
  }
}

@keyframes spin {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(360deg);
  }
}

.pubshBtn {
  position: fixed;
  right: 1rem;
  bottom: 4.5rem;
  z-index: 55;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  background: #33cc66;
  border-radius: 5rem;

  i {
    font-size: 30px;
    color: #ffffff;
  }
}

.openBtn {
  position: fixed;
  left: 50%;
  bottom: 8.5rem;
  z-index: 55;
  width: 108px;
  transform: translateX(-50%);
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: #33cc66;
  color: #fff;
  border-radius: 5rem;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

.container {
  min-height: 100vh;
  padding-bottom: 0px;

  .empty {
    font-size: 50px;
    color: #c5c5c5;
    padding-top: 6rem;

    .me-icon-loading {
      animation: spin 0.2s linear 1s infinite alternate;
      -webkit-animation: spin 0.2s linear 1s infinite alternate;
      -moz-animation: spin 0.2s linear 1s infinite;
      -ms-animation: spin 0.2s linear 1s infinite;
      -o-animation: spin 0.2s linear 1s infinite;
    }
  }
}

.menu-box {
  position: relative;
  height: 24px;
  display: inline-block;
  margin: 0 0.9rem 0 0;

  .menu-dropdown {
    position: absolute;
    background: transparent !important;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    overflow: hidden;

    &:after {
      border: none !important;
    }

    .van-dropdown-item--down {
      top: 54px !important;
    }
  }
}

.scrollGroups {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 0.5rem;
  background: #ffffff;
  margin-bottom: 0.5rem;

  // align-items: center;
  .me-icon-more {
    color: #999999;
    padding-right: 0.5rem;
    font-size: 22px;
  }

  .me-avatar,
  .wap-gigitem {
    min-width: 110px;
    margin: 0 0.9rem 0 0;
  }
}

// 推荐人新列表
.providerPerson {
  display: flex;
  // flex-flow: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  // padding: 0.5rem;
  // background: #ffffff;
  // margin-bottom: 0.5rem;

  padding: 2rem 0.5rem;
  background: #ffffff;
  border-bottom: 10px solid #f0f0f0;

  &::-webkit-scrollbar {
    display: none;
  }

  .personItem {
    flex-shrink: 0;
    width: 80vw;
    height: 344px;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    margin-right: 10px;
    display: flex;
    padding: 12px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .userName {
      margin: 12px 0 20px 0;
      font-size: 17px;
      font-weight: 600;
      color: #1a051d;
    }

    .aboutMe {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -moz-box;
      -moz-line-clamp: 2;
      -moz-box-orient: vertical;
      overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
      max-height: 40px;
      overflow: hidden;
    }

    .skills {
      margin-top: 9px;
      display: flex;

      .labelItem {
        height: 30px;
        min-width: 0px;
        padding: 0 10px;
        border-radius: 15px;
        background: #f2fcf8;
        display: flex;
        align-items: center;
        font-size: 12px;

        img {
          margin-right: 8px;
        }
      }
    }
  }
}

//推荐群组列表
.recommendGroups {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 2rem 0.5rem;
  background: #ffffff;
  border-bottom: 10px solid #f0f0f0;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  .groupItem {
    flex-shrink: 0;
    width: 70vw;
    height: 308px;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    margin-right: 10px;
    display: flex;
    padding: 12px;
    box-sizing: border-box;
    flex-direction: column;
    background: #f5f5f5;

    .groupName {
      font-size: 17px;
      font-weight: 600;
      text-align: left;
      color: #1a051d;
      margin: 12px 0 5px 0;
    }

    .groupInfo {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -moz-box;
      -moz-line-clamp: 2;
      -moz-box-orient: vertical;
      overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
      // max-height: 40px;
      overflow: hidden;
    }
  }
}

.services {
  background: #ffffff;

  // margin-bottom: 0.5rem;
  .me-icon-moreDian {
    width: 100%;
    font-size: 24px;
    text-align: center;
    color: #c5c5c5;
    padding: 0.5rem 0;
    display: inline-block;
  }
}

.openApp {
  margin-bottom: 0.5rem;
  background: #ffffff;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  // justify-content: center;
  align-items: center;
  font-size: 14px;

  img {
    width: 34px;
    height: 34px;
    margin-right: 7px;
  }

  .btn {
    color: #66c971;
    margin-left: auto;
    text-align: center;
    width: 106px;
    height: 36px;
    line-height: 34px;
    border-radius: 48px;
    border: 1px solid #66c971;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.menuDrawer {
  margin-top: 54px;

  .el-drawer__header {
    // display: none;
    margin-bottom: 0;
    padding: 0;

    .el-drawer__close-btn {
      position: fixed;
      right: 0.9rem;
      top: 4rem;
    }
  }

  .el-drawer {
    // width: 100% !important;
    height: 86% !important;
  }

  .el-drawer__body {
    // min-height: 100vh;
    overflow-y: scroll;

    .regions-panel {
      height: 100%;
      border: 0;

      .el-cascader-menu__wrap {
        height: 100%;
      }
    }

    ul {
      // padding: 0 0 30px 0;
      li {
        padding: 0.6rem 0.9rem;
        font-size: 15px;
        color: #606266;
      }

      .el-cascader-node.in-active-path,
      .el-cascader-node.is-selectable.in-checked-path,
      .el-cascader-node.is-active {
        color: #33cc66 !important;
      }
    }
  }
}
</style>
<style lang="stylus">
.page-wap {
  .van-loading {
    height: auto !important;
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    padding-top: 0 !important;
    justify-content: center;
    color: #FFFFFF;
  }

  .weixinTips {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);

    .weixinTipsImg {
      width: 100%;
      max-width: 292px;
      position: absolute;
      top: 0;
      right: 15px;
    }
  }
}

.btn {
  .van-loading {
    height: auto !important;
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    padding-top: 0 !important;
    justify-content: center;
    color: #66c971;
  }
}

.my-home{
  overflow-y: scroll;
  margin:  0;
}
</style>
