<template>
  <van-image
    fit="cover"
    v-if="src"
    :src="formartImageUrl(src)"
    :style="style"
    round
    @click="handlerToUser"
  >
    <template v-slot:loading>
      <van-loading type="spinner" size="20" />
    </template>
    <template v-slot:error :style="style">
      <i :class="isGroup ? 'me-icon-group':'me-icon-user'" :style="errorSize"></i>
    </template>
  </van-image>
  <div class="no-thumb" :style="style" v-else @click="handlerToUser">
    <i :class="isGroup?'me-icon-group':'me-icon-user'" :style="errorSize"></i>
  </div>
</template>
<script>
import { formartImageUrl } from "@/utils/help";
import { Base64 } from "js-base64";
export default {
  name: "thumb",
  props: {
    width: {
      type: String,
      default: "120px"
    },
    height: {
      type: String,
      default: "120px"
    },
    src: {
      type: String,
      default: ""
    },
    isGroup: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "20px"
    },
    id: {
      type: String,
      default: ""
    }
  },
  computed: {
    style() {
      return {
        width: this.width,
        height: this.height,
        "min-width": this.width,
        lineHeight: this.height,
        marginRight: ".5rem",
        "border-radius": this.width,
        "font-size": this.size
      };
    },
    errorSize() {
      return {
        "font-size": this.size,
        color: "#c5c5c5"
      };
    }
  },
  created() {},
  methods: {
    formartImageUrl,
    handlerToUser() {
      if (this.id){
        this.$router.push({
          name: this.isGroup ? "group" : "user",
          params: { id: "pai_" + Base64.encode(this.id) } 
        });
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
.no-thumb {
  background: #f8f8f8;
  color: #e5e5e5;
  margin-right: 0.5rem;
  text-align: center;
}
</style>