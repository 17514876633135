import {
  env
} from "@/utils";
import {
  JSEncrypt
} from "jsencrypt";
import emotionData from "@/components/common/emotion/data";
import Store from '@/store'
const emoFileCache = {};
export function formartTags(tags) {
  tags = tags && tags.indexOf(",") !== -1 ? tags.split(",") : tags !== "" ? [tags] : [];
  return tags;
}

export function formartfileIcon() {
  let name = arguments[0];
  const pic = arguments[1];
  let url = "";
  const baseurl = "https://melinked.com/frame/src/imImage/filetype/";
  if (name) name = name.toLowerCase();
  switch (name) {
    case "doc":
      url = baseurl + "icon_doc.png";
      break;
    case "txt":
      url = baseurl + "icon_txt.png";
      break;
    case "pdf":
      url = baseurl + "icon_pdf.png";
      break;
    case "ppt":
      url = baseurl + "icon_ppt.png";
      break;
    case "rar":
    case "zip":
      url = baseurl + "icon_war.png";
      break;
    case "xls":
      url = baseurl + "icon_xls.png";
      break;
    case "png":
    case "jpg":
    case "gif":
      url = pic;
      break;
    case "mp4":
    case "wav":
    case "video":
      url = baseurl + "icon_video.png";
      break;
    case "mp3":
      url = baseurl + "icon_voice.png";
      break;

    default:
      url = "file." + name;
  }
  return url;
}

export function formartImageUrl(url) {
  if (!url) {
    return "";
  } else if (url == "video") {
    return url;
  } else if (
    (url.indexOf("tfile.melinked.com") !== -1 || url.indexOf("image.melinked.com") !== -1 || url.indexOf("video.melinked.com") !== -1) && 
    url.indexOf('?imageView2') === -1 && 
    (url.toLowerCase().indexOf("png") !== -1 || url.toLowerCase().indexOf("jpg") !== -1 || url.toLowerCase().indexOf("gif") !== -1 )
  ) {
    return url + "?imageView2";
  } else if (url.indexOf("http") === 0 || url.indexOf("blob:http") === 0) {
    return url;
  } else if (url.indexOf("/upload") === 0 || url.indexOf("me20") === 0) {
    return process.env.NODE_ENV != "production" ?
      "https://mecdntest.melinked.com/" + url :
      "https://pic.melinked.com/" + url;
  } else if (url.indexOf("/images") === 0) {
    return env("BASEURL") + url;
  }
}

export function fnencrypt(password) {
  let publicKey =
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCSrattfp5EAWtghzSCvPeiiIqoC1ToSusE6whycRV/k7HHctY7ez21/YBpC7NZuBY1RDN9POfjaZ3BbixVA63DWI6xWdIkcWAJ9rDF245LTGh3elU2h2KaLQxsy2CzhBEQ2HjeFwerbJ7X2M2vWh6DnkMXHSSnYOuNKvOSCNH5nQIDAQAB";
  let encryptor = new JSEncrypt(); // JSEncrypt对象
  encryptor.setPublicKey(publicKey); // 公钥
  let rsaPassWord = encryptor.encrypt(password); // 密码进行加密
  return rsaPassWord;
}

export function lightHeight(text, keyword) {
  if (text && text.indexOf(keyword) !== -1) {
    text = text.replace(keyword, '<font color="red">' + keyword + "</font>");
  }
  return text;
}
export function clearPublishCatch() {
  localStorage.removeItem("publish-title");
  localStorage.removeItem("publish-content");
  localStorage.removeItem("publish-price");
  localStorage.removeItem("publish-categorys");
  localStorage.removeItem("publish-tags");
  localStorage.removeItem('changeSelecte')
  localStorage.removeItem('changeTag')
  localStorage.removeItem('publish-imageData')
  localStorage.removeItem('publish-tempImgs')
  localStorage.removeItem('createGroupType')
  Store.dispatch('commitSelectedCategories',[])
  Store.dispatch('comminTags',[])
  // localStorage.removeItem('')
}
export function getFile(name) {
  if (emotionData.findIndex(v => v.name == name) === -1) return false;
  if (!emoFileCache[name]) {
    emoFileCache[name] = `${env("BASEURL")}/static/emoji/${name}.gif`;
  }
  return emoFileCache[name];
}
export function replaceNameToImage(content) {
  // console.log(content, typeof content, '/content')
  if (content && typeof content === 'string')
    return content.replace(/\[!(\w+)\]/gi, (str, match) => {
      const file = getFile(match);
      if (match == "undefined") return "&nbsp;";
      return file ?
        `&nbsp;<img face-name="${match}" src="${file}" />&nbsp;` :
        `[!${match}]`;
    });
  else return ''
}
export function componentCheckHasLogin() {
  const user = localStorage.getItem('userinfo') || {}
  if (!Object.keys(user).length) {
    this.$router.push({
      name: 'login'
    })
  }
}