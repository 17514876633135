import http from "@/utils/http";
import {
  objectToUrlParams
} from "@/utils/url";

function toXml(params) {
  const req = Object.keys(params)
    .map(k => `<${k}><![CDATA[${params[k]}]]></${k}>`)
    .join("");
  return `<?xml version = "1.0" encoding = "UTF-8"?>
<requests>
  <global>
    <resultType>json</resultType>
  </global>
  <request>
  ${req}
  </request>
</requests>`;
}

export function publicDo(params, url) {
  return http({
    headers: {
      "Content-Type": "application/xml; charset=UTF-8"
    },
    method: "post",
    url: url || '/public/data.do',
    data: toXml(params)
  });
}
// v5评论列表
export function reply(params) {
  return http({
    method: "get",
    url: `/rest/review/reply/root/${params.gid}`,
    params,
    withCredentials: false
  });
}


export function getCategories(params) {
  return http({
    url: "/rest/home/v3/getCategories",
    withCredentials: false
  });
}

export function fullRegion(params) {
  return http({
    url: "/rest/system/fullRegion",
    params,
    withCredentials: false
  });
}

export function getAppHomeData(params) {
  return http({
    url: "/rest/home/v3/getAppHomeData",
    params,
    withCredentials: false
  });
}

//首页推荐数据
export function getApphelogigData(params) {
  return http({
    url: `/rest/v5/home/helogig/${params.pageNum}`,
    params,
    withCredentials: false
  });
}

// 获取服务详情
export function getServiceDetail(params) {
  return http({
    url: "/rest/detail/v3/getServiceJobDetail",
    params,
    withCredentials: false
  });
}
// 获取帖子详情数据(就是上面获取服务详情接口的最新版)
export function getPostInformation(gigId) {
  return http({
    withCredentials: false,
    url: `/rest/v5/helogig/${gigId}`,
  });
}
// 获取点赞人员列表
export function getLikePeoloes(params) {
  return http({
    url: `/rest/home/like/${params.id}/list`,
    params,
    withCredentials: false
  });
}

// 获取推荐内容
export function getRecommends(params, url) {
  return http({
    url: `/rest/recommend/${url}`,
    params,
    withCredentials: false
  });
}

// 获取帖子推荐列表的新接口(0:文字1:视频2:语音3:图片4:文件)
export function getRecommendedList(gigId, params) {
  return http({
    withCredentials: false,
    url: `/rest/v5/helogig/recommend/${gigId}`,
    params: params
  });
}

// 获取公司详情
export function getCompanyDetail(params) {
  return http({
    url: "/rest/detail/v3/getEnterpriseDetail",
    params,
    withCredentials: false
  });
}

// 获取群的基本信息
export function getGroupDetail(params) {
  return http({
    url: "/rest/detail/v3/getGroupDetail",
    params,
    withCredentials: false
  });
}

export function checkuserinfo(params) {
  return http({
    url: '/rest/friend/check',
    data: objectToUrlParams(params),
    method: 'post'
  })
}
// 获取用户个人信息
export function getUserInfo(params) {
  return http({
    url: `/rest/user/userinfo/${params.userId}`,
    data: params,
    method: "post",
    withCredentials: false
  });
}

export function getUserinfo_new(params) {
  return http({
    url: "/rest/personalCenter/v3/getProfileInfoData",
    params,
    withCredentials: false
  });
}

// 新版本获取用户详细信息接口
export function getPersonnelDetails(providerId) {
  return http({
    withCredentials: false,
    url: `/rest/v5/personal/detailInfo/get`,
    params: { providerId: providerId }
  });
}


// 获取用户服务需求数据
export function getUserServerData(params) {
  return http({
    url: "/rest/personalCenter/v3/getServicesData",
    params,
    withCredentials: false
  });
}
// 获取个人群组列表
export function getUserGroups(params) {
  return http({
    url: "/rest/personalCenter/v3/getGroupData",
    params,
    withCredentials: false
  });
}
// 获取用户的群列表(5.1.0新版本群组列表接口)
export function getGroupListData(providerId, params) {
  return http({
    url: `/rest/v5/personalCenter/group/get/${providerId}`,
    withCredentials: false,
    params
  });
}
// 获取用户的帖子列表(5.1.0新版本帖子列表接口)
export function getPostListData(providerId, params) {
  return http({
    url: `/rest/v5/personalCenter/helogig/get/${providerId}`,
    withCredentials: false,
    params
  });
}
// 改变点赞状态
export function changelikeStatus(id, params) {
  return http({
    method: "post",
    withCredentials: false,
    url: `/rest/home/like/${id}`,
    params
  });
}
// 获取个人公司列表
export function getUserCompanys(params) {
  return http({
    url: "/rest/personalCenter/v3/getEnterpriseData",
    params,
    withCredentials: false
  });
}
// 获取用户列表
export function getUserList(params) {
  return http({
    url: "/rest/list/v3/getPersonList",
    params,
    withCredentials: false
  });
}
// 获取群列表
export function getGroupList(params) {
  return http({
    url: "/rest/list/v3/getGroupList",
    params,
    withCredentials: false
  });
}
// v5获取群列表
export function getGroupListV5(params) {
  return http({
    url: "/rest/v5/group/list",
    params,
    withCredentials: false
  });
}


export function getCompanyList(params) {
  return http({
    url: "/rest/list/v3/getEnterpriseList",
    params,
    withCredentials: false
  });
}
export function getServicesList(params) {
  return http({
    url: "/rest/list/v3/getServiceJobList",
    params,
    withCredentials: false
  });
}
export function otherWayLogin(params) {
  return http({
    url: `/rest/oauth/render/${params.source}`,
    params,
    withCredentials: false
  });
}
export function autoRegister(params) {
  return http({
    url: "/rest/oauth/register",
    data: params,
    method: "post",
    withCredentials: false
  });
}

//注册账号
export function registerNew(params) {
  return http({
    url: "/rest/user/register",
    method: "post",
    withCredentials: false,
    data: objectToUrlParams(params)
  });
}

export function register(params) {
  return http({
    url: "/public/register.do",
    params,
    withCredentials: false
  });
}
export function registerByPhone(params) {
  return http({
    url: '/public/phone_register.do',
    params,
    withCredentials: false
  })
}
export function login(params) {
  return http({
    url: "/public/login.do",
    data: objectToUrlParams(params),
    method: "post",
    withCredentials: false
  });
}
export function replayViews(params) {
  return http({
    url: `/rest/review/operation/${params.operationId}`,
    params,
    method: 'post',
    withCredentials: false
  })
}

//v3搜索
export function getSearchData(params) {
  return http({ 
    url: '/rest/home/v3/searchHomeData',
    params,
    withCredentials: false
  })
}
//v5搜索
export function getSearchDataV5(params) {
  return http({
    url: '/rest/v5/home/search',
    params,
    withCredentials: false
  })
}

export function publishService(params, type = 'helogig') {
  return http({
    url: `/rest/helogig/v3/${type}`,
    method: 'post',
    data: params,
    withCredentials: false
  })
}
export function joinGroup(params) {
  return http({
    url: '/rest/helogig/v3/joinGroup',
    params,
    method: 'post',
    withCredentials: false
  })
}
export function exitGroup(params) {
  return http({
    url: '/public/v1/exitGroupMember.do',
    params,
    withCredentials: false
  })
}
export function addFriend(params) {
  return http({
    url: '/rest/friend/add',
    params,
    method: 'post',
    withCredentials: false
  })
}
export function delFriend(params) {
  return http({
    url: `/rest/friend/remove/${params.friendId}`,
    params,
    method: 'post',
    withCredentials: false
  })
}
export function logout() {
  return http({
    url: "/helome/logout.do",
    method: "get"
  });
}
export function deleteItemById(params, type) {
  return http({
    url: `/rest/helogig/v3/delete${type}`,
    method: 'post',
    params
  })
}
export function getDetailById(params, type) {
  return http({
    url: `/rest/detail/v3/get${type}`,
    params
  })
}
export function upDetail(params, type, id) {
  return http({
    url: `/rest/helogig/v3/${type}/${id}`,
    data: params,
    method: 'put'
  })
}
export function sendValidateCode(params) {
  return http({
    url: '/rest/system/sendValidateCode',
    method: 'post',
    params
  })
}
export function checkValidateCode(params) {
  return http({
    url: '/rest/system/checkValidateCode',
    method: 'post',
    params
  })
}
export function forgetPassword(params) {
  return http({
    url: '/rest/user/forgetPassword',
    method: 'post',
    params
  })
}