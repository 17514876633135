import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/user'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    User
  },
  state: {
    categoryId: '',
    countryId: '',
    keyword: '',
    selectedCategories: [],
    tags: [],
    unredNum:0
  },
  mutations: {
    setCateGoryId(state, data) {
      state.categoryId = data
    },
    setCountryId(state, data) {
      state.countryId = data
    },
    setKeyword(state, data) {
      state.keyword = data
    },
    setSelectedCategories(state, data) {
      state.selectedCategories = data
    },
    setTags(state, data) {
      state.tags = data
    },
    setUnredNum(state,num){
      state.unredNum = num
    }
  },
  actions: {
    commitCateGoryId: ({
      commit
    }, data) => {
      commit('setCateGoryId', data)
    },
    commitCountryId: ({
      commit
    }, data) => {
      commit('setCountryId', data)
    },
    commitKeyword: ({
      commit
    }, data) => {
      commit('setKeyword', data)
    },
    commitSelectedCategories: ({
      commit
    }, data) => {
      commit('setSelectedCategories', data)
    },
    comminTags: ({
      commit
    }, data) => {
      commit('setTags', data)
    },
    commitUnRed: ({
      commit
    }, data) => {
      commit('setUnredNum', data)
    }
  },
  getters: {
    getCateGoryId(state) {
      return state.categoryId
    },
    getCountryId(state) {
      return state.countryId
    },
    getKeywrod: state => state.keyword,
    getSelectedCategories: state => state.selectedCategories,
    getTags: state => state.tags
  }

})