export default {
    "langs": {
        "zh-CN": "китайский",
        "en": "английский",
        "fr": "французский",
        "es": "испанский",
        "ru-RU": "русский",
        "hi": "хинди",
        "ur": "урду язык",
        "ja": "японский",
        "ko": "корейский",
        "th": "тайский",
        "de": "немецкий",
        "it": "итальянский язык"
    },
    "responFail": "Загрузка не удалась",
    "responRetry": "Нажмите Повторить",
    "responEmpty": "Данных пока нет",
    "resendMsg": "Сообщение не отправлено, нажмите, чтобы повторить попытку",
    "sendImage": "Отправить картинку",
    "send": "послать",
    "timeAgo": {
        "0": "просто",
        "1": "{n} минут назад",
        "2": "{n} часов назад",
        "3": "вчера"
    },
    "copyGroup": "Скопируйте участников в новую группу обсуждения",
    "quitGroup": "Выйти из дискуссионной группы",
    "createGroup": "Создать дискуссионную группу",
    "inviteFriend": "Пригласить друзей",
    "login": "войти",
    "loginerror": "Ошибка входа!",
    "register": "регистрация",
    "keepOn": "Продолжайте входить",
    "forgetPwd": "Забыли пароль?",
    "logoutTip": "ОК, чтобы выйти",
    "resetPwd": "Сбросить пароль для входа",
    "all": "полный",
    "phoneErr": "Номер телефона не может быть пустым",
    "phoneformatErr": "Неверный формат номера телефона",
    "needNumber": "Пожалуйста, введите числовое значение",
    "registerErr": "Регистрация не удалась",
    "publish": "релиз",
    "group": "группы",
    "school": "школа",
    "profess": "специальность",
    "degree": "степень",
    "time": "время",
    "mustSchool": "Школа не может быть пустой",
    "mustCompany": "Компания не может быть пустой",
    "company": "бизнес",
    "workexp": "Опыт работы",
    "education": "Образовательный опыт",
    "companyName": "компании",
    "job": "офис",
    "name": "Полное имя",
    "address": "адрес",
    "called": "имя",
    "title": "заголовок",
    "price": "цена",
    "tags": "тег",
    "emoji": "выражение",
    "sendFile": "Отправить файл",
    "transferAccount": "передача",
    "image": "изображение",
    "video": "видео",
    "voice": "голос",
    "file": "файл",
    "groupRoule": {
        "0": "Открытая группа: все пользователи могут свободно присоединиться",
        "1": "Членство: после того, как пользователь отправит заявку, он может присоединиться к администратору группы.",
        "2": "Частная группа: не отображается при поиске в группе, и только группа может добавлять участников"
    },
    "man": "мужчина",
    "woman": "женщина",
    "mustBeTM": "Название / Имя Обязательно",
    "mustBeTag": "Заполните хотя бы одну метку",
    "mustBeCate": "Выберите хотя бы одну из категорий",
    "contentTip": "Заполните детали здесь ...",
    "uploadSuccess": "Загружен успешно!",
    "publishError": "Публикация не удалась!",
    "contactus": "Свяжитесь с нами",
    "privacy": "Политика конфиденциальности",
    "companyright": "авторское право",
    "sys_error": "Системная ошибка!",
    "replayEmpty": "Комментарии не могут быть пустыми!",
    "replay": "ответить",
    "replayErr": "Комментарий не удался",
    "delSuccess": "Удалено успешно",
    "defFail": "Удалить не удалось",
    "refresh": "Успешно обновлено",
    "updateSuccess": "Модификация прошла успешно, пожалуйста, войдите снова.",
    "old": "старый",
    "theNew": "новый",
    "changeFail": "Не удалось изменить пароль",
    "pwdlength": "Длина пароля",
    "getverchatCode": "Получить код подтверждения",
    "nopeoplezan": "Никому еще не понравилось, иди первым, чтобы похвалить!",
    "pwdResetSuccess": "Пароль был успешно изменен.",
    "email_required": "Почтовый ящик не может быть пустым",
    "phone_required": "Номер телефона не может быть пустым",
    done: 'ОК',
    cancle: 'Отмена',
    groupType: {
        '1': {
            name: 'Public Group',
            code: '1',
            desc: `Публичная группа: любой пользователь может свободно создавать и присоединяться к группе без просмотра. На платформе можно искать и просматривать имя группы, описание, членов.`
        },

        '2': {
            name: 'Группа участников',
            code: '2',
            desc: `Member Group: любой пользователь может свободно создавать группу и присоединяться к ней, что требует от владельца просмотра. Название, описание, члены можно искать и просматривать на платформе.`
        },
        '3': {
            name: 'Дискуссионная группа',
            code: '3',
            desc: `Дискуссионная группа: любой пользователь может создать ее, создатели и участники могут пригласить своих друзей присоединиться, название группы, членов можно искать и просматривать на платформе. `
        },
        '4': {
            name: 'Частная группа',
            code: '4',
            desc: `Частная группа: любой пользователь может создать, имя группы, описание, члены группы не могут быть найдены и просмотрены на платформе. `
        }
    },
    newPwd: 'Пожалуйста, введите новый пароль для входа',
    repNewPwd: 'Пожалуйста, повторите новый пароль',
    breakPwd: 'Восстановить пароль',
    // 2021年3月4号
    meetingTips: "Пожалуйста, сначала загрузите приложение Melinked или обновите приложение Melinked до последней версии.",
    // mechat协议
    mechat: {
      title: "Пользовательское соглашение Me Chat",
      text1: "Mechat — это продукт вопросов и ответов для чата с искусственным интеллектом, предоставляемый Maes Consulting. Когда пользователь соглашается с данным пользовательским соглашением, считается, что он согласен со всем содержанием данного пользовательского соглашения.",
      header1: "один. Как мы передаем и раскрываем личную информацию пользователей третьим лицам",
      text2: "При следующих обстоятельствах это приложение будет раскрывать личную информацию пользователя полностью или частично в соответствии с личными пожеланиями пользователя или правовыми нормами:",
      text3: "1. Раскрывается третьим лицам с предварительного согласия пользователя;",
      text4: "2. Для предоставления продуктов и услуг, запрошенных пользователем, необходимо передать личную информацию пользователя третьей стороне;",
      text5: "3. Согласно соответствующим положениям закона или требованиям административных или судебных органов раскрывать третьим лицам или административным или судебным органам;",
      text6: "4. Если пользователь нарушает соответствующие китайские законы и правила или это соглашение об обслуживании приложений или связанные с ним правила, об этом необходимо сообщить третьей стороне;",
      text7: "5. Если пользователь является квалифицированным истцом в области интеллектуальной собственности и подал жалобу, он должен раскрыть ее ответчику по запросу ответчика, чтобы обе стороны могли урегулировать возможные споры о правах;",
      text8: "6. Другие раскрытия информации, которые это приложение считает уместными в соответствии с законами, правилами или политиками веб-сайта.",
      header2: "2. Хранение и обмен информацией",
      text9: "Информация и данные о пользователях, собранные MeChat, будут храниться на серверах MeChat и (или) его дочерних компаний. На сервер в процессе чата со Мной будет загружаться только отправленная и полученная информация, которая будет использоваться для ответа модели ИИ.",
      header3: "3. Связанные с интеллектуальной собственностью",
      text10: "Мы не даем никаких гарантий законности, легитимности, целостности или качества контента, загружаемого или публикуемого пользователями. Пользователи несут риски, связанные с использованием или доверием к контенту или ресурсам, передаваемым программным обеспечением. В любом случае мы not не несет ответственности за любые возможные или фактические убытки или ущерб, возникшие в результате такого риска.",
      header4: "4. Юридическая ответственность и отказ от ответственности",
      text11: "В любом случае мы и наши партнеры не несем ответственности за любые прямые, косвенные, случайные, специальные и последующие убытки и риски, возникающие в результате использования или невозможности использования пользователями Me Chat. Если на нормальную работу службы влияют форс-мажорные обстоятельства, такие как технические сбои, мы и наши партнеры обещаем сотрудничать с соответствующими подразделениями как можно скорее, чтобы устранять и своевременно устранять их.",
      text12: "Информация, отправляемая моделью ИИ, генерируется компьютером и может быть фиктивной.Пользователей просят отличать правильное от неправильного. Когда пользователи используют это приложение для консультации по медицинским, юридическим и другим связанным вопросам, это приложение предоставляет только медицинские консультации и юридические консультации и не представляет медицинские услуги, юридические услуги или лечение. Пользователи должны консультироваться с врачами и соответствующими юридическими лицами перед использованием этого приложения и несут полную ответственность за медицинские и юридические решения. Информация и услуги в этом приложении предназначены только для справки и не представляют собой юридические услуги или медицинские решения для медицинских услуг, и мы не берем на себя никакой ответственности, возникающей в результате использования этого приложения.",
    },
    originalMail: "Исходное сообщение",
    emailRecipient: "получатель",
    emailFrom: "отправитель",
    CcTo: "СС",
}