export default {
  langs: {
    "zh-CN": "Chinese",
    en: "English",
    fr: "French",
    es: "Spanish",
    "ru-RU": "Russian",
    hi: "Hindi",
    ur: "Urdu",
    ja: "Japanese",
    ko: "Korean",
    th: "Thai",
    de: "German",
    it: "Italian"
  },
  responFail: "Failed to load",
  responRetry: "Click Retry",
  responEmpty: "No data",
  resendMsg: "Message failed to send, click to try again",
  sendImage: "send pictures",
  send: "send",
  timeAgo: {
    "0": "just",
    "1": "{n} minutes ago",
    "2": "{n} hours ago",
    "3": "yesterday"
  },
  copyGroup: "Copy members to a new discussion group",
  quitGroup: "Exit the discussion group",
  createGroup: "Create a discussion group",
  inviteFriend: "invite friends",
  login: "Login",
  loginerror: "Login failed!",
  register: "Register",
  keepOn: "Keep on",
  forgetPwd: "Forget password?",
  logoutTip: "OK to log out",
  resetPwd: "Reset login password",
  all: "All",
  phoneErr: "Phone number cannot be empty",
  phoneformatErr: "Phone number format is incorrect",
  needNumber: "Please enter a numeric value",
  registerErr: "registration failed",
  publish: "Post",
  group: "Group",
  school: "School",
  profess: "Profession",
  degree: "Bachelor of Science",
  time: "Time",
  mustSchool: "School cannot be empty",
  mustCompany: "Company can't be empty",
  company: "Enterprise",
  workexp: "Work experience",
  education: "Educational experience",
  companyName: "The company",
  job: "Position",
  name: "Name",
  address: "Address",
  called: "Name",
  title: "Title",
  price: "Price",
  tags: "Label",
  emoji: "expression",
  sendFile: "Send File",
  transferAccount: "Transfer",
  image: "image",
  video: "video",
  voice: "voice",
  file: "file",
  groupRoule: {
    "0": "Open group: All users are free to join",
    "1": "Membership: After the user submits the application, they can join by the group administrator.",
    "2": "Private group: Not visible in group search, and only the group can add members"
  },
  man: "male",
  woman: "Female",
  mustBeTM: "Title / Name Required",
  mustBeTag: "Fill in at least one label",
  mustBeCate: "Select at least one of the categories",
  contentTip: "Fill in the details here...",
  uploadSuccess: "Uploaded successfully!",
  publishError: "Publishing failed!",
  contactus: "contact us",
  privacy: "Privacy Policy",
  companyright: "all rights reserved",
  sys_error: "system error!",
  replayEmpty: "Comments can't be empty!",
  replay: "Reply",
  replayErr: "Comment failed",
  delSuccess: "successfully deleted",
  defFail: "failed to delete",
  refresh: "update completed",
  updateSuccess: "The modification is successful, please log in again.",
  old: "old",
  theNew: "new",
  changeFail: "Password modification failed",
  pwdlength: "Password length",
  getverchatCode: "get verification code",
  nopeoplezan: "No one has liked it yet, go to be the first one to praise!",
  pwdResetSuccess: "Password reset complete",
  done: 'Submit',
  cancle: 'Cancle',
  groupType: {
    '1': {
      name: 'open group',
      code: '1',
      desc: `open group: any user can create a group to join, no need to review. Group name, description, members can be searched on the platform, browse.`
    },

    '2': {
      name: 'member group',
      code: '2',
      desc: `member group: any user can create a group, free to join, requires group manager audit. Group name, description, members can be searched on the platform, browse.`
    },
    '3': {
      name: 'discussion group',
      code: '3',
      desc: `discussion group: any user can create, creator and members can invite their friends to join, group name, members can be searched on the platform, browse.`
    },
    '4': {
      name: 'private group',
      code: '4',
      desc: `private group: any user can create, group name, description, group members on the platform can not be searched, browse.`
    }
  },
  newPwd: 'Please enter a new login password',
  repNewPwd: "Please repeat the new password",
  breakPwd:'Retrieve password',
  // 2021年3月4号
  meetingTips: "Please download the Melinked App first or upgrade the Melinked APP to the latest version",
  // mechat协议
  mechat: {
    title: 'MeChat user agreement',
    text1:'Mechat is an AI chat product provided by Maes Consulting. When agreeing to this User Agreement, the User is deemed to have agreed to the entire content of this User Agreement.',
    header1: '1.How We Share and Disclose Your Personal Information with Third Parties',
    text2: `In the following cases, this application will disclose all or part of the user's personal information according to the user's personal wishes or the provisions of the law:`,
    text3: `1.1Disclosure to a third party with the prior consent of the user;`,
    text4: `1.2In order to provide the products and services requested by the user, it is necessary to share the user's personal information with a third party;`,
    text5: `1.3 Disclosure to a third party or administrative or judicial agency in accordance with the relevant provisions of the law or the requirements of administrative or judicial institutions;`,
    text6: '1.4 If the user violates relevant Chinese laws and regulations or this application service agreement or relevant rules, it needs to be disclosed to a third party;',
    text7: '1.5 If the user is a qualified intellectual property complainant and has filed a complaint, it shall be disclosed to the respondent at the request of the respondent so that both parties can deal with possible rights disputes;',
    text8: '1.6 Other disclosures that this application deems appropriate in accordance with laws, regulations or website policies.',
    header2: '2. Information storage and exchange',
    text9: `The information and materials collected by Mechat about users will be stored on the servers of Mechat and/or its affiliates. During the user's use of Mechat, only the information sent and received will be uploaded to the server for the AI model's reply.`,
    header3: '3. Intellectual property rights',
    text10:'We do not guarantee the legality, timeliness, completeness or quality of the content uploaded or posted by the user, and the user assumes the risk arising from the use of or reliance on the content or resources transmitted by the software, and we shall not be liable for any loss or damage that may or may be caused by such risk under any circumstances.',
    header4: '4. Legal Liability and Disclaimer',
    text11: `In any case, we and our partners shall not be liable for any direct, indirect, incidental, special and consequential damages and risks arising from the user's use or inability to use MeChat. If the normal operation of the service is affected by technical failures and other force majeure events, we and the Partner promise to cooperate with the relevant units in the first time and deal with and repair them in a timely manner, but we and the Partner shall not be liable for all losses suffered by the user as a result.`,
    text12: `The information emitted by the AI model is computer-generated and may be fictitious, leaving users to distinguish between right and wrong. When users use this application to consult medical, legal and other related issues, this application only provides medical advice and legal advice, and does not represent medical services, legal services or treatment. Users must consult a doctor and relevant legal personnel before using this application, and are ultimately responsible for medical and legal decisions. The information and services of this application should be for reference only, and do not constitute medical services, legal services or medical solutions, and we do not assume any responsibility arising from the use of this application.`,
  },
  originalMail: "Original email",
  emailRecipient: "To",
  emailFrom: "Sender",
  CcTo: "CC",
  // ==================================5.7.0==============================
  domainVerification: "Domain verification",
  verifyDNSConfig: "Verify DNS configuration",
  txtManualHeaderText: `MeMail is a free email service platform where you can use your own domain (such as: melinked.email) to generate email addresses and utilize email services. Before that, you need to go through the verification process, which involves providing proof of ownership for the domain in the first step, and completing DNS configuration verification in the second step. MeMail will verify the domain according to the domain verification rules to ensure that the domain you provide is valid and not a counterfeit address, and that you are the owner of the domain with domain configuration permissions.`,
  manualHeaderTitle: `Verify domain ownership through TXT record`,
  manualHeaderTitleTips: `（Verification illustrated using a foreign domain service provider as an example.）`,
  manualContent_1: "Please go to the service provider's official website of your domain(e.g:melinked.email)and log in.",
  manualContentInfo_1: "Before logging in, please ensure that you have the administrator ID and password for this domain, and then complete the login to enter the homepage.",
  manualContent_2: "Add a TXT record:",
  manualContentInfo_2: `Log in to the service provider's website homepage and click on the "Manage" button for this domain:`,
  manualContentInfo_3: `On the domain management page, find the domain you need to verify (e.g：melinked.email) and click the "Advanced DNS" button under the management options:`,
  manualContentInfo_4: `On the "Advanced DNS" page, click the "Add Record" button:`,
  manualContentInfo_5: "Go back to the domain verification page, copy the TXT record:",
  manualContentInfo_6: `Compare the contents of the TXT record table, fill in the data in the service provider's record items as required, and click "Save":`,
  manualContent_3: `After completing the above steps, return to this page and click the "Verify Domain" button below:`,
  manualContent_4: "Please be patient and wait for the verification result:",
  manualContentInfo_7: `TXT record verification may take 5 minutes to 24 hours, so please wait patiently. If failed, please follow the fail log instructions and try again.`,
  manualContent_5: "Add the MX, SPF:",
  manualContentInfo_8: "Return to the DNS verification page, check the list for the MX, SPF and copy the contents according to their respective type (all two records must be added):",
  manualContentInfo_9: "Compare the contents of the MX, SPF and fill in the data according to the requirements into the service provider's record item, then click save:",
  manualContent_6: `After completing the above steps, return to this page and click the "Verify DNS configuration" button below:`,
  manualContentInfo_10: "verification may take 5 minutes to 24 hours, so please wait patiently. If failed, please follow the fail log instructions and try again.",
  verificationSuccess: "Domain validation configuration is successful, welcome!",
  largeAttachment: "Large attachment",
  
};