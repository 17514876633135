/*eslint disable*/
import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Store from './store'
import {
  clearPublishCatch
} from "@/utils/help";
Vue.use(Router)
const router = new Router({
  // mode:'history',
  routes: [{
    path: '/',
    name: 'home',
    meta: {
      keepAlive: true, // 需要被缓存
      scollTopPosition: 0 //页面滚动位置
    },
    component: Home
  },
  // 专门用于在APP上展示的个人简介
  {
    path: '/detailsForApp/:id?/:language?',
    name: 'detailsForApp',
    component: () => import('./views/pages/detailsForApp.vue')
  },
  // 隐私条款页面
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("./views/pages/privacy.vue")
  },
  // ==============兼容新版本分享出来的帖子详情====================
  {
    path: '/postContentDetails/:id?/:language?',
    name: 'postContentDetails',
    component: () => import('./views/pages/detail.vue')
  },
  {
    path: '/detail/:id?/:language?',
    name: 'detail',
    component: () => import('./views/pages/detail.vue')
  },
  // ============================================================
  {
    path: '/meeting/:data?',
    name: 'meeting',
    component: () => import('./views/pages/meeting.vue')
  },
  {
    path: '/download/:data?',
    name: 'download',
    component: () => import('./views/pages/download.vue')
  },
  // =====================兼容新版的群组详情======================
  {
    path: '/groupCenter/:id?/:language?',
    name: 'groupCenter',
    component: () => import('./views/pages/detail-group.vue')
  },
  {
    path: '/group/:id?/:language?',
    name: 'group',
    component: () => import('./views/pages/detail-group.vue')
  },
  // =============================================================
  // =====================兼容新版的个人中心========================
  {
    path: '/personalCenter/:id?/:language?',
    name: 'personalCenter',
    component: () => import('./views/pages/userInfo_newVersion.vue')
  },
  {
    path: '/user/:id?/:language?',
    name: 'user',
    component: () => import('./views/pages/userInfo_newVersion.vue')
  },
  // ===============================================================
  {
    path: '/list/:type?',
    name: 'list',
    meta: {
      keepAlive: true, // 需要被缓存
      scollTopPosition: 0 //页面滚动位置
    },
    component: () => import('./views/pages/list.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('./views/chat')
  }, {
    path: '/friends',
    name: 'friends',
    component: () => import('./views/friends')
  }, {
    path: '/my/:active?',
    name: 'my',
    component: () => import('./views/my')
  }, {
    path: '/login',
    name: 'login',
    component: () => import('./views/pages/login.vue')
  }, {
    path: '/breakPwd',
    name: 'breakPwd',
    component: () => import('./views/pages/breakPwd.vue')
  }, {
    path: '/publish',
    name: 'publish',
    component: () => import('./views/pages/publish.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('./views/pages/search.vue')
  }, {
    path: '/categorys',
    name: 'selectCategory',
    component: () => import('./views/pages/category-select.vue')
  }, {
    path: '/tagInput',
    name: 'tagInput',
    component: () => import('./views/pages/tag-input.vue')
  }, {
    path: '/my-groups',
    name: 'myGroups',
    component: () => import('./views/pages/my-groups.vue')
  }, {
    path: '/chatWith/:id',
    name: 'chatWith',
    component: () => import('./views/pages/chat-msg-action.vue')
  }, {
    path: '/langs/:type/:target?',
    name: 'langs',
    component: () => import('./views/pages/language')
  }, {
    path: '/chatGroupDetail/:id',
    name: 'chatGroupDetail',
    component: () => import('./views/chat/group/group-detail')
  },
  {
    path: '/chat-group-info',
    name: 'chatGroupInfo',
    component: () => import('./views/chat/group/group-info')
  }, {
    path: '/rest-pwd',
    name: 'resetPwd',
    component: () => import('./views/pages/resetPwd.vue')
  }, {
    path: '/notices/:id/:type',
    name: 'notices',
    component: () => import('./views/pages/notices.vue')
  },
  {
    path: '/search-msg',
    name: 'search-msg',
    component: () => import('./views/pages/search-msg.vue')
  },
  {
    path: '/share',
    name: '分享',
    component: () => import('./views/share/Index.vue')
  },
  {
    path: '/notificationSettings',
    name: '通知设置',
    component: () => import('./views/pages/notificationSettings.vue')
  },
  {
    path: '/mechat',
    name: '通知设置',
    component: () => import('./views/pages/mechat.vue')
  },
  {
    path: '/editable',
    name: '可编辑页面',
    component: () => import('./views/pages/editablePage.vue')
  },
  {
    path: '/verifyDomain',
    name: '验证域名页面',
    component: () => import('./views/pages/verifyDomain_client.vue')
  },
  ],
  scrollBehavior: () => {
    return {
      y: 0,
      x: 0
    }
  }
})
const _checkHasLogin = (to) => {
  const user = localStorage.getItem('userinfo') || {}
  console.log(user, '/user');

  if (!user || !Object.keys(user).length) {
    delete to.params.replay
    router.push({
      name: 'login',
      query: {
        redirect: to.name,
        ...to.params
      }
    })
  }
}
const _checkChatObj = () => {
  const chatObjStr = localStorage.getItem('chatObj')
  const chatObj = chatObjStr ? JSON.parse(chatObjStr) : {}
  return chatObj.id
}
router.beforeEach((to, from, next) => {
  if (from.name === 'search' && to.name !== 'list' || to.name === 'home') {
    Store.dispatch('commitKeyword', '')
  }
  if (from.name === 'publish' && to.name !== 'selectCategory' && to.name !== 'tagInput' && to.name !== 'chatGroupInfo') {
    clearPublishCatch()
  }
  if (to.name === 'chatWith') {
    if (_checkChatObj() != to.params.id) {
      localStorage.setItem('chatObj', JSON.stringify({
        ...to.params
      }))
      delete to.params.name
      delete to.params.type
    }
  }
  const user = localStorage.getItem('userinfo') || {}
  if (to.name === 'login' && Object.keys(user).length) {
    router.push({
      name: 'home'
    })
    next()
  }
  // if (from.name === 'chatWith') {
  // localStorage.removeItem('chatObj')
  // }
  switch (to.name) {
    case 'chat':
    case 'my':
    case 'friends':
    case 'publish':
    case 'chatWith':
      _checkHasLogin(to)
      next()
      break;
    default:
      next()
      break
  }
})
export default router