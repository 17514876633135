export default {
    "langs": {
        "zh-CN": "Chinesisch",
        "en": "Englisch",
        "fr": "Französisch",
        "es": "Spanisch",
        "ru-RU": "Russisch",
        "hi": "Hindi",
        "ur": "Urdu",
        "ja": "Japanisch",
        "ko": "Koreanisch",
        "th": "Thai",
        "de": "Deutsch",
        "it": "Italienisch"
    },
    "responFail": "Laden fehlgeschlagen",
    "responRetry": "Klicken Sie auf Wiederholen",
    "responEmpty": "Noch keine Daten",
    "resendMsg": "Nachricht konnte nicht gesendet werden. Klicken Sie auf, um es erneut zu versuchen",
    "sendImage": "Bild senden",
    "send": "Senden",
    "timeAgo": {
        "0": "Nur",
        "1": "vor {n} Minuten",
        "2": "vor {n} Stunden",
        "3": "Gestern"
    },
    "copyGroup": "Kopieren Sie Mitglieder in eine neue Diskussionsgruppe",
    "quitGroup": "Verlasse die Diskussionsgruppe",
    "createGroup": "Erstellen Sie eine Diskussionsgruppe",
    "inviteFriend": "Freunde einladen",
    "login": "Einloggen",
    "loginerror": "Login fehlgeschlagen!",
    "register": "Registrierung",
    "keepOn": "Weiter einloggen",
    "forgetPwd": "Passwort vergessen?",
    "logoutTip": "OK, um sich abzumelden",
    "resetPwd": "Login-Passwort zurücksetzen",
    "all": "Alle",
    "phoneErr": "Die Telefonnummer darf nicht leer sein",
    "phoneformatErr": "Das Telefonnummernformat ist falsch",
    "needNumber": "Bitte geben Sie einen numerischen Wert ein",
    "registerErr": "Registrierung fehlgeschlagen",
    "publish": "Freigabe",
    "group": "Gruppe",
    "school": "Schule",
    "profess": "Professionell",
    "degree": "Abschluss",
    "time": "Zeit",
    "mustSchool": "Die Schule kann nicht leer sein",
    "mustCompany": "Firma kann nicht leer sein",
    "company": "Geschäft",
    "workexp": "Berufserfahrung",
    "education": "Pädagogische Erfahrung",
    "companyName": "Gesellschaft",
    "job": "Position",
    "name": "Name",
    "address": "Adresse",
    "called": "Name",
    "title": "Überschrift",
    "price": "Preis",
    "tags": "Label",
    "emoji": "Ausdruck",
    "sendFile": "Datei senden",
    "transferAccount": "Übertragen",
    "image": "Bild",
    "video": "Video",
    "voice": "Stimme",
    "file": "Datei",
    "groupRoule": {
        "0": "Offene Gruppe: Alle Benutzer können teilnehmen",
        "1": "Mitgliedschaft: Nachdem der Benutzer die Anwendung eingereicht hat, kann er dem Gruppenadministrator beitreten.",
        "2": "Private Gruppe: In der Gruppensuche nicht sichtbar, und nur die Gruppe kann Mitglieder hinzufügen"
    },
    "man": "Männlich",
    "woman": "Weiblich",
    "mustBeTM": "Titel / Name erforderlich",
    "mustBeTag": "Füllen Sie mindestens ein Etikett aus",
    "mustBeCate": "Wählen Sie mindestens eine der Kategorien aus",
    "contentTip": "Füllen Sie die Details hier aus ...",
    "uploadSuccess": "Erfolgreich hochgeladen!",
    "publishError": "Veröffentlichung fehlgeschlagen!",
    "contactus": "Sprechen Sie uns an",
    "privacy": "Datenschutzerklärung",
    "companyright": "Copyright",
    "sys_error": "Systemfehler!",
    "replayEmpty": "Kommentare dürfen nicht leer sein!",
    "replay": "Antworte",
    "replayErr": "Kommentar fehlgeschlagen",
    "delSuccess": "Erfolgreich gelöscht",
    "defFail": "Löschen fehlgeschlagen",
    "refresh": "Erfolgreich aktualisiert",
    "updateSuccess": "Die Änderung ist erfolgreich, bitte melden Sie sich erneut an.",
    "old": "Alt",
    "theNew": "Neu",
    "changeFail": "Passwortänderung fehlgeschlagen",
    "pwdlength": "Passwortlänge",
    "getverchatCode": "Bestätigungscode abrufen",
    "nopeoplezan": "Noch hat es niemand gemocht, sei der Erste, der lobt!",
    "pwdResetSuccess": "Das Passwort wurde erfolgreich geändert.",
    "email_required": "Mailbox darf nicht leer sein",
    "phone_required": "Die Telefonnummer darf nicht leer sein",
    done: 'sicher',
    cancle: 'absagen',
    groupType: {
        '1': {
            name: 'offene meute',
            code: '1',
            desc: `öffentlich: zum alles nutzer eine freiheit mit, nicht die Von namen,, mitglieder terrassen kann auffindbar.`
        },

        '2': {
            name: 'Für die mitgliedschaft',
            code: '2',
            desc: `mitglieder: zum alles nutzer eine freiheit mit, brauchen Von herrn die Von namen,, mitglieder terrassen kann auffindbar`
        },
        '3': {
            name: 'diskutiergruppe',
            code: '3',
            desc: `über: alles nutzer eine gründer mitglieder können ihre freunde einzuladen,, Von namen, mitglieder terrassen kann auffindbar`
        },
        '4': {
            name: 'geheime gruppe',
            code: '4',
            desc: `privat: zum alles nutzer eine Von namen,, Von mitglieder terrassen nicht erlaubt`
        }
    },
    newPwd: 'Bitte geben Sie ein neues Login-Passwort ein',
    repNewPwd: "Bitte wiederholen Sie das neue Passwort",
    breakPwd: 'Passwort abrufen',
    // 2021年3月4号
    meetingTips: "Bitte laden Sie zuerst die Melinked App herunter oder aktualisieren Sie die Melinked APP auf die neueste Version",
    // mechat协议
    mechat: {
      title: "Me Chat-Nutzungsvereinbarung",
      text1: "Mechat ist ein KI-Chat-Begleiter für Fragen und Antworten, der von Maes Consulting bereitgestellt wird. Wenn der Nutzer dieser Nutzungsvereinbarung zustimmt, wird davon ausgegangen, dass er dem gesamten Inhalt dieser Nutzungsvereinbarung zugestimmt hat.",
      header1: "eins. Wie wir die personenbezogenen Daten der Benutzer an Dritte weitergeben und offenlegen",
      text2: "Unter den folgenden Umständen wird diese Anwendung die persönlichen Daten des Benutzers ganz oder teilweise gemäß den persönlichen Wünschen des Benutzers oder den gesetzlichen Bestimmungen offenlegen:",
      text3: "1. Weitergabe an Dritte mit vorheriger Zustimmung des Nutzers;",
      text4: "2. Um die vom Benutzer angeforderten Produkte und Dienstleistungen bereitzustellen, ist es notwendig, die persönlichen Daten des Benutzers an Dritte weiterzugeben;",
      text5: "3. gemäß den einschlägigen gesetzlichen Bestimmungen oder den Anforderungen von Verwaltungs- oder Justizbehörden an Dritte oder Verwaltungs- oder Justizbehörden weiterzugeben;",
      text6: "4. Wenn der Benutzer gegen relevante chinesische Gesetze und Vorschriften oder gegen diese Anwendungsdienstleistungsvereinbarung oder damit verbundene Regeln verstößt, muss dies einem Dritten offengelegt werden;",
      text7: "5. Wenn der Benutzer ein qualifizierter Beschwerdeführer für geistiges Eigentum ist und eine Beschwerde eingereicht hat, muss er diese auf Verlangen des Beschwerdegegners dem Beschwerdegegner offenlegen, damit beide Parteien mögliche Rechtsstreitigkeiten behandeln können;",
      text8: "6. Andere Offenlegungen, die diese Anwendung in Übereinstimmung mit Gesetzen, Vorschriften oder Website-Richtlinien für angemessen hält.",
      header2: "2. Informationsspeicherung und -austausch",
      text9: "Die von MeChat gesammelten Informationen und Daten über Benutzer werden auf den Servern von MeChat und (oder) seinen verbundenen Unternehmen gespeichert. Nur die gesendeten und empfangenen Informationen werden beim Chatten mit mir auf den Server hochgeladen, die für die Antwort des KI-Modells verwendet werden.",
      header3: "3. Im Zusammenhang mit geistigem Eigentum",
      text10: "Wir geben keine Garantien für die Rechtmäßigkeit, Legitimität, Integrität oder Qualität der von Benutzern hochgeladenen oder veröffentlichten Inhalte. Benutzer tragen die Risiken, die sich aus der Nutzung oder dem Vertrauen auf die von der Software übermittelten Inhalte oder Ressourcen ergeben. In jedem Fall werden wir dies tun haftet nicht für mögliche oder tatsächliche Verluste oder Schäden, die sich aus einem solchen Risiko ergeben.",
      header4: "4. Gesetzliche Haftung und Haftungsausschluss",
      text11: "In jedem Fall haften wir und unsere Partner nicht für direkte, indirekte, zufällige, besondere und Folgeschäden und Risiken, die sich aus der Nutzung oder Unfähigkeit von Nutzern ergeben, Me Chat zu nutzen. Wenn der normale Betrieb des Dienstes durch Ereignisse höherer Gewalt wie technische Ausfälle beeinträchtigt wird, versprechen wir und unsere Partner, so schnell wie möglich mit den zuständigen Stellen zusammenzuarbeiten, um diese zeitnah zu beheben und zu beheben.",
      text12: "Die vom KI-Modell gesendeten Informationen werden vom Computer generiert und können fiktiv sein.Benutzer werden aufgefordert, richtig von falsch zu unterscheiden. Wenn Benutzer diese Anwendung verwenden, um medizinische, rechtliche oder andere damit zusammenhängende Fragen zu konsultieren, bietet diese Anwendung nur medizinische Beratung und Rechtsberatung und stellt keine medizinischen Dienstleistungen, juristischen Dienstleistungen oder Behandlungen dar. Benutzer müssen Ärzte und relevante juristische Personen konsultieren, bevor sie diese Anwendung verwenden, und sind letztendlich für medizinische und rechtliche Entscheidungen verantwortlich. Die Informationen und Dienstleistungen in dieser App dienen nur als Referenz und stellen keine Rechtsdienstleistungen oder medizinischen Lösungen für medizinische Dienstleistungen dar, und wir übernehmen keine Verantwortung, die sich aus der Nutzung dieser App ergibt.",
    },
    originalMail: "Die ursprüngliche Nachricht",
    emailRecipient: "Empfänger",
    emailFrom: "Absender",
    CcTo: "CC",
}