export default {
    langs: {
        "zh-CN": "chinez",
        en: "Engleză",
        fr: "limba franceza",
        es: "Spaniolă",
        "ru-RU": "Rusă",
        hi: "hindi",
        ur: "Urdu",
        ja: "japonez",
        ko: "coreeană",
        th: "thailandez",
        de: "limba germana",
        it: "Italiană",
    },
    responFail: "Incarcarea a esuat",
    responRetry: "faceți clic pe reîncercați",
    responEmpty: "Nu există date",
    resendMsg: "Trimiterea mesajului nu a reușit, dați clic pentru a încerca din nou",
    sendImage: "Trimite imagini",
    send: "trimite",
    //fastSend: "Apăsați {tasta} pentru a trimite rapid",
    //sendMsg: "Trimite un mesaj",
    //clickDownload: "faceți clic pentru a descărca",
    timeAgo: {
        0: "doar",
        1: "acum {n} minute",
        2: "acum {n} ore",
        3: "ieri",
    },
    copyGroup: "Copiați membrii în noul grup de discuții",
    quitGroup: "părăsiți grupul de discuții",
    createGroup: "crearea unui grup de discuții",
    inviteFriend: "invita prieteni",
    // web site
    login: "Log in",
    loginerror: "Autentificare eșuată!",
    register: "Inregistreaza-te",
    keepOn: "rămâneți conectat",
    forgetPwd: "ai uitat parola?",
    logoutTip: "OK pentru a te deconecta",
    resetPwd: "Resetați parola de conectare",
    all: "toate",
    // 注册
    phoneErr: "Numărul de telefon nu poate fi gol",
    phoneformatErr: "Formatul numărului de telefon este incorect",
    needNumber: "Vă rugăm să introduceți o valoare numerică",
    registerErr: "inregistrare esuata",
    // publish
    publish: "eliberare",
    group: "grup",
    school: "Şcoală",
    profess: "major",
    degree: "Licențiat în știință",
    time: "timp",
    desc: "descrie",
    mustSchool: "școala nu poate fi goală",
    mustCompany: "compania nu poate fi goală",
    company: "afacere",
    workexp: "experiență de muncă",
    education: "Experiență educațională",
    companyName: "companie",
    job: "Poziţie",
    name: "Nume",
    address: "abordare",
    called: "Nume",
    title: "titlu",
    price: "Preț",
    tags: "Eticheta",
    emoji: "expresie",
    sendFile: "Trimite fișier",
    transferAccount: "transfer",
    image: "imagine",
    video: "video",
    voice: "voce",
    file: "document",
    permissions: "permisiuni",
    groupRoule: {
        "0": "Grup public: toți utilizatorii sunt liberi să se alăture",
        "1": "Grup de membri: După ce utilizatorul depune cererea, acesta se poate alătura după ce a fost aprobat de administratorul grupului",
        "2": "Grupuri private: nu este vizibilă în căutarea de grup și numai grupul poate adăuga membri",
    },
    man: "masculin",
    woman: "Femeie",
    mustBeTM: "Titlul/Numele este obligatoriu",
    mustBeTag: "Completați cel puțin o etichetă",
    mustBeCate: "Alegeți cel puțin o categorie",
    contentTip: "Completează aici detaliile...",
    uploadSuccess: "Încărcat cu succes!",
    publishError: "Publicarea a eșuat!",
    // footer
    contactus: "contactaţi-ne",
    privacy: "Politica de confidențialitate",
    companyright: "toate drepturile rezervate",
    // 其他
    sys_error: "eroare de sistem!",
    replayEmpty: "Conținutul comentariului nu poate fi gol!",
    replay: "răspuns",
    replayErr: "comentariul a eșuat",
    delSuccess: "șters cu succes",
    defFail: "nu s-a șters",
    refresh: "actualizare finalizată",
    updateSuccess: "Modificarea a reușit, vă rugăm să vă conectați din nou",
    old: "vechi",
    theNew: "nou",
    changeFail: "Modificarea parolei a eșuat",
    pwdlength: "lungimea parolei",
    getverchatCode: "obține codul de verificare",
    nopeoplezan: "Nimănui nu-i place încă, fii primul căruia îi place!",
    pwdResetSuccess: "Resetarea parolei este finalizată",
    email_required: "E-mailul nu poate fi gol",
    phone_required: "Numărul de telefon mobil nu poate fi gol",
    addfriend: "Cererea de adăugare a prietenilor a fost trimisă cu succes, așteaptă ca cealaltă parte să fie de acord",
    Draft: "proiect",
    breakPwd: "Recuperează Parola",
    "Request to view history messages": "Aplicați pentru a vedea știrile istorice",
    "Wait for group manager to agree": "Așteptați ca proprietarul grupului să fie de acord",
    'Please enter a search term': "Introduceți cuvinte cheie de căutare",
    done: "Sigur",
    cancle: "Anulare",
    groupType: {
        '1': {
            name: "grup public",
            code: "1",
            desc: "„Grup deschis: orice utilizator poate crea un grup care este liber să se alăture fără revizuire. Numele grupului descrie membrii și poate fi căutat și răsfoit pe platformă. `",
        },
        '2': {
            name: "grup de membri",
            code: "2",
            desc: "„Grup de membri: Orice utilizator poate crea un grup care se poate alătura liber și trebuie să fie revizuit de proprietarul grupului. Numele grupului descrie membrii și poate fi căutat și răsfoit pe platformă. `",
        },
        '3': {
            name: "grup de discuții",
            code: "3",
            desc: "`Grup de discuții: orice utilizator poate crea creatori și membrii își pot invita prietenii să se alăture grupului. Numele membrilor pot fi căutați și răsfoiți pe platformă. `",
        },
        '4': {
            name: "grup privat",
            code: "4",
            desc: "„Grup privat: orice utilizator poate crea un nume de grup pentru a descrie membrii grupului și nu poate fi căutat și răsfoit pe platformă. `",
        }
    },
    newPwd: "Vă rugăm să introduceți o nouă parolă de conectare",
    repNewPwd: "Vă rugăm să reintroduceți noua parolă",
    // 2021年3月4号
    meetingTips: "Descărcați mai întâi aplicația melinked sau actualizați aplicația melinked la cea mai recentă versiune",
    // mechat协议
    mechat: {
        title: "Acordul utilizatorului Me Chat",
        text1: "Mechat este un produs de întrebări și răspunsuri însoțitor de chat AI oferit de Maes Consulting. Atunci când utilizatorul este de acord cu acest acord de utilizare, se consideră că a fost de acord cu întregul conținut al acestui acord de utilizare.",
        header1: "unu. Cum partajăm și dezvăluim informațiile personale ale utilizatorilor cu terțe părți",
        text2: "În următoarele circumstanțe, această aplicație va dezvălui informațiile personale ale utilizatorului în întregime sau parțial, conform dorințelor personale ale utilizatorului sau reglementărilor legale:",
        text3: "1. Dezvăluite terților cu acordul prealabil al utilizatorului;",
        text4: "2. Pentru a furniza produsele și serviciile solicitate de utilizator este necesară partajarea informațiilor personale ale utilizatorului cu o terță parte;",
        text5: "3. Conform prevederilor relevante ale legii, sau cerințelor agențiilor administrative sau judiciare, dezvăluiți terților sau agențiilor administrative sau judiciare;",
        text6: "4. În cazul în care utilizatorul încalcă legile și reglementările chineze relevante sau acest acord de servicii pentru aplicații sau regulile conexe, acesta trebuie să fie dezvăluit unei terțe părți;",
        text7: "5. În cazul în care utilizatorul este un reclamant calificat de proprietate intelectuală și a depus o plângere, acesta o va dezvălui pârâtului la solicitarea pârâtului, astfel încât ambele părți să poată soluționa eventualele litigii privind drepturile;",
        text8: "6. Alte dezvăluiri pe care această aplicație le consideră adecvate în conformitate cu legile, reglementările sau politicile site-ului web.",
        header2: "2. Stocarea și schimbul de informații",
        text9: "Informațiile și datele despre utilizatori colectate de MeChat vor fi stocate pe serverele MeChat și (sau) companiilor sale afiliate. Doar informațiile trimise și primite vor fi încărcate pe server în procesul de chat cu Mine, care vor fi folosite pentru răspunsul modelului AI.",
        header3: "3. Legat de proprietate intelectuală",
        text10: "Nu oferim nicio garanție pentru legalitatea, legitimitatea, integritatea sau calitatea conținutului încărcat sau publicat de utilizatori. Utilizatorii vor suporta riscurile care decurg din utilizarea sau bazarea pe conținutul sau resursele transmise de software. În orice caz, vom suporta not nu va fi responsabil pentru nicio pierdere sau daune posibile sau reale care decurg din un astfel de risc.",
        header4: "4. Răspundere juridică și declinare a răspunderii",
        text11: "În orice caz, noi și partenerii noștri nu vom fi răspunzători pentru nicio daune și riscuri directe, indirecte, incidentale, speciale și ulterioare care decurg din utilizarea sau incapacitatea utilizatorilor de a utiliza Me Chat. În cazul în care funcționarea normală a serviciului este afectată de evenimente de forță majoră, cum ar fi defecțiuni tehnice, noi și partenerii noștri promitem să cooperăm cu unitățile relevante cât mai curând posibil pentru a le trata și a le repara în timp util.",
        text12: "Informațiile trimise de modelul AI sunt generate de computer și pot fi fictive.Utilizatorii sunt rugați să distingă bine de rău. Când utilizatorii folosesc această aplicație pentru a consulta probleme medicale, juridice și alte probleme conexe, această aplicație oferă doar sfaturi medicale și consiliere juridică și nu reprezintă servicii medicale, servicii juridice sau tratament. Utilizatorii trebuie să consulte medicii și persoanele juridice relevante înainte de a utiliza această aplicație și sunt în ultimă instanță responsabili pentru deciziile medicale și legale. Informațiile și serviciile din această aplicație sunt doar pentru referință și nu constituie servicii legale sau soluții medicale pentru servicii medicale și nu ne asumăm nicio responsabilitate care decurge din utilizarea acestei aplicații.",
    },
    originalMail: "Mesajul original",
    emailRecipient: "destinatar",
    emailFrom: "expeditor",
    CcTo: "CC",
};
