import { render, staticRenderFns } from "./thumb.vue?vue&type=template&id=248c287c&scoped=true&"
import script from "./thumb.vue?vue&type=script&lang=js&"
export * from "./thumb.vue?vue&type=script&lang=js&"
import style0 from "./thumb.vue?vue&type=style&index=0&id=248c287c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "248c287c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\main project\\h5\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('248c287c')) {
      api.createRecord('248c287c', component.options)
    } else {
      api.reload('248c287c', component.options)
    }
    module.hot.accept("./thumb.vue?vue&type=template&id=248c287c&scoped=true&", function () {
      api.rerender('248c287c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/thumb.vue"
export default component.exports