export default {
    "langs": {
        "zh-CN": "cinese",
        "en": "inglese",
        "fr": "francese",
        "es": "spagnolo",
        "ru-RU": "russo",
        "hi": "hindi",
        "ur": "lingua Urdu",
        "ja": "giapponese",
        "ko": "coreano",
        "th": "Thai",
        "de": "tedesco",
        "it": "lingua italiana"
    },
    "responFail": "Caricamento fallito",
    "responRetry": "Fai clic su Riprova",
    "responEmpty": "Nessun dato ancora",
    "resendMsg": "Impossibile inviare il messaggio, fare clic per riprovare",
    "sendImage": "Invia foto",
    "send": "inviare",
    "timeAgo": {
        "0": "solo",
        "1": "{n} minuti fa",
        "2": "{n} ore fa",
        "3": "ieri"
    },
    "copyGroup": "Copia i membri in un nuovo gruppo di discussione",
    "quitGroup": "Esci dal gruppo di discussione",
    "createGroup": "Crea un gruppo di discussione",
    "inviteFriend": "Invita amici",
    "login": "Entra",
    "loginerror": "Accesso fallito!",
    "register": "registrazione",
    "keepOn": "Continua ad accedere",
    "forgetPwd": "Hai dimenticato la password?",
    "logoutTip": "OK per disconnettersi",
    "resetPwd": "Reimposta password di accesso",
    "all": "completo",
    "phoneErr": "Il numero di telefono non può essere vuoto",
    "phoneformatErr": "Il formato del numero di telefono non è corretto",
    "needNumber": "Si prega di inserire un valore numerico",
    "registerErr": "Registrazione non riuscita",
    "publish": "rilascio",
    "group": "gruppi",
    "school": "scuola",
    "profess": "specialità",
    "degree": "laurea",
    "time": "tempo",
    "mustSchool": "La scuola non può essere vuota",
    "mustCompany": "La società non può essere vuota",
    "company": "affari",
    "workexp": "Esperienza lavorativa",
    "education": "Esperienza educativa",
    "companyName": "società",
    "job": "ufficio",
    "name": "nome completo",
    "address": "indirizzo",
    "called": "nome",
    "title": "titolo",
    "price": "prezzo",
    "tags": "etichetta",
    "emoji": "espressione",
    "sendFile": "Invia file",
    "transferAccount": "trasferimento",
    "image": "immagine",
    "video": "video",
    "voice": "voce",
    "file": "file",
    "groupRoule": {
        "0": "Apri gruppo: tutti gli utenti sono liberi di aderire",
        "1": "Iscrizione: dopo che l'utente ha inviato la domanda, può partecipare dall'amministratore del gruppo.",
        "2": "Gruppo privato: non visibile nella ricerca di gruppo e solo il gruppo può aggiungere membri"
    },
    "man": "maschio",
    "woman": "donna",
    "mustBeTM": "Titolo / Nome richiesti",
    "mustBeTag": "Compila almeno un'etichetta",
    "mustBeCate": "Seleziona almeno una delle categorie",
    "contentTip": "Compila i dettagli qui ...",
    "uploadSuccess": "Caricato con successo!",
    "publishError": "Pubblicazione fallita!",
    "contactus": "Contattaci",
    "privacy": "Politica sulla privacy",
    "companyright": "diritto d'autore",
    "sys_error": "Errore di sistema!",
    "replayEmpty": "I commenti non possono essere vuoti!",
    "replay": "rispondere",
    "replayErr": "Commento non riuscito",
    "delSuccess": "Eliminato con successo",
    "defFail": "Eliminazione non riuscita",
    "refresh": "Aggiornato con successo",
    "updateSuccess": "La modifica ha esito positivo, accedi di nuovo.",
    "old": "vecchio",
    "theNew": "nuovo",
    "changeFail": "Modifica password non riuscita",
    "pwdlength": "Lunghezza della password",
    "getverchatCode": "Ottieni il codice di verifica",
    "nopeoplezan": "A nessuno è ancora piaciuto, vai a essere il primo a lodare!",
    "pwdResetSuccess": "La password è stata modificata con successo",
    "email_required": "La cassetta postale non può essere vuota",
    "phone_required": "Il numero di telefono non può essere vuoto",
    done: 'OK',
    cancle: 'Annulla',
    groupType: {
        '1': {
            name: 'Gruppo pubblico',
            code: '1',
            desc: `Gruppo pubblico: qualsiasi utente può creare e unirsi liberamente al gruppo senza revisione. Nome del gruppo, descrizione, membri possono essere cercati e sfogliati sulla piattaforma.`
        },

        '2': {
            name: 'Gruppo di membri',
            code: '2',
            desc: `Gruppo di membri: qualsiasi utente può creare e unirsi liberamente al gruppo, il che richiede che il proprietario lo riveda.Nome, descrizione, membri del gruppo possono essere cercati e sfogliati sulla piattaforma.`
        },
        '3': {
            name: 'Gruppo di discussione',
            code: '3',
            desc: `Gruppo di discussione: qualsiasi utente può crearlo, i creatori e i membri possono invitare i propri amici a partecipare, il nome del gruppo, i membri possono essere cercati e sfogliati sulla piattaforma. `
        },
        '4': {
            name: 'Gruppo privato',
            code: '4',
            desc: `Gruppo privato: qualsiasi utente può creare, nome del gruppo, descrizione, membri del gruppo non possono essere cercati e sfogliati sulla piattaforma.`
        }
    },
    newPwd: "Inserisci una nuova password di accesso",
    repNewPwd: "Ripeti la nuova password",
    breakPwd: 'Recupera password',
    // 2021年3月4号
    meetingTips: "Scarica prima l'app Melinked o aggiorna l'APP Melinked alla versione più recente",
    // mechat协议
    mechat: {
      title: "Me Chat Accordo con l&#39;utente",
      text1: "Mechat è un prodotto Q&amp;A compagno di chat AI fornito da Maes Consulting. Quando l&#39;utente accetta questo accordo con l&#39;utente, si considera che abbia accettato l&#39;intero contenuto di questo accordo con l&#39;utente.",
      header1: "uno. Come condividiamo e divulghiamo le informazioni personali degli utenti con terze parti",
      text2: "Nelle seguenti circostanze, questa applicazione divulgherà le informazioni personali dell&#39;utente in tutto o in parte secondo i desideri personali dell&#39;utente o le normative legali:",
      text3: "1. Divulgati a terzi previo consenso dell&#39;utente;",
      text4: "2. Al fine di fornire i prodotti ei servizi richiesti dall&#39;utente, è necessario condividere le informazioni personali dell&#39;utente con una terza parte;",
      text5: "3. In base alle disposizioni di legge in materia, o alle esigenze degli organi amministrativi o giudiziari, comunicare a terzi o organi amministrativi o giudiziari;",
      text6: "4. Se l&#39;utente viola le leggi e i regolamenti cinesi pertinenti o questo contratto di servizio dell&#39;applicazione o regole correlate, deve essere divulgato a terzi;",
      text7: "5. Se l&#39;utente è un denunciante di proprietà intellettuale qualificato e ha presentato un reclamo, lo comunicherà al convenuto su richiesta del convenuto in modo che entrambe le parti possano gestire eventuali controversie sui diritti;",
      text8: "6. Altre divulgazioni che questa Applicazione ritenga opportune ai sensi di leggi, regolamenti o politiche del sito web.",
      header2: "2. Archiviazione e scambio di informazioni",
      text9: "Le informazioni e i dati sugli utenti raccolti da MeChat saranno archiviati sui server di MeChat e (o) delle sue società affiliate. Solo le informazioni inviate e ricevute verranno caricate sul server nel processo di chattare con Me, che verrà utilizzato per la risposta del modello AI.",
      header3: "3. Proprietà intellettuale relativa",
      text10: "Non forniamo alcuna garanzia per la legalità, la legittimità, l&#39;integrità o la qualità dei contenuti caricati o pubblicati dagli utenti. Gli utenti si assumono i rischi derivanti dall&#39;utilizzo o dall&#39;affidamento ai contenuti o alle risorse trasmesse dal software. In ogni caso, ci impegniamo not non sarà responsabile per qualsiasi perdita o danno possibile o effettivo derivante da tale rischio.",
      header4: "4. Responsabilità legale ed esclusione di responsabilità",
      text11: "In ogni caso, noi ei nostri partner non saremo responsabili per eventuali danni e rischi diretti, indiretti, incidentali, speciali e conseguenti derivanti dall&#39;uso o dall&#39;incapacità degli utenti di utilizzare Me Chat. Se il normale funzionamento del servizio è influenzato da eventi di forza maggiore come guasti tecnici, noi e i nostri partner promettiamo di collaborare con le unità competenti il prima possibile per affrontarli e ripararli in modo tempestivo.",
      text12: "Le informazioni inviate dal modello AI sono generate dal computer e possono essere fittizie, agli utenti viene chiesto di distinguere il giusto dallo sbagliato. Quando gli utenti utilizzano questa applicazione per consultare questioni mediche, legali e altre questioni correlate, questa applicazione fornisce solo consulenza medica e consulenza legale e non rappresenta servizi medici, servizi legali o cure. Gli utenti devono consultare i medici e le persone giuridiche interessate prima di utilizzare questa applicazione e sono i responsabili ultimi delle decisioni mediche e legali. Le informazioni e i servizi in questa app sono solo di riferimento e non costituiscono servizi legali o soluzioni mediche per servizi medici e non ci assumiamo alcuna responsabilità derivante dall&#39;uso di questa app.",
    },
    originalMail: "Il messaggio originale",
    emailRecipient: "destinatario",
    emailFrom: "mittente",
    CcTo: "CC",
}