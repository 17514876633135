var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "item-header",
      on: {
        click: function($event) {
          return _vm.handlerToUserPage(_vm.item.id)
        }
      }
    },
    [
      _c("Avatar", {
        attrs: {
          src: _vm.item.avatar,
          round: true,
          width: "40px",
          height: "40px"
        }
      }),
      _c(
        "div",
        { staticClass: "item-header__info" },
        [
          _c("div", { staticClass: "base" }, [
            _c("span", {
              staticClass: "name",
              domProps: { innerHTML: _vm._s(_vm.item.name) }
            }),
            _c("i", {
              staticClass: "time",
              domProps: {
                innerHTML: _vm._s(_vm.item.time || _vm.item.publishTime)
              }
            })
          ]),
          _vm._t("price")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }