export default {
    "langs": {
        "zh-CN": "중국어",
        "en": "영어",
        "fr": "프랑스어",
        "es": "스페인어",
        "ru-RU": "러시아어",
        "hi": "힌디어",
        "ur": "우르두어",
        "ja": "일본어",
        "ko": "한국어",
        "th": "태국 사람",
        "de": "독일어",
        "it": "이탈리아어"
    },
    "responFail": "로드 실패",
    "responRetry": "재 시도를 클릭하십시오",
    "responEmpty": "아직 데이터가 없습니다",
    "resendMsg": "메시지를 보내지 못했습니다. 다시 시도하십시오",
    "sendImage": "사진 보내기",
    "send": "보내기",
    "timeAgo": {
        "0": "그냥",
        "1": "{n} 분 전",
        "2": "{n} 시간 전",
        "3": "어제"
    },
    "copyGroup": "회원을 새로운 토론 그룹에 복사",
    "quitGroup": "토론 그룹 종료",
    "createGroup": "토론 그룹 만들기",
    "inviteFriend": "친구 초대",
    "login": "로그인",
    "loginerror": "로그인 실패!",
    "register": "등록",
    "keepOn": "로그인 유지",
    "forgetPwd": "비밀번호를 잊었습니까?",
    "logoutTip": "로그 아웃 OK",
    "resetPwd": "로그인 비밀번호 재설정",
    "all": "모두",
    "phoneErr": "전화 번호는 비워 둘 수 없습니다",
    "phoneformatErr": "전화 번호 형식이 잘못되었습니다",
    "needNumber": "숫자를 입력하십시오",
    "registerErr": "등록 실패",
    "publish": "릴리즈",
    "group": "그룹",
    "school": "학교",
    "profess": "프로페셔널",
    "degree": "학위",
    "time": "시간",
    "mustSchool": "학교는 비워 둘 수 없습니다",
    "mustCompany": "회사는 비워 둘 수 없습니다",
    "company": "사업",
    "workexp": "업무 경험",
    "education": "교육 경험",
    "companyName": "회사",
    "job": "직위",
    "name": "이름",
    "address": "주소",
    "called": "이름",
    "title": "직함",
    "price": "가격",
    "tags": "라벨",
    "emoji": "표현",
    "sendFile": "파일 보내기",
    "transferAccount": "양도",
    "image": "사진",
    "video": "비디오",
    "voice": "음성",
    "file": "파일",
    "groupRoule": {
        "0": "공개 그룹 : 모든 사용자가 자유롭게 참여할 수 있습니다",
        "1": "멤버십 : 사용자가 애플리케이션을 제출 한 후 그룹 관리자가 참여할 수 있습니다.",
        "2": "비공개 그룹 : 그룹 검색에 표시되지 않으며 그룹 만 회원을 추가 할 수 있습니다"
    },
    "man": "남성",
    "woman": "여성",
    "mustBeTM": "제목 / 이름 필요",
    "mustBeTag": "하나 이상의 라벨을 작성",
    "mustBeCate": "카테고리 중 하나 이상을 선택하십시오",
    "contentTip": "여기에 세부 사항을 입력하십시오 ...",
    "uploadSuccess": "성공적으로 업로드되었습니다!",
    "publishError": "게시하지 못했습니다!",
    "contactus": "연락주세요",
    "privacy": "개인 정보 보호 정책",
    "companyright": "저작권",
    "sys_error": "시스템 오류!",
    "replayEmpty": "댓글은 비워 둘 수 없습니다!",
    "replay": "답장",
    "replayErr": "댓글 실패",
    "delSuccess": "성공적으로 삭제되었습니다",
    "defFail": "삭제 실패",
    "refresh": "성공적으로 업데이트",
    "updateSuccess": "수정이 성공했습니다. 다시 로그인하십시오.",
    "old": "오래된",
    "theNew": "새로운",
    "changeFail": "비밀번호 수정 실패",
    "pwdlength": "비밀번호 길이",
    "getverchatCode": "인증 코드 받기",
    "nopeoplezan": "아직 그것을 좋아 한 사람은 없습니다. 칭찬 할 첫 번째 사람이 되십시오!",
    "pwdResetSuccess": "비밀번호가 수정되었습니다.",
    "email_required": "사서함은 비워 둘 수 없습니다",
    "phone_required": "전화 번호는 비워 둘 수 없습니다",
    done: '확실히',
    cancle: '취소',
    groupType: {
        '1': {
            name: '오픈소사이어티',
            code: '1',
            desc: `공개 군은 모든 사용자 창설 할 수 있다. 자유의 무리에 가입하면서 심사 할 필요 가 없다. 이름들이 묘사 멤버 플랫폼에서 검색 할 수 있고 훑어보다.`
        },

        '2': {
            name: '회원군',
            code: '2',
            desc: `회원들은 모든 사용자 창설 할 수 있 으며 자유 합류 한 무리들이 주 심사 할 필요 가 있다. 이름들이 묘사 멤버 플랫폼에서 검색 할 수 있고 훑어보다.`
        },
        '3': {
            name: '토론 그룹',
            code: '3',
            desc: ` 토론 그룹:어떤 사용자 모두 생성 할 수 있 으며 창립자 및 성원들은 모두 자신의 절친 한 친구인을 영입 할 수 있 으며 이름들이 멤버 플랫폼에서 검색 할 수 있고 훑어보다.`
        },
        '4': {
            name: '비밀 단체',
            code: '4',
            desc: `마시 오 군은 모든 사용자 창설 할 수 있 으며 이름들이 묘사, 멤버들이 플랫폼에서 검색 되지 않고 할 수 있습니다.`
        }
    },
    newPwd: '새 로그인 비밀번호를 입력하십시오',
    repNewPwd: "새 비밀번호를 반복하십시오",
    breakPwd: '비밀번호 검색',
    // 2021年3月4号
    meetingTips: "먼저 Melinked 앱을 다운로드하거나 Melinked 앱을 최신 버전으로 업그레이드하세요.",
    // mechat协议
    mechat: {
      title: "Me Chat 사용자 계약",
      text1: "메챗은 매스컨설팅에서 제공하는 AI 채팅 컴패니언 Q&amp;A 상품입니다. 이용자가 본 이용약관에 동의하는 경우 본 이용약관의 전체 내용에 동의한 것으로 간주합니다.",
      header1: "하나. 당사가 사용자의 개인 정보를 제3자와 공유 및 공개하는 방법",
      text2: "다음과 같은 상황에서 이 애플리케이션은 사용자의 개인적인 의사 또는 법적 규정에 따라 사용자의 개인 정보 전체 또는 일부를 공개합니다.",
      text3: "1. 이용자의 사전 동의를 받아 제3자에게 공개하는 경우",
      text4: "2. 이용자가 요청한 상품 및 서비스를 제공하기 위하여 이용자의 개인정보를 제3자와 공유하기 위하여 필요한 경우",
      text5: "3. 법률의 관련 규정 또는 행정 또는 사법 기관의 요구에 따라 제3자 또는 행정 또는 사법 기관에 공개",
      text6: "4. 사용자가 관련 중국 법률 및 규정 또는 본 응용 프로그램 서비스 계약 또는 관련 규칙을 위반하는 경우 제3자에게 공개해야 합니다.",
      text7: "5. 이용자가 적격한 지적재산권 고발인으로서 고발장을 제출한 경우, 피고의 요청에 따라 피고에게 이를 공개하여 양 당사자가 발생할 수 있는 권리 분쟁을 처리할 수 있도록 해야 합니다.",
      text8: "6. 법률, 규정 또는 웹사이트 정책에 따라 본 애플리케이션이 적절하다고 판단하는 기타 공개.",
      header2: "2. 정보 저장 및 교환",
      text9: "MeChat에서 수집한 사용자에 대한 정보 및 데이터는 MeChat 및(또는) 그 계열사의 서버에 저장됩니다. 주고받은 정보만 Me와 채팅하는 과정에서 서버에 업로드되어 AI 모델의 답장에 사용됩니다.",
      header3: "3. 지적재산권 관련",
      text10: "당사는 사용자가 업로드하거나 게시한 콘텐츠의 합법성, 합법성, 무결성 또는 품질에 대해 어떠한 보증도 하지 않으며, 사용자는 소프트웨어에서 전송된 콘텐츠 또는 리소스를 사용하거나 의존함으로써 발생하는 위험을 부담해야 합니다. 그러한 위험으로 인해 발생할 수 있는 또는 실제적인 손실이나 손해에 대해 책임을 지지 않습니다.",
      header4: "4. 법적 책임 및 면책 조항",
      text11: "어떠한 경우에도 당사와 당사의 파트너는 사용자의 Me Chat 사용 또는 사용 불가능으로 인해 발생하는 직접적, 간접적, 우발적, 특별 및 후속 손해 및 위험에 대해 책임을 지지 않습니다. 기술적인 장애 등 불가항력적인 사유로 서비스의 정상적인 운영에 지장이 발생한 경우, 당사 및 파트너사는 해당 부서와 최대한 빠른 시일 내에 협조하여 적시에 처리 및 복구할 것을 약속하며, 이에 대한 책임을 지지 않습니다.",
      text12: "AI 모델이 보낸 정보는 컴퓨터가 생성한 것으로 가상의 정보일 수 있어 사용자에게 옳고 그름을 구분하도록 요청한다. 사용자가 이 애플리케이션을 사용하여 의료, 법률 및 기타 관련 문제를 상담할 때 이 애플리케이션은 의료 자문 및 법률 자문만 제공하며 의료 서비스, 법률 서비스 또는 치료를 나타내지 않습니다. 사용자는 이 애플리케이션을 사용하기 전에 의사 및 관련 법적 담당자와 상담해야 하며, 의료 및 법적 결정에 대한 궁극적인 책임은 사용자에게 있습니다. 이 앱의 정보 및 서비스는 참고용이며 법률 서비스 또는 의료 서비스를 위한 의료 솔루션을 구성하지 않으며 이 앱 사용으로 인해 발생하는 어떠한 책임도 지지 않습니다.",
    },
    originalMail: "원본 메시지",
    emailRecipient: "받는 사람",
    emailFrom: "보내는 사람",
    CcTo: "CC",
}