export default {
    langs: {
        "zh-CN": "chiński",
        en: "język angielski",
        fr: "Francuski",
        es: "hiszpański",
        "ru-RU": "Rosyjski",
        hi: "hinduski",
        ur: "urdu",
        ja: "język japoński",
        ko: "koreański",
        th: "tajski",
        de: "Niemiecki",
        it: "Włoski",
    },
    responFail: "Nie udało się załadować",
    responRetry: "kliknij ponów próbę",
    responEmpty: "Brak danych",
    resendMsg: "Nie udało się wysłać wiadomości, kliknij, aby spróbować ponownie",
    sendImage: "wyślij zdjęcia",
    send: "wysłać",
    //fastSend: "Naciśnij {klawisz}, aby szybko wysłać",
    //sendMsg: "Wysłać wiadomość",
    //clickDownload: "kliknij, aby pobrać",
    timeAgo: {
        0: "Tylko",
        1: "{n} minut temu",
        2: "{n} godzin temu",
        3: "Wczoraj",
    },
    copyGroup: "Skopiuj członków do nowej grupy dyskusyjnej",
    quitGroup: "opuścić grupę dyskusyjną",
    createGroup: "utwórz grupę dyskusyjną",
    inviteFriend: "zaprosić przyjaciół",
    // web site
    login: "Zaloguj sie",
    loginerror: "Logowanie nie powiodło się!",
    register: "rejestr",
    keepOn: "Pozostań zalogowany",
    forgetPwd: "zapomniałeś hasła?",
    logoutTip: "OK, aby się wylogować",
    resetPwd: "Zresetuj hasło logowania",
    all: "Wszystko",
    // 注册
    phoneErr: "Numer telefonu nie może być pusty",
    phoneformatErr: "Format numeru telefonu jest nieprawidłowy",
    needNumber: "Wprowadź wartość liczbową",
    registerErr: "Rejestracja nie powiodła się",
    // publish
    publish: "uwolnienie",
    group: "Grupa",
    school: "Szkoła",
    profess: "główny",
    degree: "Licencjat",
    time: "czas",
    desc: "opisać",
    mustSchool: "szkoła nie może być pusta",
    mustCompany: "firma nie może być pusta",
    company: "przedsiębiorstwo",
    workexp: "doświadczenie zawodowe",
    education: "Edukacyjne doświadczenie",
    companyName: "firma",
    job: "Pozycja",
    name: "Nazwa",
    address: "adres",
    called: "nazwa",
    title: "tytuł",
    price: "cena",
    tags: "Etykieta",
    emoji: "wyrażenie",
    sendFile: "Wyślij plik",
    transferAccount: "przenosić",
    image: "zdjęcie",
    video: "wideo",
    voice: "głos",
    file: "dokument",
    permissions: "uprawnienia",
    groupRoule: {
        "0": "Grupa publiczna: wszyscy użytkownicy mogą dołączyć",
        "1": "Grupa członkowska: Po przesłaniu zgłoszenia użytkownik może dołączyć po zatwierdzeniu przez administratora grupy",
        "2": "Grupy prywatne: niewidoczne w wyszukiwaniu grup i tylko grupa może dodawać członków",
    },
    man: "Mężczyzna",
    woman: "Kobieta",
    mustBeTM: "Tytuł/nazwisko jest wymagane",
    mustBeTag: "Wypełnij co najmniej jeden tag",
    mustBeCate: "Wybierz co najmniej jedną kategorię",
    contentTip: "Tutaj uzupełnij dane...",
    uploadSuccess: "Przesłano pomyślnie!",
    publishError: "Publikowanie nie powiodło się!",
    // footer
    contactus: "Skontaktuj się z nami",
    privacy: "Polityka prywatności",
    companyright: "Wszelkie prawa zastrzeżone",
    // 其他
    sys_error: "błąd systemu!",
    replayEmpty: "Treść komentarza nie może być pusta!",
    replay: "odpowiedź",
    replayErr: "komentarz nie powiódł się",
    delSuccess: "pomyślnie usunięte",
    defFail: "nie udało się usunąć",
    refresh: "aktualizacja zakończona",
    updateSuccess: "Modyfikacja powiodła się, zaloguj się ponownie",
    old: "stary",
    theNew: "nowy",
    changeFail: "Modyfikacja hasła nie powiodła się",
    pwdlength: "długość hasła",
    getverchatCode: "uzyskać kod weryfikacyjny",
    nopeoplezan: "Nikt jeszcze tego nie lubi, polub to jako pierwszy!",
    pwdResetSuccess: "Resetowanie hasła zakończone",
    email_required: "E-mail nie może być pusty",
    phone_required: "Numer telefonu komórkowego nie może być pusty",
    addfriend: "Wniosek o dodanie znajomych został pomyślnie wysłany, oczekuje na akceptację drugiej strony",
    Draft: "projekt",
    breakPwd: "odzyskać hasło",
    "Request to view history messages": "Zastosuj, aby wyświetlić wiadomości historyczne",
    "Wait for group manager to agree": "Poczekaj, aż właściciel grupy wyrazi zgodę",
    'Please enter a search term': "Wprowadź słowa kluczowe wyszukiwania",
    done: "Jasne",
    cancle: "Anulować",
    groupType: {
        '1': {
            name: "grupa publiczna",
            code: "1",
            desc: "`Grupa otwarta: każdy użytkownik może utworzyć grupę, do której można dołączyć bez sprawdzania. Nazwa grupy opisuje członków i można ją przeszukiwać i przeglądać na platformie. `",
        },
        '2': {
            name: "grupa członkowska",
            code: "2",
            desc: "`Grupa członkowska: każdy użytkownik może utworzyć grupę, do której może swobodnie dołączać i która musi zostać sprawdzona przez właściciela grupy. Nazwa grupy opisuje członków i można ją przeszukiwać i przeglądać na platformie. `",
        },
        '3': {
            name: "Grupa dyskusyjna",
            code: "3",
            desc: "`Grupa dyskusyjna: każdy użytkownik może tworzyć twórców, a członkowie mogą zapraszać swoich znajomych do przyłączenia się do grupy. Nazwę członków można wyszukiwać i przeglądać na platformie. `",
        },
        '4': {
            name: "prywatna grupa",
            code: "4",
            desc: "`Prywatna grupa: każdy użytkownik może utworzyć nazwę grupy opisującą członków grupy i nie można jej wyszukiwać ani przeglądać na platformie. `",
        }
    },
    newPwd: "Wprowadź nowe hasło logowania",
    repNewPwd: "Wprowadź ponownie nowe hasło",
    // 2021年3月4号
    meetingTips: "Najpierw pobierz aplikację melinked lub zaktualizuj aplikację melinked do najnowszej wersji",
    // mechat协议
    mechat: {
        title: "Umowa użytkownika czatu",
        text1: "Mechat to towarzyszący czatowi AI produkt Q&amp;A dostarczany przez Maes Consulting. Kiedy użytkownik zgadza się na niniejszą umowę użytkownika, uznaje się, że zgadza się na całą treść tej umowy użytkownika.",
        header1: "jeden. Jak udostępniamy i ujawniamy dane osobowe użytkowników stronom trzecim",
        text2: "W następujących okolicznościach ta aplikacja ujawni dane osobowe użytkownika w całości lub w części zgodnie z osobistymi życzeniami użytkownika lub przepisami prawnymi:",
        text3: "1. Ujawnione osobom trzecim za uprzednią zgodą użytkownika;",
        text4: "2. W celu dostarczenia żądanych przez użytkownika produktów i usług konieczne jest udostępnienie danych osobowych użytkownika stronie trzeciej;",
        text5: "3. Zgodnie z odpowiednimi przepisami prawa lub wymaganiami organów administracyjnych lub sądowych ujawniać osobom trzecim lub organom administracyjnym lub sądowym;",
        text6: "4. Jeśli użytkownik naruszy odpowiednie chińskie przepisy ustawowe i wykonawcze lub niniejszą umowę o świadczenie usług aplikacji lub powiązane zasady, należy to ujawnić stronie trzeciej;",
        text7: "5. Jeżeli użytkownik jest kwalifikowanym skarżącym dotyczącym własności intelektualnej i złożył skargę, na żądanie pozwanego ujawni ją pozwanemu, aby obie strony mogły rozpatrzyć ewentualne spory dotyczące praw;",
        text8: "6. Inne ujawnienia, które ta aplikacja uzna za stosowne zgodnie z przepisami ustawowymi, wykonawczymi lub zasadami dotyczącymi witryny.",
        header2: "2. Przechowywanie i wymiana informacji",
        text9: "Informacje i dane o użytkownikach zebrane przez MeChat będą przechowywane na serwerach MeChat i (lub) firm stowarzyszonych. Tylko wysłane i odebrane informacje zostaną przesłane na serwer w trakcie czatowania ze Mną, które posłużą do odpowiedzi modelu AI.",
        header3: "3. Związane z własnością intelektualną",
        text10: "Nie udzielamy żadnych gwarancji co do legalności, zasadności, integralności lub jakości treści przesyłanych lub publikowanych przez użytkowników. Użytkownicy ponoszą ryzyko wynikające z korzystania lub polegania na treści lub zasobach przesyłanych przez oprogramowanie. W każdym przypadku będziemy nie ponosi odpowiedzialności za ewentualne lub rzeczywiste straty lub szkody wynikające z takiego ryzyka.",
        header4: "4. Odpowiedzialność prawna i wyłączenie odpowiedzialności",
        text11: "W żadnym przypadku my i nasi partnerzy nie ponosimy odpowiedzialności za jakiekolwiek bezpośrednie, pośrednie, przypadkowe, specjalne i późniejsze szkody i ryzyko wynikające z korzystania lub niemożności korzystania przez użytkowników z usługi Me Chat. Jeżeli normalne działanie serwisu zostanie zakłócone przez zdarzenia siły wyższej, takie jak awarie techniczne, my i nasi partnerzy obiecujemy współpracować z odpowiednimi jednostkami tak szybko, jak to możliwe, aby zająć się nimi i naprawić je w odpowiednim czasie.",
        text12: "Informacje przesyłane przez model AI są generowane przez komputer i mogą być fikcyjne.Użytkownicy proszeni są o odróżnienie dobra od zła. Gdy użytkownicy korzystają z tej aplikacji w celu konsultacji medycznych, prawnych i innych powiązanych kwestii, ta aplikacja zapewnia jedynie porady medyczne i prawne i nie reprezentuje usług medycznych, usług prawnych ani leczenia. Użytkownicy muszą skonsultować się z lekarzami i odpowiednimi osobami prawnymi przed użyciem tej aplikacji i ponoszą ostateczną odpowiedzialność za decyzje medyczne i prawne. Informacje i usługi w tej aplikacji służą wyłącznie celom informacyjnym i nie stanowią usług prawnych ani rozwiązań medycznych dla usług medycznych, a my nie ponosimy żadnej odpowiedzialności wynikającej z korzystania z tej aplikacji.",
    },
    originalMail: "Oryginalna wiadomość",
    emailRecipient: "odbiorca",
    emailFrom: "nadawca",
    CcTo: "CC",
};
