var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "page", staticClass: "page-wap" },
    [
      _vm.showWeixinTips
        ? _c("div", { staticClass: "weixinTips" }, [
            _c("img", {
              staticClass: "weixinTipsImg",
              attrs: { src: require("../assets/images/meeting/weixinTips.png") }
            })
          ])
        : _vm._e(),
      _c("Header", { attrs: { id: "header" } }, [
        _c(
          "span",
          {
            staticClass: "menu-box",
            attrs: { slot: "left-one" },
            on: {
              click: function($event) {
                return _vm.handlerChangeStatus("menuDrawer")
              }
            },
            slot: "left-one"
          },
          [
            _c(
              "van-dropdown-menu",
              {
                staticClass: "menu-dropdown",
                attrs: { "active-color": "#33cc66" }
              },
              [
                _c("van-dropdown-item", {
                  ref: "item",
                  attrs: { "title-class": "hidden", options: _vm.categories },
                  on: { change: _vm.handlerCateGoryChange },
                  model: {
                    value: _vm.curCategoryId,
                    callback: function($$v) {
                      _vm.curCategoryId = $$v
                    },
                    expression: "curCategoryId"
                  }
                })
              ],
              1
            ),
            _c("i", {
              staticClass: "me-icon-menu",
              staticStyle: { "margin-left": "0" }
            })
          ],
          1
        ),
        _c("i", {
          staticClass: "me-icon-location",
          attrs: { slot: "left-second" },
          on: {
            click: function($event) {
              return _vm.handlerChangeStatus("showRegion")
            }
          },
          slot: "left-second"
        }),
        _c("i", {
          staticClass: "me-icon-search",
          attrs: { slot: "left-thred" },
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "search" })
            }
          },
          slot: "left-thred"
        }),
        _c(
          "span",
          {
            staticClass: "header-right",
            attrs: { slot: "right-one" },
            slot: "right-one"
          },
          [
            _c("languageSelect", {
              attrs: { slot: "right-one", locale: _vm.locale },
              on: {
                change: _vm.changeLang,
                close: function($event) {
                  return _vm.handlerChangeStatus("showLang")
                },
                open: function($event) {
                  return _vm.handlerChangeStatus("showLang")
                }
              },
              slot: "right-one"
            }),
            _c("span", { staticClass: "langBox" }, [
              _vm._v("\n        " + _vm._s(_vm.langName) + "\n        "),
              _c("i", { staticClass: "me-icon-down-solid" })
            ])
          ],
          1
        )
      ]),
      _c("TreeSelect", {
        attrs: { items: _vm.regions, show: _vm.showRegion },
        on: {
          closed: _vm.handlerChangeShowRegion,
          getCountry: _vm.handlerGetCountryCode
        }
      }),
      _c("div", { staticClass: "openApp" }, [
        _c("img", {
          attrs: { src: require("../assets/meeting/meetingIcon.png") }
        }),
        _c("div", { staticClass: "txt" }, [_vm._v("Melinked")]),
        _vm.deviceBrand != "HUAWEI"
          ? _c(
              "div",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    return _vm.openApp(true)
                  }
                }
              },
              [
                !_vm.showOpenLoading
                  ? [_vm._v("Open app")]
                  : [
                      _c("van-loading", {
                        attrs: { type: "spinner", size: "20" }
                      })
                    ]
              ],
              2
            )
          : _vm._e()
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "scrollUpdate",
              rawName: "v-scrollUpdate",
              value: _vm.updateData,
              expression: "updateData"
            },
            {
              name: "scroll",
              rawName: "v-scroll",
              value: {
                distance: 150,
                onTop: _vm._onTop,
                onBottom: _vm._onBottom
              },
              expression:
                "{\n      distance: 150,\n      onTop: _onTop,\n      onBottom: _onBottom,\n    }"
            }
          ],
          staticClass: "my-home",
          style: { height: _vm.scrollBoxHei }
        },
        [
          _c(
            "div",
            { ref: "container", staticClass: "container" },
            [
              (_vm.homeData.providerData && _vm.homeData.providerData.length) ||
              (_vm.homeData.recommendHelogigs &&
                _vm.homeData.recommendHelogigs.length) ||
              (_vm.homeData.groupData && _vm.homeData.groupData.length) ||
              (_vm.homeData.enterpriseData &&
                _vm.homeData.enterpriseData.length)
                ? [
                    _vm.homeData.recommendHelogigs &&
                    _vm.homeData.recommendHelogigs.length
                      ? _c(
                          "div",
                          { staticClass: "services" },
                          _vm._l(_vm.homeData.recommendHelogigs, function(
                            item,
                            index
                          ) {
                            return _c(
                              "Item",
                              { key: index, attrs: { item: item } },
                              [
                                item.price
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "item__header__info--price",
                                        attrs: { slot: "price" },
                                        slot: "price"
                                      },
                                      [_vm._v(_vm._s(item.price))]
                                    )
                                  : _vm._e()
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e()
                  ]
                : _vm.loading
                ? _c("van-loading", {
                    staticClass: "loading",
                    attrs: { color: "#33cc66" }
                  })
                : _c("div", { staticClass: "empty" }, [
                    _c("i", { staticClass: "me-icon-empty" })
                  ])
            ],
            2
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "pubshBtn",
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "publish" })
            }
          }
        },
        [_c("i", { staticClass: "me-icon-plus" })]
      ),
      _vm.deviceBrand != "HUAWEI"
        ? _c(
            "div",
            {
              staticClass: "openBtn",
              on: {
                click: function($event) {
                  return _vm.openApp(true)
                }
              }
            },
            [
              !_vm.showOpenLoading
                ? [_vm._v("Open app")]
                : [
                    _c("van-loading", {
                      attrs: { type: "spinner", size: "20" }
                    })
                  ]
            ],
            2
          )
        : _vm._e(),
      _c("Tabbar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }