export default {
    langs: {
        "zh-CN": "Chinese",
        en: "Engels",
        fr: "Frans",
        es: "Spaans",
        "ru-RU": "Russisch",
        hi: "Hindi",
        ur: "Urdu",
        ja: "Japans",
        ko: "Koreaans",
        th: "Thais",
        de: "Duits",
        it: "Italiaans",
    },
    responFail: "Laden mislukt",
    responRetry: "klik opnieuw",
    responEmpty: "Geen gegevens",
    resendMsg: "Kan bericht niet verzenden, klik om het opnieuw te proberen",
    sendImage: "Stuur foto&#39;s",
    send: "versturen",
    //fastSend: "Druk op {key} om snel te verzenden",
    //sendMsg: "Stuur een bericht",
    //clickDownload: "klik om te downloaden",
    timeAgo: {
        0: "zojuist",
        1: "{n} minuten geleden",
        2: "{n} uur geleden",
        3: "gisteren",
    },
    copyGroup: "Kopieer leden naar nieuwe discussiegroep",
    quitGroup: "verlaat de discussiegroep",
    createGroup: "discussiegroep maken",
    inviteFriend: "nodig vrienden uit",
    // web site
    login: "Log in",
    loginerror: "Aanmelden mislukt!",
    register: "register",
    keepOn: "blijf ingelogd",
    forgetPwd: "wachtwoord vergeten?",
    logoutTip: "OK om uit te loggen",
    resetPwd: "Stel het inlogwachtwoord opnieuw in",
    all: "alle",
    // 注册
    phoneErr: "Telefoonnummer mag niet leeg zijn",
    phoneformatErr: "Het formaat van het telefoonnummer is onjuist",
    needNumber: "Voer een numerieke waarde in",
    registerErr: "registratie mislukt",
    // publish
    publish: "uitgave",
    group: "groep",
    school: "School",
    profess: "belangrijk",
    degree: "Bachelor in de wetenschappen",
    time: "tijd",
    desc: "beschrijven",
    mustSchool: "school kan niet leeg zijn",
    mustCompany: "bedrijf kan niet leeg zijn",
    company: "onderneming",
    workexp: "werkervaring",
    education: "Leerzame ervaring",
    companyName: "bedrijf",
    job: "Positie",
    name: "Naam",
    address: "adres",
    called: "naam",
    title: "titel",
    price: "prijs",
    tags: "Etiket",
    emoji: "uitdrukking",
    sendFile: "Verstuur bestand",
    transferAccount: "overdracht",
    image: "afbeelding",
    video: "video",
    voice: "stem",
    file: "document",
    permissions: "rechten",
    groupRoule: {
        "0": "Openbare groep: alle gebruikers kunnen gratis lid worden",
        "1": "Ledengroep: Nadat de gebruiker de aanvraag heeft ingediend, kan hij lid worden na goedkeuring door de groepsbeheerder",
        "2": "Privégroepen: niet zichtbaar bij het zoeken naar groepen en alleen de groep kan leden toevoegen",
    },
    man: "mannelijk",
    woman: "vrouwelijk",
    mustBeTM: "Titel/Naam is vereist",
    mustBeTag: "Vul minimaal één tag in",
    mustBeCate: "Kies minimaal één categorie",
    contentTip: "Vul hier de gegevens in...",
    uploadSuccess: "Succesvol geüpload!",
    publishError: "Publiceren mislukt!",
    // footer
    contactus: "Neem contact met ons op",
    privacy: "Privacybeleid",
    companyright: "alle rechten voorbehouden",
    // 其他
    sys_error: "systeemfout!",
    replayEmpty: "Reactie-inhoud mag niet leeg zijn!",
    replay: "antwoord",
    replayErr: "commentaar mislukt",
    delSuccess: "succesvol verwijderd",
    defFail: "verwijderen mislukt",
    refresh: "bijwerken voltooid",
    updateSuccess: "De wijziging is gelukt, log a.u.b. opnieuw in",
    old: "oud",
    theNew: "nieuw",
    changeFail: "Het wijzigen van het wachtwoord is mislukt",
    pwdlength: "wachtwoord lengte",
    getverchatCode: "verificatiecode ophalen",
    nopeoplezan: "Niemand vindt het nog leuk, wees de eerste die het leuk vindt!",
    pwdResetSuccess: "Wachtwoord opnieuw instellen voltooid",
    email_required: "E-mail mag niet leeg zijn",
    phone_required: "Mobiel telefoonnummer mag niet leeg zijn",
    addfriend: "De aanvraag voor het toevoegen van vrienden is succesvol verzonden, in afwachting van akkoord van de andere partij",
    Draft: "voorlopige versie",
    breakPwd: "wachtwoord ophalen",
    "Request to view history messages": "Toepassen om historisch nieuws te bekijken",
    "Wait for group manager to agree": "Wacht tot de groepseigenaar akkoord gaat",
    'Please enter a search term': "Voer zoekwoorden in",
    done: "Zeker",
    cancle: "Annuleren",
    groupType: {
        '1': {
            name: "openbare groep",
            code: "1",
            desc: "`Open groep: elke gebruiker kan een groep maken die vrij is om zonder beoordeling lid van te worden. De groepsnaam beschrijft de leden en kan worden doorzocht en gebladerd op het platform. `",
        },
        '2': {
            name: "lid groep",
            code: "2",
            desc: "`Ledengroep: elke gebruiker kan een groep maken die vrij kan deelnemen en moet worden beoordeeld door de groepseigenaar. De groepsnaam beschrijft de leden en kan worden doorzocht en gebladerd op het platform. `",
        },
        '3': {
            name: "discussiegroep",
            code: "3",
            desc: "`Discussiegroep: elke gebruiker kan makers maken en leden kunnen hun vrienden uitnodigen om lid te worden van de groepsnaam. Leden kunnen op het platform worden gezocht en doorzocht. `",
        },
        '4': {
            name: "besloten groep",
            code: "4",
            desc: "`Privégroep: elke gebruiker kan een groepsnaam maken om de groepsleden te beschrijven en kan niet worden gezocht en bekeken op het platform. `",
        }
    },
    newPwd: "Voer een nieuw inlogwachtwoord in",
    repNewPwd: "Voer het nieuwe wachtwoord opnieuw in",
    // 2021年3月4号
    meetingTips: "Download eerst de melinked-app of upgrade de melinked-app naar de nieuwste versie",
    // mechat协议
    mechat: {
        title: "Me Chat Gebruikersovereenkomst",
        text1: "Mechat is een Q&amp;A-product voor AI-chatgenoten dat wordt aangeboden door Maes Consulting. Wanneer de gebruiker akkoord gaat met deze gebruikersovereenkomst, wordt hij geacht akkoord te gaan met de volledige inhoud van deze gebruikersovereenkomst.",
        header1: "een. Hoe we persoonlijke informatie van gebruikers delen en vrijgeven aan derden",
        text2: "Onder de volgende omstandigheden zal deze applicatie de persoonlijke informatie van de gebruiker geheel of gedeeltelijk vrijgeven volgens de persoonlijke wensen van de gebruiker of wettelijke voorschriften:",
        text3: "1. Geopenbaard aan derden met voorafgaande toestemming van de gebruiker;",
        text4: "2. Om de door de gebruiker gevraagde producten en diensten te kunnen leveren, is het noodzakelijk om de persoonlijke gegevens van de gebruiker met een derde partij te delen;",
        text5: "3. Volgens de relevante bepalingen van de wet, of de vereisten van administratieve of gerechtelijke instanties, bekendmaken aan derden of administratieve of gerechtelijke instanties;",
        text6: "4. Als de gebruiker de relevante Chinese wet- en regelgeving of deze applicatieserviceovereenkomst of gerelateerde regels overtreedt, moet dit worden bekendgemaakt aan een derde partij;",
        text7: "5. Indien de gebruiker een gekwalificeerde klager op het gebied van intellectueel eigendom is en een klacht heeft ingediend, zal hij deze op verzoek van de verweerder bekendmaken aan de verweerder, zodat beide partijen eventuele rechtengeschillen kunnen afhandelen;",
        text8: "6. Andere openbaarmakingen die deze applicatie passend acht in overeenstemming met wetten, voorschriften of websitebeleid.",
        header2: "2. Opslag en uitwisseling van informatie",
        text9: "De door MeChat verzamelde informatie en gegevens over gebruikers worden opgeslagen op de servers van MeChat en (of) de aan haar gelieerde ondernemingen. Alleen de verzonden en ontvangen informatie wordt geüpload naar de server tijdens het chatten met mij, die zal worden gebruikt voor het antwoord van het AI-model.",
        header3: "3. Intellectueel eigendom gerelateerd",
        text10: "We geven geen enkele garantie voor de wettigheid, legitimiteit, integriteit of kwaliteit van de inhoud die door gebruikers is geüpload of gepubliceerd. Gebruikers dragen de risico&#39;s die voortvloeien uit het gebruik van of het vertrouwen op de inhoud of bronnen die door de software worden verzonden. In elk geval zullen we not is niet aansprakelijk voor enig mogelijk of daadwerkelijk verlies of schade die voortvloeit uit een dergelijk risico.",
        header4: "4. Wettelijke aansprakelijkheid en disclaimer",
        text11: "In ieder geval zijn wij en onze partners niet aansprakelijk voor enige directe, indirecte, incidentele, speciale en daaropvolgende schade en risico&#39;s die voortvloeien uit het gebruik of het onvermogen van gebruikers om Me Chat te gebruiken. Als de normale werking van de service wordt beïnvloed door overmacht, zoals technische storingen, beloven wij en onze partners om zo snel mogelijk samen te werken met relevante eenheden om deze tijdig op te lossen en te repareren.",
        text12: "De informatie die door het AI-model wordt verzonden, wordt door de computer gegenereerd en kan fictief zijn.Gebruikers wordt gevraagd goed van fout te onderscheiden. Wanneer gebruikers deze applicatie gebruiken om medische, juridische en andere gerelateerde kwesties te raadplegen, geeft deze applicatie alleen medisch advies en juridisch advies en vertegenwoordigt deze geen medische diensten, juridische diensten of behandelingen. Gebruikers moeten artsen en relevante rechtspersonen raadplegen voordat ze deze applicatie gebruiken en zijn uiteindelijk verantwoordelijk voor medische en juridische beslissingen. De informatie en diensten in deze app zijn alleen ter referentie en vormen geen juridische diensten of medische oplossingen voor medische diensten, en we aanvaarden geen enkele verantwoordelijkheid die voortvloeit uit het gebruik van deze app.",
    },
    originalMail: "Het originele bericht",
    emailRecipient: "ontvanger",
    emailFrom: "afzender",
    CcTo: "CC",
};
