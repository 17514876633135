<template>
  <van-popup
    v-model="status"
    position="top"
    class="me-popup"
    @closed="closed"
    :style="styleStr"
  >
    <van-tree-select
      :items="items"
      :active-id.sync="activeId"
      :main-active-index.sync="curActiveIndex"
      @click-item="selectedItem"
      @click-nav="selectedNav"
    />
  </van-popup>
</template>
<script>
export default {
  name: "treeSelect",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    styleStr:{
      type:Object,
      default:()=>{
        return {
          height:'80%'
        }
      }
    }
  },
  computed: {
    // status() {
    //   return this.show;
    // }
  },
  data() {
    return {
      activeId: 0,
      curActiveIndex: 1,
      status: false
    };
  },
  watch: {
    show: {
      handler(val) {
        this.status = val;
      },
      deep: true
    }
  },
  created() {
    this.status = this.show;
  },
  methods: {
    closed() {
      this.$emit("closed");
    },
    selectedItem(data) {
      this.$emit("getCountry", data.value);
      this.closed();
    },
    selectedNav(index) {
      this.activeId = 0;
      this.curActiveIndex = index;
      if (index === 0) {
        this.$emit("getCountry", index);
        this.closed();
      }
    }
  }
};
</script>
<style lang="stylus">
.page-wap {
  .me-popup, .van-overlay {
    top: 54px !important;
  }
}

.me-popup {
  .van-tree-select {
    height: 100% !important;

    .van-sidebar-item--select {
      border-color: #33cc66;
    }

    .van-tree-select__content {
      .van-tree-select__item {
        font-weight: normal;
        text-align: left;
        text-indent: 1.25rem;
        line-height:40px;
      }

      .van-tree-select__item--active {
        color: #33cc66;
      }
    }
  }
}
</style>