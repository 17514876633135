export default [
  { name: "wx", title: "Smile" },
  { name: "pz", title: "Grimace" },
  { name: "se", title: "Drooling" },
  { name: "fd", title: "Scowl" },
  { name: "dy", title: "Chill" },
  { name: "ll", title: "Sob" },
  { name: "hx", title: "Shy" },
  { name: "bz", title: "Silence" },
  { name: "shui", title: "Sleep" },
  { name: "dk", title: "Cry" },
  { name: "gg", title: "Embarrassed" },
  { name: "fn", title: "On Fire" },
  { name: "tp", title: "Wink" },
  { name: "cy", title: "Grin" },
  { name: "jy", title: "Surprised" },
  { name: "ng", title: "Frown" },
  { name: "kuk", title: "Cool" },
  { name: "lengh", title: "Blush" },
  { name: "zk", title: "Scream" },
  { name: "tuu", title: "Puke" },
  { name: "tx", title: "Chuckle" },
  { name: "ka", title: "Pleased" },
  { name: "baiy", title: "Doubtful" },
  { name: "am", title: "Smug" },
  { name: "jie", title: "Sneer" },
  { name: "kun", title: "Drowsy" },
  { name: "jk", title: "Panic" },
  { name: "lh", title: "Sweating" },
  { name: "hanx", title: "Laugh" },
  { name: "db", title: "Soldier" },
  { name: "fendou", title: "Strive" },
  { name: "zhm", title: "Scold" },
  { name: "yiw", title: "Confused" },
  { name: "xu", title: "Shhh" },
  { name: "yun", title: "Hypnotized" },
  { name: "zhem", title: "Frustrated" },
  { name: "shuai", title: "In the Dark" },
  { name: "kl", title: "Skull" },
  { name: "qiao", title: "Hammer" },
  { name: "zj", title: "Wave" },
  { name: "ch", title: "Relieved" },
  { name: "kb", title: "Pick Nose" },
  { name: "gz", title: "Applause" },
  { name: "qd", title: "Flushed" },
  { name: "huaix", title: "Hellooo" },
  { name: "zhh", title: "Snub 1" },
  { name: "yhh", title: "Snub 2" },
  { name: "hq", title: "Yawn" },
  { name: "bs", title: "Booo" },
  { name: "wq", title: "Distressed" },
  { name: "kk", title: "Sniffle" },
  { name: "yx", title: "Sly" },
  { name: "qq", title: "Pucker" },
  { name: "xia", title: "Uh Oh" },
  { name: "kel", title: "Whimper" },
  { name: "cd", title: "Cleaver" },
  { name: "xig", title: "Water Melon" },
  { name: "pj", title: "Beer" },
  { name: "lq", title: "Basketball" },
  { name: "pp", title: "Ping Pong" },
  { name: "kf", title: "Coffee" },
  { name: "fan", title: "Rice" },
  { name: "zt", title: "Pig" },
  { name: "mg", title: "Rose" },
  { name: "dx", title: "Wilt" },
  { name: "sa", title: "Kisses" },
  { name: "xin", title: "Heart" },
  { name: "xs", title: "Broken Heart" },
  { name: "dg", title: "Cake" },
  { name: "shd", title: "Lightning" },
  { name: "zhd", title: "Bomb" },
  { name: "dao", title: "Dagger" },
  { name: "zq", title: "Soccer" },
  { name: "pch", title: "Ladybug" },
  { name: "bb", title: "Poop" },
  { name: "yl", title: "Moon" },
  { name: "ty", title: "Sun" },
  { name: "lw", title: "Present" },
  { name: "yb", title: "Hug" },
  { name: "qiang", title: "Thumbs" },
  { name: "ruo", title: "Thumbs Down" },
  { name: "ws", title: "Shake" },
  { name: "shl", title: "Victory" },
  { name: "bq", title: "Admire" },
  { name: "gy", title: "Beckon" },
  { name: "qt", title: "Fist" },
  { name: "cj", title: "Pinky" },
  { name: "aini", title: "Love" },
  { name: "bu", title: "No No" },
  { name: "hd", title: "OK" }
  /* 
  {name: 'aiq', title: 'Lovebirds'},
  {name: 'fw', title: 'In Love'},
  {name: 'tiao', title: 'Waddle'},
  {name: 'fad', title: 'Sit'},
  {name: 'oh', title: 'Aaagh'},
  {name: 'zhq', title: 'Twirl'},
  {name: 'kt', title: 'Bow'},
  {name: 'ht', title: 'Turn Away'},
  {name: 'tsh', title: 'Jump'},
  {name: 'hsh', title: 'Surrender'},
  {name: 'jd', title: 'Hooray'},
  {name: 'jw', title: 'Hey Hey'},
  {name: 'xw', title: 'Smooches'},
  {name: 'youtj', title: 'Fighting Boy'},
  {name: 'zuotj', title: 'Fighting Girl'},
  */
];
