export default {
    langs: {
        "zh-CN": "čínština",
        en: "Angličtina",
        fr: "francouzština",
        es: "španělština",
        "ru-RU": "ruština",
        hi: "hindština",
        ur: "Urdu",
        ja: "japonský",
        ko: "korejština",
        th: "thajština",
        de: "Němec",
        it: "italština",
    },
    responFail: "Nepodařilo se načíst",
    responRetry: "klikněte na opakovat",
    responEmpty: "Žádná data",
    resendMsg: "Odeslání zprávy se nezdařilo, kliknutím to zkuste znovu",
    sendImage: "poslat obrázky",
    send: "poslat",
    //fastSend: "Stisknutím {klávesy} rychle odešlete",
    //sendMsg: "Poslat zprávu",
    //clickDownload: "kliknutím stáhnete",
    timeAgo: {
        0: "prostě",
        1: "před {n} minutami",
        2: "před {n} hodinami",
        3: "včera",
    },
    copyGroup: "Zkopírujte členy do nové diskusní skupiny",
    quitGroup: "opustit diskusní skupinu",
    createGroup: "vytvořit diskusní skupinu",
    inviteFriend: "pozvat přátele",
    // web site
    login: "Přihlásit se",
    loginerror: "Přihlášení selhalo!",
    register: "Registrovat",
    keepOn: "zůstat přihlášen",
    forgetPwd: "zapomenout heslo?",
    logoutTip: "OK pro odhlášení",
    resetPwd: "Obnovit přihlašovací heslo",
    all: "Všechno",
    // 注册
    phoneErr: "Telefonní číslo nesmí být prázdné",
    phoneformatErr: "Formát telefonního čísla je nesprávný",
    needNumber: "Zadejte číselnou hodnotu",
    registerErr: "registrace se nezdařila",
    // publish
    publish: "uvolnění",
    group: "skupina",
    school: "Škola",
    profess: "hlavní, důležitý",
    degree: "Bakalář věd",
    time: "čas",
    desc: "popsat",
    mustSchool: "škola nemůže být prázdná",
    mustCompany: "společnost nemůže být prázdná",
    company: "podnik",
    workexp: "pracovní zkušenost",
    education: "Vzdělávací zkušenosti",
    companyName: "společnost",
    job: "Pozice",
    name: "název",
    address: "adresa",
    called: "název",
    title: "titul",
    price: "cena",
    tags: "Označení",
    emoji: "výraz",
    sendFile: "Poslat soubor",
    transferAccount: "převod",
    image: "obrázek",
    video: "video",
    voice: "hlas",
    file: "dokument",
    permissions: "oprávnění",
    groupRoule: {
        "0": "Veřejná skupina: Všichni uživatelé se mohou připojit",
        "1": "Členská skupina: Poté, co uživatel odešle přihlášku, může se po schválení administrátorem skupiny připojit",
        "2": "Soukromé skupiny: Neviditelné ve skupinovém vyhledávání a pouze skupina může přidávat členy",
    },
    man: "mužský",
    woman: "ženský",
    mustBeTM: "Titul/Jméno je povinný údaj",
    mustBeTag: "Vyplňte alespoň jednu značku",
    mustBeCate: "Vyberte alespoň jednu kategorii",
    contentTip: "Zde vyplňte podrobnosti...",
    uploadSuccess: "Úspěšně nahráno!",
    publishError: "Publikování se nezdařilo!",
    // footer
    contactus: "kontaktujte nás",
    privacy: "Zásady ochrany osobních údajů",
    companyright: "všechna práva vyhrazena",
    // 其他
    sys_error: "chyba systému!",
    replayEmpty: "Obsah komentáře nemůže být prázdný!",
    replay: "odpověď",
    replayErr: "komentář se nezdařil",
    delSuccess: "úspěšně smazáno",
    defFail: "se nepodařilo odstranit",
    refresh: "aktualizace dokončena",
    updateSuccess: "Úprava proběhla úspěšně, přihlaste se prosím znovu",
    old: "starý",
    theNew: "Nový",
    changeFail: "Změna hesla se nezdařila",
    pwdlength: "délka hesla",
    getverchatCode: "získat ověřovací kód",
    nopeoplezan: "Zatím se to nikomu nelíbí, buďte první, komu se to líbí!",
    pwdResetSuccess: "Obnovení hesla dokončeno",
    email_required: "E-mail nesmí být prázdný",
    phone_required: "Číslo mobilního telefonu nesmí být prázdné",
    addfriend: "Žádost o přidání přátel byla úspěšně odeslána, čeká se na souhlas druhé strany",
    Draft: "návrh",
    breakPwd: "získat heslo",
    "Request to view history messages": "Požádejte o zobrazení historických zpráv",
    "Wait for group manager to agree": "Počkejte na souhlas vlastníka skupiny",
    'Please enter a search term': "Zadejte klíčová slova pro vyhledávání",
    done: "Tak určitě",
    cancle: "zrušení",
    groupType: {
        '1': {
            name: "veřejná skupina",
            code: "1",
            desc: "`Otevřená skupina: Každý uživatel může vytvořit skupinu, do které se může připojit bez kontroly. Název skupiny popisuje členy a lze ji vyhledávat a procházet na platformě. `",
        },
        '2': {
            name: "členská skupina",
            code: "2",
            desc: "`Skupina členů: Každý uživatel může vytvořit skupinu, do které se může volně připojit a kterou musí zkontrolovat vlastník skupiny. Název skupiny popisuje členy a lze ji vyhledávat a procházet na platformě. `",
        },
        '3': {
            name: "diskusní skupina",
            code: "3",
            desc: "`Diskuzní skupina: každý uživatel může vytvářet tvůrce a členové mohou pozvat své přátele, aby se připojili k názvu skupiny, členy lze vyhledávat a procházet na platformě. `",
        },
        '4': {
            name: "soukromá skupina",
            code: "4",
            desc: "`Soukromá skupina: Kterýkoli uživatel může vytvořit název skupiny pro popis členů skupiny a nelze jej prohledávat a procházet na platformě. `",
        }
    },
    newPwd: "Zadejte prosím nové přihlašovací heslo",
    repNewPwd: "Zadejte prosím znovu nové heslo",
    // 2021年3月4号
    meetingTips: "Nejprve si stáhněte aplikaci melinked nebo upgradujte aplikaci melinked na nejnovější verzi",
    // mechat协议
    mechat: {
        title: "Me Chat User Agreement",
        text1: "Mechat je doplňkový produkt pro otázky a odpovědi AI pro chat poskytovaný společností Maes Consulting. Pokud uživatel souhlasí s touto uživatelskou smlouvou, má se za to, že souhlasí s celým obsahem této uživatelské smlouvy.",
        header1: "jeden. Jak sdílíme a zveřejňujeme osobní údaje uživatelů s třetími stranami",
        text2: "Za následujících okolností tato aplikace zpřístupní osobní údaje uživatele zcela nebo zčásti podle osobních přání uživatele nebo právních předpisů:",
        text3: "1. Sděleno třetím stranám s předchozím souhlasem uživatele;",
        text4: "2. Pro poskytování produktů a služeb požadovaných uživatelem je nutné sdílet osobní údaje uživatele s třetí stranou;",
        text5: "3. Podle příslušných ustanovení zákona nebo požadavků správních nebo soudních orgánů sdělovat třetím stranám nebo správním nebo soudním orgánům;",
        text6: "4. Pokud uživatel poruší příslušné čínské zákony a předpisy nebo tuto smlouvu o poskytování služeb aplikace nebo související pravidla, je třeba to oznámit třetí straně;",
        text7: "5. Pokud je uživatel kvalifikovaným stěžovatelem na duševní vlastnictví a podal stížnost, sdělí ji na žádost odpůrce odpůrci, aby obě strany mohly řešit případné spory o práva;",
        text8: "6. Další zveřejnění, která tato aplikace považuje za vhodná v souladu se zákony, předpisy nebo zásadami webových stránek.",
        header2: "2. Ukládání a výměna informací",
        text9: "Informace a údaje o uživatelích shromážděné MeChat budou uloženy na serverech MeChat a (nebo) jeho přidružených společností. Pouze odeslané a přijaté informace budou nahrány na server v procesu chatování se mnou, které budou použity pro odpověď modelu AI.",
        header3: "3. Související s duševním vlastnictvím",
        text10: "Neposkytujeme žádné záruky za zákonnost, legitimitu, integritu nebo kvalitu obsahu nahraného nebo publikovaného uživateli. Uživatelé ponesou rizika vyplývající z používání nebo spoléhání se na obsah nebo zdroje přenášené softwarem. V každém případě nenese odpovědnost za jakoukoli možnou nebo skutečnou ztrátu nebo škodu vyplývající z takového rizika.",
        header4: "4. Právní odpovědnost a vyloučení odpovědnosti",
        text11: "V žádném případě neneseme my a naši partneři odpovědnost za žádné přímé, nepřímé, náhodné, zvláštní a následné škody a rizika vyplývající z používání nebo neschopnosti uživatelů používat Me Chat. Pokud je normální provoz služby ovlivněn událostmi vyšší moci, jako jsou technické poruchy, my a naši partneři slibujeme, že budeme co nejdříve spolupracovat s příslušnými jednotkami, abychom je mohli včas řešit a opravit.",
        text12: "Informace odesílané modelem AI jsou generovány počítačem a mohou být fiktivní. Uživatelé jsou požádáni, aby rozlišili správné od nesprávného. Když uživatelé používají tuto aplikaci ke konzultaci s lékařskými, právními a jinými souvisejícími otázkami, tato aplikace poskytuje pouze lékařské poradenství a právní poradenství a nepředstavuje lékařské služby, právní služby nebo léčbu. Uživatelé se musí před použitím této aplikace poradit s lékaři a příslušnými právnickými osobami a jsou v konečném důsledku odpovědní za lékařská a právní rozhodnutí. Informace a služby v této aplikaci jsou pouze orientační a nepředstavují právní služby ani lékařská řešení pro lékařské služby a nepřebíráme žádnou odpovědnost vyplývající z používání této aplikace.",
    },
    originalMail: "Původní zpráva",
    emailRecipient: "příjemce",
    emailFrom: "odesílatel",
    CcTo: "CC",
};
