var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-tabbar",
    {
      staticClass: "me-tabbar",
      attrs: { "active-color": "#33cc66", route: "" },
      model: {
        value: _vm.active,
        callback: function($$v) {
          _vm.active = $$v
        },
        expression: "active"
      }
    },
    [
      _c("van-tabbar-item", {
        staticClass: "tabbar me-icon-home",
        attrs: { to: "/" }
      }),
      _c("van-tabbar-item", {
        staticClass: "tabbar me-icon-chat",
        attrs: { to: "/chat" }
      }),
      _c("van-tabbar-item", {
        staticClass: "tabbar me-icon-friends",
        attrs: { to: "/friends" }
      }),
      _c("van-tabbar-item", {
        staticClass: "tabbar me-icon-my",
        attrs: { to: "/my" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }