export default {
    langs: {
        "zh-CN": "Xitoy",
        en: "Ingliz",
        fr: "frantsuz",
        es: "ispancha",
        "ru-RU": "rus",
        hi: "hind",
        ur: "urdu",
        ja: "yapon",
        ko: "koreys",
        th: "tay",
        de: "nemis",
        it: "italyan",
    },
    responFail: "Yuklab bo‘lmadi",
    responRetry: "qayta urinish tugmasini bosing",
    responEmpty: "Maʼlumot yoʻq",
    resendMsg: "Xabar yuborilmadi, qayta urinib ko‘rish uchun bosing",
    sendImage: "rasmlar yuboring",
    send: "yuborish",
    //fastSend: "Tez yuborish uchun {key} tugmasini bosing",
    //sendMsg: "Xabar yuboring",
    //clickDownload: "yuklab olish uchun bosing",
    timeAgo: {
        0: "shunchaki",
        1: "{n} daqiqa oldin",
        2: "{n} soat oldin",
        3: "kecha",
    },
    copyGroup: "A&#39;zolarni yangi muhokama guruhiga nusxalash",
    quitGroup: "muhokama guruhini tark eting",
    createGroup: "muhokama guruhini yaratish",
    inviteFriend: "do&#39;stlarni taklif qiling",
    // web site
    login: "Tizimga kirish",
    loginerror: "Tizimga kirishda xatolik yuz berdi!",
    register: "ro&#39;yxatdan o&#39;tish",
    keepOn: "tizimga kiring",
    forgetPwd: "parolni unutdingizmi?",
    logoutTip: "Chiqish uchun OK",
    resetPwd: "Kirish parolini tiklash",
    all: "hammasi",
    // 注册
    phoneErr: "Telefon raqami boʻsh boʻlishi mumkin emas",
    phoneformatErr: "Telefon raqami formati noto&#39;g&#39;ri",
    needNumber: "Raqamli qiymat kiriting",
    registerErr: "ro&#39;yxatdan o&#39;tish muvaffaqiyatsiz tugadi",
    // publish
    publish: "ozod qilish",
    group: "guruh",
    school: "Maktab",
    profess: "mayor",
    degree: "fanlar bo&#39;yicha bakalavr",
    time: "vaqt",
    desc: "tasvirlab bering",
    mustSchool: "maktab bo&#39;sh bo&#39;lishi mumkin emas",
    mustCompany: "kompaniya bo&#39;sh bo&#39;lishi mumkin emas",
    company: "korxona",
    workexp: "ish tajribasi",
    education: "Ta&#39;lim tajribasi",
    companyName: "kompaniya",
    job: "Lavozim",
    name: "Ism",
    address: "manzil",
    called: "nomi",
    title: "sarlavha",
    price: "narx",
    tags: "Yorliq",
    emoji: "ifoda",
    sendFile: "Faylni yuborish",
    transferAccount: "transfer",
    image: "rasm",
    video: "video",
    voice: "ovoz",
    file: "hujjat",
    permissions: "ruxsatlar",
    groupRoule: {
        "0": "Ommaviy guruh: Barcha foydalanuvchilar qo&#39;shilishlari mumkin",
        "1": "Guruh a&#39;zosi: foydalanuvchi arizani topshirgandan so&#39;ng, u guruh administratori tomonidan ma&#39;qullangandan so&#39;ng qo&#39;shilishi mumkin",
        "2": "Shaxsiy guruhlar: Guruh qidiruvida ko&#39;rinmaydi va faqat guruh a&#39;zolarni qo&#39;shishi mumkin",
    },
    man: "erkak",
    woman: "ayol",
    mustBeTM: "Sarlavha/Ism kerak",
    mustBeTag: "Kamida bitta tegni to&#39;ldiring",
    mustBeCate: "Kamida bitta toifani tanlang",
    contentTip: "Tafsilotlarni shu yerda toʻldiring...",
    uploadSuccess: "Yuklandi!",
    publishError: "Chop etish amalga oshmadi!",
    // footer
    contactus: "Biz bilan bog&#39;lanish",
    privacy: "Maxfiylik siyosati",
    companyright: "Barcha huquqlar himoyalangan",
    // 其他
    sys_error: "tizim xatosi!",
    replayEmpty: "Fikr mazmuni bo&#39;sh bo&#39;lishi mumkin emas!",
    replay: "javob bering",
    replayErr: "izoh bajarilmadi",
    delSuccess: "muvaffaqiyatli o&#39;chirildi",
    defFail: "oʻchirib boʻlmadi",
    refresh: "yangilash tugallandi",
    updateSuccess: "Oʻzgartirish muvaffaqiyatli boʻldi, qayta kiring",
    old: "eski",
    theNew: "yangi",
    changeFail: "Parolni o‘zgartirib bo‘lmadi",
    pwdlength: "parol uzunligi",
    getverchatCode: "tasdiqlash kodini oling",
    nopeoplezan: "Bu hali hech kimga yoqmaydi, birinchi bo&#39;lib yoqadi!",
    pwdResetSuccess: "Parolni tiklash tugallandi",
    email_required: "Elektron pochta bo&#39;sh bo&#39;lishi mumkin emas",
    phone_required: "Mobil telefon raqami bo&#39;sh bo&#39;lishi mumkin emas",
    addfriend: "Do&#39;stlar qo&#39;shish uchun ariza boshqa tomon rozi bo&#39;lishini kutib, muvaffaqiyatli yuborildi",
    Draft: "qoralama",
    breakPwd: "parolni olish",
    "Request to view history messages": "Tarixiy yangiliklarni ko&#39;rish uchun murojaat qiling",
    "Wait for group manager to agree": "Guruh egasi rozi bo&#39;lguncha kuting",
    'Please enter a search term': "Qidiruv kalit so&#39;zlarini kiriting",
    done: "Albatta",
    cancle: "Bekor qilish",
    groupType: {
        '1': {
            name: "jamoat guruhi",
            code: "1",
            desc: "`Ochiq guruh: Har qanday foydalanuvchi koʻrib chiqmasdan qoʻshilishi mumkin boʻlgan guruh yaratishi mumkin. Guruh nomi aʼzolarni tavsiflaydi va uni platformada qidirish va koʻrish mumkin. `",
        },
        '2': {
            name: "a&#39;zolar guruhi",
            code: "2",
            desc: "`A&#39;zolar guruhi: Har qanday foydalanuvchi erkin qo&#39;shilishi mumkin bo&#39;lgan va guruh egasi tomonidan ko&#39;rib chiqilishi kerak bo&#39;lgan guruh yaratishi mumkin. Guruh nomi a&#39;zolarni tavsiflaydi va uni platformada qidirish va ko&#39;rib chiqish mumkin. `",
        },
        '3': {
            name: "muhokama guruhi",
            code: "3",
            desc: "`Muhokama guruhi: har qanday foydalanuvchi ijodkorlarni yaratishi mumkin va a&#39;zolar o&#39;z do&#39;stlarini guruhga qo&#39;shilishga taklif qilishlari mumkin. `",
        },
        '4': {
            name: "shaxsiy guruh",
            code: "4",
            desc: "`Shaxsiy guruh: Har qanday foydalanuvchi guruh a&#39;zolarini tavsiflash uchun guruh nomini yaratishi mumkin va uni platformada qidirish va ko&#39;rib chiqish mumkin emas. `",
        }
    },
    newPwd: "Iltimos, yangi login parolini kiriting",
    repNewPwd: "Iltimos, yangi parolni qayta kiriting",
    // 2021年3月4号
    meetingTips: "Iltimos, avval melinked ilovasini yuklab oling yoki melinked APPni soʻnggi versiyaga yangilang",
    // mechat协议
    mechat: {
        title: "Me Chat foydalanuvchi shartnomasi",
        text1: "Mechat - bu Maes Consulting tomonidan taqdim etilgan AI suhbatdoshi savol-javob mahsulotidir. Agar foydalanuvchi ushbu foydalanuvchi shartnomasiga rozi bo&#39;lsa, u ushbu foydalanuvchi shartnomasining barcha mazmuniga rozi bo&#39;lgan hisoblanadi.",
        header1: "bitta. Biz foydalanuvchilarning shaxsiy ma&#39;lumotlarini uchinchi shaxslar bilan qanday baham ko&#39;ramiz va oshkor qilamiz",
        text2: "Quyidagi hollarda ushbu ilova foydalanuvchining shaxsiy maʼlumotlarini toʻliq yoki qisman foydalanuvchining shaxsiy xohish-istaklari yoki qonun hujjatlariga muvofiq oshkor qiladi:",
        text3: "1. Foydalanuvchining oldindan roziligi bilan uchinchi shaxslarga oshkor qilingan;",
        text4: "2. Foydalanuvchi so‘ragan mahsulot va xizmatlarni taqdim etish uchun foydalanuvchining shaxsiy ma’lumotlarini uchinchi shaxs bilan bo‘lishish zarur;",
        text5: "3. Qonunning tegishli qoidalariga yoki ma&#39;muriy yoki sud organlarining talablariga muvofiq uchinchi shaxslarga yoki ma&#39;muriy yoki sud organlariga oshkor qilish;",
        text6: "4. Agar foydalanuvchi Xitoyning tegishli qonunlari va qoidalarini yoki ushbu dasturga xizmat ko&#39;rsatish shartnomasini yoki tegishli qoidalarni buzsa, u uchinchi shaxsga oshkor qilinishi kerak;",
        text7: "5. Agar foydalanuvchi intellektual mulk bo&#39;yicha malakali shikoyatchi bo&#39;lsa va shikoyat qilgan bo&#39;lsa, u har ikki tomon ham mumkin bo&#39;lgan huquqlar bo&#39;yicha nizolarni hal qila olishi uchun uni javobgarning iltimosiga binoan javobgarga oshkor qiladi;",
        text8: "6. Qonunlar, qoidalar yoki veb-sayt siyosatiga muvofiq ushbu ilova tegishli deb hisoblagan boshqa maʼlumotlar.",
        header2: "2. Axborotni saqlash va almashish",
        text9: "MeChat tomonidan to&#39;plangan foydalanuvchilar haqidagi ma&#39;lumotlar va ma&#39;lumotlar MeChat va (yoki) unga tegishli kompaniyalar serverlarida saqlanadi. Men bilan suhbat jarayonida serverga faqat yuborilgan va qabul qilingan maʼlumotlar yuklanadi, u AI modeliga javob berish uchun ishlatiladi.",
        header3: "3. Intellektual mulk bilan bog&#39;liq",
        text10: "Biz foydalanuvchilar tomonidan yuklangan yoki nashr etilgan kontentning qonuniyligi, qonuniyligi, yaxlitligi yoki sifati uchun hech qanday kafolat bermaymiz. Foydalanuvchilar dasturiy taʼminot orqali uzatiladigan kontent yoki resurslardan foydalanish yoki ularga tayanish bilan bogʻliq xavflarni oʻz zimmasiga oladi. bunday xavf tufayli yuzaga kelishi mumkin bo&#39;lgan yoki haqiqiy yo&#39;qotish yoki zarar uchun javobgar bo&#39;lmaydi.",
        header4: "4. Yuridik javobgarlik va rad etish",
        text11: "Qanday bo&#39;lmasin, biz va bizning hamkorlarimiz foydalanuvchilarning Me Chat-dan foydalanishi yoki foydalana olmasligi natijasida yuzaga keladigan har qanday to&#39;g&#39;ridan-to&#39;g&#39;ri, bilvosita, tasodifiy, maxsus va keyingi zararlar va xavflar uchun javobgar emasmiz. Agar xizmatning normal ishlashiga texnik nosozliklar kabi fors-major holatlari ta&#39;sir qilsa, biz va bizning hamkorlarimiz ularni o&#39;z vaqtida bartaraf etish va ta&#39;mirlash uchun tegishli bo&#39;linmalar bilan imkon qadar tezroq hamkorlik qilishga va&#39;da beramiz.",
        text12: "AI modeli tomonidan yuborilgan ma&#39;lumotlar kompyuter tomonidan ishlab chiqariladi va xayoliy bo&#39;lishi mumkin.Foydalanuvchilardan to&#39;g&#39;ri va noto&#39;g&#39;rini ajratish so&#39;raladi. Foydalanuvchilar ushbu ilovadan tibbiy, huquqiy va boshqa tegishli masalalarga murojaat qilish uchun foydalansa, ushbu ilova faqat tibbiy maslahat va yuridik maslahat beradi va tibbiy xizmatlar, yuridik xizmatlar yoki davolanishni ifodalamaydi. Foydalanuvchilar ushbu ilovadan foydalanishdan oldin shifokorlar va tegishli yuridik shaxslar bilan maslahatlashishlari kerak va oxir-oqibat tibbiy va qonuniy qarorlar uchun javobgardirlar. Ushbu ilovadagi ma&#39;lumotlar va xizmatlar faqat ma&#39;lumot uchun mo&#39;ljallangan va tibbiy xizmatlar uchun yuridik xizmatlar yoki tibbiy echimlarni tashkil etmaydi va biz ushbu ilovadan foydalanishdan kelib chiqadigan hech qanday javobgarlikni o&#39;z zimmamizga olmaymiz.",
    },
    originalMail: "Asl xabar",
    emailRecipient: "oluvchi",
    emailFrom: "jo&#39;natuvchi",
    CcTo: "CC",
};
