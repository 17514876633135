import {
  env,
  getBrowserInfo
} from "@/utils";
import UUID from "uuidjs";
import store from "@/store";
let IMSocket = {};
let listeners = [];
let listenersOpen = () => {};
const createIMSocket = () => {
  const {
    id,
    token
  } = store.getters.getUserinfo;
  const readyState = [0, 1, 2];

  if (readyState.includes(IMSocket.readyState)) return;
  IMSocket = new WebSocket(env("IMWSURL"));
  IMSocket.onopen = function () {
		console.log('WebSocket连接成功');
    const browser = getBrowserInfo();
    const params1 = {
      code: 1,
      messageId: UUID.generate(),
      id: id,
      token: token,
      type: 0
    };
    send(params1);
    const params2 = {
      code: 10001,
      type: "set",
      messageId: UUID.generate(),
      to: "presence.melinked.com",
      from: `${id}@melinked.com/web_${browser}`,
      header: {},
      protocal: {
        deviceType: "web",
        resource: `web/${browser}`,
        status: "online"
      }
    };
    listenersOpen();
    send(params2);

  };
  IMSocket.onmessage = function (event) {
    const data = JSON.parse(event.data);
    listeners.forEach((callback, index) => {
      callback(data, () => {
        listeners.splice(index, 1);
      });
    });
  };
  IMSocket.onerror = function (e) {
    console.log("error");
  };
  IMSocket.onclose = function (event) {
    if (event.code === 1006 && id) {
      console.info("WebSocket正在重连...");
      setTimeout(() => {
        createIMSocket();
        console.timeEnd("recollection");
      }, 0);
    }
  };
};

export function listener(callback) {
  createIMSocket();
  listeners.push(callback);
}
export function listenerOpen(callback) {
  listenersOpen = callback;
}
export function send(data) {
  IMSocket.send(JSON.stringify(data));
}
export function close() {
  console.log("退出socket");
  IMSocket.close();
  listeners = [];
}