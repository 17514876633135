import Vue from "vue";
import {
  debounce
} from "throttle-debounce";

/**
 * vue上点击事件处理类
 */
class VueTouch {
  /**
   * @param el
   * @param binding
   * @param type
   */
  constructor(el, binding, type) {

    let g = this;

    g.obj = el;
    g.binding = binding;
    g.touchType = type;

    g.firstTouchPosition = {
      x: 0,
      y: 0
    };
    g.firstTouchTime = 0;
    g.callBack = typeof (binding.value) === "object" ? binding.value.fn : binding.value;

    g.moved = false;
    g.leaved = false;
    g.longTouched = false;

    // 事件监听回调集合
    let _listener = Object.create(null);

    // 事件方法
    let _listen = (type) => {
      return function (e) {
        let {
          stop,
          prevent,
          self,
          once
        } = g.binding.modifiers;

        // 配置判断
        if (stop) e.stopPropagation();
        if (prevent) e.preventDefault();
        if (once) g.obj.removeEventListener("touch" + type, _listener[type]);
        if (self && e.target !== e.currentTarget) return;

        g[type](e);
      }
    };

    _listener.start = _listen('start');
    _listener.end = _listen('end');
    _listener.move = _listen('move');

    this.obj.addEventListener("touchstart", _listener.start, false);
    this.obj.addEventListener("touchend", _listener.end, false);
    this.obj.addEventListener("touchmove", _listener.move, false);
  }

  start(e) {

    let g = this;

    // 初始化点击状态
    g.moved = false;
    g.leaved = false;
    g.longTouched = false;

    g.firstTouchPosition = g.getMultiCenter(e.changedTouches);
    g.firstTouchTime = e.timeStamp;
    g.time = setTimeout(function () {
      if (!g.leaved && !g.moved) {
        g.touchType === "longtap" && g.callBack(e, g.binding.value);
        g.longTouched = true;
      }
    }.bind(g), 1000);
  }

  end(e) {

    let g = this;

    let {
      x,
      y
    } = g.getMultiCenter(e.changedTouches);
    let _disX = x - g.firstTouchPosition.x;
    let _disY = y - g.firstTouchPosition.y;
    let _dis = Math.sqrt(_disX * _disX + _disY * _disY);
    let _timeDis = e.timeStamp - g.firstTouchTime;

    clearTimeout(g.time);

    let _angle = this.getAngle(_disX, _disY);

    if (_dis > 18 && _timeDis < 300) {
      g.touchType === "swipe" && g.callBack(e, g.binding.value);
      if (_angle >= 60 && _angle <= 120)
        g.touchType === "swipedown" && g.callBack(e, g.binding.value);
      if (_angle <= -60 && _angle >= -120)
        g.touchType === "swipeup" && g.callBack(e, g.binding.value);
      if (_angle <= 20 && _angle >= -20)
        g.touchType === "swipeleft" && g.callBack(e, g.binding.value);
      if ((_angle <= -160 && _angle > -180) || (_angle >= 160 && _angle <= 180))
        g.touchType === "swiperight" && g.callBack(e, g.binding.value);
    } else {
      if (!g.longTouched && !g.moved) {
        g.touchType === "tap" && g.callBack(e, g.binding.value);
        g.leaved = true;
      }
    }
  }

  move() {
    this.moved = true;
  }

  /**
   * 获取点击集合的中心坐标
   * @param touches touch对象集合
   * @return {{x: number, y: number}}
   */
  getMultiCenter(touches) {

    let g = this,
      x = 0,
      y = 0;

    const _length = touches.length;

    for (let i = 0; i < _length; i++) {
      x += touches[i].pageX;
      y += touches[i].pageY;
    }

    return {
      x: Math.round(x / _length),
      y: Math.round(y / _length)
    };
  }

  getAngle(disX, disY) {
    let g = this;

    if (typeof disX !== 'number' || typeof disY !== 'number')
      return 45;

    return Math.atan2(disY, disX) * 180 / Math.PI;
  }
}


Vue.directive("scroll", {
  bind(el, binding) {
    const {
      distance,
      onTop,
      onBottom
    } = {
      ...{
        distance: 40,
        onTop() {},
        onBottom() {}
      },
      ...binding.value
    };
    el.addEventListener(
      "scroll",
      debounce(150, () => {
        if (el.getAttribute("scroll-active") == "false") return;
        const scrollTop = el.scrollTop;
        const scrollHeight = el.scrollHeight;
        const height = el.clientHeight;
        if (scrollTop + height + distance >= scrollHeight) {
          onBottom();
        }
        if (scrollTop <= distance) {          
          onTop();
        }
      })
    );
  },
  update(el, binding) {
    el.setAttribute("scroll-active", binding.value.active);
  }
});
Vue.directive('focus', {

  inserted: function (el, {
    value
  }) {
    if (value) {

      el.focus();

    }

  }

})

Vue.directive("tap", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "tap");
  }
});
Vue.directive("swipe", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "swipe");
  }
});
Vue.directive("swipeleft", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "swipeleft");
  }
});
Vue.directive("swiperight", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "swiperight");
  }
});
Vue.directive("swipedown", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "swipedown");
  }
});
Vue.directive("swipeup", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "swipeup");
  }
});
Vue.directive("longtap", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "longtap");
  }
});
//自定义scrollerUpdate
Vue.directive('scrollUpdate', {
    inserted: function(el, binding, vnode, oldVnode) {
        let touchStarY=0, touchEndY=0, touchDistance=0, 
            pageDownTimer=null, newLiEle=null, updateState=0;
        el.addEventListener('touchstart', async(e) => {
            touchStarY = e.touches[0].clientY;
            clearInterval(pageDownTimer);
        })
        el.addEventListener('touchmove', async(e) => {
            touchEndY = e.touches[0].clientY;
            touchDistance = touchEndY - touchStarY;
            let UpdateEle = document.getElementById("downUpdateEle");
            if(touchDistance < 0){      //上拉
                if(UpdateEle) el.removeChild(newLiEle)
            }else{
                if(el.scrollTop==0&&touchDistance<60){
                    e.preventDefault();   //阻止浏览器的黑色背景
                    if(!UpdateEle){
                        //添加 li
                        newLiEle = document.createElement("li");
                        // 设置 li 属性： id、style；
                        newLiEle.setAttribute("id", "downUpdateEle");
                        newLiEle.setAttribute('style', 'list-style: none; background: #efefef; color: #999999; font-size: 16px; opacity: 1;');
                        newLiEle.innerHTML = "下拉刷新";
                        el.insertBefore(newLiEle, el.firstChild);
                    }
                    newLiEle.style.lineHeight = touchDistance+'px';
                    if(touchDistance > 40){
                        newLiEle.innerHTML = "释放即可刷新...";
                        newLiEle.style.textAlign="center"
                        newLiEle.style.lineHeight = '60px';
                        newLiEle.style.fontSize = '12px'
                        updateState = 1;
                    }
                }

            }
        })
        el.addEventListener('touchend', async() => {
            if(updateState==1){         //刷新数据；
                let expression_name = binding.expression;
                let expression_fun = vnode.context[expression_name];
                expression_fun && await expression_fun();
                
                updateState = 0;
                resetNewTopEle();
            }else{                      //不触发刷新数据
                resetNewTopEle()
            }
            function resetNewTopEle(){
                pageDownTimer = setInterval(()=>{
                    if(document.getElementById("downUpdateEle")){
                        //样式获取
                        let newLiLineheight = parseInt(newLiEle.style.lineHeight);
                        let newLiFontSize = parseInt(newLiEle.style.fontSize);
                        let newLiOpacity = newLiEle.style.opacity;
                        if(newLiLineheight<1){
                            el.removeChild(newLiEle)
                            clearInterval(pageDownTimer)
                        }else{
                            newLiEle.style.lineHeight = newLiLineheight/2+'px';
                            newLiEle.style.fontSize = newLiFontSize/2+'px';
                            newLiEle.style.opacity = newLiOpacity/2;
                        }
                    }
                }, 70)
            }
        })
    }
})
//自定义scrollMore
Vue.directive('scrollMore', {
    inserted: function(el, binding, vnode, oldVnode) {
        //console.log(el, binding, vnode)
        let scrollHeight = el.offsetHeight,
            expression_name = binding.expression,
            expression_fun = vnode.context[expression_name];
        el.addEventListener('scroll', async() => {
            if(scrollHeight + el.scrollTop + 10 >= el.firstChild.clientHeight) {
                try {
                    console.log("加载了.....")
                    expression_fun && await expression_fun();
                } catch(error) {
                    console.log(error)
                }
            }
        })
    }
})
