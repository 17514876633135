import i18n from "../i18n";
import CryptoJS from "crypto-js";
export function handlerEncrypt(word, keyStr) {
  keyStr = keyStr ? keyStr : "alkXFYemRgZUZ6I3";
  var key = CryptoJS.enc.Utf8.parse(keyStr); //Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}
/**
 * AES 解密
 * @param {*} word
 * @param {*} keyStr
 */
export function handlerDecrypt(word, keyStr) {
  keyStr = keyStr ? keyStr : "alkXFYemRgZUZ6I3";
  var key = CryptoJS.enc.Utf8.parse(keyStr); //Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  var decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

export function convertUnicode(text) {
  return unescape(text.replace(/\\u/g, "%u"));
}
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);

  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return i18n.t("timeAgo[0]");
  } else if (diff < 3600) {
    // less 1 hour
    return i18n.t("timeAgo[1]", {
      n: Math.ceil(diff / 60)
    });
  } else if (diff < 3600 * 24) {
    return i18n.t("timeAgo[2]", {
      n: Math.ceil(diff / 3600)
    });
  } else if (diff < 3600 * 24 * 2) {
    return i18n.t("timeAgo[3]") + " " + parseTime(time, "{h}:{i}");
  }

  if (!option) {
    option = "{m}-{d} {h}:{i}";
    if (d.getFullYear() != new Date().getFullYear()) {
      option = "{y}-{m}-{d} {h}:{i}";
    }
  }
  return parseTime(time, option);
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousand(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}

/**
 * 10000 => 10MB
 * @param {number} num
 */
export function toBytesSize(num) {
  if (num === 0) return "0 B";
  var k = 1000, // or 1024
    sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(num) / Math.log(k));

  return (num / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
}

/**
 * 首字母大写
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * 将 FILE 转为 base64
 * @param {File} file
 */
export function fileToBase64(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(this.result);
      //self.uploadPart(params);
    };
  });
}

export function lightHeight(text,keyword) {  
  if (text && text.indexOf(keyword) !== -1 ) {
    text = text.replace(eval("/" + keyword + "/gi"), '<font color="red">' + keyword + "</font>");
  }
  return text;
}
/**
 * 将 FILE 转为 本地URL
 * @param {File} file
 */
export const fileToURL = {
  create: file => {
    if (URL && file) {
      return URL.createObjectURL(file);
    }
  },
  revoke: fileurl => {
    if (URL && fileurl) return URL.revokeObjectURL(fileurl);
  }
};

export function downloadFile() {
  let url = arguments[0];
  let name = arguments[1] || "download";
  const a = document.createElement("a");
  a.download = name;
  a.target = "_blank";
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function env(key) {
  return process.env[`VUE_APP_${key}`];
}

export function clickoutside(uniqueClass, not = () => {}, is = () => {}) {
  document.addEventListener("click", e => {
    let el = e.target;
    while (el) {
      if (!el || !el.classList) {
        not(e);
        return;
      }
      if (el.classList.contains(uniqueClass)) {
        is(e);
        return;
      }
      el = el.parentNode;
    }
  });
}

export function getBrowserInfo() {
  var agent = navigator.userAgent.toLowerCase();
  var regStr_ie = /msie [\d.]+;/gi;
  var regStr_ff = /firefox\/[\d.]+/gi;
  var regStr_chrome = /chrome\/[\d.]+/gi;
  var regStr_saf = /safari\/[\d.]+/gi;
  // IE
  if (agent.indexOf("msie") > 0) {
    return agent.match(regStr_ie)[0].replace("/", "_");
  }
  // firefox
  if (agent.indexOf("firefox") > 0) {
    return agent.match(regStr_ff)[0].replace("/", "_");
  }
  // Chrome
  if (agent.indexOf("chrome") > 0) {
    return agent.match(regStr_chrome)[0].replace("/", "_");
  }
  // Safari
  if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
    return agent.match(regStr_saf)[0].replace("/", "_");
  }
}

export function objectToFormdata(obj) {
  const form = new FormData();
  Object.keys(obj).forEach(key => form.set(key, obj[key]));
  return form;
}