const CacheDrive = window.localStorage;
export function get(key) {
  return CacheDrive.getItem(key);
}
export function set(key, val) {
  CacheDrive.setItem(key, val);
}
export function remove(key) {
  CacheDrive.removeItem(key);
}
export function clear() {
  CacheDrive.clear();
}
