var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.src
    ? _c("van-image", {
        staticStyle: { "flex-shrink": "0" },
        attrs: {
          fit: "cover",
          src: _vm.formartImageUrl(_vm.src),
          height: _vm.height,
          width: _vm.width,
          round: _vm.round
        },
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "loading",
              fn: function() {
                return [
                  _c("van-loading", { attrs: { type: "spinner", size: "20" } })
                ]
              },
              proxy: true
            },
            {
              key: "error",
              fn: function() {
                return [
                  _c("div", { staticClass: "error-img" }, [_vm._v("Melinked")])
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          818041181
        )
      })
    : _c(
        "div",
        {
          staticClass: "no-thumb",
          style: _vm.noImg,
          on: {
            click: function($event) {
              return _vm.$emit("click")
            }
          }
        },
        [_vm._v("Melinked")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }