export default {
    langs: {
        "zh-CN": "kinesiska",
        en: "engelsk",
        fr: "franska",
        es: "spanska",
        "ru-RU": "ryska",
        hi: "hindi",
        ur: "Urdu",
        ja: "japanska",
        ko: "koreanska",
        th: "Thai",
        de: "tysk",
        it: "italienska",
    },
    responFail: "Misslyckades att ladda",
    responRetry: "klicka på försök igen",
    responEmpty: "Inga data",
    resendMsg: "Det gick inte att skicka meddelandet, klicka för att försöka igen",
    sendImage: "skicka bilder",
    send: "skicka",
    //fastSend: "Tryck på {tangent} för att skicka snabbt",
    //sendMsg: "Skicka ett meddelande",
    //clickDownload: "klicka för att ladda ner",
    timeAgo: {
        0: "bara",
        1: "för {n} minuter sedan",
        2: "för {n} timmar sedan",
        3: "i går",
    },
    copyGroup: "Kopiera medlemmar till ny diskussionsgrupp",
    quitGroup: "lämna diskussionsgruppen",
    createGroup: "skapa diskussionsgrupp",
    inviteFriend: "bjuda in vänner",
    // web site
    login: "Logga in",
    loginerror: "Inloggningen misslyckades!",
    register: "Registrera",
    keepOn: "stanna inloggad",
    forgetPwd: "glömt lösenordet?",
    logoutTip: "OK för att logga ut",
    resetPwd: "Återställ inloggningslösenord",
    all: "Allt",
    // 注册
    phoneErr: "Telefonnumret får inte vara tomt",
    phoneformatErr: "Telefonnummerformatet är felaktigt",
    needNumber: "Ange ett numeriskt värde",
    registerErr: "registreringen misslyckades",
    // publish
    publish: "släpp",
    group: "grupp",
    school: "Skola",
    profess: "större",
    degree: "Civilingenjör",
    time: "tid",
    desc: "beskriva",
    mustSchool: "skolan får inte stå tom",
    mustCompany: "företaget kan inte vara tomt",
    company: "företag",
    workexp: "arbetserfarenhet",
    education: "Utbildningserfarenhet",
    companyName: "företag",
    job: "Placera",
    name: "namn",
    address: "adress",
    called: "namn",
    title: "titel",
    price: "pris",
    tags: "Märka",
    emoji: "uttryck",
    sendFile: "Skicka Fil",
    transferAccount: "överföra",
    image: "bild",
    video: "video",
    voice: "röst",
    file: "dokumentera",
    permissions: "behörigheter",
    groupRoule: {
        "0": "Offentlig grupp: Alla användare är fria att gå med",
        "1": "Medlemsgrupp: Efter att användaren skickat in ansökan kan han gå med efter att ha godkänts av gruppadministratören",
        "2": "Privata grupper: Syns inte i gruppsökning och bara gruppen kan lägga till medlemmar",
    },
    man: "manlig",
    woman: "kvinna",
    mustBeTM: "Titel/namn krävs",
    mustBeTag: "Fyll i minst en tagg",
    mustBeCate: "Välj minst en kategori",
    contentTip: "Fyll i detaljerna här...",
    uploadSuccess: "Laddades upp!",
    publishError: "Publiceringen misslyckades!",
    // footer
    contactus: "kontakta oss",
    privacy: "Integritetspolicy",
    companyright: "Alla rättigheter förbehållna",
    // 其他
    sys_error: "systemfel!",
    replayEmpty: "Kommentarsinnehåll får inte vara tomt!",
    replay: "svar",
    replayErr: "kommentar misslyckades",
    delSuccess: "raderades framgångsrikt",
    defFail: "misslyckades med att radera",
    refresh: "uppdateringen är klar",
    updateSuccess: "Ändringen lyckades, logga in igen",
    old: "gammal",
    theNew: "ny",
    changeFail: "Lösenordsändring misslyckades",
    pwdlength: "lösenords längd",
    getverchatCode: "få verifieringskod",
    nopeoplezan: "Ingen gillar det än, var först att gilla det!",
    pwdResetSuccess: "Lösenordsåterställning slutförd",
    email_required: "E-post får inte vara tom",
    phone_required: "Mobilnummer kan inte vara tomt",
    addfriend: "Ansökan om att lägga till vänner har skickats framgångsrikt och väntar på att den andra parten ska gå med på det",
    Draft: "förslag",
    breakPwd: "Hämta lösenord",
    "Request to view history messages": "Ansök för att se historiska nyheter",
    "Wait for group manager to agree": "Vänta på att gruppägaren kommer överens",
    'Please enter a search term': "Ange sökord",
    done: "Säker",
    cancle: "Annullera",
    groupType: {
        '1': {
            name: "offentlig grupp",
            code: "1",
            desc: "`Öppen grupp: Alla användare kan skapa en grupp som är gratis att gå med utan granskning. Gruppnamnet beskriver medlemmar och kan sökas och bläddras på plattformen. `",
        },
        '2': {
            name: "medlemsgrupp",
            code: "2",
            desc: "`Medlemsgrupp: Alla användare kan skapa en grupp som kan gå med fritt och som måste granskas av gruppägaren Gruppnamnet beskriver medlemmarna och kan sökas och bläddras på plattformen. `",
        },
        '3': {
            name: "diskussionsgrupp",
            code: "3",
            desc: "`Diskussionsgrupp: alla användare kan skapa skapare och medlemmar kan bjuda in sina vänner att gå med i gruppen namn medlemmar kan söka och bläddra på plattformen. `",
        },
        '4': {
            name: "privat grupp",
            code: "4",
            desc: "`Privat grupp: Alla användare kan skapa ett gruppnamn för att beskriva gruppmedlemmarna och kan inte sökas i och bläddra på plattformen. `",
        }
    },
    newPwd: "Vänligen ange ett nytt inloggningslösenord",
    repNewPwd: "Vänligen ange det nya lösenordet igen",
    // 2021年3月4号
    meetingTips: "Ladda ner melinked-appen först eller uppgradera melinked-appen till den senaste versionen",
    // mechat协议
    mechat: {
        title: "Mig Chat Användaravtal",
        text1: "Mechat är en Q&amp;A-produkt för AI-chatt som tillhandahålls av Maes Consulting. När användaren samtycker till detta användaravtal anses denne ha samtyckt till hela innehållet i detta användaravtal.",
        header1: "ett. Hur vi delar och avslöjar användarnas personuppgifter med tredje part",
        text2: "Under följande omständigheter kommer denna applikation att avslöja användarens personliga information helt eller delvis i enlighet med användarens personliga önskemål eller lagliga regler:",
        text3: "1. Utlämnad till tredje part med användarens förhandsgodkännande;",
        text4: "2. För att kunna tillhandahålla de produkter och tjänster som efterfrågas av användaren är det nödvändigt att dela användarens personliga information med en tredje part;",
        text5: "3. I enlighet med relevanta bestämmelser i lagen, eller kraven från administrativa eller rättsliga organ, avslöja till tredje part eller administrativa eller rättsliga organ;",
        text6: "4. Om användaren bryter mot relevanta kinesiska lagar och förordningar eller detta applikationstjänstavtal eller relaterade regler måste det avslöjas för en tredje part;",
        text7: "5. Om användaren är en kvalificerad klagande om immateriella rättigheter och har lämnat in ett klagomål, ska han avslöja det för svaranden på svarandens begäran så att båda parter kan hantera eventuella rättighetstvister;",
        text8: "6. Andra upplysningar som denna applikation anser lämpliga i enlighet med lagar, förordningar eller webbplatspolicyer.",
        header2: "2. Informationslagring och utbyte",
        text9: "Informationen och data om användare som samlas in av MeChat kommer att lagras på MeChats och (eller) dess anslutna företags servrar. Endast den skickade och mottagna informationen kommer att laddas upp till servern i processen att chatta med mig, som kommer att användas för att svara på AI-modellen.",
        header3: "3. Immateriella rättigheter",
        text10: "Vi lämnar inga garantier för lagligheten, legitimiteten, integriteten eller kvaliteten på innehållet som laddas upp eller publiceras av användare. Användare ska bära riskerna som uppstår genom att använda eller förlita sig på innehållet eller resurserna som överförs av programvaran. I alla fall ska vi not ska inte hållas ansvarig för eventuella eller faktiska förluster eller skador som uppstår från sådan risk.",
        header4: "4. Juridiskt ansvar och ansvarsfriskrivning",
        text11: "I vilket fall som helst ska vi och våra partners inte hållas ansvariga för några direkta, indirekta, tillfälliga, speciella och efterföljande skador och risker som uppstår från användarens användning eller oförmåga att använda Me Chat. Om den normala driften av tjänsten påverkas av force majeure-händelser såsom tekniska fel, lovar vi och våra partners att samarbeta med relevanta enheter så snart som möjligt för att hantera och reparera dem i tid.",
        text12: "Informationen som skickas av AI-modellen genereras av datorn och kan vara fiktiv. Användare uppmanas att skilja rätt från fel. När användare använder denna applikation för att konsultera medicinska, juridiska och andra relaterade frågor, tillhandahåller denna applikation endast medicinsk rådgivning och juridisk rådgivning, och representerar inte medicinska tjänster, juridiska tjänster eller behandling. Användare måste rådfråga läkare och relevanta juridiska personer innan de använder denna applikation och är ytterst ansvariga för medicinska och juridiska beslut. Informationen och tjänsterna i denna app är endast för referens och utgör inte juridiska tjänster eller medicinska lösningar för medicinska tjänster, och vi tar inget ansvar som uppstår från användningen av denna app.",
    },
    originalMail: "Det ursprungliga meddelandet",
    emailRecipient: "mottagare",
    emailFrom: "avsändare",
    CcTo: "CC",
};
