//获取URL后缀名
export function getSuffix(url) {
  const file = parse(url).file;
  const index = file.lastIndexOf(".");
  if (index !== -1) {
    return file.substr(index + 1, file.length);
  }
}
export function objectToUrlParams(obj) {
  obj.language = localStorage.getItem("langCode") || '1';
  let params = [];
  params = Object.keys(obj).map(
    key => `${key}=${encodeURIComponent(obj[key])}`
  );
  return params.join("&");
}
export function parse(url) {
  parse.cache = parse.cache || {};
  if (!parse.cache[url]) {
    const a = document.createElement("a");
    a.href = url;
    parse.cache[url] = {
      source: url,
      protocol: a.protocol.replace(":", ""),
      host: a.hostname,
      port: a.port,
      query: a.search,
      params: (function () {
        var ret = {},
          seg = a.search.replace(/^\?/, "").split("&"),
          len = seg.length,
          i = 0,
          s;
        for (; i < len; i++) {
          if (!seg[i]) {
            continue;
          }
          s = seg[i].split("=");
          ret[s[0]] = s[1];
        }
        return ret;
      })(),
      file: (a.pathname.match(/([^/?#]+)$/i) || [, ""])[1],
      hash: a.hash.replace("#", ""),
      path: a.pathname.replace(/^([^/])/, "/$1"),
      relative: (a.href.match(/tps?:\/[^/]+(.+)/) || [, ""])[1],
      segments: a.pathname.replace(/^\//, "").split("/")
    };
  }
  return parse.cache[url];
}