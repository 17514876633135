export default {
    langs: {
        "zh-CN": "Çince",
        en: "İngilizce",
        fr: "Fransızca",
        es: "İspanyol",
        "ru-RU": "Rusça",
        hi: "Hintçe",
        ur: "Urduca",
        ja: "Japonca",
        ko: "Koreli",
        th: "Tayland",
        de: "Almanca",
        it: "İtalyan",
    },
    responFail: "Yükleme başarısız",
    responRetry: "yeniden dene&#39;yi tıklayın",
    responEmpty: "Veri yok",
    resendMsg: "Mesaj gönderilemedi, tekrar denemek için tıklayın",
    sendImage: "resim gönder",
    send: "Göndermek",
    //fastSend: "Hızlı göndermek için {key} tuşuna basın",
    //sendMsg: "Bir mesaj göndermek",
    //clickDownload: "İndirmek İçin Tıklayın",
    timeAgo: {
        0: "Sadece",
        1: "{n} dakika önce",
        2: "{n} saat önce",
        3: "Dün",
    },
    copyGroup: "Üyeleri yeni tartışma grubuna kopyala",
    quitGroup: "tartışma grubundan ayrıl",
    createGroup: "tartışma grubu oluştur",
    inviteFriend: "arkadaşları davet etmek",
    // web site
    login: "Giriş yapmak",
    loginerror: "Giriş başarısız oldu!",
    register: "kayıt olmak",
    keepOn: "bağlı kal",
    forgetPwd: "şifreyi unuttun mu?",
    logoutTip: "oturumu kapatmak için Tamam",
    resetPwd: "Oturum açma parolasını sıfırla",
    all: "Tümü",
    // 注册
    phoneErr: "Telefon numarası boş olamaz",
    phoneformatErr: "Telefon numarası biçimi yanlış",
    needNumber: "Lütfen sayısal bir değer girin",
    registerErr: "kayıt başarısız",
    // publish
    publish: "serbest bırakmak",
    group: "grup",
    school: "Okul",
    profess: "ana",
    degree: "Fen Fakültesi mezunu",
    time: "zaman",
    desc: "betimlemek",
    mustSchool: "okul boş olamaz",
    mustCompany: "şirket boş olamaz",
    company: "girişim",
    workexp: "iş deneyimi",
    education: "Eğitimsel tecrübe",
    companyName: "şirket",
    job: "Konum",
    name: "İsim",
    address: "adres",
    called: "isim",
    title: "başlık",
    price: "fiyat",
    tags: "Etiket",
    emoji: "ifade",
    sendFile: "Dosya Gönder",
    transferAccount: "Aktar",
    image: "resim",
    video: "video",
    voice: "ses",
    file: "belge",
    permissions: "izinler",
    groupRoule: {
        "0": "Herkese açık grup: Tüm kullanıcılar katılmakta özgürdür",
        "1": "Üye grubu: Kullanıcı başvurusunu yaptıktan sonra grup yöneticisi tarafından onaylandıktan sonra üye olabilir.",
        "2": "Özel Gruplar: Grup aramasında görünmez ve yalnızca grup üye ekleyebilir",
    },
    man: "erkek",
    woman: "dişi",
    mustBeTM: "Unvan/Ad gereklidir",
    mustBeTag: "En az bir etiket doldurun",
    mustBeCate: "En az bir kategori seçin",
    contentTip: "Ayrıntıları buraya girin...",
    uploadSuccess: "Başarıyla yüklendi!",
    publishError: "Yayınlanamadı!",
    // footer
    contactus: "bize Ulaşın",
    privacy: "Gizlilik Politikası",
    companyright: "her hakkı saklıdır",
    // 其他
    sys_error: "Sistem hatası!",
    replayEmpty: "Yorum içeriği boş geçilemez!",
    replay: "cevap vermek",
    replayErr: "yorum başarısız oldu",
    delSuccess: "başarıyla silindi",
    defFail: "silinemedi",
    refresh: "Güncelleme tamamlandı",
    updateSuccess: "Değişiklik başarılı, lütfen tekrar giriş yapın",
    old: "eskimiş",
    theNew: "yeni",
    changeFail: "Şifre değişikliği başarısız oldu",
    pwdlength: "şifre uzunluğu",
    getverchatCode: "doğrulama kodu al",
    nopeoplezan: "Henüz kimse beğenmedi, ilk beğenen siz olun!",
    pwdResetSuccess: "Parola sıfırlama tamamlandı",
    email_required: "E-posta boş geçilemez",
    phone_required: "Cep telefonu numarası boş geçilemez",
    addfriend: "Arkadaş ekleme başvurusu başarıyla gönderildi, karşı tarafın kabul etmesi bekleniyor",
    Draft: "taslak",
    breakPwd: "Parola Al",
    "Request to view history messages": "Tarihsel haberleri görüntülemek için başvurun",
    "Wait for group manager to agree": "Grup sahibinin kabul etmesini bekleyin",
    'Please enter a search term': "Arama anahtar kelimelerini girin",
    done: "Elbette",
    cancle: "İptal etmek",
    groupType: {
        '1': {
            name: "genel grup",
            code: "1",
            desc: "`Açık grup: Herhangi bir kullanıcı, inceleme olmaksızın ücretsiz olarak katılabilen bir grup oluşturabilir.Grup adı, üyeleri tanımlar ve platformda aranabilir ve göz atılabilir. `",
        },
        '2': {
            name: "üye grubu",
            code: "2",
            desc: "`Üye grubu: Herhangi bir kullanıcı, serbestçe katılabilen ve grup sahibi tarafından gözden geçirilmesi gereken bir grup oluşturabilir.Grup adı, üyeleri açıklar ve platformda aranabilir ve göz atılabilir. `",
        },
        '3': {
            name: "tartışma grubu",
            code: "3",
            desc: "`Tartışma grubu: herhangi bir kullanıcı oluşturucular oluşturabilir ve üyeler, arkadaşlarını gruba katılmaya davet edebilir, platformda üyeler aranabilir ve göz atılabilir. `",
        },
        '4': {
            name: "özel grup",
            code: "4",
            desc: "`Özel grup: Herhangi bir kullanıcı, grup üyelerini tanımlamak için bir grup adı oluşturabilir ve platformda aranamaz ve göz atılamaz. `",
        }
    },
    newPwd: "Lütfen yeni bir giriş şifresi girin",
    repNewPwd: "Lütfen yeni şifreyi tekrar girin",
    // 2021年3月4号
    meetingTips: "Lütfen önce melinked Uygulamasını indirin veya melinked APP&#39;yi en son sürüme yükseltin",
    // mechat协议
    mechat: {
        title: "Me Chat Kullanıcı Sözleşmesi",
        text1: "Mechat, Maes Consulting tarafından sağlanan bir AI sohbet arkadaşı Soru-Cevap ürünüdür. Kullanıcı, bu kullanıcı sözleşmesini kabul ettiğinde, bu kullanıcı sözleşmesinin tüm içeriğini kabul etmiş sayılır.",
        header1: "bir. Kullanıcıların kişisel bilgilerini üçüncü taraflarla nasıl paylaşır ve ifşa ederiz?",
        text2: "Aşağıdaki durumlarda bu uygulama, kullanıcının kişisel bilgilerinin tamamını veya bir kısmını kullanıcının kişisel istekleri veya yasal düzenlemelere göre açıklayacaktır:",
        text3: "1. Kullanıcının önceden onayı ile üçüncü kişilere açıklanmış;",
        text4: "2. Kullanıcı tarafından talep edilen ürün ve hizmetlerin sağlanabilmesi için kullanıcının kişisel bilgilerinin üçüncü bir kişi ile paylaşılmasının gerekli olması;",
        text5: "3. Kanunun ilgili hükümlerine veya idari veya adli mercilerin gerekliliklerine göre, üçüncü kişilere veya idari veya adli mercilere ifşa etmeyi;",
        text6: "4. Kullanıcı, ilgili Çin yasalarını ve düzenlemelerini veya bu uygulama hizmet sözleşmesini veya ilgili kuralları ihlal ederse, bunun üçüncü bir tarafa ifşa edilmesi gerekir;",
        text7: "5. Kullanıcı nitelikli bir fikri mülkiyet şikayetçisiyse ve bir şikayette bulunduysa, her iki tarafın da olası hak ihtilaflarını halledebilmesi için davalının talebi üzerine bunu davalıya ifşa edecektir;",
        text8: "6. Bu uygulamanın yasalara, düzenlemelere veya web sitesi politikalarına göre uygun gördüğü diğer açıklamalar.",
        header2: "2. Bilgi depolama ve alışverişi",
        text9: "MeChat tarafından toplanan kullanıcılar hakkında bilgi ve veriler, MeChat ve/veya bağlı şirketlerinin sunucularında saklanacaktır. AI modelinin yanıtı için kullanılacak olan Benimle sohbet sürecinde yalnızca gönderilen ve alınan bilgiler sunucuya yüklenecektir.",
        header3: "3. Fikri mülkiyetle ilgili",
        text10: "Kullanıcılar tarafından yüklenen veya yayınlanan içeriğin yasallığı, meşruiyeti, bütünlüğü veya kalitesi hakkında herhangi bir garanti vermiyoruz.Yazılım tarafından iletilen içerik veya kaynakların kullanımından veya bunlara güvenilmesinden doğacak riskler Kullanıcılara aittir. bu riskten doğabilecek olası veya fiili kayıp veya hasarlardan sorumlu olmayacaktır.",
        header4: "4. Yasal sorumluluk ve sorumluluk reddi",
        text11: "Her durumda, biz ve ortaklarımız, kullanıcıların Me Chat&#39;i kullanmalarından veya kullanamamalarından kaynaklanan doğrudan, dolaylı, arızi, özel ve müteakip zararlardan ve risklerden sorumlu tutulamayız. Hizmetin normal işleyişinin teknik arızalar gibi mücbir sebeplerden etkilenmesi durumunda, biz ve iş ortaklarımız, ilgili birimlerle mümkün olan en kısa sürede işbirliği yaparak bunları zamanında onarmayı taahhüt ederiz.",
        text12: "AI modeli tarafından gönderilen bilgiler bilgisayar tarafından üretilir ve hayali olabilir.Kullanıcılardan doğruyu yanlışı ayırt etmesi istenir. Kullanıcılar bu uygulamayı tıbbi, yasal ve diğer ilgili konularda danışmak için kullandıklarında, bu uygulama yalnızca tıbbi tavsiye ve yasal tavsiye sağlar ve tıbbi hizmetleri, yasal hizmetleri veya tedaviyi temsil etmez. Kullanıcılar bu uygulamayı kullanmadan önce doktorlarına ve ilgili tüzel kişilere danışmak zorundadırlar ve tıbbi ve hukuki kararlardan nihai olarak kendileri sorumludur. Bu uygulamadaki bilgiler ve hizmetler yalnızca referans amaçlıdır ve tıbbi hizmetler için yasal hizmetler veya tıbbi çözümler teşkil etmez ve bu uygulamanın kullanımından kaynaklanan herhangi bir sorumluluk kabul etmiyoruz.",
    },
    originalMail: "Orijinal mesaj",
    emailRecipient: "alıcı",
    emailFrom: "gönderen",
    CcTo: "CC",
};
