import http from "@/utils/http";
import {
  objectToUrlParams
} from "@/utils/url";
//获取好友列表
export function queryFriend(page = 0, pageNum = 1) {
  return http({
    url: "public/queryFriend.do",
    method: "get",
    params: {
      _im: "web",
      page,
      pageNum,
      pageSize: 65535
    }
  });
}

//获取最近聊天好友和群组
export function queryChatMessageEXT(page = 0, pageNum = 1) {
  return http({
    url: "melinked/msg/queryChatMessageEXT.do",
    method: "get",
    params: {
      msgType: "no_system",
      page,
      pageNum
    }
  });
}
export function searchByKeyword(params){
  return http({
    url:'rest/es/search',
    params
  })
}
export function searchFiles(type,params){
  return http({
    url: `rest/es/${type}`,
    params
  })
}

//获取群组列表
export function queryMemberRelationGroups(userId, page = 0, pageNum = 1) {
  return http({
    url: "public/v1/queryMemberRelationGroups.do",
    method: "get",
    params: {
      user_id: userId,
      page,
      pageNum
    }
  });
}

//获取和某个群组或好友的聊天记录
export function queryChatRecordEXT(
  contactId,
  type = "user",
  pageDownDate = "",
  lastQueryDate = "",
  pageIndex = 0,
  pageSize = 10
) {
  const params = {
    [`${type}Id`]: contactId,
    pageIndex,
    pageSize,
    pageDownDate,
    lastQueryDate,
    _: new Date().getTime()
  };
  // if (index == 0) {
  //   params.pageIndex = 0;
  //   index = 1;
  // }
  return http({
    url: "public/msg/queryChatRecordEXT.do",
    method: "get",
    params: params
  });
}

//获取群信息 群成员
export function queryGroupInfoAndMembers(groupId) {
  return http({
    url: "public/v1/queryGroupInfoAndMembers.do",
    method: "get",
    params: {
      group_id: groupId
    }
  });
}
export function isGroupMember(groupId, userId) {
  return http({
    url: "public/v1/isGroupMember.do",
    method: "get",
    params: {
      group_id: groupId,
      user_id: userId
    }
  });
}

//搜索聊天记录
export function searchChatDataAction(
  text = "",
  type = "all",
  attachmentType = "",
  page = 0,
  pageLength = 4
) {
  return http({
    url: "public/searchChatDataAction.do",
    method: "get",
    params: {
      term: text,
      type,
      attachmentType,
      page,
      pageLength
    }
  });
}
//搜索聊天记录消息

export function searchChatRecordEXT(contactId, msgId, type = "user") {
  return http({
    url: "public/msg/searchChatRecordEXT.do",
    method: "get",
    params: {
      [`${type}Id`]: contactId,
      msgID: msgId,
      pageSize: 10
    }
  });
}

// 搜索群
export function searchGroupByKey(params) {
  return http({
    url: '/public/v1/queryMemberRelationKeyGroups.do',
    method: 'get',
    params
  })
}
//创建讨论组
export function addSessionGroup(ids, names) {
  const params = {
    group_member: ids.join(",")
  };
  if (names) params.group_name = names.join(",");
  return http({
    url: "/public/v1/addSessionGroup.do",
    method: "get",
    params
  });
}

//发送消息 （转发消息，群聊消息）
export function sendMsgtoMuc(params) {
  params = {
    ...{
      mimeType: "text",
      excluder: "web"
    },
    ...params
  };
  params.group_id = params.groupId;
  delete params.groupId;
  if (params.content)
    params.content = params.content.replace(/<br\s*\/?>/gi, "\r\n");
  // .replace(/<\/?.+?\/?>/g, "");
  return http({
    url: "public/sendMsgtoMuc.do",
    method: "post",
    data: objectToUrlParams(params)
  });
}
export function replyGroupNotice(tipId, status,token) {
  return http({
    url: "public/v1/replyGroupNotice.do",
    method: "post",
    data: objectToUrlParams({
      tip_id: tipId,
      status,
      token
    })
  });
}
export function updataTranslateMsg(params) {
  return http({
    url: 'rest/group/message/translate/update',
    method: 'post',
    data: params,
    withCredentials: false
  })
}

//清除某个联系人的未读消息
export function cleanMsg(sendId, receiveId) {
  return http({
    url: "public/msg/cleanMsg.do",
    method: "get",
    params: {
      send_id: sendId,
      receive_id: receiveId
    }
  });
}

export function removeRelationshipAction(from, to, terminalos = "web") {
  return http({
    url: "public/removeRelationshipAction.do",
    method: "get",
    params: {
      terminalos,
      from,
      to
    }
  });
}
export function replyNotice(tipId, status = 1) {
  return http({
    url: "public/replyNotice.do",
    method: "post",
    data: objectToUrlParams({
      tip_id: tipId,
      status
    })
  });
}

//群成员退出群
export function exitGroupMember(groupId) {
  return http({
    url: "public/v1/exitGroupMember.do",
    method: "get",
    params: {
      group_id: groupId
    }
  });
}

//群主退出群
export function removeGroup(groupId) {
  return http({
    url: "public/v1/removeGroup.do",
    method: "get",
    params: {
      group_id: groupId
    }
  });
}
export function getGroupOwners(params){
  return http({
    url: `/rest/group/owners/${params.userId}`,
    params
  })
}
//添加群成员
export function addGroupMembers(groupId, groupMember) {
  return http({
    url: "public/v1/addGroupMembers.do",
    method: "post",
    params: {
      group_id: groupId,
      group_member: groupMember.join(","),
      addType: 1,
      gtype: 3,
      type: 0
    },
    data: {}
  });
}

export function removeGroupMember(groupId, userId,language = 1) {
  return http({
    url: "public/v1/removeGroupMember.do",
    params: {
      group_id: groupId,
      user_id: userId,
      language
    }
  });
}
export function getUnRedNum(userId) {
  return http({
    url: 'rest/message/v3/getUnreadMsgNum',
    withCredentials: false,
    params:{
      userId
    }
  })
}

export function updateGroupName(params) {
  return http({
    url: "public/v1/updateGroupName.do",
    method: "post",
    data: objectToUrlParams(params)
  });
}
export function updateGroupTrans(params) {
  return http({
    url: '/rest/translate/type/update',
    method: 'post',
    data: objectToUrlParams(params)
  })
}