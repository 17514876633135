<template>
  <van-tabbar v-model="active" class="me-tabbar" active-color="#33cc66" route>
    <van-tabbar-item to="/" class="tabbar me-icon-home"></van-tabbar-item>
    <van-tabbar-item to="/chat" class="tabbar me-icon-chat"> 
    <!-- :info="unread != 0 ? unread : ''" -->
     <!-- <van-icon :class="{'msg-dot':true,'hidden':!unread || unread == 0}" :info="5" /> -->
     </van-tabbar-item>
    <van-tabbar-item to="/friends" class="tabbar me-icon-friends"></van-tabbar-item>
    <van-tabbar-item to="/my" class="tabbar me-icon-my"></van-tabbar-item>
  </van-tabbar>
</template>
<script>
import {getUnRedNum} from '@/api/im'
export default {
  name: "tabbar",
  props: {},
  data() {
    return {
      active: 0,
      loginUser:{},
      unread:0,
      catchMsg:{}
    };
  },
  watch:{
    active:{
      handler(){
        const User = this.$store.state.User
        if(User.userinfo && User.userinfo.id)
        this.handlerGetUnRedNum(User.userinfo.id)
      }
    },
    '$store.state.unredNum':{
      handler(val){
        this.unread = val
      },
      deep:true
    },
  
  },
  async created(){
    const userinfo = localStorage.getItem('userinfo')
    this.loginUser = userinfo ? JSON.parse(userinfo) : {}
    
    await this.handlerGetUnRedNum(this.loginUser.id || '')
  },
  mounted(){
     this.$listener(async socketData => {
      if (socketData.code == 2) { 
        this.$send({ code: 2 });        
        return;
      }
      const data = socketData     
       const protocal = JSON.parse(data.protocal);
       if (!data || !data.type || data.type === 'receipt' || data.type === 'set' || data.type === 'result' || !protocal.messageId) return;
    
      // const header = data.header;
      const messageId= data.messageId      
      if(!this.catchMsg[messageId]){
        this.catchMsg[messageId] = true
        this.unread = this.$store.state.unredNum + 1
        this.$store.dispatch('commitUnRed', this.unread)
      }
      // const item = {
      //   avatar,
      //   name,
      //   messageId,
      //   id:target.replace('G',''),
      //   isGroup:data.type === 'group' ? true : false,
      //   isNotice:data.type === "notice",
      //   msgNum:1,
      //   time:header.servertimestamp,
      //   messageData:{
      //     data:protocal.data
      //   }

      // }
      
      // if(this.catchMsg.some(el=>{        
      //   return el.messageId !== data.messageId
      // }) || !this.catchMsg.length){
      //   this.unread = this.$store.state.unredNum + 1
      //   this.$store.dispatch('commitUnRed', this.unread)
      //   this.catchMsg.push(item)
      // }
    })
  },
  methods:{
    async handlerGetUnRedNum(id){
      if(!id) return      
      const result = await getUnRedNum(id)      
      if(result && result.code === 200){
        this.unread = result.data.data
        this.$store.dispatch('commitUnRed',this.unread)
      }
       
      
    }
  }
};
</script>
<style lang="stylus">
.me-tabbar {
  z-index: 100;

  .tabbar {
    font-size: 24px;
    .van-tabbar-item__icon{
      margin 0
      .van-info{
        top -18px
        margin 0
        right -16px
      }
    }
  }
}
</style>