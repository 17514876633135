var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-dropdown-menu",
    {
      staticClass: "lang-dropdown",
      style: { height: "80%" },
      attrs: { "active-color": "#33cc66" }
    },
    [
      _c("van-dropdown-item", {
        attrs: { options: _vm.langs },
        on: {
          change: _vm.handlerChangeLang,
          opened: _vm.handlerOpen,
          close: _vm.handlerClose
        },
        model: {
          value: _vm.curLocale,
          callback: function($$v) {
            _vm.curLocale = $$v
          },
          expression: "curLocale"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }