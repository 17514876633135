export default {
    langs: {
        "zh-CN": "cina",
        en: "Inggeris",
        fr: "Perancis",
        es: "bahasa Sepanyol",
        "ru-RU": "bahasa Rusia",
        hi: "Hindi",
        ur: "bahasa Urdu",
        ja: "Jepun",
        ko: "Korea",
        th: "Thai",
        de: "Jerman",
        it: "itali",
    },
    responFail: "Gagal memuatkan",
    responRetry: "klik cuba semula",
    responEmpty: "Tiada data",
    resendMsg: "Gagal menghantar mesej, klik untuk mencuba lagi",
    sendImage: "hantar gambar-gambar",
    send: "hantar",
    //fastSend: "Tekan {key} untuk menghantar dengan cepat",
    //sendMsg: "Hantar satu mesej",
    //clickDownload: "klik untuk memuat turun",
    timeAgo: {
        0: "cuma",
        1: "{n} minit yang lalu",
        2: "{n} jam yang lalu",
        3: "semalam",
    },
    copyGroup: "Salin ahli ke kumpulan perbincangan baharu",
    quitGroup: "meninggalkan kumpulan perbincangan",
    createGroup: "buat kumpulan perbincangan",
    inviteFriend: "menjemput kawan-kawan",
    // web site
    login: "Log masuk",
    loginerror: "Daftar masuk gagal!",
    register: "mendaftar",
    keepOn: "kekal log masuk",
    forgetPwd: "lupa kata laluan?",
    logoutTip: "OK untuk log keluar",
    resetPwd: "Tetapkan semula kata laluan log masuk",
    all: "semua",
    // 注册
    phoneErr: "Nombor telefon tidak boleh kosong",
    phoneformatErr: "Format nombor telefon tidak betul",
    needNumber: "Sila masukkan nilai angka",
    registerErr: "pendaftaran gagal",
    // publish
    publish: "lepaskan",
    group: "kumpulan",
    school: "Sekolah",
    profess: "jurusan",
    degree: "Sarjana Muda Sains",
    time: "masa",
    desc: "huraikan",
    mustSchool: "sekolah tidak boleh kosong",
    mustCompany: "syarikat tidak boleh kosong",
    company: "perusahaan",
    workexp: "pengalaman kerja",
    education: "Pengalaman pendidikan",
    companyName: "syarikat",
    job: "kedudukan",
    name: "Nama",
    address: "alamat",
    called: "nama",
    title: "tajuk",
    price: "harga",
    tags: "Label",
    emoji: "ungkapan",
    sendFile: "Hantar fail",
    transferAccount: "pemindahan",
    image: "gambar",
    video: "video",
    voice: "suara",
    file: "dokumen",
    permissions: "kebenaran",
    groupRoule: {
        "0": "Kumpulan awam: Semua pengguna bebas untuk menyertai",
        "1": "Kumpulan ahli: Selepas pengguna menyerahkan permohonan, dia boleh menyertai selepas diluluskan oleh pentadbir kumpulan",
        "2": "Kumpulan Persendirian: Tidak kelihatan dalam carian kumpulan dan hanya kumpulan itu boleh menambah ahli",
    },
    man: "lelaki",
    woman: "perempuan",
    mustBeTM: "Tajuk/Nama diperlukan",
    mustBeTag: "Isikan sekurang-kurangnya satu tag",
    mustBeCate: "Pilih sekurang-kurangnya satu kategori",
    contentTip: "Isikan butiran di sini...",
    uploadSuccess: "Berjaya dimuat naik!",
    publishError: "Terbitan gagal!",
    // footer
    contactus: "hubungi Kami",
    privacy: "Dasar Privasi",
    companyright: "hak cipta terpelihara",
    // 其他
    sys_error: "ralat sistem!",
    replayEmpty: "Kandungan ulasan tidak boleh kosong!",
    replay: "balas",
    replayErr: "komen gagal",
    delSuccess: "berjaya dipadamkan",
    defFail: "gagal memadam",
    refresh: "kemas kini selesai",
    updateSuccess: "Pengubahsuaian berjaya, sila log masuk semula",
    old: "tua",
    theNew: "baru",
    changeFail: "Pengubahsuaian kata laluan gagal",
    pwdlength: "panjang kata laluan",
    getverchatCode: "dapatkan kod pengesahan",
    nopeoplezan: "Belum ada yang menyukainya, jadilah yang pertama menyukainya!",
    pwdResetSuccess: "Tetapan semula kata laluan selesai",
    email_required: "E-mel tidak boleh kosong",
    phone_required: "Nombor telefon bimbit tidak boleh kosong",
    addfriend: "Permohonan untuk menambah rakan telah berjaya dihantar, menunggu pihak lain bersetuju",
    Draft: "draf",
    breakPwd: "dapatkan kata laluan",
    "Request to view history messages": "Mohon untuk melihat berita sejarah",
    "Wait for group manager to agree": "Tunggu pemilik kumpulan bersetuju",
    'Please enter a search term': "Masukkan kata kunci carian",
    done: "pasti",
    cancle: "Batal",
    groupType: {
        '1': {
            name: "kumpulan awam",
            code: "1",
            desc: "`Kumpulan terbuka: Mana-mana pengguna boleh membuat kumpulan yang bebas untuk disertai tanpa semakan. Nama kumpulan menerangkan ahli dan boleh dicari dan dilayari di platform. `",
        },
        '2': {
            name: "kumpulan ahli",
            code: "2",
            desc: "`Kumpulan ahli: Mana-mana pengguna boleh membuat kumpulan yang boleh menyertai secara bebas dan perlu disemak oleh pemilik kumpulan. Nama kumpulan menerangkan ahli dan boleh dicari dan dilayari di platform. `",
        },
        '3': {
            name: "kumpulan perbincangan",
            code: "3",
            desc: "`Kumpulan perbincangan: mana-mana pengguna boleh mencipta pencipta dan ahli boleh menjemput rakan mereka untuk menyertai nama kumpulan ahli boleh dicari dan dilayari di platform. `",
        },
        '4': {
            name: "kumpulan persendirian",
            code: "4",
            desc: "`Kumpulan persendirian: Mana-mana pengguna boleh mencipta nama kumpulan untuk menerangkan ahli kumpulan dan tidak boleh dicari dan dilayari di platform. `",
        }
    },
    newPwd: "Sila masukkan kata laluan log masuk baharu",
    repNewPwd: "Sila masukkan semula kata laluan baharu",
    // 2021年3月4号
    meetingTips: "Sila muat turun Apl melinked dahulu atau tingkatkan APP melinked kepada versi terkini",
    // mechat协议
    mechat: {
        title: "Perjanjian Pengguna Sembang Saya",
        text1: "Mechat ialah produk Soal Jawab rakan sembang AI yang disediakan oleh Maes Consulting. Apabila pengguna bersetuju dengan perjanjian pengguna ini, ia dianggap telah bersetuju dengan keseluruhan kandungan perjanjian pengguna ini.",
        header1: "satu. Cara kami berkongsi dan mendedahkan maklumat peribadi pengguna dengan pihak ketiga",
        text2: "Di bawah keadaan berikut, aplikasi ini akan mendedahkan maklumat peribadi pengguna secara keseluruhan atau sebahagian mengikut kehendak peribadi atau peraturan undang-undang pengguna:",
        text3: "1. Didedahkan kepada pihak ketiga dengan kebenaran pengguna terlebih dahulu;",
        text4: "2. Untuk menyediakan produk dan perkhidmatan yang diminta oleh pengguna, adalah perlu untuk berkongsi maklumat peribadi pengguna dengan pihak ketiga;",
        text5: "3. Menurut peruntukan undang-undang yang berkaitan, atau keperluan agensi pentadbiran atau kehakiman, dedahkan kepada pihak ketiga atau agensi pentadbiran atau kehakiman;",
        text6: "4. Jika pengguna melanggar undang-undang dan peraturan China yang berkaitan atau perjanjian perkhidmatan aplikasi ini atau peraturan berkaitan, ia perlu didedahkan kepada pihak ketiga;",
        text7: "5. Jika pengguna adalah pengadu harta intelek yang berkelayakan dan telah memfailkan aduan, dia hendaklah mendedahkannya kepada responden atas permintaan responden supaya kedua-dua pihak boleh mengendalikan kemungkinan pertikaian hak;",
        text8: "6. Pendedahan lain yang difikirkan sesuai oleh aplikasi ini menurut undang-undang, peraturan atau dasar tapak web.",
        header2: "2. Penyimpanan dan pertukaran maklumat",
        text9: "Maklumat dan data tentang pengguna yang dikumpul oleh MeChat akan disimpan pada pelayan MeChat dan (atau) syarikat gabungannya. Hanya maklumat yang dihantar dan diterima akan dimuat naik ke pelayan dalam proses berbual dengan Saya, yang akan digunakan untuk balasan model AI.",
        header3: "3. Berkaitan harta intelek",
        text10: "Kami tidak membuat sebarang jaminan untuk kesahihan, kesahihan, integriti atau kualiti kandungan yang dimuat naik atau diterbitkan oleh pengguna. Pengguna hendaklah menanggung risiko yang timbul daripada menggunakan atau bergantung pada kandungan atau sumber yang dihantar oleh perisian. Walau apa pun, kami akan not tidak akan bertanggungjawab untuk sebarang kemungkinan atau kerugian sebenar atau kerosakan yang timbul daripada risiko tersebut.",
        header4: "4. Liabiliti undang-undang dan penafian",
        text11: "Dalam apa jua keadaan, kami dan rakan kongsi kami tidak akan bertanggungjawab untuk sebarang kerosakan dan risiko langsung, tidak langsung, sampingan, khas dan seterusnya yang timbul daripada penggunaan atau ketidakupayaan pengguna untuk menggunakan Me Chat. Jika operasi biasa perkhidmatan terjejas oleh peristiwa force majeure seperti kegagalan teknikal, kami dan rakan kongsi kami berjanji untuk bekerjasama dengan unit berkaitan secepat mungkin untuk menangani dan membaikinya tepat pada masanya. Tidak bertanggungjawab.",
        text12: "Maklumat yang dihantar oleh model AI dijana oleh komputer dan mungkin rekaan. Pengguna diminta untuk membezakan yang betul dari yang salah. Apabila pengguna menggunakan aplikasi ini untuk berunding dengan isu perubatan, undang-undang dan isu lain yang berkaitan, aplikasi ini hanya menyediakan nasihat perubatan dan nasihat undang-undang, dan tidak mewakili perkhidmatan perubatan, perkhidmatan undang-undang atau rawatan. Pengguna mesti berunding dengan doktor dan orang undang-undang yang berkaitan sebelum menggunakan aplikasi ini, dan akhirnya bertanggungjawab untuk keputusan perubatan dan undang-undang. Maklumat dan perkhidmatan dalam aplikasi ini adalah untuk rujukan sahaja, dan tidak membentuk perkhidmatan undang-undang atau penyelesaian perubatan untuk perkhidmatan perubatan, dan kami tidak memikul sebarang tanggungjawab yang timbul daripada penggunaan aplikasi ini.",
    },
    originalMail: "Mesej asal",
    emailRecipient: "penerima",
    emailFrom: "penghantar",
    CcTo: "CC",
};
