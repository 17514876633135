export default {
    "langs": {
        "zh-CN": "Chinois",
        "en": "Anglais",
        "fr": "Le français",
        "es": "Espagnol",
        "ru-RU": "Russe",
        "hi": "Hindi",
        "ur": "Ourdou",
        "ja": "Japonais",
        "ko": "Coréen",
        "th": "Thaï",
        "de": "Allemand",
        "it": "Italien"
    },
    "responFail": "Chargement échoué",
    "responRetry": "Cliquez sur Réessayer",
    "responEmpty": "Pas encore de données",
    "resendMsg": "Échec d'envoi du message, cliquez pour réessayer",
    "sendImage": "Envoyer une photo",
    "send": "Envoyer",
    "timeAgo": {
        "0": "Juste",
        "1": "Il y a {n} minutes",
        "2": "Il y a {n} heures",
        "3": "Hier"
    },
    "copyGroup": "Copier les membres dans un nouveau groupe de discussion",
    "quitGroup": "Quitter le groupe de discussion",
    "createGroup": "Créer un groupe de discussion",
    "inviteFriend": "Inviter des amis",
    "login": "Connexion",
    "loginerror": "La connexion a échoué!",
    "register": "Inscription",
    "keepOn": "Continuez à vous connecter",
    "forgetPwd": "Mot de passe oublié?",
    "logoutTip": "OK pour vous déconnecter",
    "resetPwd": "Réinitialiser le mot de passe de connexion",
    "all": "Tous",
    "phoneErr": "Le numéro de téléphone ne peut pas être vide",
    "phoneformatErr": "Le format du numéro de téléphone est incorrect",
    "needNumber": "S'il vous plaît entrer une valeur numérique",
    "registerErr": "L'enregistrement a échoué",
    "publish": "Libération",
    "group": "Groupe",
    "school": "L'école",
    "profess": "Professionnel",
    "degree": "Degré",
    "time": "Le temps",
    "mustSchool": "L'école ne peut pas être vide",
    "mustCompany": "La compagnie ne peut pas être vide",
    "company": "Affaires",
    "workexp": "Expérience de travail",
    "education": "Expérience éducative",
    "companyName": "Société",
    "job": "Position",
    "name": "Nom",
    "address": "Adresse",
    "called": "Nom",
    "title": "Titre",
    "price": "Prix",
    "tags": "Étiquette",
    "emoji": "Expression",
    "sendFile": "Envoyer le fichier",
    "transferAccount": "Transfert",
    "image": "Photo",
    "video": "Vidéo",
    "voice": "Voix",
    "file": "Fichier",
    "groupRoule": {
        "0": "Groupe ouvert: tous les utilisateurs sont libres de rejoindre",
        "1": "Adhésion: une fois que l'utilisateur a soumis l'application, il peut être rejoint par l'administrateur du groupe.",
        "2": "Groupe privé: non visible dans la recherche de groupe, et seul le groupe peut ajouter des membres"
    },
    "man": "Mâle",
    "woman": "Femme",
    "mustBeTM": "Titre / Nom Obligatoire",
    "mustBeTag": "Remplir au moins une étiquette",
    "mustBeCate": "Sélectionnez au moins une des catégories",
    "contentTip": "Remplissez les détails ici ...",
    "uploadSuccess": "Téléchargé avec succès!",
    "publishError": "La publication a échoué!",
    "contactus": "Nous contacter",
    "privacy": "Politique de confidentialité",
    "companyright": "Droit d'auteur",
    "sys_error": "Erreur système!",
    "replayEmpty": "Les commentaires ne peuvent pas être vides!",
    "replay": "Répondre",
    "replayErr": "Le commentaire a échoué",
    "delSuccess": "Supprimé avec succès",
    "defFail": "La suppression a échoué",
    "refresh": "Mis à jour avec succès",
    "updateSuccess": "La modification est réussie, veuillez vous reconnecter.",
    "old": "Vieux",
    "theNew": "Nouveau",
    "changeFail": "La modification du mot de passe a échoué",
    "pwdlength": "Longueur du mot de passe",
    "getverchatCode": "Obtenir le code de vérification",
    "nopeoplezan": "Personne n'a encore aimé, allez être le premier à louer!",
    "pwdResetSuccess": "Le mot de passe a été modifié avec succès.",
    "email_required": "La boîte aux lettres ne peut pas être vide",
    "phone_required": "Le numéro de téléphone ne peut pas être vide",
    done: 'confirmé',
    cancle: 'annulé',
    groupType: {
        '1': {
            name: 'public group',
            code: 1,
            desc: ` groupe public: tout utilisateur peut créer la liberté d’adhésion, pas besoin. Le nom, la description peut être recherchée de la plateforme,.
`
        },
        '2': {
            name: 'groupe d’ états membres',
            code: "2",
            desc: ` membres du groupe: tout utilisateur peut créer la liberté d’adhésion, le groupe d’audit. Groupe de noms, les membres de la plateforme peut être recherchée.
`
        },
        '3': {
            name: 'groupe de discussion',
            code: 3,
            desc: `groupe de discussion：tout utilisateur peut créer les créateurs et les membres peuvent inviter leurs amis nom, membres de la plateforme peut être recherchée.
`
        },
        '4': {
            name: 'private group',
            code: 4,
            desc: `intimes groupe: tout utilisateur peut créer le nom, la description de membres du groupe de recherche sur la plate-forme ne peut pas.
`
        }
    },
    newPwd: 'Veuillez saisir un nouveau mot de passe de connexion',
    repNewPwd: "Veuillez répéter le nouveau mot de passe",
    breakPwd: 'Récupérer le mot de passe',
    // 2021年3月4号
    meetingTips: "Veuillez d'abord télécharger l'application Melinked ou mettre à niveau l'application Melinked vers la dernière version",
    // mechat协议
    mechat: {
      title: "Accord d&#39;utilisation de Me Chat",
      text1: "Mechat est un produit Q&amp;A compagnon de chat AI fourni par Maes Consulting. Lorsque l&#39;utilisateur accepte le présent contrat d&#39;utilisation, il est réputé avoir accepté l&#39;intégralité du contenu du présent contrat d&#39;utilisation.",
      header1: "un. Comment nous partageons et divulguons les informations personnelles des utilisateurs avec des tiers",
      text2: "Dans les circonstances suivantes, cette application divulguera les informations personnelles de l&#39;utilisateur en tout ou en partie selon les souhaits personnels de l&#39;utilisateur ou les réglementations légales :",
      text3: "1. Divulgués à des tiers avec le consentement préalable de l&#39;utilisateur ;",
      text4: "2. Afin de fournir les produits et services demandés par l&#39;utilisateur, il est nécessaire de partager les informations personnelles de l&#39;utilisateur avec un tiers ;",
      text5: "3. Selon les dispositions pertinentes de la loi, ou les exigences des organismes administratifs ou judiciaires, divulguer à des tiers ou des organismes administratifs ou judiciaires ;",
      text6: "4. Si l&#39;utilisateur enfreint les lois et réglementations chinoises pertinentes ou le présent contrat de service d&#39;application ou les règles connexes, il doit être divulgué à un tiers ;",
      text7: "5. Si l&#39;utilisateur est un plaignant qualifié en matière de propriété intellectuelle et a déposé une plainte, il doit la divulguer au défendeur à la demande du défendeur afin que les deux parties puissent gérer d&#39;éventuels litiges en matière de droits ;",
      text8: "6. Autres divulgations que cette application juge appropriées conformément aux lois, règlements ou politiques du site Web.",
      header2: "2. Stockage et échange d&#39;informations",
      text9: "Les informations et données sur les utilisateurs collectées par MeChat seront stockées sur les serveurs de MeChat et (ou) de ses sociétés affiliées. Seules les informations envoyées et reçues seront téléchargées sur le serveur lors du processus de discussion avec moi, qui seront utilisées pour la réponse du modèle AI.",
      header3: "3. Liés à la propriété intellectuelle",
      text10: "Nous n&#39;offrons aucune garantie quant à la légalité, la légitimité, l&#39;intégrité ou la qualité du contenu téléchargé ou publié par les utilisateurs. Les utilisateurs assument les risques découlant de l&#39;utilisation ou de la confiance dans le contenu ou les ressources transmises par le logiciel. Dans tous les cas, nous devons ne sera pas responsable de toute perte ou dommage éventuel ou réel résultant d&#39;un tel risque.",
      header4: "4. Responsabilité légale et clause de non-responsabilité",
      text11: "Dans tous les cas, nous et nos partenaires ne serons pas responsables des dommages et risques directs, indirects, accessoires, spéciaux et ultérieurs résultant de l&#39;utilisation ou de l&#39;incapacité des utilisateurs à utiliser Me Chat. Si le fonctionnement normal du service est affecté par des événements de force majeure tels que des défaillances techniques, nous et nos partenaires nous engageons à coopérer avec les unités concernées dès que possible pour les traiter et les réparer en temps opportun.",
      text12: "Les informations envoyées par le modèle d&#39;IA sont générées par l&#39;ordinateur et peuvent être fictives.Les utilisateurs sont invités à distinguer le vrai du faux. Lorsque les utilisateurs utilisent cette application pour consulter des questions médicales, juridiques et autres, cette application ne fournit que des conseils médicaux et des conseils juridiques, et ne représente pas des services médicaux, des services juridiques ou des traitements. Les utilisateurs doivent consulter les médecins et les personnes morales concernées avant d&#39;utiliser cette application, et sont responsables en dernier ressort des décisions médicales et juridiques. Les informations et les services de cette application sont fournis à titre indicatif uniquement et ne constituent pas des services juridiques ou des solutions médicales pour les services médicaux, et nous n&#39;assumons aucune responsabilité découlant de l&#39;utilisation de cette application.",
    },
    originalMail: "Le message d&#39;origine",
    emailRecipient: "destinataire",
    emailFrom: "expéditeur",
    CcTo: "CC",
}