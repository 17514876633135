var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-share" },
    [
      _c(
        "van-popup",
        {
          staticClass: "me-share--items",
          style: { height: "15%" },
          attrs: { position: "bottom", "get-container": "body" },
          on: {
            closed: function($event) {
              return _vm.$emit("close")
            }
          },
          model: {
            value: _vm.status,
            callback: function($$v) {
              _vm.status = $$v
            },
            expression: "status"
          }
        },
        [
          _c("social-sharing", {
            attrs: {
              url: _vm.data.url,
              title: _vm.data.title,
              description:
                _vm.data.desc && _vm.data.desc.length > 25
                  ? _vm.data.desc.slice(0, 25)
                  : _vm.data.desc,
              quote: _vm.data.title,
              hashtags: _vm.data.tags || "",
              "twitter-user": _vm.data.title
            },
            on: {
              close: function($event) {
                return _vm.$emit("close")
              }
            },
            inlineTemplate: {
              render: function() {
                var _vm = this
                var _h = _vm.$createElement
                var _c = _vm._self._c || _h
                return _c(
                  "div",
                  { staticClass: "me-share__items" },
                  [
                    _c("network", { attrs: { network: "googleplus" } }, [
                      _c("i", { staticClass: "me-icon-google" })
                    ]),
                    _c("network", { attrs: { network: "weibo" } }, [
                      _c("i", { staticClass: "me-icon-weibo" })
                    ]),
                    _c("network", { attrs: { network: "facebook" } }, [
                      _c("i", { staticClass: "me-icon-facebook" })
                    ]),
                    _c("network", { attrs: { network: "linkedin" } }, [
                      _c("i", { staticClass: "me-icon-linkedin" })
                    ]),
                    _c("network", { attrs: { network: "twitter" } }, [
                      _c("i", { staticClass: "me-icon-twitter" })
                    ])
                  ],
                  1
                )
              },
              staticRenderFns: []
            }
          }),
          _c(
            "span",
            { staticClass: "addCopyBtn", on: { click: _vm.handlerCopy } },
            [_c("i", { staticClass: "me-icon-link" })]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }