export default {
    langs: {
        "zh-CN": "kínai",
        en: "angol",
        fr: "Francia",
        es: "spanyol",
        "ru-RU": "orosz",
        hi: "hindi",
        ur: "urdu",
        ja: "japán",
        ko: "koreai",
        th: "thai",
        de: "német",
        it: "olasz",
    },
    responFail: "Nem sikerült betölteni",
    responRetry: "kattintson újra",
    responEmpty: "Nincs adat",
    resendMsg: "Nem sikerült elküldeni az üzenetet, kattintson az újrapróbálkozáshoz",
    sendImage: "Küldj képeket",
    send: "Küld",
    //fastSend: "Nyomja meg a {billentyűt} a gyors küldéshez",
    //sendMsg: "Üzenetet küldeni",
    //clickDownload: "kattintson a letöltéshez",
    timeAgo: {
        0: "éppen",
        1: "{n} perce",
        2: "{n} órája",
        3: "tegnap",
    },
    copyGroup: "Tagok másolása új vitacsoportba",
    quitGroup: "hagyja el a vitacsoportot",
    createGroup: "vitacsoport létrehozása",
    inviteFriend: "meghívja barátait",
    // web site
    login: "Belépés",
    loginerror: "Bejelentkezés sikertelen!",
    register: "Regisztráció",
    keepOn: "maradj bejelentkezve",
    forgetPwd: "elfelejtette a jelszót?",
    logoutTip: "OK a kijelentkezéshez",
    resetPwd: "Állítsa vissza a bejelentkezési jelszót",
    all: "minden",
    // 注册
    phoneErr: "A telefonszám nem lehet üres",
    phoneformatErr: "A telefonszám formátuma helytelen",
    needNumber: "Kérjük, adjon meg egy számértéket",
    registerErr: "Regisztráció sikertelen",
    // publish
    publish: "kiadás",
    group: "csoport",
    school: "Iskola",
    profess: "Jelentősebb",
    degree: "Bachelor of Science",
    time: "idő",
    desc: "leírni",
    mustSchool: "az iskola nem lehet üres",
    mustCompany: "a cég nem lehet üres",
    company: "vállalkozás",
    workexp: "munkatapasztalat",
    education: "Oktatási tapasztalat",
    companyName: "vállalat",
    job: "Pozíció",
    name: "Név",
    address: "cím",
    called: "név",
    title: "cím",
    price: "ár",
    tags: "Címke",
    emoji: "kifejezés",
    sendFile: "Fájl küldése",
    transferAccount: "átruházás",
    image: "kép",
    video: "videó",
    voice: "hang",
    file: "dokumentum",
    permissions: "engedélyeket",
    groupRoule: {
        "0": "Nyilvános csoport: Minden felhasználó szabadon csatlakozhat",
        "1": "Tagcsoport: Miután a felhasználó elküldte a jelentkezését, a csoport adminisztrátorának jóváhagyása után csatlakozhat",
        "2": "Privát csoportok: Nem látható a csoportkeresésben, és csak a csoport vehet fel tagokat",
    },
    man: "férfi",
    woman: "női",
    mustBeTM: "Cím/név kötelező",
    mustBeTag: "Töltsön ki legalább egy címkét",
    mustBeCate: "Válasszon legalább egy kategóriát",
    contentTip: "Töltse ki a részleteket itt...",
    uploadSuccess: "Sikeresen feltöltve!",
    publishError: "A közzététel nem sikerült!",
    // footer
    contactus: "lépjen kapcsolatba velünk",
    privacy: "Adatvédelmi irányelvek",
    companyright: "minden jog fenntartva",
    // 其他
    sys_error: "rendszer hiba!",
    replayEmpty: "A megjegyzés tartalma nem lehet üres!",
    replay: "válasz",
    replayErr: "megjegyzés sikertelen",
    delSuccess: "sikeresen törölve",
    defFail: "nem sikerült törölni",
    refresh: "frissítés befejeződött",
    updateSuccess: "A módosítás sikeres, kérjük, jelentkezzen be újra",
    old: "régi",
    theNew: "új",
    changeFail: "A jelszó módosítása nem sikerült",
    pwdlength: "jelszó hosszúság",
    getverchatCode: "kap ellenőrző kódot",
    nopeoplezan: "Még senkinek sem tetszik, legyen az első, akinek tetszik!",
    pwdResetSuccess: "A jelszó visszaállítása kész",
    email_required: "Az e-mail nem lehet üres",
    phone_required: "A mobiltelefonszám nem lehet üres",
    addfriend: "Az ismerősök felvételére vonatkozó kérelmet sikeresen elküldtük, a másik fél beleegyezésére várva",
    Draft: "tervezet",
    breakPwd: "jelszó lekérése",
    "Request to view history messages": "Jelentkezzen a történelmi hírek megtekintéséhez",
    "Wait for group manager to agree": "Várja meg, amíg a csoport tulajdonosa beleegyezik",
    'Please enter a search term': "Írja be a keresési kulcsszavakat",
    done: "biztos",
    cancle: "Megszünteti",
    groupType: {
        '1': {
            name: "nyilvános csoport",
            code: "1",
            desc: "`Nyitott csoport: Bármely felhasználó létrehozhat egy csoportot, amelyhez ingyenesen csatlakozhat felülvizsgálat nélkül. A csoport neve leírja a tagokat, és kereshető és böngészhető a platformon. `",
        },
        '2': {
            name: "tagcsoport",
            code: "2",
            desc: "`Tag csoport: Bármely felhasználó létrehozhat egy csoportot, amelyhez szabadon csatlakozhat, és a csoport tulajdonosának ellenőriznie kell, a csoport neve leírja a tagokat, és kereshető és böngészhető a platformon. `",
        },
        '3': {
            name: "vitacsoport",
            code: "3",
            desc: "`Vitazócsoport: bármely felhasználó létrehozhat alkotókat, a tagok pedig meghívhatják ismerőseiket a csoportba. A platformon a tagok kereshetők és böngészhetők. `",
        },
        '4': {
            name: "privát csoport",
            code: "4",
            desc: "`Privát csoport: Bármely felhasználó létrehozhat egy csoportnevet a csoporttagok leírására, és nem kereshet és nem böngészhet a platformon. `",
        }
    },
    newPwd: "Kérjük, adjon meg egy új bejelentkezési jelszót",
    repNewPwd: "Kérjük, adja meg újra az új jelszót",
    // 2021年3月4号
    meetingTips: "Kérjük, először töltse le a melinked alkalmazást, vagy frissítse a melinked APP-t a legújabb verzióra",
    // mechat协议
    mechat: {
        title: "Me Chat felhasználói szerződés",
        text1: "A Mechat a Maes Consulting által biztosított AI-csevegési társtermék. Ha a felhasználó elfogadja ezt a felhasználói szerződést, úgy kell tekinteni, hogy elfogadta a jelen felhasználói szerződés teljes tartalmát.",
        header1: "egy. Hogyan osztjuk meg és közöljük a felhasználók személyes adatait harmadik felekkel",
        text2: "Az alábbi körülmények között ez az alkalmazás részben vagy egészben nyilvánosságra hozza a felhasználó személyes adatait a felhasználó személyes kívánságának vagy jogi szabályozásának megfelelően:",
        text3: "1. A felhasználó előzetes beleegyezésével harmadik fél számára hozzáférhetővé válik;",
        text4: "2. A felhasználó által igényelt termékek és szolgáltatások biztosításához szükséges a felhasználó személyes adatainak megosztása harmadik féllel;",
        text5: "3. A vonatkozó törvényi rendelkezéseknek, illetve a közigazgatási vagy igazságügyi szervek követelményeinek megfelelően felfedni harmadik személyekkel vagy közigazgatási vagy igazságügyi szervekkel;",
        text6: "4. Ha a felhasználó megsérti a vonatkozó kínai törvényeket és előírásokat, vagy a jelen alkalmazásszolgáltatási szerződést vagy a kapcsolódó szabályokat, azt közölni kell egy harmadik féllel;",
        text7: "5. Ha a felhasználó szellemi tulajdonjoggal kapcsolatos minősített panaszos, és panaszt nyújtott be, azt az alperes kérésére közölnie kell az alperessel, hogy mindkét fél kezelni tudja az esetleges jogvitákat;",
        text8: "6. Egyéb közzétételek, amelyeket az alkalmazás a törvényekkel, rendeletekkel vagy a webhely szabályzatával összhangban megfelelőnek tart.",
        header2: "2. Információ tárolása és cseréje",
        text9: "A MeChat által a felhasználókról gyűjtött információkat és adatokat a MeChat és (vagy) kapcsolt vállalatai szerverein tároljuk. Csak az elküldött és fogadott információk kerülnek fel a szerverre a Velem folytatott csevegés során, amelyeket az AI-modell válaszához használunk fel.",
        header3: "3. Szellemi tulajdonhoz kapcsolódó",
        text10: "Nem vállalunk garanciát a felhasználók által feltöltött vagy közzétett tartalom jogszerűségéért, legitimitásáért, sértetlenségéért vagy minőségéért. A felhasználók viselik a szoftver által továbbított tartalom vagy erőforrások felhasználásából vagy azokra való támaszkodásból eredő kockázatokat. Mindenesetre nem vállal felelősséget az ilyen kockázatból eredő esetleges vagy tényleges veszteségekért vagy károkért.",
        header4: "4. Jogi felelősség és felelősség kizárása",
        text11: "Semmilyen esetben sem mi, sem partnereink nem vállalunk felelősséget semmilyen közvetlen, közvetett, véletlen, speciális és utólagos kárért és kockázatért, amely abból ered, hogy a felhasználók a Me Chat-et használják, vagy nem tudják használni. Ha a szolgáltatás normál működését vis maior események, például műszaki meghibásodások befolyásolják, mi és partnereink megígérjük, hogy a lehető legrövidebb időn belül együttműködünk az érintett egységekkel azok időben történő kezelése és javítása érdekében.",
        text12: "Az AI-modell által küldött információkat a számítógép generálja, és előfordulhat, hogy fiktív. A felhasználókat arra kérik, hogy tegyenek különbséget a jó és a rossz között. Amikor a felhasználók ezt az alkalmazást orvosi, jogi és egyéb kapcsolódó kérdések megvitatására használják, ez az alkalmazás csak orvosi tanácsot és jogi tanácsot ad, és nem képvisel orvosi szolgáltatásokat, jogi szolgáltatásokat vagy kezelést. A felhasználóknak konzultálniuk kell orvosokkal és az érintett jogi személyekkel az alkalmazás használata előtt, és végső soron felelősek az orvosi és jogi döntésekért. Az alkalmazásban található információk és szolgáltatások csak tájékoztató jellegűek, és nem minősülnek jogi szolgáltatásoknak vagy orvosi megoldásoknak az orvosi szolgáltatásokhoz, és nem vállalunk semmilyen felelősséget az alkalmazás használatából eredően.",
    },
    originalMail: "Az eredeti üzenet",
    emailRecipient: "befogadó",
    emailFrom: "feladó",
    CcTo: "CC",
};
