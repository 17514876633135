export default {
    langs: {
        "zh-CN": "صينى",
        en: "إنجليزي",
        fr: "فرنسي",
        es: "الأسبانية",
        "ru-RU": "الروسية",
        hi: "هندي",
        ur: "الأردية",
        ja: "اليابانية",
        ko: "الكورية",
        th: "التايلاندية",
        de: "ألمانية",
        it: "الايطالية",
    },
    responFail: "فشل في التحميل",
    responRetry: "انقر فوق إعادة المحاولة",
    responEmpty: "لايوجد بيانات",
    resendMsg: "فشل إرسال الرسالة ، انقر للمحاولة مرة أخرى",
    sendImage: "إرسال الصور",
    send: "يرسل",
    //fastSend: "اضغط على {مفتاح} للإرسال بسرعة",
    //sendMsg: "ارسل رسالة",
    //clickDownload: "اضغط للتحميل",
    timeAgo: {
        0: "فقط",
        1: "قبل {n} دقيقة",
        2: "قبل {n} ساعة",
        3: "أمس",
    },
    copyGroup: "انسخ الأعضاء إلى مجموعة مناقشة جديدة",
    quitGroup: "اترك مجموعة المناقشة",
    createGroup: "إنشاء مجموعة مناقشة",
    inviteFriend: "ادعو أصدقاء",
    // web site
    login: "تسجيل الدخول",
    loginerror: "فشل تسجيل الدخول!",
    register: "يسجل",
    keepOn: "ابق متصلا",
    forgetPwd: "نسيت كلمة السر؟",
    logoutTip: "موافق لتسجيل الخروج",
    resetPwd: "إعادة تعيين كلمة مرور تسجيل الدخول",
    all: "الجميع",
    // 注册
    phoneErr: "لا يمكن أن يكون رقم الهاتف فارغًا",
    phoneformatErr: "تنسيق رقم الهاتف غير صحيح",
    needNumber: "الرجاء إدخال قيمة عددية",
    registerErr: "فشل في التسجيل",
    // publish
    publish: "يطلق",
    group: "مجموعة",
    school: "مدرسة",
    profess: "رئيسي",
    degree: "بكالوريوس العلوم",
    time: "وقت",
    desc: "يصف",
    mustSchool: "لا يمكن أن تكون المدرسة فارغة",
    mustCompany: "لا يمكن أن تكون الشركة فارغة",
    company: "مَشرُوع",
    workexp: "خبرة في العمل",
    education: "الخبرة التعليمية",
    companyName: "شركة",
    job: "موضع",
    name: "اسم",
    address: "عنوان",
    called: "اسم",
    title: "عنوان",
    price: "سعر",
    tags: "ملصق",
    emoji: "تعبير",
    sendFile: "إرسال ملف",
    transferAccount: "تحويل",
    image: "صورة",
    video: "فيديو",
    voice: "صوت",
    file: "وثيقة",
    permissions: "أذونات",
    groupRoule: {
        "0": "مجموعة عامة: جميع المستخدمين أحرار في الانضمام",
        "1": "مجموعة الأعضاء: بعد أن يقدم المستخدم الطلب ، يمكنه الانضمام بعد الموافقة عليه من قبل مسؤول المجموعة",
        "2": "المجموعات الخاصة: غير مرئي في بحث المجموعة ، ويمكن للمجموعة فقط إضافة أعضاء",
    },
    man: "ذكر",
    woman: "أنثى",
    mustBeTM: "العنوان / الاسم مطلوب",
    mustBeTag: "املأ علامة واحدة على الأقل",
    mustBeCate: "اختر فئة واحدة على الأقل",
    contentTip: "املأ التفاصيل هنا ...",
    uploadSuccess: "تم الرفع بنجاح!",
    publishError: "فشل النشر!",
    // footer
    contactus: "اتصل بنا",
    privacy: "سياسة الخصوصية",
    companyright: "كل الحقوق محفوظة",
    // 其他
    sys_error: "خطأ في النظام!",
    replayEmpty: "لا يمكن أن يكون محتوى التعليق فارغًا!",
    replay: "رد",
    replayErr: "فشل التعليق",
    delSuccess: "تم الحذف بنجاح",
    defFail: "فشل في الحذف",
    refresh: "تحديث الانتهاء",
    updateSuccess: "التعديل ناجح ، يرجى تسجيل الدخول مرة أخرى",
    old: "قديم",
    theNew: "جديد",
    changeFail: "فشل تعديل كلمة المرور",
    pwdlength: "طول كلمة المرور",
    getverchatCode: "احصل على رمز التحقق",
    nopeoplezan: "لا أحد يحبها حتى الآن ، كن أول من يعجبها!",
    pwdResetSuccess: "اكتملت إعادة تعيين كلمة المرور",
    email_required: "لا يمكن أن يكون البريد الإلكتروني فارغًا",
    phone_required: "لا يمكن أن يكون رقم الهاتف المحمول فارغًا",
    addfriend: "تم إرسال طلب إضافة الأصدقاء بنجاح ، بانتظار موافقة الطرف الآخر",
    Draft: "مسودة",
    breakPwd: "استرجاع كلمة المرور",
    "Request to view history messages": "تطبيق لعرض الأخبار التاريخية",
    "Wait for group manager to agree": "انتظر حتى يوافق مالك المجموعة",
    'Please enter a search term': "أدخل كلمات البحث",
    done: "بالتأكيد",
    cancle: "يلغي",
    groupType: {
        '1': {
            name: "مجموعة عامة",
            code: "1",
            desc: "&quot;مجموعة مفتوحة: يمكن لأي مستخدم إنشاء مجموعة مجانية للانضمام دون مراجعة. يصف اسم المجموعة الأعضاء ويمكن البحث عنها وتصفحها على النظام الأساسي. &quot;",
        },
        '2': {
            name: "مجموعة الأعضاء",
            code: "2",
            desc: "مجموعة الأعضاء: يمكن لأي مستخدم إنشاء مجموعة يمكنها الانضمام بحرية وتحتاج إلى مراجعتها من قبل مالك المجموعة. يصف اسم المجموعة الأعضاء ويمكن البحث عنها وتصفحها على النظام الأساسي. &quot;",
        },
        '3': {
            name: "مجموعة المناقشة",
            code: "3",
            desc: "مجموعة المناقشة: يمكن لأي مستخدم إنشاء منشئي محتوى ويمكن للأعضاء دعوة أصدقائهم للانضمام إلى اسم المجموعة ويمكن البحث عن أعضاء وتصفحهم على النظام الأساسي. &quot;",
        },
        '4': {
            name: "مجموعة خاصة",
            code: "4",
            desc: "مجموعة خاصة: يمكن لأي مستخدم إنشاء اسم مجموعة لوصف أعضاء المجموعة ولا يمكن البحث عنه وتصفحه على النظام الأساسي. &quot;",
        }
    },
    newPwd: "الرجاء إدخال كلمة مرور جديدة لتسجيل الدخول",
    repNewPwd: "الرجاء إعادة إدخال كلمة المرور الجديدة",
    // 2021年3月4号
    meetingTips: "يرجى تنزيل تطبيق melinked أولاً أو ترقية التطبيق إلى أحدث إصدار",
    // mechat协议
    mechat: {
        title: "اتفاقية مستخدم Me Chat",
        text1: "Mechat هو منتج أسئلة وأجوبة مصاحب للدردشة بالذكاء الاصطناعي مقدم من شركة Maes Consulting. عندما يوافق المستخدم على اتفاقية المستخدم هذه ، يُعتبر أنه وافق على المحتوى الكامل لاتفاقية المستخدم هذه.",
        header1: "واحد. كيف نشارك ونفصح عن معلومات المستخدمين الشخصية مع أطراف ثالثة",
        text2: "في ظل الظروف التالية ، سيكشف هذا التطبيق عن المعلومات الشخصية للمستخدم كليًا أو جزئيًا وفقًا لرغبات المستخدم الشخصية أو اللوائح القانونية:",
        text3: "1. تم الكشف عنها لأطراف ثالثة بموافقة مسبقة من المستخدم ؛",
        text4: "2. من أجل توفير المنتجات والخدمات التي يطلبها المستخدم ، من الضروري مشاركة المعلومات الشخصية للمستخدم مع طرف ثالث ؛",
        text5: "3. الإفصاح للغير أو للجهات الإدارية أو القضائية ، وفقاً لأحكام القانون ذات الصلة أو متطلبات الجهات الإدارية أو القضائية.",
        text6: "4. إذا كان المستخدم ينتهك القوانين واللوائح الصينية ذات الصلة أو اتفاقية خدمة التطبيق هذه أو القواعد ذات الصلة ، فيجب الكشف عنها لطرف ثالث ؛",
        text7: "5. إذا كان المستخدم مقدم شكوى مؤهلة للملكية الفكرية وقدم شكوى ، فيجب عليه الإفصاح عنها للمدعى عليه بناءً على طلب المدعى عليه بحيث يمكن للطرفين التعامل مع نزاعات الحقوق المحتملة ؛",
        text8: "6. الإفصاحات الأخرى التي يراها هذا التطبيق مناسبة وفقًا للقوانين أو اللوائح أو سياسات موقع الويب.",
        header2: "2. تخزين المعلومات وتبادلها",
        text9: "سيتم تخزين المعلومات والبيانات حول المستخدمين التي تم جمعها بواسطة MeChat على خوادم MeChat و (أو) الشركات التابعة لها. سيتم فقط تحميل المعلومات المرسلة والمستلمة على الخادم في عملية الدردشة معي ، والتي سيتم استخدامها للرد على نموذج AI.",
        header3: "3. الملكية الفكرية ذات الصلة",
        text10: "نحن لا نقدم أي ضمانات لقانونية أو شرعية أو نزاهة أو جودة المحتوى الذي تم تحميله أو نشره من قبل المستخدمين. يتحمل المستخدمون المخاطر الناشئة عن استخدام أو الاعتماد على المحتوى أو الموارد المنقولة بواسطة البرنامج. في أي حال ، يجب علينا لن تكون مسؤولة عن أي خسارة أو ضرر محتمل أو فعلي ينشأ عن مثل هذه المخاطر.",
        header4: "4. المسؤولية القانونية وإخلاء المسؤولية",
        text11: "في أي حال ، لن نتحمل نحن وشركاؤنا المسؤولية عن أي أضرار ومخاطر مباشرة أو غير مباشرة أو عرضية أو خاصة أو لاحقة تنشأ عن استخدام أو عدم قدرة المستخدمين على استخدام Me Chat. إذا تأثر التشغيل العادي للخدمة بأحداث القوة القاهرة مثل الأعطال الفنية ، فإننا نعد وشركاؤنا بالتعاون مع الوحدات ذات الصلة في أقرب وقت ممكن للتعامل معها وإصلاحها في الوقت المناسب.",
        text12: "يتم إنشاء المعلومات التي يرسلها نموذج الذكاء الاصطناعي بواسطة الكمبيوتر وقد تكون وهمية ، ويطلب من المستخدمين التمييز بين الصواب والخطأ. عندما يستخدم المستخدمون هذا التطبيق للتشاور مع القضايا الطبية والقانونية وغيرها من القضايا ذات الصلة ، فإن هذا التطبيق يقدم فقط المشورة الطبية والمشورة القانونية ، ولا يمثل الخدمات الطبية أو الخدمات القانونية أو العلاج. يجب على المستخدمين استشارة الأطباء والأشخاص الاعتباريين المعنيين قبل استخدام هذا التطبيق ، وهم مسؤولون في النهاية عن القرارات الطبية والقانونية. المعلومات والخدمات الموجودة في هذا التطبيق هي للإشارة فقط ، ولا تشكل خدمات قانونية أو حلولًا طبية للخدمات الطبية ، ولا نتحمل أي مسؤولية تنشأ عن استخدام هذا التطبيق.",
    },
    originalMail: "الرسالة الأصلية",
    emailRecipient: "متلقي",
    emailFrom: "مرسل",
    CcTo: "نسخة",
};
