var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.src
    ? _c("van-image", {
        style: _vm.style,
        attrs: { fit: "cover", src: _vm.formartImageUrl(_vm.src), round: "" },
        on: { click: _vm.handlerToUser },
        scopedSlots: _vm._u(
          [
            {
              key: "loading",
              fn: function() {
                return [
                  _c("van-loading", { attrs: { type: "spinner", size: "20" } })
                ]
              },
              proxy: true
            },
            {
              key: "error",
              fn: function() {
                return [
                  _c("i", {
                    class: _vm.isGroup ? "me-icon-group" : "me-icon-user",
                    style: _vm.errorSize
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          189492281
        )
      })
    : _c(
        "div",
        {
          staticClass: "no-thumb",
          style: _vm.style,
          on: { click: _vm.handlerToUser }
        },
        [
          _c("i", {
            class: _vm.isGroup ? "me-icon-group" : "me-icon-user",
            style: _vm.errorSize
          })
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }