export default {
    "langs": {
        "zh-CN": "จีน",
        "en": "อังกฤษ",
        "fr": "ฝรั่งเศส",
        "es": "สเปน",
        "ru-RU": "รัสเซีย",
        "hi": "ภาษาฮินดี",
        "ur": "ภาษาอูรดู",
        "ja": "ญี่ปุ่น",
        "ko": "เกาหลี",
        "th": "ไทย",
        "de": "เยอรมัน",
        "it": "ภาษาอิตาเลี่ยน"
    },
    "responFail": "การโหลดล้มเหลว",
    "responRetry": "คลิกลองอีกครั้ง",
    "responEmpty": "ยังไม่มีข้อมูล",
    "resendMsg": "ข้อความล้มเหลวในการส่งคลิกเพื่อลองอีกครั้ง",
    "sendImage": "ส่งรูปภาพ",
    "send": "ส่ง",
    "timeAgo": {
        "0": "เพียงแค่",
        "1": "{n} นาทีก่อน",
        "2": "{n} ชั่วโมงก่อน",
        "3": "เมื่อวาน"
    },
    "copyGroup": "คัดลอกสมาชิกไปยังกลุ่มสนทนาใหม่",
    "quitGroup": "ออกจากกลุ่มสนทนา",
    "createGroup": "สร้างกลุ่มสนทนา",
    "inviteFriend": "เชิญเพื่อน ๆ",
    "login": "เข้าสู่ระบบ",
    "loginerror": "การเข้าสู่ระบบล้มเหลว!",
    "register": "การลงทะเบียน",
    "keepOn": "เข้าสู่ระบบต่อไป",
    "forgetPwd": "ลืมรหัสผ่าน?",
    "logoutTip": "ตกลงเพื่อออกจากระบบ",
    "resetPwd": "รีเซ็ตรหัสผ่านการเข้าสู่ระบบ",
    "all": "สมบูรณ์",
    "phoneErr": "หมายเลขโทรศัพท์ต้องไม่ว่างเปล่า",
    "phoneformatErr": "รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง",
    "needNumber": "โปรดป้อนค่าตัวเลข",
    "registerErr": "การลงทะเบียนล้มเหลว",
    "publish": "ปล่อย",
    "group": "กลุ่ม",
    "school": "โรงเรียน",
    "profess": "ชนิดพิเศษ",
    "degree": "ปริญญา",
    "time": "เวลา",
    "mustSchool": "โรงเรียนต้องไม่ว่างเปล่า",
    "mustCompany": "บริษัท ต้องไม่ว่างเปล่า",
    "company": "ธุรกิจ",
    "workexp": "ประสบการณ์การทำงาน",
    "education": "ประสบการณ์การศึกษา",
    "companyName": "บริษัท",
    "job": "สำนักงาน",
    "name": "ชื่อเต็ม",
    "address": "ที่อยู่",
    "called": "ชื่อ",
    "title": "พาดหัว",
    "price": "ราคา",
    "tags": "แท็ก",
    "emoji": "การแสดงออก",
    "sendFile": "ส่งไฟล์",
    "transferAccount": "โอน",
    "image": "ภาพ",
    "video": "วีดีโอ",
    "voice": "เสียงพูด",
    "file": "ไฟล์",
    "groupRoule": {
        "0": "เปิดกลุ่ม: ผู้ใช้ทุกคนสามารถเข้าร่วมได้ฟรี",
        "1": "การเป็นสมาชิก: หลังจากที่ผู้ใช้ส่งใบสมัครพวกเขาสามารถเข้าร่วมโดยผู้ดูแลกลุ่ม",
        "2": "กลุ่มส่วนตัว: ไม่ปรากฏในการค้นหากลุ่มและมีเพียงกลุ่มเท่านั้นที่สามารถเพิ่มสมาชิกได้"
    },
    "man": "ชาย",
    "woman": "หญิง",
    "mustBeTM": "ต้องระบุชื่อ / ชื่อ",
    "mustBeTag": "กรอกอย่างน้อยหนึ่งป้ายกำกับ",
    "mustBeCate": "เลือกอย่างน้อยหนึ่งหมวดหมู่",
    "contentTip": "กรอกรายละเอียดที่นี่ ...",
    "uploadSuccess": "อัปโหลดสำเร็จแล้ว!",
    "publishError": "การเผยแพร่ล้มเหลว!",
    "contactus": "ติดต่อเรา",
    "privacy": "นโยบายความเป็นส่วนตัว",
    "companyright": "ลิขสิทธิ์",
    "sys_error": "ระบบผิดพลาด!",
    "replayEmpty": "ความคิดเห็นต้องไม่ว่างเปล่า!",
    "replay": "ตอบ",
    "replayErr": "ความคิดเห็นล้มเหลว",
    "delSuccess": "ลบสำเร็จแล้ว",
    "defFail": "การลบล้มเหลว",
    "refresh": "อัปเดตเรียบร้อยแล้ว",
    "updateSuccess": "การแก้ไขสำเร็จแล้วโปรดลงชื่อเข้าใช้อีกครั้ง",
    "old": "เก่า",
    "theNew": "ใหม่",
    "changeFail": "การแก้ไขรหัสผ่านล้มเหลว",
    "pwdlength": "ความยาวรหัสผ่าน",
    "getverchatCode": "รับรหัสยืนยัน",
    "nopeoplezan": "ยังไม่มีใครชอบเลยจงเป็นคนแรกที่ได้ชม!",
    "pwdResetSuccess": "รหัสผ่านถูกแก้ไขสำเร็จแล้ว",
    "email_required": "กล่องจดหมายต้องไม่ว่างเปล่า",
    "phone_required": "หมายเลขโทรศัพท์ต้องไม่ว่างเปล่า",
    done: 'ตกลง',
    cancle: 'ยกเลิก',
    groupType: {
        '1': {
            name: 'กลุ่มสาธารณะ',
            code: '1',
            desc: `กลุ่มสาธารณะ: ผู้ใช้สามารถสร้างและเข้าร่วมกลุ่มได้อย่างอิสระโดยไม่ต้องตรวจสอบชื่อกลุ่มคำอธิบายสมาชิกสามารถค้นหาและเรียกดูได้บนแพลตฟอร์ม`
        },

        '2': {
            name: 'กลุ่มสมาชิก',
            code: '2',
            desc: `กลุ่มสมาชิก: ผู้ใช้สามารถสร้างและเข้าร่วมกลุ่มได้อย่างอิสระซึ่งต้องการให้เจ้าของทำการตรวจสอบชื่อกลุ่มคำอธิบายสมาชิกสามารถค้นหาและเรียกดูได้บนแพลตฟอร์ม`
        },
        '3': {
            name: 'กลุ่มสนทนา',
            code: '3',
            desc: `กลุ่มสนทนา: ผู้ใช้สามารถสร้างมันได้ผู้สร้างและสมาชิกสามารถเชิญเพื่อนเข้าร่วมชื่อกลุ่มสมาชิกสามารถค้นหาและเรียกดูได้บนแพลตฟอร์ม`
        },
        '4': {
            name: 'กลุ่มส่วนตัว',
            code: '4',
            desc: `กลุ่มส่วนตัว: ผู้ใช้สามารถสร้างชื่อกลุ่มคำอธิบายสมาชิกกลุ่มไม่สามารถค้นหาและเรียกดูได้บนแพลตฟอร์ม`
        }
    },
    newPwd: 'โปรดป้อนรหัสผ่านการเข้าสู่ระบบใหม่',
    repNewPwd: "กรุณาทำซ้ำรหัสผ่านใหม่",
    breakPwd: 'ดึงรหัสผ่าน',
    // 2021年3月4号
    meetingTips: "โปรดดาวน์โหลดแอป Melinked ก่อนหรืออัปเกรดแอป Melinked เป็นเวอร์ชันล่าสุด",
    // mechat协议
    mechat: {
      title: "ข้อตกลงผู้ใช้ Me Chat",
      text1: "Mechat เป็นผลิตภัณฑ์ Q&amp;A คู่หูในการแชทด้วย AI ที่จัดทำโดย Maes Consulting เมื่อผู้ใช้ยอมรับข้อตกลงผู้ใช้นี้จะถือว่าได้ยอมรับเนื้อหาทั้งหมดของข้อตกลงผู้ใช้นี้",
      header1: "หนึ่ง. วิธีที่เราแบ่งปันและเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้กับบุคคลที่สาม",
      text2: "ภายใต้สถานการณ์ต่อไปนี้ แอปพลิเคชันนี้จะเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้ทั้งหมดหรือบางส่วนตามความประสงค์ส่วนตัวของผู้ใช้หรือข้อบังคับทางกฎหมาย:",
      text3: "1. เปิดเผยต่อบุคคลที่สามโดยได้รับความยินยอมล่วงหน้าจากผู้ใช้",
      text4: "2. เพื่อจัดหาผลิตภัณฑ์และบริการที่ผู้ใช้ร้องขอ จำเป็นต้องแบ่งปันข้อมูลส่วนบุคคลของผู้ใช้กับบุคคลที่สาม",
      text5: "3. ตามบทบัญญัติที่เกี่ยวข้องของกฎหมายหรือข้อกำหนดของหน่วยงานด้านการบริหารหรือด้านตุลาการ ให้เปิดเผยต่อบุคคลที่สามหรือหน่วยงานด้านการบริหารหรือด้านตุลาการ",
      text6: "4. หากผู้ใช้ละเมิดกฎหมายและข้อบังคับของจีนที่เกี่ยวข้องหรือข้อตกลงบริการแอปพลิเคชันนี้หรือกฎที่เกี่ยวข้อง จะต้องเปิดเผยให้บุคคลที่สามทราบ",
      text7: "5. หากผู้ใช้เป็นผู้ร้องเรียนทรัพย์สินทางปัญญาที่มีคุณสมบัติเหมาะสมและได้ยื่นเรื่องร้องเรียน ผู้ใช้จะต้องเปิดเผยข้อมูลดังกล่าวต่อผู้ถูกร้องตามคำร้องขอของผู้ถูกร้อง เพื่อให้ทั้งสองฝ่ายสามารถจัดการกับข้อพิพาทด้านสิทธิ์ที่อาจเกิดขึ้นได้",
      text8: "6. การเปิดเผยอื่น ๆ ที่แอปพลิเคชันนี้เห็นสมควรตามกฎหมาย ข้อบังคับ หรือนโยบายของเว็บไซต์",
      header2: "2. การจัดเก็บและแลกเปลี่ยนข้อมูล",
      text9: "ข้อมูลและข้อมูลเกี่ยวกับผู้ใช้ที่รวบรวมโดย MeChat จะถูกจัดเก็บไว้ในเซิร์ฟเวอร์ของ MeChat และ (หรือ) บริษัทในเครือ เฉพาะข้อมูลที่ส่งและรับเท่านั้นที่จะถูกอัปโหลดไปยังเซิร์ฟเวอร์ในกระบวนการสนทนากับฉัน ซึ่งจะใช้ในการตอบกลับของโมเดล AI",
      header3: "3. ทรัพย์สินทางปัญญาที่เกี่ยวข้อง",
      text10: "เราไม่รับประกันใด ๆ สำหรับความถูกต้องตามกฎหมาย ความถูกต้อง ความสมบูรณ์ หรือคุณภาพของเนื้อหาที่อัปโหลดหรือเผยแพร่โดยผู้ใช้ ผู้ใช้จะต้องแบกรับความเสี่ยงที่เกิดจากการใช้หรือพึ่งพาเนื้อหาหรือทรัพยากรที่ส่งโดยซอฟต์แวร์ ในกรณีใด ๆ เราจะ ไม่จะไม่รับผิดชอบต่อความสูญเสียหรือความเสียหายที่อาจเกิดขึ้นหรือเกิดขึ้นจริงจากความเสี่ยงดังกล่าว",
      header4: "4. ความรับผิดทางกฎหมายและการปฏิเสธความรับผิด",
      text11: "ไม่ว่าในกรณีใดก็ตาม เราและพันธมิตรของเราจะไม่รับผิดชอบต่อความเสียหายทั้งทางตรง ทางอ้อม โดยบังเอิญ พิเศษและที่ตามมาและความเสี่ยงใดๆ ที่เกิดขึ้นจากการใช้หรือการไม่สามารถใช้ Me Chat ของผู้ใช้ หากการทำงานปกติของบริการได้รับผลกระทบจากเหตุสุดวิสัย เช่น ความล้มเหลวทางเทคนิค เราและพันธมิตรของเราสัญญาว่าจะร่วมมือกับหน่วยงานที่เกี่ยวข้องโดยเร็วที่สุดเพื่อจัดการและซ่อมแซมในเวลาที่เหมาะสม ไม่รับผิดชอบ",
      text12: "ข้อมูลที่ส่งโดยโมเดล AI นั้นสร้างขึ้นโดยคอมพิวเตอร์และอาจเป็นข้อมูลสมมติ ผู้ใช้จะถูกขอให้แยกแยะว่าถูกจากผิด เมื่อผู้ใช้ใช้แอปพลิเคชันนี้เพื่อปรึกษาปัญหาทางการแพทย์ กฎหมาย และอื่นๆ ที่เกี่ยวข้อง แอปพลิเคชันนี้จะให้คำแนะนำทางการแพทย์และคำแนะนำทางกฎหมายเท่านั้น และไม่ได้แสดงถึงบริการทางการแพทย์ บริการทางกฎหมาย หรือการรักษา ผู้ใช้ต้องปรึกษาแพทย์และบุคคลทางกฎหมายที่เกี่ยวข้องก่อนที่จะใช้แอปพลิเคชันนี้ และสุดท้ายแล้วจะต้องรับผิดชอบต่อการตัดสินใจทางการแพทย์และทางกฎหมาย ข้อมูลและบริการในแอพนี้มีไว้สำหรับอ้างอิงเท่านั้น และไม่ถือเป็นบริการทางกฎหมายหรือโซลูชั่นทางการแพทย์สำหรับบริการทางการแพทย์ และเราไม่รับผิดชอบใด ๆ ที่เกิดจากการใช้แอพนี้",
    },
    originalMail: "ข้อความต้นฉบับ",
    emailRecipient: "ผู้รับ",
    emailFrom: "ผู้ส่ง",
    CcTo: "ซีซี",
}