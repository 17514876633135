import Vue from "vue";
import "./plugins";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "./directives";
import "@/styles/index.styl";
import Http from '@/utils/http'
import i18n from "./i18n/index";
import SocialSharing from "vue-social-sharing";
import { Toast } from 'vant';
import {
  listener,
  send
} from "@/utils/server";
import AddThis from 'vue-simple-addthis-share'
import Vue2TouchEvents from "vue2-touch-events";
import Bridge from './JSbridge.js';

Vue.use(Vue2TouchEvents);
Vue.use(Toast);

// import vueTap from "vue-js-tap";
// import vueTouch from 'kim-vue-touch'
// Vue.use(vueTouch)
// Vue.use(vueTap);
Vue.component('add-this', AddThis)
Vue.use(SocialSharing);
// import Share from "vue-social-share";
// import "vue-social-share/dist/client.css";
// import { listener } from './utils/server';
// Vue.use(Share);
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard)

Vue.config.productionTip = false;
Vue.prototype.$http = Http
Vue.prototype.$listener = listener
Vue.prototype.$send = send
Vue.prototype.$bridge = Bridge


Vue.prototype.back = function () {
  if (window.history.length > 1) {
    this.$router.go(-1)
  } else {
    this.$router.push({name:'home'})
  }
}
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");