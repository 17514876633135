export default {
    langs: {
        "zh-CN": "चिनियाँ",
        en: "अंग्रेजी",
        fr: "फ्रान्सेली",
        es: "स्पेनिश",
        "ru-RU": "रुसी",
        hi: "हिन्दी",
        ur: "उर्दू",
        ja: "जापानी",
        ko: "कोरियाली",
        th: "थाई",
        de: "जर्मन",
        it: "इटालियन",
    },
    responFail: "लोड गर्न असफल भयो",
    responRetry: "पुन: प्रयास क्लिक गर्नुहोस्",
    responEmpty: "डाटा छैन",
    resendMsg: "सन्देश पठाउन असफल भयो, फेरि प्रयास गर्न क्लिक गर्नुहोस्",
    sendImage: "चित्रहरू पठाउनुहोस्",
    send: "पठाउनुहोस्",
    //fastSend: "छिटो पठाउन {key} थिच्नुहोस्",
    //sendMsg: "सन्देश पठाउनुहोस्",
    //clickDownload: "डाउनलोड गर्न क्लिक गर्नुहोस्",
    timeAgo: {
        0: "मात्र",
        1: "{n} मिनेट अघि",
        2: "{n} घण्टा अघि",
        3: "हिजो",
    },
    copyGroup: "नयाँ छलफल समूहमा सदस्यहरूलाई प्रतिलिपि गर्नुहोस्",
    quitGroup: "छलफल समूह छोड्नुहोस्",
    createGroup: "छलफल समूह सिर्जना गर्नुहोस्",
    inviteFriend: "साथीहरुलाई निम्तो गर",
    // web site
    login: "लग - इन",
    loginerror: "लगइन असफल भयो!",
    register: "दर्ता गर्नुहोस्",
    keepOn: "लगइन रहनुहोस्",
    forgetPwd: "पासवर्ड बिर्सनुभयो?",
    logoutTip: "लग आउट गर्न ठीक छ",
    resetPwd: "लगइन पासवर्ड रिसेट गर्नुहोस्",
    all: "सबै",
    // 注册
    phoneErr: "फोन नम्बर खाली हुन सक्दैन",
    phoneformatErr: "फोन नम्बर ढाँचा गलत छ",
    needNumber: "कृपया संख्यात्मक मान प्रविष्ट गर्नुहोस्",
    registerErr: "दर्ता असफल भयो",
    // publish
    publish: "रिलीज",
    group: "समूह",
    school: "विद्यालय",
    profess: "प्रमुख",
    degree: "विज्ञानमा स्नातक",
    time: "समय",
    desc: "वर्णन गर्नुहोस्",
    mustSchool: "विद्यालय खाली हुन सक्दैन",
    mustCompany: "कम्पनी खाली हुन सक्दैन",
    company: "उद्यम",
    workexp: "कार्य अनुभव",
    education: "शैक्षिक अनुभव",
    companyName: "कम्पनी",
    job: "स्थिति",
    name: "नाम",
    address: "ठेगाना",
    called: "नाम",
    title: "शीर्षक",
    price: "मूल्य",
    tags: "लेबल",
    emoji: "अभिव्यक्ति",
    sendFile: "फाइल पठाउनुहोस्",
    transferAccount: "स्थानान्तरण",
    image: "चित्र",
    video: "भिडियो",
    voice: "आवाज",
    file: "कागजात",
    permissions: "अनुमतिहरू",
    groupRoule: {
        "0": "सार्वजनिक समूह: सबै प्रयोगकर्ताहरू सामेल हुन स्वतन्त्र छन्",
        "1": "सदस्य समूह: प्रयोगकर्ताले आवेदन पेश गरेपछि, उसले समूह प्रशासकबाट अनुमोदन गरेपछि सामेल हुन सक्छ",
        "2": "निजी समूहहरू: समूह खोजमा देखिने छैन, र केवल समूहले सदस्यहरू थप्न सक्छ",
    },
    man: "पुरुष",
    woman: "महिला",
    mustBeTM: "शीर्षक/नाम आवश्यक छ",
    mustBeTag: "कम्तिमा एउटा ट्याग भर्नुहोस्",
    mustBeCate: "कम्तिमा एउटा श्रेणी छान्नुहोस्",
    contentTip: "विवरणहरू यहाँ भर्नुहोस्...",
    uploadSuccess: "सफलतापूर्वक अपलोड गरियो!",
    publishError: "प्रकाशित गर्न सकिएन!",
    // footer
    contactus: "हामीलाई सम्पर्क गर्नुहोस",
    privacy: "गोपनीयता नीति",
    companyright: "सबै अधिकार सुरक्षित",
    // 其他
    sys_error: "प्रणाली त्रुटि!",
    replayEmpty: "टिप्पणी सामग्री खाली हुन सक्दैन!",
    replay: "जवाफ",
    replayErr: "टिप्पणी असफल भयो",
    delSuccess: "सफलतापूर्वक मेटाइयो",
    defFail: "मेटाउन असफल भयो",
    refresh: "अपडेट पूरा भयो",
    updateSuccess: "परिमार्जन सफल भयो, कृपया फेरि लग इन गर्नुहोस्",
    old: "पुरानो",
    theNew: "नयाँ",
    changeFail: "पासवर्ड परिमार्जन असफल भयो",
    pwdlength: "पासवर्ड लम्बाइ",
    getverchatCode: "प्रमाणीकरण कोड प्राप्त गर्नुहोस्",
    nopeoplezan: "अहिलेसम्म कसैले मन पराउँदैन, यसलाई मन पराउने पहिलो बन्नुहोस्!",
    pwdResetSuccess: "पासवर्ड रिसेट पूरा भयो",
    email_required: "इ-मेल खाली हुन सक्दैन",
    phone_required: "मोबाइल फोन नम्बर खाली हुन सक्दैन",
    addfriend: "साथीहरू थप्नको लागि आवेदन सफलतापूर्वक पठाइएको छ, अर्को पक्ष सहमत हुन पर्खँदै",
    Draft: "मस्यौदा",
    breakPwd: "पासवर्ड पुन: प्राप्त",
    "Request to view history messages": "ऐतिहासिक समाचार हेर्न आवेदन दिनुहोस्",
    "Wait for group manager to agree": "समूह मालिक सहमत हुनको लागि पर्खनुहोस्",
    'Please enter a search term': "खोज किवर्डहरू प्रविष्ट गर्नुहोस्",
    done: "निश्चित",
    cancle: "रद्द गर्नुहोस्",
    groupType: {
        '1': {
            name: "सार्वजनिक समूह",
            code: "१",
            desc: "`खुला समूह: कुनै पनि प्रयोगकर्ताले समुह सिर्जना गर्न सक्छ जुन बिना समीक्षा सामेल हुन स्वतन्त्र छ। समूहको नामले सदस्यहरूको वर्णन गर्दछ र प्लेटफर्ममा खोजी र ब्राउज गर्न सकिन्छ। `",
        },
        '2': {
            name: "सदस्य समूह",
            code: "२",
            desc: "`सदस्य समूह: कुनै पनि प्रयोगकर्ताले स्वतन्त्र रूपमा सामेल हुन सक्ने समूह सिर्जना गर्न सक्छ र समूह मालिकद्वारा समीक्षा गर्न आवश्यक छ। समूहको नामले सदस्यहरूको वर्णन गर्दछ र प्लेटफर्ममा खोजी र ब्राउज गर्न सकिन्छ। `",
        },
        '3': {
            name: "छलफल समूह",
            code: "३",
            desc: "`चर्चा समूह: कुनै पनि प्रयोगकर्ताले सिर्जनाकर्ताहरू सिर्जना गर्न सक्छन् र सदस्यहरूले आफ्ना साथीहरूलाई समूहमा सामेल हुन आमन्त्रित गर्न सक्छन् नामका सदस्यहरूलाई प्लेटफर्ममा खोजी र ब्राउज गर्न सकिन्छ। `",
        },
        '4': {
            name: "निजी समूह",
            code: "४",
            desc: "`निजी समूह: कुनै पनि प्रयोगकर्ताले समूहका सदस्यहरूको वर्णन गर्न समूहको नाम सिर्जना गर्न सक्छ र प्लेटफर्ममा खोजी र ब्राउज गर्न सकिँदैन। `",
        }
    },
    newPwd: "कृपया नयाँ लगइन पासवर्ड प्रविष्ट गर्नुहोस्",
    repNewPwd: "कृपया नयाँ पासवर्ड पुन: प्रविष्ट गर्नुहोस्",
    // 2021年3月4号
    meetingTips: "कृपया पहिले melinked एप डाउनलोड गर्नुहोस् वा नवीनतम संस्करणमा melinked APP अपग्रेड गर्नुहोस्",
    // mechat协议
    mechat: {
        title: "मी च्याट प्रयोगकर्ता सम्झौता",
        text1: "Mechat Maes Consulting द्वारा प्रदान गरिएको AI कुराकानी साथी प्रश्नोत्तर उत्पादन हो। जब प्रयोगकर्ता यो प्रयोगकर्ता सम्झौतामा सहमत हुन्छ, यो प्रयोगकर्ता सम्झौताको सम्पूर्ण सामग्रीमा सहमत भएको मानिन्छ।",
        header1: "एउटा। हामी कसरी तेस्रो पक्षहरूसँग प्रयोगकर्ताहरूको व्यक्तिगत जानकारी साझा र खुलासा गर्छौं",
        text2: "निम्न परिस्थितिहरूमा, यो अनुप्रयोगले प्रयोगकर्ताको व्यक्तिगत इच्छा वा कानुनी नियमहरू अनुसार सम्पूर्ण वा आंशिक रूपमा प्रयोगकर्ताको व्यक्तिगत जानकारी खुलासा गर्नेछ:",
        text3: "१. प्रयोगकर्ताको पूर्व सहमतिमा तेस्रो पक्षहरूलाई खुलासा गरिएको;",
        text4: "2. प्रयोगकर्ताद्वारा अनुरोध गरिएका उत्पादनहरू र सेवाहरू प्रदान गर्न, प्रयोगकर्ताको व्यक्तिगत जानकारी तेस्रो पक्षसँग साझेदारी गर्न आवश्यक छ;",
        text5: "3. कानूनको सान्दर्भिक प्रावधानहरू अनुसार, वा प्रशासनिक वा न्यायिक एजेन्सीहरूको आवश्यकताहरू, तेस्रो पक्ष वा प्रशासनिक वा न्यायिक एजेन्सीहरूलाई खुलासा गर्नुहोस्;",
        text6: "4. यदि प्रयोगकर्ताले सान्दर्भिक चिनियाँ कानून र नियमहरू वा यो अनुप्रयोग सेवा सम्झौता वा सम्बन्धित नियमहरू उल्लङ्घन गर्दछ भने, यो तेस्रो पक्षलाई खुलासा गर्न आवश्यक छ;",
        text7: "5. यदि प्रयोगकर्ता एक योग्य बौद्धिक सम्पत्ति उजुरीकर्ता हो र उजुरी दायर गरेको छ भने, उसले प्रतिवादीको अनुरोधमा उत्तरदातालाई खुलासा गर्नेछ ताकि दुबै पक्षहरूले सम्भावित अधिकार विवादहरू ह्यान्डल गर्न सक्छन्;",
        text8: "6. अन्य खुलासाहरू जुन यस अनुप्रयोगले कानून, नियमहरू वा वेबसाइट नीतिहरू अनुसार उपयुक्त ठानेको छ।",
        header2: "2. सूचना भण्डारण र विनिमय",
        text9: "MeChat द्वारा संकलित प्रयोगकर्ताहरू बारे जानकारी र डाटा MeChat र (वा) यसको सम्बद्ध कम्पनीहरूको सर्भरहरूमा भण्डारण गरिनेछ। पठाइएको र प्राप्त जानकारी मात्र सर्भरमा मेरो च्याट गर्ने प्रक्रियामा अपलोड गरिनेछ, जुन एआई मोडेलको जवाफको लागि प्रयोग गरिनेछ।",
        header3: "3. बौद्धिक सम्पत्ति सम्बन्धित",
        text10: "हामी प्रयोगकर्ताहरूद्वारा अपलोड वा प्रकाशित सामग्रीको वैधानिकता, वैधता, अखण्डता वा गुणस्तरको लागि कुनै ग्यारेन्टी गर्दैनौं। प्रयोगकर्ताहरूले सफ्टवेयरद्वारा प्रसारित सामग्री वा स्रोतहरू प्रयोग वा भर पर्दा उत्पन्न हुने जोखिमहरू वहन गर्नेछन्। कुनै पनि अवस्थामा, हामी यस्तो जोखिमबाट उत्पन्न हुने कुनै पनि सम्भावित वा वास्तविक हानि वा क्षतिको लागि उत्तरदायी हुने छैन।",
        header4: "4. कानूनी दायित्व र अस्वीकरण",
        text11: "कुनै पनि अवस्थामा, हामी र हाम्रा साझेदारहरू कुनै पनि प्रत्यक्ष, अप्रत्यक्ष, आकस्मिक, विशेष र त्यसपछिका क्षतिहरू र प्रयोगकर्ताहरूको मी च्याट प्रयोग गर्न असमर्थताबाट उत्पन्न हुने जोखिमहरूको लागि उत्तरदायी हुने छैनौं। यदि सेवाको सामान्य सञ्चालनमा प्राविधिक विफलताहरू जस्तै बल माजेर घटनाहरूबाट प्रभावित भएको छ भने, हामी र हाम्रा साझेदारहरूले तिनीहरूलाई समयमै समाधान गर्न र मर्मत गर्नको लागि सकेसम्म चाँडो सम्बन्धित एकाइहरूसँग सहयोग गर्ने वाचा गर्दछौं।",
        text12: "एआई मोडेलले पठाएको जानकारी कम्प्युटरद्वारा उत्पन्न हुन्छ र काल्पनिक हुन सक्छ। प्रयोगकर्ताहरूलाई सही र गलत छुट्याउन आग्रह गरिन्छ। जब प्रयोगकर्ताहरूले चिकित्सा, कानुनी र अन्य सम्बन्धित मुद्दाहरू परामर्श गर्न यो अनुप्रयोग प्रयोग गर्छन्, यो अनुप्रयोगले चिकित्सा सल्लाह र कानुनी सल्लाह मात्र प्रदान गर्दछ, र चिकित्सा सेवाहरू, कानुनी सेवाहरू वा उपचारको प्रतिनिधित्व गर्दैन। प्रयोगकर्ताहरूले यो अनुप्रयोग प्रयोग गर्नु अघि डाक्टरहरू र सान्दर्भिक कानुनी व्यक्तिहरूसँग परामर्श गर्नुपर्छ, र अन्ततः चिकित्सा र कानुनी निर्णयहरूको लागि जिम्मेवार छन्। यस एपमा जानकारी र सेवाहरू केवल सन्दर्भको लागि हो, र चिकित्सा सेवाहरूको लागि कानूनी सेवाहरू वा चिकित्सा समाधानहरू गठन गर्दैन, र हामी यस एपको प्रयोगबाट उत्पन्न हुने कुनै पनि जिम्मेवारी मान्दैनौं।",
    },
    originalMail: "मूल सन्देश",
    emailRecipient: "प्राप्तकर्ता",
    emailFrom: "प्रेषक",
    CcTo: "CC",
};
