<template>
  <div class="me-share">
    <van-popup
      v-model="status"
      position="bottom"
      :style="{ height: '15%' }"
      @closed="$emit('close')"
      get-container="body"
      class="me-share--items"
    >
      <social-sharing
        :url="data.url"
        :title="data.title"
        :description="
          data.desc && data.desc.length > 25
            ? data.desc.slice(0, 25)
            : data.desc
        "
        :quote="data.title"
        :hashtags="data.tags || ''"
        :twitter-user="data.title"
        inline-template
        @close="$emit('close')"
      >
        <div class="me-share__items">
          <network network="googleplus">
            <i class="me-icon-google"></i>
          </network>
          <network network="weibo">
            <i class="me-icon-weibo"></i>
          </network>

          <network network="facebook">
            <i class="me-icon-facebook"></i>
          </network>
          <network network="linkedin">
            <i class="me-icon-linkedin"></i>
          </network>
          <network network="twitter">
            <i class="me-icon-twitter"></i>
          </network>               
        </div>
      </social-sharing>
      <span class="addCopyBtn" @click="handlerCopy">
          <i class="me-icon-link"></i>
      </span>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "share",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      status: false,
      config: {},
      url:''
    };
  },
  watch: {
    show: {
      handler(val) {
        this.status = val;
      }
    }
  },
  created() {
    this.url = this.data.url || window.location.href
  },
  methods: {
    handlerCopy(){
      this.$copyText(this.url).then(()=>{
         this.status = !this.status
        this.$toast.success()        
      },()=>{
        this.$toast.fail()
      })

    },
   
  }
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

+b(me-share) {
  padding: 0.5rem;
  z-index:30000
  +m(items){
    display:flex;
    flex-flow:row;
    .addCopyBtn{
      display:flex;
      align-items:center;
      // justify-content:center;
      // width:15%;
      margin:0 .9rem;
      i{
        font-size:30px;
        color #666666;
      }
    }

  }

  +e(items) {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    // padding: 0 0.9rem;
    flex:1;    
    justify-content: space-around;

    span {
      // width: 25%;
      // text-align center;
      display: flex;
      flex-flow: column;
      align-items: center;

      i {
        font-size: 30px;

        &.me-icon-weibo {
          color: #fb6e65;
        }

        &.me-icon-facebook {
          color: #3c5a99;
        }

        &.me-icon-twitter {
          color: #55acee;
        }

        &.me-icon-linkedin {
          color: #007bb5;
        }
        &.me-icon-google{
          color rgba(221,76,63,1)
        }
      }
    }
  }
}
</style>
