export default {
    langs: {
        "zh-CN": "chinês",
        en: "Inglês",
        fr: "Francês",
        es: "espanhol",
        "ru-RU": "russo",
        hi: "hindi",
        ur: "urdu",
        ja: "japonês",
        ko: "coreano",
        th: "tailandês",
        de: "Alemão",
        it: "italiano",
    },
    responFail: "Falha ao carregar",
    responRetry: "clique em tentar novamente",
    responEmpty: "sem dados",
    resendMsg: "Falha ao enviar mensagem, clique para tentar novamente",
    sendImage: "enviar fotos",
    send: "enviar",
    //fastSend: "Pressione {tecla} para enviar rapidamente",
    //sendMsg: "Envie uma mensagem",
    //clickDownload: "Clique para fazer o download",
    timeAgo: {
        0: "apenas",
        1: "{n} minutos atrás",
        2: "{n} horas atrás",
        3: "ontem",
    },
    copyGroup: "Copiar membros para um novo grupo de discussão",
    quitGroup: "sair do grupo de discussão",
    createGroup: "criar grupo de discussão",
    inviteFriend: "convide amigos",
    // web site
    login: "Conecte-se",
    loginerror: "Falha no login!",
    register: "registro",
    keepOn: "permaneça logado",
    forgetPwd: "esqueceu a senha?",
    logoutTip: "OK para sair",
    resetPwd: "Redefinir senha de login",
    all: "todos",
    // 注册
    phoneErr: "O número de telefone não pode estar vazio",
    phoneformatErr: "O formato do número de telefone está incorreto",
    needNumber: "Insira um valor numérico",
    registerErr: "registração falhou",
    // publish
    publish: "liberar",
    group: "grupo",
    school: "Escola",
    profess: "principal",
    degree: "bacharel em Ciências",
    time: "tempo",
    desc: "descrever",
    mustSchool: "a escola não pode estar vazia",
    mustCompany: "a empresa não pode estar vazia",
    company: "empreendimento",
    workexp: "experiência de trabalho",
    education: "Experiencia educacional",
    companyName: "empresa",
    job: "Posição",
    name: "Nome",
    address: "endereço",
    called: "nome",
    title: "título",
    price: "preço",
    tags: "Rótulo",
    emoji: "expressão",
    sendFile: "Enviar arquivo",
    transferAccount: "transferir",
    image: "foto",
    video: "vídeo",
    voice: "voz",
    file: "documento",
    permissions: "permissões",
    groupRoule: {
        "0": "Grupo público: todos os usuários são livres para participar",
        "1": "Grupo de membros: depois que o usuário envia o aplicativo, ele pode ingressar após ser aprovado pelo administrador do grupo",
        "2": "Grupos privados: não visíveis na pesquisa de grupo e somente o grupo pode adicionar membros",
    },
    man: "macho",
    woman: "fêmea",
    mustBeTM: "Título/Nome é obrigatório",
    mustBeTag: "Preencha pelo menos uma tag",
    mustBeCate: "Escolha pelo menos uma categoria",
    contentTip: "Preencha os dados aqui...",
    uploadSuccess: "Carregado com sucesso!",
    publishError: "Falha ao publicar!",
    // footer
    contactus: "Contate-nos",
    privacy: "política de Privacidade",
    companyright: "todos os direitos reservados",
    // 其他
    sys_error: "erro no sistema!",
    replayEmpty: "O conteúdo do comentário não pode estar vazio!",
    replay: "responder",
    replayErr: "comentário falhou",
    delSuccess: "excluído com sucesso",
    defFail: "falha ao deletar",
    refresh: "atualização completa",
    updateSuccess: "A modificação foi bem-sucedida, faça o login novamente",
    old: "velho",
    theNew: "novo",
    changeFail: "A modificação da senha falhou",
    pwdlength: "comprimento da senha",
    getverchatCode: "obter código de verificação",
    nopeoplezan: "Ninguém gostou ainda, seja o primeiro a curtir!",
    pwdResetSuccess: "Redefinição de senha concluída",
    email_required: "O e-mail não pode estar vazio",
    phone_required: "O número do celular não pode estar vazio",
    addfriend: "O pedido de adição de amigos foi enviado com sucesso, aguardando a concordância da outra parte",
    Draft: "rascunho",
    breakPwd: "Recuperar Senha",
    "Request to view history messages": "Inscreva-se para ver notícias históricas",
    "Wait for group manager to agree": "Aguarde até que o proprietário do grupo concorde",
    'Please enter a search term': "Insira palavras-chave de pesquisa",
    done: "Claro",
    cancle: "Cancelar",
    groupType: {
        '1': {
            name: "grupo público",
            code: "1",
            desc: "`Grupo aberto: qualquer usuário pode criar um grupo livre para ingressar sem revisão. O nome do grupo descreve os membros e pode ser pesquisado e navegado na plataforma. `",
        },
        '2': {
            name: "grupo de membros",
            code: "2",
            desc: "`Grupo de membros: Qualquer usuário pode criar um grupo que pode ingressar livremente e precisa ser revisado pelo proprietário do grupo. O nome do grupo descreve os membros e pode ser pesquisado e navegado na plataforma. `",
        },
        '3': {
            name: "grupo de discussão",
            code: "3",
            desc: "`Grupo de discussão: qualquer usuário pode criar criadores e os membros podem convidar seus amigos para participar do grupo, os membros do nome podem ser pesquisados e navegados na plataforma. `",
        },
        '4': {
            name: "grupo privado",
            code: "4",
            desc: "`Grupo privado: Qualquer usuário pode criar um nome de grupo para descrever os membros do grupo e não pode ser pesquisado e navegado na plataforma. `",
        }
    },
    newPwd: "Insira uma nova senha de login",
    repNewPwd: "Por favor, digite novamente a nova senha",
    // 2021年3月4号
    meetingTips: "Faça o download do aplicativo melinked primeiro ou atualize o aplicativo melinked para a versão mais recente",
    // mechat协议
    mechat: {
        title: "Contrato de Usuário do Me Chat",
        text1: "O Mechat é um produto de perguntas e respostas de bate-papo de IA fornecido pela Maes Consulting. Quando o usuário concorda com este contrato de usuário, considera-se que ele concordou com todo o conteúdo deste contrato de usuário.",
        header1: "um. Como compartilhamos e divulgamos as informações pessoais dos usuários com terceiros",
        text2: "Nas seguintes circunstâncias, este aplicativo divulgará as informações pessoais do usuário no todo ou em parte de acordo com os desejos pessoais ou regulamentos legais do usuário:",
        text3: "1. Divulgado a terceiros com o consentimento prévio do usuário;",
        text4: "2. Para fornecer os produtos e serviços solicitados pelo usuário, é necessário compartilhar as informações pessoais do usuário com terceiros;",
        text5: "3. De acordo com as disposições pertinentes da lei, ou as exigências dos órgãos administrativos ou judiciais, divulgar a terceiros ou órgãos administrativos ou judiciais;",
        text6: "4. Se o usuário violar as leis e regulamentos chineses relevantes ou este contrato de serviço de aplicativo ou regras relacionadas, isso precisa ser divulgado a terceiros;",
        text7: "5. Se o usuário for um reclamante qualificado de propriedade intelectual e tiver apresentado uma reclamação, ele deverá divulgá-la ao respondente a pedido do respondente para que ambas as partes possam lidar com possíveis disputas de direitos;",
        text8: "6. Outras divulgações que este aplicativo considere apropriadas de acordo com as leis, regulamentos ou políticas do site.",
        header2: "2. Armazenamento e troca de informações",
        text9: "As informações e dados sobre os usuários coletados pelo MeChat serão armazenados nos servidores do MeChat e (ou) de suas empresas afiliadas. Somente as informações enviadas e recebidas serão carregadas no servidor no processo de chat comigo, que será utilizado para a resposta do modelo AI.",
        header3: "3. Propriedade intelectual relacionada",
        text10: "Não oferecemos nenhuma garantia pela legalidade, legitimidade, integridade ou qualidade do conteúdo carregado ou publicado pelos usuários. Os usuários devem arcar com os riscos decorrentes do uso ou confiança no conteúdo ou recursos transmitidos pelo software. Em qualquer caso, iremos não será responsável por qualquer perda ou dano possível ou real decorrente de tal risco.",
        header4: "4. Responsabilidade legal e isenção de responsabilidade",
        text11: "Em qualquer caso, nós e nossos parceiros não seremos responsáveis por quaisquer danos e riscos diretos, indiretos, incidentais, especiais e subsequentes decorrentes do uso do usuário ou incapacidade de usar o Me Chat. Se a operação normal do serviço for afetada por eventos de força maior, como falhas técnicas, nós e nossos parceiros prometemos cooperar com as unidades relevantes o mais rápido possível para lidar e repará-los em tempo hábil.",
        text12: "As informações enviadas pelo modelo de IA são geradas pelo computador e podem ser fictícias. Os usuários são solicitados a distinguir o certo do errado. Quando os usuários usam este aplicativo para consultar questões médicas, legais e outras relacionadas, este aplicativo fornece apenas aconselhamento médico e jurídico e não representa serviços médicos, serviços jurídicos ou tratamento. Os usuários devem consultar médicos e pessoas jurídicas relevantes antes de usar este aplicativo e são os responsáveis finais pelas decisões médicas e legais. As informações e serviços deste aplicativo são apenas para referência e não constituem serviços jurídicos ou soluções médicas para serviços médicos, e não assumimos nenhuma responsabilidade decorrente do uso deste aplicativo.",
    },
    originalMail: "A mensagem original",
    emailRecipient: "destinatário",
    emailFrom: "remetente",
    CcTo: "CC",
};
