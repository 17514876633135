<template>
  <div class="wap-gigitem" @click="handlerToDetail(item.groupId)">
    <Thumb v-if="type == 'group'" :src="item.groupHeader | checkUrl" width="110px" height="90px" margin="0"></Thumb>
    <Thumb v-else :src="item.profilePhoto | checkUrl" width="110px" height="90px" margin="0"></Thumb>
    <span class="wap-gigitem--title">
      {{
          handlerSliceTitle(item.name, length) 
      }}
    </span>
    <i :class="{
      icon: true,
      'me-icon-group': type == 'group',
      'me-icon-company': type == 'company'
    }"></i>
  </div>
</template>
<script>
import Thumb from "@/components/common/thumb";
export default {
  name: "gigitem",
  components: {
    Thumb
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          title: "這是標題",
          thumb: "",
          id: ""
        };
      }
    },
    type: {
      type: String,
      default: ""
    },
    length: {
      type: Number,
      default: 7
    }
  },
  created() { },
  filters: {
    checkUrl(url) {
      let purl = url;
      if (url && url.indexOf('http') == -1) {
        purl = 'https://pic.melinked.com/' + url
      }
      return purl
    }
  },
  methods: {
    handlerSliceTitle(title, length) {
      if (title && title.length > length) {
        return title.trim().slice(0, length) + "...";
      } else {
        return title;
      }
    },
    handlerToDetail(id) {
      id = "pai_" + Base64.encode(id);
      this.$router.push({
        name: this.type === "company" ? "detail" : "group",
        params: { id, type: this.type === "company" ? "enterprise" : this.type }
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index.styl';

.wap-gigitem {
  display: flex;
  flex-flow: column;
  position: relative;

  .icon {
    background: #fe0657;
    position: absolute;
    top: 0;
    right: 0;
    // z-index: 5;
    color: #ffffff;
    padding: 0.15rem;
  }

  +m(title) {
    font-size: 14px;
    margin-top: 0.25rem;
    text-align: center;
  }
}
</style>
