var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "header",
      style: {
        height: _vm.headerHeight !== 0 ? _vm.headerHeight + "px" : "auto",
        padding: _vm.headerHeight === 0 ? "0.9rem 0" : "0",
        background: _vm.headerBackground
      }
    },
    [
      _c(
        "div",
        { staticClass: "left" },
        [_vm._t("left-one"), _vm._t("left-second"), _vm._t("left-thred")],
        2
      ),
      _c("div", { staticClass: "center" }, [_vm._t("center")], 2),
      _c(
        "div",
        { staticClass: "right" },
        [_vm._t("right-two"), _vm._t("right-one")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }