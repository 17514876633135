export default {
    langs: {
        "zh-CN": "Cina",
        en: "Bahasa inggris",
        fr: "Perancis",
        es: "Orang Spanyol",
        "ru-RU": "Rusia",
        hi: "Hindi",
        ur: "Urdu",
        ja: "Jepang",
        ko: "Korea",
        th: "Thai",
        de: "Jerman",
        it: "Italia",
    },
    responFail: "Gagal untuk memuat",
    responRetry: "klik coba lagi",
    responEmpty: "Tidak ada data",
    resendMsg: "Gagal mengirim pesan, klik untuk mencoba lagi",
    sendImage: "mengirim gambar",
    send: "mengirim",
    //fastSend: "Tekan {key} untuk mengirim dengan cepat",
    //sendMsg: "Kirim pesan",
    //clickDownload: "klik untuk mengunduh",
    timeAgo: {
        0: "hanya",
        1: "{n} menit yang lalu",
        2: "{n} jam yang lalu",
        3: "Kemarin",
    },
    copyGroup: "Salin anggota ke grup diskusi baru",
    quitGroup: "keluar dari grup diskusi",
    createGroup: "membuat grup diskusi",
    inviteFriend: "mengundang teman-teman",
    // web site
    login: "Gabung",
    loginerror: "Gagal masuk!",
    register: "daftar",
    keepOn: "tetap masuk",
    forgetPwd: "lupa kata sandinya?",
    logoutTip: "Oke untuk logout",
    resetPwd: "Setel ulang kata sandi masuk",
    all: "semua",
    // 注册
    phoneErr: "Nomor telepon tidak boleh kosong",
    phoneformatErr: "Format nomor telepon salah",
    needNumber: "Masukkan nilai numerik",
    registerErr: "registrasi gagal",
    // publish
    publish: "melepaskan",
    group: "kelompok",
    school: "Sekolah",
    profess: "besar",
    degree: "Sarjana Sains",
    time: "waktu",
    desc: "menggambarkan",
    mustSchool: "sekolah tidak boleh kosong",
    mustCompany: "perusahaan tidak boleh kosong",
    company: "perusahaan",
    workexp: "pengalaman kerja",
    education: "Pengalaman pendidikan",
    companyName: "perusahaan",
    job: "Posisi",
    name: "Nama",
    address: "alamat",
    called: "nama",
    title: "judul",
    price: "harga",
    tags: "Label",
    emoji: "ekspresi",
    sendFile: "Kirim Berkas",
    transferAccount: "transfer",
    image: "gambar",
    video: "video",
    voice: "suara",
    file: "dokumen",
    permissions: "izin",
    groupRoule: {
        "0": "Grup publik: Semua pengguna bebas untuk bergabung",
        "1": "Grup anggota: Setelah pengguna mengirimkan aplikasi, ia dapat bergabung setelah disetujui oleh administrator grup",
        "2": "Grup Pribadi: Tidak terlihat dalam pencarian grup, dan hanya grup yang dapat menambahkan anggota",
    },
    man: "pria",
    woman: "perempuan",
    mustBeTM: "Gelar/Nama wajib diisi",
    mustBeTag: "Isi setidaknya satu tag",
    mustBeCate: "Pilih setidaknya satu kategori",
    contentTip: "Isi detailnya disini...",
    uploadSuccess: "Berhasil diunggah!",
    publishError: "Publikasi gagal!",
    // footer
    contactus: "Hubungi kami",
    privacy: "Kebijakan pribadi",
    companyright: "seluruh hak cipta",
    // 其他
    sys_error: "sistem bermasalah!",
    replayEmpty: "Konten komentar tidak boleh kosong!",
    replay: "membalas",
    replayErr: "komentar gagal",
    delSuccess: "berhasil dihapus",
    defFail: "gagal untuk menghapus",
    refresh: "pembaruan selesai",
    updateSuccess: "Modifikasi berhasil, silahkan login kembali",
    old: "tua",
    theNew: "baru",
    changeFail: "Modifikasi kata sandi gagal",
    pwdlength: "panjang sandi",
    getverchatCode: "dapatkan kode verifikasi",
    nopeoplezan: "Belum ada yang suka, jadilah yang pertama menyukainya!",
    pwdResetSuccess: "Penyetelan ulang kata sandi selesai",
    email_required: "Email tidak boleh kosong",
    phone_required: "Nomor ponsel tidak boleh kosong",
    addfriend: "Permohonan penambahan teman sudah berhasil terkirim, menunggu persetujuan pihak lain",
    Draft: "draf",
    breakPwd: "mengambil kata sandi",
    "Request to view history messages": "Terapkan untuk melihat berita sejarah",
    "Wait for group manager to agree": "Tunggu hingga pemilik grup setuju",
    'Please enter a search term': "Masukkan kata kunci pencarian",
    done: "Tentu",
    cancle: "Membatalkan",
    groupType: {
        '1': {
            name: "grup publik",
            code: "1",
            desc: "`Grup terbuka: Setiap pengguna dapat membuat grup yang bebas untuk bergabung tanpa ulasan. Nama grup menggambarkan anggota dan dapat dicari dan dijelajahi di platform. `",
        },
        '2': {
            name: "kelompok anggota",
            code: "2",
            desc: "`Grup anggota: Setiap pengguna dapat membuat grup yang dapat bergabung dengan bebas dan perlu ditinjau oleh pemilik grup. Nama grup menggambarkan anggota dan dapat dicari dan dijelajahi di platform. `",
        },
        '3': {
            name: "grup diskusi",
            code: "3",
            desc: "`Grup diskusi: setiap pengguna dapat membuat pembuat dan anggota dapat mengundang teman mereka untuk bergabung dengan grup nama anggota dapat dicari dan dijelajahi di platform. `",
        },
        '4': {
            name: "grup pribadi",
            code: "4",
            desc: "`Grup pribadi: Setiap pengguna dapat membuat nama grup untuk mendeskripsikan anggota grup dan tidak dapat dicari dan dijelajahi di platform. `",
        }
    },
    newPwd: "Silakan masukkan kata sandi login baru",
    repNewPwd: "Silakan masukkan kembali kata sandi baru",
    // 2021年3月4号
    meetingTips: "Silakan unduh Aplikasi melinked terlebih dahulu atau tingkatkan APP melinked ke versi terbaru",
    // mechat协议
    mechat: {
        title: "Perjanjian Pengguna Obrolan Saya",
        text1: "Mechat adalah produk Q&amp;A pendamping obrolan AI yang disediakan oleh Maes Consulting. Ketika pengguna menyetujui perjanjian pengguna ini, maka dianggap telah menyetujui seluruh isi perjanjian pengguna ini.",
        header1: "satu. Bagaimana kami membagikan dan mengungkapkan informasi pribadi pengguna dengan pihak ketiga",
        text2: "Dalam keadaan berikut, aplikasi ini akan mengungkapkan informasi pribadi pengguna secara keseluruhan atau sebagian sesuai dengan keinginan pribadi pengguna atau peraturan hukum:",
        text3: "1. Diungkapkan kepada pihak ketiga dengan persetujuan pengguna sebelumnya;",
        text4: "2. Untuk menyediakan produk dan layanan yang diminta oleh pengguna, informasi pribadi pengguna harus dibagikan dengan pihak ketiga;",
        text5: "3. Menurut ketentuan undang-undang yang relevan, atau persyaratan lembaga administratif atau yudikatif, mengungkapkannya kepada pihak ketiga atau lembaga administratif atau yudikatif;",
        text6: "4. Jika pengguna melanggar undang-undang dan peraturan Tiongkok yang relevan atau perjanjian layanan aplikasi ini atau aturan terkait, hal itu perlu diungkapkan kepada pihak ketiga;",
        text7: "5. Jika pengguna adalah pengadu kekayaan intelektual yang memenuhi syarat dan telah mengajukan keluhan, ia harus mengungkapkannya kepada tergugat atas permintaan tergugat sehingga kedua belah pihak dapat menangani kemungkinan sengketa hak;",
        text8: "6. Pengungkapan lain yang dianggap tepat oleh aplikasi ini sesuai dengan undang-undang, peraturan, atau kebijakan situs web.",
        header2: "2. Penyimpanan dan pertukaran informasi",
        text9: "Informasi dan data tentang pengguna yang dikumpulkan oleh MeChat akan disimpan di server MeChat dan (atau) perusahaan afiliasinya. Hanya informasi yang dikirim dan diterima yang akan diunggah ke server dalam proses mengobrol dengan Saya, yang akan digunakan untuk balasan model AI.",
        header3: "3. Terkait kekayaan intelektual",
        text10: "Kami tidak menjamin legalitas, legitimasi, integritas, atau kualitas konten yang diunggah atau diterbitkan oleh pengguna. Pengguna akan menanggung risiko yang timbul dari penggunaan atau mengandalkan konten atau sumber daya yang dikirimkan oleh perangkat lunak. Dalam hal apa pun, kami akan tidak akan bertanggung jawab atas kerugian atau kerusakan yang mungkin atau aktual yang timbul dari risiko tersebut.",
        header4: "4. Tanggung jawab hukum dan penafian",
        text11: "Dalam hal apa pun, kami dan mitra kami tidak akan bertanggung jawab atas kerusakan dan risiko langsung, tidak langsung, insidental, khusus dan selanjutnya yang timbul dari penggunaan atau ketidakmampuan pengguna untuk menggunakan Me Chat. Jika operasi normal layanan dipengaruhi oleh peristiwa force majeure seperti kegagalan teknis, kami dan mitra kami berjanji untuk bekerja sama dengan unit terkait sesegera mungkin untuk menangani dan memperbaikinya tepat waktu.",
        text12: "Informasi yang dikirim oleh model AI dihasilkan oleh komputer dan mungkin fiktif.Pengguna diminta untuk membedakan yang benar dan yang salah. Saat pengguna menggunakan aplikasi ini untuk berkonsultasi dengan masalah medis, hukum, dan masalah terkait lainnya, aplikasi ini hanya memberikan nasihat medis dan nasihat hukum, dan tidak mewakili layanan medis, layanan hukum, atau perawatan. Pengguna harus berkonsultasi dengan dokter dan badan hukum terkait sebelum menggunakan aplikasi ini, dan pada akhirnya bertanggung jawab atas keputusan medis dan hukum. Informasi dan layanan dalam aplikasi ini hanya untuk referensi, dan bukan merupakan layanan hukum atau solusi medis untuk layanan medis, dan kami tidak bertanggung jawab atas penggunaan aplikasi ini.",
    },
    originalMail: "Pesan aslinya",
    emailRecipient: "penerima",
    emailFrom: "pengirim",
    CcTo: "CC",
};
