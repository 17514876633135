var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-popup",
    {
      staticClass: "me-popup",
      style: _vm.styleStr,
      attrs: { position: "top" },
      on: { closed: _vm.closed },
      model: {
        value: _vm.status,
        callback: function($$v) {
          _vm.status = $$v
        },
        expression: "status"
      }
    },
    [
      _c("van-tree-select", {
        attrs: {
          items: _vm.items,
          "active-id": _vm.activeId,
          "main-active-index": _vm.curActiveIndex
        },
        on: {
          "update:activeId": function($event) {
            _vm.activeId = $event
          },
          "update:active-id": function($event) {
            _vm.activeId = $event
          },
          "update:mainActiveIndex": function($event) {
            _vm.curActiveIndex = $event
          },
          "update:main-active-index": function($event) {
            _vm.curActiveIndex = $event
          },
          "click-item": _vm.selectedItem,
          "click-nav": _vm.selectedNav
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }