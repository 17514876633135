<template>
  <van-dropdown-menu
    class="lang-dropdown"
    active-color="#33cc66"
    :style="{ height: '80%' }"
  >
    <van-dropdown-item
      v-model="curLocale"
      :options="langs"
      @change="handlerChangeLang"
      @opened="handlerOpen"
      @close="handlerClose"
    />
  </van-dropdown-menu>
</template>

<script>
export default {
  name: "LanguageSelect",
  props: {
    locale: {
      type: String,
      required: true,
      default: "zh-CN",
    },
  },
  data() {
    return {
      curLocale: "",
    };
  },
  created() {
    this.curLocale = this.locale;
  },
  computed: {
    langs() {
      const messages = this.$i18n.messages;
      let langs = [];
      for (const langName in messages) {
        const curmsg = messages[langName];
        let name = messages[this.locale].langs[langName];
        let item = {
          text: `${name}(${curmsg.langName})`,
          fulltext: [name, curmsg.langName],
          value: langName,
          code: curmsg.code,
          encode: curmsg.encode,
        };
        langs.push(item);
      }
      return langs;
    },
  },
  methods: {
    handlerOpen() {
      this.$emit("oepn");
    },
    handlerClose() {
      this.$emit("close");
    },
    handlerChangeLang(value) {
      this.$emit("change", {
        code: this.$i18n.messages[value].code,
        value,
        langName: this.$i18n.messages[value].langName,
      });
    },
  },
};
</script>

<style lang="stylus">
@import '~styles/utils/index.styl';

+b(lang-dropdown) {
  height: auto !important;
  min-width: 40px;
  min-height: 30px;
  background: none !important;
  position: absolute !important;
  right: -0.5rem;

  &::after {
    border: none !important;
  }

  .van-dropdown-menu__title {
    color: #ffffff !important;
    font-size: 16px;
    opacity: 0;
    min-height: 30px;
  }

  .van-dropdown-item {
    top: 54px !important;
  }
}
</style>
