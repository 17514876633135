import Vue from "vue";
import "vant/lib/index.css";
import {
    Image,
    Button,
    Loading,
    Popup,
    PullRefresh,
    DropdownMenu,
    DropdownItem,
    Tabbar,
    TabbarItem,
    TreeSelect,
    Tag,
    Swipe,
    SwipeItem,
    Lazyload,
    ImagePreview,
    Tab,
    Tabs,
    Field,
    CellGroup,
    Cell,
    Uploader,
    Toast,
    IndexBar,
    IndexAnchor,
    Search,
    Dialog,
    Overlay,
    List,
    Progress,
    Circle,
    Icon,
    SwipeCell,
    Sticky,
} from "vant";
Vue.use(SwipeCell)
Vue.use(Image);
Vue.use(Button);
Vue.use(Loading);
Vue.use(PullRefresh);
Vue.use(Popup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(TreeSelect);
Vue.use(Tag);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(ImagePreview);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(Uploader);
Vue.use(Toast);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Search)
Vue.use(Dialog)
Vue.use(Overlay)
Vue.use(List)
Vue.use(Progress)
Vue.use(Circle)
Vue.use(Icon)
Vue.use(Sticky)