export default {
    "langs": {
        "zh-CN": "中国語",
        "en": "英語",
        "fr": "フランス語",
        "es": "スペイン語",
        "ru-RU": "ロシア語",
        "hi": "ヒンディー語",
        "ur": "ウルドゥー語",
        "ja": "日本語",
        "ko": "韓国人",
        "th": "タイ語",
        "de": "ドイツ人",
        "it": "イタリア人"
    },
    "responFail": "ロードに失敗しました",
    "responRetry": "再試行をクリックします",
    "responEmpty": "まだデータがありません",
    "resendMsg": "メッセージを送信できませんでした。クリックして再試行してください",
    "sendImage": "写真を送る",
    "send": "送る",
    "timeAgo": {
        "0": "ただ",
        "1": "{n}分前",
        "2": "{n}時間前",
        "3": "昨日"
    },
    "copyGroup": "メンバーを新しいディスカッショングループにコピーする",
    "quitGroup": "ディスカッショングループを終了する",
    "createGroup": "ディスカッショングループを作成する",
    "inviteFriend": "友達を招待する",
    "login": "ログイン",
    "loginerror": "ログインに失敗しました！",
    "register": "登録",
    "keepOn": "ログインを続ける",
    "forgetPwd": "パスワードを忘れましたか？",
    "logoutTip": "ログアウトしてOK",
    "resetPwd": "ログインパスワードをリセット",
    "all": "全部",
    "phoneErr": "電話番号は空にできません",
    "phoneformatErr": "電話番号の形式が正しくありません",
    "needNumber": "数値を入力してください",
    "registerErr": "登録に失敗しました",
    "publish": "リリース",
    "group": "団体",
    "school": "学校",
    "profess": "プロ",
    "degree": "学位",
    "time": "時間",
    "mustSchool": "学校は空にできません",
    "mustCompany": "会社を空にすることはできません",
    "company": "事業内容",
    "workexp": "実務経験",
    "education": "教育経験",
    "companyName": "会社",
    "job": "役職",
    "name": "お名前",
    "address": "住所",
    "called": "お名前",
    "title": "役職",
    "price": "価格",
    "tags": "ラベル",
    "emoji": "表現",
    "sendFile": "ファイルを送信",
    "transferAccount": "乗り換え",
    "image": "写真",
    "video": "映像",
    "voice": "声",
    "file": "ファイル",
    "groupRoule": {
        "0": "オープングループ：すべてのユーザーが自由に参加できます",
        "1": "メンバーシップ：ユーザーがアプリケーションを送信すると、グループ管理者が参加できます。",
        "2": "プライベートグループ：グループ検索には表示されず、グループのみがメンバーを追加できます"
    },
    "man": "男性",
    "woman": "雌",
    "mustBeTM": "タイトル/名前が必要",
    "mustBeTag": "少なくとも1つのラベルを記入してください",
    "mustBeCate": "少なくとも1つのカテゴリを選択します",
    "contentTip": "ここに詳細を入力してください...",
    "uploadSuccess": "アップロードしました！",
    "publishError": "公開に失敗しました！",
    "contactus": "お問い合わせ",
    "privacy": "プライバシーポリシー",
    "companyright": "著作権",
    "sys_error": "システムエラー！",
    "replayEmpty": "コメントを空にすることはできません！",
    "replay": "返信",
    "replayErr": "コメントに失敗しました",
    "delSuccess": "正常に削除されました",
    "defFail": "削除に失敗しました",
    "refresh": "正常に更新されました",
    "updateSuccess": "変更が成功しました。もう一度ログインしてください。",
    "old": "古い",
    "theNew": "新しい",
    "changeFail": "パスワードの変更に失敗しました",
    "pwdlength": "パスワードの長さ",
    "getverchatCode": "確認コードを取得する",
    "nopeoplezan": "誰もまだそれを好きではありません、最初に賞賛する人になりましょう！",
    "pwdResetSuccess": "パスワードは正常に変更されました。",
    "email_required": "メールボックスを空にすることはできません",
    "phone_required": "電話番号は空にできません",
    done: '確定',
    cancle: 'キャンセル',
    groupType: {
        '1': {
            name: '公開グループ',
            code: '1',
            desc: `公開群もユーザーが創設し、自由に加入の群、審査を必要としない。群の名前を描写し、メンバーのプラットフォームの上でできる検索されて、閲覧。`
        },

        '2': {
            name: '会員群',
            code: '2',
            desc: `員の群もユーザーが創設し、自由に加入の群、群主検討が必要。群の名前を描写し、メンバーのプラットフォームの上でできる検索されて、閲覧。`
        },
        '3': {
            name: '議論グループ',
            code: '3',
            desc: `討論チームもユーザーが創設し、設立者及びメンバーが自分の亲友に招待して、群の名前、メンバーのプラットフォームの上でできる検索されて、目を通す。`
        },
        '4': {
            name: 'プライベートグループ',
            code: '4',
            desc: `隠し群:何のユーザーが創設し、群の名前を描いて、群メンバーのプラットフォームの上で検索されて、できない。`
        }
    },
    newPwd:'新しいログインパスワードを入力してください',
    repNewPwd:"新しいパスワードを繰り返してください",
    breakPwd: 'パスワードの取得',
    // 2021年3月4号
    meetingTips: "最初にMelinkedAppをダウンロードするか、MelinkedAPPを最新バージョンにアップグレードしてください",
    // mechat协议
    mechat: {
      title: "ミーチャット利用規約",
      text1: "Mechat は、Maes Consulting が提供する AI チャット コンパニオン Q&amp;A 製品です。ユーザーが本利用規約に同意した場合、本利用規約の内容全体に同意したものとみなされます。",
      header1: "一。ユーザーの個人情報を第三者と共有および開示する方法",
      text2: "以下の状況において、本アプリは、ユーザーの個人的な希望または法的規制に従って、ユーザーの個人情報の全部または一部を開示します。",
      text3: "1. 利用者の事前の同意を得た第三者への開示",
      text4: "2. ユーザーが要求する製品およびサービスを提供するために、ユーザーの個人情報を第三者と共有する必要がある場合;",
      text5: "3. 法律の関連規定または行政機関または司法機関の要求に従って、第三者または行政機関または司法機関に開示する。",
      text6: "4.ユーザーが関連する中国の法律や規制、またはこのアプリケーションサービス契約または関連する規則に違反した場合、第三者に開示する必要があります。",
      text7: "5. ユーザーが有資格の知的財産権の申立人であり、申立てを提出した場合、両当事者が起こり得る権利紛争を処理できるように、申立人の要求に応じて申立人に開示するものとします。",
      text8: "6. その他、法令または当サイトのポリシーに基づき、本アプリが適切と判断する開示。",
      header2: "2. 情報の保管と交換",
      text9: "MeChat によって収集されたユーザーに関する情報とデータは、MeChat および (または) その関連会社のサーバーに保存されます。 Meとのチャットの過程で送受信された情報のみがサーバーにアップロードされ、AIモデルの返信に使用されます。",
      header3: "3. 知的財産関連",
      text10: "当社は、ユーザーがアップロードまたは公開したコンテンツの合法性、合法性、完全性、または品質について一切保証しません. ユーザーは、ソフトウェアによって送信されたコンテンツまたはリソースを使用または信頼することから生じるリスクを負担するものとします. いかなる場合でも、当社は、そのようなリスクから生じる可能性のある、または実際の損失または損害に対して責任を負わないものとします。",
      header4: "4. 法的責任と免責事項",
      text11: "いずれの場合も、当社および当社のパートナーは、ユーザーが Me Chat を使用すること、または使用できないことから生じる、直接的、間接的、偶発的、特別、およびその後の損害およびリスクについて、責任を負わないものとします。サービスの通常の運用が技術的な障害などの不可抗力の出来事によって影響を受けた場合、当社およびパートナーは、適切なタイミングで対処および修復するために、できるだけ早く関連部門と協力することを約束します。",
      text12: "AIモデルによって送信された情報はコンピューターによって生成されたものであり、架空のものである可能性があります.ユーザーは善悪を区別するよう求められます.ユーザーがこのアプリケーションを使用して医療、法律、およびその他の関連問題を相談する場合、このアプリケーションは医学的アドバイスと法的アドバイスのみを提供し、医療サービス、法律サービス、または治療を表すものではありません。ユーザーは、このアプリケーションを使用する前に医師および関連する法人に相談する必要があり、最終的には医学的および法的決定に責任を負います。このアプリの情報およびサービスは参考用であり、法的サービスまたは医療サービスの医療ソリューションを構成するものではなく、このアプリの使用から生じるいかなる責任も負いません。",
    },
    originalMail: "オリジナルのメッセージ",
    emailRecipient: "受信者",
    emailFrom: "差出人",
    CcTo: "CC",
}