import axios from "axios";
import {
  env,
  handlerEncrypt
} from "@/utils";
import { Base64 } from "js-base64";
import Store from "@/store";
// import Jquery from 'jquery'
//import store from "@/store";
//import router from "@/router";
//import { message } from "element-ui";

/** 包装响应数据，将不同的返回类型统一为一种 */
const packageResponse = data => {
  if (data.global && data.responses) {
    const { flag, message, items, total } = data.responses[0];
    return {
      code: flag,
      message,
      data: items,
      total
    };
  }
  if (data.list && data.status) {
    let code = data.status;
    delete data.status;
    return {
      message: "",
      code,
      data
      //code
    };
  }
  if (data.code && data.message) {
    const { message, code } = data;
    delete data.message;
    delete data.code;
    return {
      message,
      code,
      data: data
    };
  }
  return data;
};

const http = axios.create({
  transformResponse: [
    function(data) {
      const formartData = data.replace(/\t/gi, "\r\n") || {}
        data = JSON.parse(formartData);
      // data = Jquery.parseJSON(data)
      return data;
    }
  ],
  withCredentials: false,
  method: "get",
  baseURL: env("BASEURL"),
  timeout: 25000
});

// 请求拦截器
http.interceptors.request.use(
  config => {
     const userinfo = localStorage.getItem("userinfo");
     const userObj = userinfo ? JSON.parse(userinfo) : {};
     const language = localStorage.getItem("langCode") &&
       localStorage.getItem("langCode") != null ?
       localStorage.getItem("langCode") :
       "1";

     const permStr = JSON.stringify({
       language,
       // token: userObj.token,
       userId: userObj.id,
       time: new Date().getTime()
     });
     const key = handlerEncrypt(permStr);
     config.url +=
       config.url.indexOf("?") !== -1 ?
       `&permissions=${Base64.encode(key)}` :
       `?permissions=${Base64.encode(key)}`;
    if (config.params) {
     
      if (!config.params["language"])
        config.params["language"] = language
          
      if (userObj.token) {
        config.params["token"] = userObj.token;
        // config.params['userId'] = userObj.id
      }
    }
    return config;
  },
  error => Promise.reject(error.response.data)
);

// 响应拦截器
http.interceptors.response.use(
  response => {
    if (response.code == 4003) {
      localStorage.setItem("userinfo", {});
      Store.dispatch("commitUserinfo", {});
    }

    return packageResponse(response.data);
  },
  error => {
    // message({
    //   message: `服务器发生错误：错误码 ${error}`,
    //   type: "error"
    // });
    return Promise.reject(error);
  }
);

export default http;
