export default {
    langs: {
        "zh-CN": "người Trung Quốc",
        en: "Tiếng Anh",
        fr: "người Pháp",
        es: "người Tây Ban Nha",
        "ru-RU": "tiếng Nga",
        hi: "tiếng Hin-ddi",
        ur: "tiếng Urdu",
        ja: "tiếng Nhật",
        ko: "Hàn Quốc",
        th: "tiếng Thái",
        de: "tiếng Đức",
        it: "người Ý",
    },
    responFail: "Không tải được",
    responRetry: "nhấp thử lại",
    responEmpty: "Không có dữ liệu",
    resendMsg: "Không thể gửi tin nhắn, nhấp để thử lại",
    sendImage: "gửi ảnh",
    send: "gửi",
    //fastSend: "Nhấn phím {key} để gửi nhanh",
    //sendMsg: "Gửi tin nhắn",
    //clickDownload: "bấm chuột để tải xuống",
    timeAgo: {
        0: "chỉ",
        1: "{n} phút trước",
        2: "{n} giờ trước",
        3: "Hôm qua",
    },
    copyGroup: "Sao chép thành viên vào nhóm thảo luận mới",
    quitGroup: "rời khỏi nhóm thảo luận",
    createGroup: "tạo nhóm thảo luận",
    inviteFriend: "mời bạn bè",
    // web site
    login: "Đăng nhập",
    loginerror: "Đăng nhập thất bại!",
    register: "đăng ký",
    keepOn: "giữ trạng thái đăng nhập",
    forgetPwd: "quên mật khẩu?",
    logoutTip: "OK để đăng xuất",
    resetPwd: "Đặt lại mật khẩu đăng nhập",
    all: "tất cả",
    // 注册
    phoneErr: "Số điện thoại không được để trống",
    phoneformatErr: "Định dạng số điện thoại không chính xác",
    needNumber: "Vui lòng nhập một giá trị số",
    registerErr: "đăng ký thất bại",
    // publish
    publish: "giải phóng",
    group: "nhóm",
    school: "Trường học",
    profess: "lớn lao",
    degree: "Cử nhân khoa học",
    time: "thời gian",
    desc: "mô tả",
    mustSchool: "trường không thể để trống",
    mustCompany: "công ty không được để trống",
    company: "xí nghiệp",
    workexp: "kinh nghiệm làm việc",
    education: "Kinh nghiệm giáo dục",
    companyName: "công ty",
    job: "Chức vụ",
    name: "Tên",
    address: "Địa chỉ",
    called: "tên",
    title: "tiêu đề",
    price: "giá",
    tags: "Nhãn",
    emoji: "sự biểu lộ",
    sendFile: "Gửi file",
    transferAccount: "chuyển khoản",
    image: "hình ảnh",
    video: "băng hình",
    voice: "tiếng nói",
    file: "tài liệu",
    permissions: "quyền",
    groupRoule: {
        "0": "Nhóm công khai: Tất cả người dùng đều được tham gia miễn phí",
        "1": "Nhóm thành viên: Sau khi người dùng gửi đơn đăng ký, anh ta có thể tham gia sau khi được quản trị viên phê duyệt",
        "2": "Nhóm riêng tư: Không hiển thị trong tìm kiếm nhóm và chỉ nhóm mới có thể thêm thành viên",
    },
    man: "nam giới",
    woman: "nữ giới",
    mustBeTM: "Tiêu đề/Tên là bắt buộc",
    mustBeTag: "Điền vào ít nhất một thẻ",
    mustBeCate: "Chọn ít nhất một danh mục",
    contentTip: "Điền thông tin chi tiết tại đây...",
    uploadSuccess: "Tải lên thành công!",
    publishError: "Xuất bản không thành công!",
    // footer
    contactus: "liên hệ chúng tôi",
    privacy: "Chính sách bảo mật",
    companyright: "Đã đăng ký Bản quyền",
    // 其他
    sys_error: "lỗi hệ thống!",
    replayEmpty: "Nội dung bình luận không được để trống!",
    replay: "hồi đáp",
    replayErr: "bình luận không thành công",
    delSuccess: "xóa thành công",
    defFail: "không thể xóa",
    refresh: "hoàn thành cập nhật",
    updateSuccess: "Sửa đổi thành công, vui lòng đăng nhập lại",
    old: "cũ",
    theNew: "mới",
    changeFail: "Sửa đổi mật khẩu không thành công",
    pwdlength: "độ dài mật khẩu",
    getverchatCode: "lấy mã xác minh",
    nopeoplezan: "Chưa có ai thích, hãy là người đầu tiên thích nó!",
    pwdResetSuccess: "Đặt lại mật khẩu hoàn tất",
    email_required: "Email không được để trống",
    phone_required: "Số điện thoại di động không được để trống",
    addfriend: "Yêu cầu thêm bạn bè đã được gửi thành công, chờ bên kia đồng ý",
    Draft: "bản nháp",
    breakPwd: "lấy lại mật khẩu",
    "Request to view history messages": "Áp dụng để xem tin tức lịch sử",
    "Wait for group manager to agree": "Chờ chủ nhóm đồng ý",
    'Please enter a search term': "Nhập từ khóa tìm kiếm",
    done: "Chắc chắn",
    cancle: "Hủy bỏ",
    groupType: {
        '1': {
            name: "nhóm công cộng",
            code: "1",
            desc: "`Nhóm mở: Bất kỳ người dùng nào cũng có thể tạo một nhóm để tham gia miễn phí mà không cần xem xét. Tên nhóm mô tả các thành viên và có thể được tìm kiếm và duyệt trên nền tảng. `",
        },
        '2': {
            name: "nhóm thành viên",
            code: "2",
            desc: "`Nhóm thành viên: Bất kỳ người dùng nào cũng có thể tạo nhóm có thể tham gia tự do và cần được chủ nhóm xem xét. Tên nhóm mô tả các thành viên và có thể được tìm kiếm và duyệt trên nền tảng. `",
        },
        '3': {
            name: "nhóm thảo luận",
            code: "3",
            desc: "`Nhóm thảo luận: bất kỳ người dùng nào cũng có thể tạo người tạo và thành viên có thể mời bạn bè của họ tham gia tên nhóm có thể tìm kiếm và duyệt các thành viên trên nền tảng. `",
        },
        '4': {
            name: "nhóm riêng",
            code: "4",
            desc: "`Nhóm riêng tư: Bất kỳ người dùng nào cũng có thể tạo tên nhóm để mô tả các thành viên của nhóm và không thể tìm kiếm và duyệt trên nền tảng. `",
        }
    },
    newPwd: "Vui lòng nhập mật khẩu đăng nhập mới",
    repNewPwd: "Vui lòng nhập lại mật khẩu mới",
    // 2021年3月4号
    meetingTips: "Vui lòng tải xuống ứng dụng melinked trước hoặc nâng cấp ứng dụng melinked lên phiên bản mới nhất",
    // mechat协议
    mechat: {
        title: "Thỏa thuận người dùng trò chuyện với tôi",
        text1: "Mechat là một sản phẩm Q&amp;A đồng hành với trò chuyện AI do Maes Consulting cung cấp. Khi người dùng đồng ý với thỏa thuận người dùng này, thì được coi là đã đồng ý với toàn bộ nội dung của thỏa thuận người dùng này.",
        header1: "một. Cách chúng tôi chia sẻ và tiết lộ thông tin cá nhân của người dùng với bên thứ ba",
        text2: "Trong các trường hợp sau, ứng dụng này sẽ tiết lộ toàn bộ hoặc một phần thông tin cá nhân của người dùng theo mong muốn cá nhân của người dùng hoặc theo quy định của pháp luật:",
        text3: "1. Tiết lộ cho bên thứ ba với sự đồng ý trước của người dùng;",
        text4: "2. Để cung cấp các sản phẩm và dịch vụ mà người dùng yêu cầu, cần chia sẻ thông tin cá nhân của người dùng với bên thứ ba;",
        text5: "3. Theo các quy định có liên quan của pháp luật, hoặc yêu cầu của các cơ quan hành chính hoặc tư pháp, tiết lộ cho bên thứ ba hoặc các cơ quan hành chính hoặc tư pháp;",
        text6: "4. Nếu người dùng vi phạm luật pháp và quy định có liên quan của Trung Quốc hoặc thỏa thuận dịch vụ ứng dụng này hoặc các quy tắc liên quan, nó cần được tiết lộ cho bên thứ ba;",
        text7: "5. Nếu người dùng là người khiếu nại đủ điều kiện về sở hữu trí tuệ và đã nộp đơn khiếu nại, anh ta sẽ tiết lộ cho người bị khiếu nại theo yêu cầu của người bị khiếu nại để cả hai bên có thể xử lý các tranh chấp quyền có thể xảy ra;",
        text8: "6. Các tiết lộ khác mà ứng dụng này cho là phù hợp theo luật pháp, quy định hoặc chính sách của trang web.",
        header2: "2. Lưu trữ và trao đổi thông tin",
        text9: "Thông tin và dữ liệu về người dùng do MeChat thu thập sẽ được lưu trữ trên máy chủ của MeChat và (hoặc) các công ty liên kết của nó. Chỉ những thông tin đã gửi và nhận sẽ được tải lên máy chủ trong quá trình trò chuyện với Tôi, thông tin này sẽ được sử dụng để trả lời mô hình AI.",
        header3: "3. Liên quan đến sở hữu trí tuệ",
        text10: "Chúng tôi không đưa ra bất kỳ đảm bảo nào về tính hợp pháp, hợp pháp, toàn vẹn hoặc chất lượng của nội dung do người dùng tải lên hoặc xuất bản. Người dùng sẽ chịu rủi ro phát sinh từ việc sử dụng hoặc dựa vào nội dung hoặc tài nguyên do phần mềm truyền tải. Trong mọi trường hợp, chúng tôi sẽ not sẽ không chịu trách nhiệm cho bất kỳ tổn thất hoặc thiệt hại có thể hoặc thực tế nào phát sinh từ rủi ro đó.",
        header4: "4. Trách nhiệm pháp lý và tuyên bố miễn trừ trách nhiệm",
        text11: "Trong mọi trường hợp, chúng tôi và các đối tác của chúng tôi sẽ không chịu trách nhiệm pháp lý đối với bất kỳ thiệt hại và rủi ro trực tiếp, gián tiếp, ngẫu nhiên, đặc biệt và sau đó nào phát sinh từ việc người dùng sử dụng hoặc không thể sử dụng Me Chat. Nếu hoạt động bình thường của dịch vụ bị ảnh hưởng bởi các sự kiện bất khả kháng như lỗi kỹ thuật, chúng tôi và các đối tác cam kết sẽ phối hợp với các đơn vị liên quan trong thời gian sớm nhất để xử lý, sửa chữa kịp thời và không chịu trách nhiệm pháp lý.",
        text12: "Thông tin mà mô hình AI gửi đến là do máy tính tạo ra và có thể là hư cấu, người dùng được yêu cầu phân biệt đúng sai. Khi người dùng sử dụng ứng dụng này để tư vấn về y tế, pháp lý và các vấn đề liên quan khác, ứng dụng này chỉ cung cấp tư vấn y tế và tư vấn pháp lý chứ không đại diện cho các dịch vụ y tế, dịch vụ pháp lý hoặc điều trị. Người dùng phải tham khảo ý kiến bác sĩ và pháp nhân có liên quan trước khi sử dụng ứng dụng này và chịu trách nhiệm cuối cùng về các quyết định y tế và pháp lý. Thông tin và dịch vụ trong ứng dụng này chỉ mang tính chất tham khảo, không cấu thành dịch vụ pháp lý hay giải pháp y tế cho các dịch vụ y tế và chúng tôi không chịu bất kỳ trách nhiệm nào phát sinh từ việc sử dụng ứng dụng này.",
    },
    originalMail: "Tin nhắn gốc",
    emailRecipient: "người nhận",
    emailFrom: "người gửi",
    CcTo: "CC",
};
