<template>
  <div id="app" ref="app">
    <router-view> </router-view>
  </div>
</template>
<script>
export default {
  name: "app",
  components: {},
  watch: {},
  async mounted() {
    await this.$nextTick();
    this.$refs.app.scrollTop = "0px";
  },
};
</script>
<style lang="stylus">
@import '~assets/newFont/iconfont.css';
@font-face {
  font-family: 'iconfont_Me';
  src: url('./assets/newFont/iconfont.eot');
  src: url('./assets/newFont/iconfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/newFont/iconfont.woff2') format('woff2'),
    url('./assets/newFont/iconfont.woff') format('woff'),
    url('./assets/newFont/iconfont.ttf') format('truetype'),
    url('./assets/newFont/iconfont.svg#iconfont_Me') format('svg');
}
.publish-btn{
  width:100%;
  height:30px;
  margin:.9rem auto;
  border-bottom:1px solid #e5e5e5;
  padding:.9rem 0;
  background:#ffffff;
  display:flex;
  align-items:center;
  justify-content:center;
  i{
    font-size:30px;
    color:#33cc66;
    font-weight:bold
  }
}
.joinGroup__title {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 0 0.9rem;
  margin-bottom: 0.5rem;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.empty {
  text-align: center;
}

#app {
  font-family:'PingFang SC','Microsoft YaHei','微软雅黑','Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: transparent;
  width 100vw;
  height 100vh
  overflow-y scroll

  .page-wap {
    padding: 54px 0;
    display: flex;
    flex-flow: column;
  }
}

.van-loading {
  height: 85vh;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  padding-top: 8rem;
  justify-content: center;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
