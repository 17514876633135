// 孟加拉语
import bn from "./bn";
// 德语
import de from "./de";
// 英语
import en from "./en";
// 西班牙语
import es from "./es";
// 法语
import fr from "./fr";
// 印地语
import hi from "./hi";
// 意大利语
import it from "./it";
// 日语
import ja from "./ja";
// 韩语
import ko from "./ko";
// 罗马尼亚语
import ro from "./ro";
// 俄语
import ru from "./ru";
// 泰语
import th from "./th";
// 乌尔都语
import ur from "./ur";
// 中文
import zhCN from "./zh-CN";
//越南语
import vi from "./vi"
//波斯语
import fa from "./fa"
//阿拉伯语
import ar from "./ar"
//荷兰语
import nl from "./nl"
//缅甸语
import my from "./my"
//印尼语
import id from "./id"
//马来语 
import ms from "./ms"
//葡萄牙语
import pt from "./pt"
//土耳其语
import tr from "./tr"
//波兰语
import pl from "./pl"
//希腊语
import el from "./el"
//尼泊尔语
import ne from "./ne"
//瑞典语
import sv from "./sv"
//捷克语
import cs from "./cs"
//匈牙利语
import hu from "./hu"
//乌兹别克语
import uz from "./uz"

const lang = {
  "zh-CN": zhCN,
  en,
  fr,
  es,
  "ru-RU": ru,
  hi,
  ur,
  ja,
  ko,
  th,
  de,
  it,
  ro,
  bn,
  vi,
  fa,
  ar,
  nl,
  my,
  id,
  ms,
  pt,
  tr,
  pl,
  el,
  ne,
  sv,
  cs,
  hu,
  uz
};
const langNames = {
  "zh-CN": "中文",
  en: "English",
  fr: "Français",
  es: "Español",
  "ru-RU": "Pусский",
  hi: "हिंदी",
  ur: "اردو زبان",
  ja: "日本語",
  ko: "한국어",
  th: "ไทย",
  de: "Deutsch",
  it: "Italiano",
  ro: "România",
  bn: "বাংলা",
  vi: "Tiếng Việt",
  fa: "فارسی",
  ar: "عربي",
  nl: "Nederlands",
  my: "မြန်မာ",
  id: "bahasa Indonesia",
  ms: "Melayu",
  pt: "português",
  tr: "Türk",
  pl: "Polski",
  el: "Ελληνικά",
  ne: "नेपाली",
  sv: "svenska",
  cs: "čeština",
  hu: "Magyar",
  uz: "o'zbek"
};
const encodeMap = {
  "zh-CN": "zh",
  "ru-RU": "ru"
};
//合并 element-ui 语言包
Object.keys(lang).forEach((name, key) => {
  lang[name].langName = langNames[name];
  lang[name].encode = encodeMap[name] || name;
  lang[name].code = (key + 1).toString();
  // !lang[name].el &&
  //   (lang[name].el = {
  //     ...require(`element-ui/lib/locale/lang/${name}`).default.el
  //   });
});

export default lang;