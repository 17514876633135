export default {
    langs: {
        "zh-CN": "κινέζικα",
        en: "Αγγλικά",
        fr: "γαλλική γλώσσα",
        es: "Ισπανικά",
        "ru-RU": "Ρωσική",
        hi: "Χίντι",
        ur: "Ουρντού",
        ja: "Ιαπωνικά",
        ko: "κορεάτης",
        th: "Ταϊλανδός",
        de: "Γερμανός",
        it: "ιταλικός",
    },
    responFail: "Απέτυχε να φορτώσει",
    responRetry: "κάντε κλικ στην επανάληψη",
    responEmpty: "Χωρίς δεδομένα",
    resendMsg: "Αποτυχία αποστολής μηνύματος, κάντε κλικ για να προσπαθήσετε ξανά",
    sendImage: "να στείλετε φωτογραφίες",
    send: "στείλετε",
    //fastSend: "Πατήστε {key} για γρήγορη αποστολή",
    //sendMsg: "Στέλνω ένα μήνυμα",
    //clickDownload: "κάντε κλικ για λήψη",
    timeAgo: {
        0: "μόλις",
        1: "πριν από {n} λεπτά",
        2: "πριν από {n} ώρες",
        3: "εχθές",
    },
    copyGroup: "Αντιγράψτε μέλη σε νέα ομάδα συζήτησης",
    quitGroup: "αποχωρήστε από την ομάδα συζήτησης",
    createGroup: "δημιουργία ομάδας συζήτησης",
    inviteFriend: "προσκαλέσετε τους φίλους",
    // web site
    login: "Σύνδεση",
    loginerror: "Η σύνδεση απέτυχε!",
    register: "κανω ΕΓΓΡΑΦΗ",
    keepOn: "Μείνε συνδεδεμένος",
    forgetPwd: "ξεχάσετε τον κωδικό πρόσβασης;",
    logoutTip: "ΟΚ για να αποσυνδεθείτε",
    resetPwd: "Επαναφορά κωδικού πρόσβασης σύνδεσης",
    all: "όλα",
    // 注册
    phoneErr: "Ο αριθμός τηλεφώνου δεν μπορεί να είναι κενός",
    phoneformatErr: "Η μορφή του αριθμού τηλεφώνου είναι εσφαλμένη",
    needNumber: "Εισαγάγετε μια αριθμητική τιμή",
    registerErr: "η εγγραφή απέτυχε",
    // publish
    publish: "ελευθέρωση",
    group: "ομάδα",
    school: "Σχολείο",
    profess: "μείζων",
    degree: "Πτυχίο Επιστημών",
    time: "χρόνος",
    desc: "περιγράφω",
    mustSchool: "το σχολείο δεν μπορεί να είναι άδειο",
    mustCompany: "η εταιρεία δεν μπορεί να είναι άδεια",
    company: "επιχείρηση",
    workexp: "προϋπηρεσία",
    education: "Εκπαιδευτική εμπειρία",
    companyName: "Εταιρία",
    job: "Θέση",
    name: "Ονομα",
    address: "διεύθυνση",
    called: "όνομα",
    title: "τίτλος",
    price: "τιμή",
    tags: "Επιγραφή",
    emoji: "έκφραση",
    sendFile: "Αποστολή αρχείου",
    transferAccount: "ΜΕΤΑΦΟΡΑ",
    image: "εικόνα",
    video: "βίντεο",
    voice: "φωνή",
    file: "έγγραφο",
    permissions: "άδειες",
    groupRoule: {
        "0": "Δημόσια ομάδα: Όλοι οι χρήστες είναι ελεύθεροι να εγγραφούν",
        "1": "Ομάδα μελών: Αφού ο χρήστης υποβάλει την αίτηση, μπορεί να συμμετάσχει αφού εγκριθεί από τον διαχειριστή της ομάδας",
        "2": "Ιδιωτικές Ομάδες: Δεν είναι ορατό στην ομαδική αναζήτηση και μόνο η ομάδα μπορεί να προσθέσει μέλη",
    },
    man: "αρσενικός",
    woman: "θηλυκός",
    mustBeTM: "Απαιτείται τίτλος/όνομα",
    mustBeTag: "Συμπληρώστε τουλάχιστον μία ετικέτα",
    mustBeCate: "Επιλέξτε τουλάχιστον μία κατηγορία",
    contentTip: "Συμπληρώστε τα στοιχεία εδώ...",
    uploadSuccess: "Μεταφορτώθηκε με επιτυχία!",
    publishError: "Η δημοσίευση απέτυχε!",
    // footer
    contactus: "επικοινωνήστε μαζί μας",
    privacy: "Πολιτική Απορρήτου",
    companyright: "Όλα τα δικαιώματα διατηρούνται",
    // 其他
    sys_error: "σφάλμα συστήματος!",
    replayEmpty: "Το περιεχόμενο του σχολίου δεν μπορεί να είναι κενό!",
    replay: "απάντηση",
    replayErr: "το σχόλιο απέτυχε",
    delSuccess: "διαγράφηκε επιτυχώς",
    defFail: "απέτυχε να διαγραφεί",
    refresh: "ολοκληρώθηκε η ενημέρωση",
    updateSuccess: "Η τροποποίηση είναι επιτυχής, συνδεθείτε ξανά",
    old: "παλαιός",
    theNew: "νέος",
    changeFail: "Η τροποποίηση του κωδικού πρόσβασης απέτυχε",
    pwdlength: "μήκος κωδικού πρόσβασης",
    getverchatCode: "λάβετε κωδικό επαλήθευσης",
    nopeoplezan: "Σε κανέναν δεν αρέσει ακόμα, γίνε ο πρώτος που θα αρέσει!",
    pwdResetSuccess: "Η επαναφορά του κωδικού πρόσβασης ολοκληρώθηκε",
    email_required: "Το e-mail δεν μπορεί να είναι κενό",
    phone_required: "Ο αριθμός κινητού τηλεφώνου δεν μπορεί να είναι κενός",
    addfriend: "Η αίτηση για προσθήκη φίλων στάλθηκε με επιτυχία, περιμένοντας να συμφωνήσει το άλλο μέρος",
    Draft: "προσχέδιο",
    breakPwd: "Ανάκτηση κωδικού",
    "Request to view history messages": "Κάντε αίτηση για να δείτε ιστορικές ειδήσεις",
    "Wait for group manager to agree": "Περιμένετε να συμφωνήσει ο ιδιοκτήτης της ομάδας",
    'Please enter a search term': "Εισαγάγετε λέξεις-κλειδιά αναζήτησης",
    done: "Σίγουρος",
    cancle: "Ματαίωση",
    groupType: {
        '1': {
            name: "δημόσια ομάδα",
            code: "1",
            desc: "`Ανοικτή ομάδα: Οποιοσδήποτε χρήστης μπορεί να δημιουργήσει μια ομάδα στην οποία είναι ελεύθερη η συμμετοχή χωρίς έλεγχο. Το όνομα της ομάδας περιγράφει τα μέλη και μπορεί να αναζητηθεί και να περιηγηθεί στην πλατφόρμα. `",
        },
        '2': {
            name: "ομάδα μελών",
            code: "2",
            desc: "`Ομάδα μελών: Οποιοσδήποτε χρήστης μπορεί να δημιουργήσει μια ομάδα που μπορεί να εγγραφεί ελεύθερα και πρέπει να ελεγχθεί από τον κάτοχο της ομάδας. Το όνομα της ομάδας περιγράφει τα μέλη και μπορεί να αναζητηθεί και να περιηγηθεί στην πλατφόρμα. `",
        },
        '3': {
            name: "ομάδα συζήτησης",
            code: "3",
            desc: "`Ομάδα συζήτησης: οποιοσδήποτε χρήστης μπορεί να δημιουργήσει δημιουργούς και τα μέλη μπορούν να προσκαλέσουν τους φίλους τους να συμμετάσχουν στο όνομα της ομάδας τα μέλη μπορούν να αναζητηθούν και να περιηγηθούν στην πλατφόρμα. `",
        },
        '4': {
            name: "ιδιωτική ομάδα",
            code: "4",
            desc: "`Ιδιωτική ομάδα: Οποιοσδήποτε χρήστης μπορεί να δημιουργήσει ένα όνομα ομάδας για να περιγράψει τα μέλη της ομάδας και δεν είναι δυνατή η αναζήτηση και η περιήγησή του στην πλατφόρμα. `",
        }
    },
    newPwd: "Εισαγάγετε έναν νέο κωδικό πρόσβασης σύνδεσης",
    repNewPwd: "Εισαγάγετε ξανά τον νέο κωδικό πρόσβασης",
    // 2021年3月4号
    meetingTips: "Κατεβάστε πρώτα την εφαρμογή melinked ή αναβαθμίστε την εφαρμογή melinked στην πιο πρόσφατη έκδοση",
    // mechat协议
    mechat: {
        title: "Συμφωνία χρήστη συνομιλίας μου",
        text1: "Το Mechat είναι ένα συνοδευτικό προϊόν Q&amp;A συνομιλίας AI που παρέχεται από τη Maes Consulting. Όταν ο χρήστης συμφωνεί με αυτήν τη συμφωνία χρήστη, θεωρείται ότι έχει συμφωνήσει με ολόκληρο το περιεχόμενο αυτής της συμφωνίας χρήστη.",
        header1: "ένας. Πώς μοιραζόμαστε και αποκαλύπτουμε τα προσωπικά στοιχεία των χρηστών με τρίτα μέρη",
        text2: "Υπό τις ακόλουθες συνθήκες, αυτή η εφαρμογή θα αποκαλύψει τα προσωπικά στοιχεία του χρήστη εν όλω ή εν μέρει σύμφωνα με τις προσωπικές επιθυμίες του χρήστη ή τους νομικούς κανονισμούς:",
        text3: "1. Αποκαλύπτεται σε τρίτους με την προηγούμενη συγκατάθεση του χρήστη.",
        text4: "2. Για την παροχή των προϊόντων και των υπηρεσιών που ζητά ο χρήστης, είναι απαραίτητο να κοινοποιηθούν τα προσωπικά στοιχεία του χρήστη με τρίτους.",
        text5: "3. Σύμφωνα με τις σχετικές διατάξεις του νόμου ή τις απαιτήσεις των διοικητικών ή δικαστικών φορέων, γνωστοποιήστε σε τρίτους ή διοικητικές ή δικαστικές υπηρεσίες.",
        text6: "4. Εάν ο χρήστης παραβιάζει τους σχετικούς κινεζικούς νόμους και κανονισμούς ή αυτή τη συμφωνία παροχής υπηρεσιών εφαρμογής ή σχετικούς κανόνες, πρέπει να γνωστοποιηθεί σε τρίτο μέρος.",
        text7: "5. Εάν ο χρήστης είναι πιστοποιημένος καταγγέλλων πνευματικής ιδιοκτησίας και έχει υποβάλει καταγγελία, θα το αποκαλύψει στον εναγόμενο κατόπιν αιτήματος του εναγόμενου, ώστε και τα δύο μέρη να μπορούν να χειριστούν πιθανές διαφορές δικαιωμάτων.",
        text8: "6. Άλλες γνωστοποιήσεις που αυτή η εφαρμογή κρίνει κατάλληλες σύμφωνα με τους νόμους, τους κανονισμούς ή τις πολιτικές του ιστότοπου.",
        header2: "2. Αποθήκευση και ανταλλαγή πληροφοριών",
        text9: "Οι πληροφορίες και τα δεδομένα σχετικά με τους χρήστες που συλλέγονται από το MeChat θα αποθηκεύονται στους διακομιστές του MeChat και (ή) των συνδεδεμένων με αυτό εταιρειών. Μόνο οι αποσταλμένες και ληφθείσες πληροφορίες θα μεταφορτωθούν στον διακομιστή κατά τη διαδικασία συνομιλίας μαζί μου, οι οποίες θα χρησιμοποιηθούν για την απάντηση του μοντέλου AI.",
        header3: "3. Σχετική πνευματική ιδιοκτησία",
        text10: "Δεν παρέχουμε καμία εγγύηση για τη νομιμότητα, τη νομιμότητα, την ακεραιότητα ή την ποιότητα του περιεχομένου που ανεβάζουν ή δημοσιεύουν οι χρήστες. Οι χρήστες αναλαμβάνουν τους κινδύνους που προκύπτουν από τη χρήση ή τη χρήση του περιεχομένου ή των πόρων που μεταδίδονται από το λογισμικό. Σε κάθε περίπτωση, θα δεν φέρει ευθύνη για οποιαδήποτε πιθανή ή πραγματική απώλεια ή ζημία που προκύπτει από τέτοιο κίνδυνο.",
        header4: "4. Νομική ευθύνη και αποποίηση ευθύνης",
        text11: "Σε κάθε περίπτωση, εμείς και οι συνεργάτες μας δεν φέρουμε καμία ευθύνη για άμεσες, έμμεσες, τυχαίες, ειδικές και επακόλουθες ζημίες και κινδύνους που προκύπτουν από τη χρήση ή την αδυναμία των χρηστών να χρησιμοποιήσουν το Me Chat. Εάν η κανονική λειτουργία της υπηρεσίας επηρεαστεί από γεγονότα ανωτέρας βίας, όπως τεχνικές βλάβες, εμείς και οι συνεργάτες μας υποσχόμαστε να συνεργαστούμε με τις αρμόδιες μονάδες το συντομότερο δυνατό για την έγκαιρη αντιμετώπιση και επισκευή τους.",
        text12: "Οι πληροφορίες που αποστέλλονται από το μοντέλο τεχνητής νοημοσύνης δημιουργούνται από τον υπολογιστή και μπορεί να είναι εικονικές. Οι χρήστες καλούνται να διακρίνουν το σωστό από το λάθος. Όταν οι χρήστες χρησιμοποιούν αυτήν την εφαρμογή για να συμβουλευτούν ιατρικά, νομικά και άλλα συναφή ζητήματα, αυτή η εφαρμογή παρέχει μόνο ιατρικές συμβουλές και νομικές συμβουλές και δεν αντιπροσωπεύει ιατρικές υπηρεσίες, νομικές υπηρεσίες ή θεραπεία. Οι χρήστες πρέπει να συμβουλεύονται γιατρούς και σχετικά νομικά πρόσωπα πριν από τη χρήση αυτής της εφαρμογής και είναι τελικά υπεύθυνοι για ιατρικές και νομικές αποφάσεις. Οι πληροφορίες και οι υπηρεσίες σε αυτήν την εφαρμογή είναι μόνο για αναφορά και δεν αποτελούν νομικές υπηρεσίες ή ιατρικές λύσεις για ιατρικές υπηρεσίες και δεν αναλαμβάνουμε καμία ευθύνη που προκύπτει από τη χρήση αυτής της εφαρμογής.",
    },
    originalMail: "Το αρχικό μήνυμα",
    emailRecipient: "παραλήπτης",
    emailFrom: "αποστολέας",
    CcTo: "CC",
};
