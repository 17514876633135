<template>
  <div class="item-header" @click="handlerToUserPage(item.id)">
    <Avatar :src="item.avatar" :round="true" width="40px" height="40px" />
    <div class="item-header__info">
      <div class="base">
        <span class="name" v-html="item.name"></span>
        <i class="time" v-html="item.time || item.publishTime"></i>
      </div>
      <slot name="price"></slot>
    </div>
  </div>
</template>
<script>
import { parseTime } from "@/utils";
import Avatar from "@/components/common/avatar";
import { Base64 } from "js-base64";
export default {
  name: "author",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    Avatar
  },
  created(){
    // alert(this.item.time,'/time');
  },
  methods: {
    parseTime,
    handlerToUserPage() {
      this.$router.push({ name: "user", params: { id: "pai_" + Base64.encode(this.item.id) } });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

+b(item-header) {
  display: flex;
  flex-flow: row;
  align-items: center;

  +e(info) {
    flex: 1;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-left: 0.35rem;

    .base {
      display: flex;
      flex-flow: column;
      align-items: flex-start;

      .name {
        font-size: 15px;
        margin-bottom: 0.25rem;
        word-spacing: 0;
        letter-spacing: 0;
      }

      .time {
        font-size: 12px;
        color: #999999;
        font-style: normal;
      }
    }

    +m(price) {
      color: red;
      font-size: 15px;
    }
  }
}
</style>